import * as actionTypes from "./actionsTypes";
import axios from "axios";
import {
  verify as loginApiService,
  logout as logoutApi,
} from "../../Services/API/IndividualApis/User/";
import Cookie from "js-cookie";
import { user } from "Services/API/IndividualApis/Users";
import { IMeResponse, Roles } from "Interfaces/Api/User";
import { apiPolicy } from "Services/API/IndividualApis/Policy";

console.log("localhost", "apiBaseUrl");

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

// export async function refreshAuthToken(token, refreshToken) {
//   let data = {
//     token: token,
//     refreshToken: refreshToken,
//   };

//   try {
//     const response = await axios.post(getAppUrl("user/refresh"), data);
//     return response.data;
//   } catch (err) {
//     console.log(err);
//   }
// }

// export const saveMyDetails = () => {
//   return async (dispatch) => {
//     const me = await getMe();
//     dispatch({ type: actionTypes.SAVE_MY_DETAILS, me: me.result });
//   };
// };

// export const loginFromGoogle = (tokenObj) => {
//   return async (dispatch) => {
//     axios
//       .post(getAppUrl("api/v1/user/google"), { id_token: tokenObj.id_token })
//       .then((response: any) => {
//         if (response && response.data) {
//           const user = response.data.result.user;
//           const auth = response.data.result.auth;
//           dispatch(authSuccess(user, auth));
//         }
//       })
//       .catch((error) => {
//       });
//   };
// };

export const authSuccess = (user: IMeResponse) => {
  return async (dispatch: any) => {
    if (user.role === Roles.ADMIN) {
      const policyResponse = await apiPolicy.getSingle(user.policy as string);
      user.policy = policyResponse;
    }
    dispatch({
      type: actionTypes.AUTH_SUCCESS,
      user: user,
    });
  };
};

export const logout = () => {
  return async (dispatch) => {
    await logoutApi.post({});
    dispatch({
      type: actionTypes.LOGOUT,
    });
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const loginAction = (phoneNumber, otp) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.HIDEERROR });

    dispatch(authStart());
    try {
      const response: any = await loginApiService.post({
        phoneNumber,
        code: otp,
      });

      dispatch(authSuccess(response.user));
    } catch (err) {
      console.log(err.response.data.errors);
      dispatch({ type: actionTypes.SHOWERROR });

      dispatch(authFail(err.response.data.errors));
    }
  };
};

export const checkAuthStatus = () => {
  return async (dispatch) => {
    const userId = Cookie.get("userId");

    try {
      if (userId) {
        const response: any = await user.getMYDetails();
        dispatch(authSuccess(response));
      }
    } catch (err) {
      console.log(err.response.data.errors);
      dispatch(authFail(err.response.data.errors));
    }
    // if (userId) {
    //   dispatch(authSuccess());
    // } else {
    //   dispatch(authFail(""));
    // }
  };
};
