import React, { useEffect, useRef, useState } from "react";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { products } from "Services/API/IndividualApis/Product";

import General from "../CommonComponents/General";
import ImagesComponent from "../CommonComponents/ImagesComponent";
import ShippingComponent from "../CommonComponents/ShippingComponent";
import SEOComponent from "../CommonComponents/SEOComponent";
import Properties from "../CommonComponents/Properties";
import Inventory from "../CommonComponents/Inventory";
import { IProductProperties } from "Interfaces/Pages/Products";
import { IProduct } from "Interfaces/Api/Product";
import { ICategory } from "Interfaces/Api/Category";
import { Alert } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%", position: "relative" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const properties = {
  name: "",
  price: "",
  availableQuantity: "",
  // Initial value default price
  maxQuantityAllowedInSignleCart: "100",
  subTitle: "",
  discount: "",
  categories: [""],
  whatIsIt: "<p><br></p>",
  whatsInIt: "",
  howToUse: "",
  image: [],
  media: [],
  length: "",
  weight: "",
  breadth: "",
  height: "",
  title: "",
  description: "",
  keywords: [],
  sku: "",
  properties: {},
};
interface GeneralProps {
  allCategories: ICategory[];
  editMode: boolean;
  editItem: IProduct;
  onCancel: Function;
  onClose: Function;
}

const SimpleComponent = ({
  allCategories,
  editItem,
  editMode,
  onCancel,
  onClose,
}) => {
  const [value, setValue] = useState<number>(0);
  const [error, setError] = useState({
    name: true,
    price: true,
    quantity: true,
    category: true,
    whatIsIt: true,
    image: true,
    sku: true,
    length: true,
    breadth: true,
    height: true,
    weight: true,
  });
  const [simpleProductProperties, setSimpleProductProperties] =
    useState<Record<string, any>>(properties);
  const [isInvalidCategory, setIsInvalidCategory] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [disableImageTab, setDisableImageTab] = useState<boolean>(true);
  const [disableInventortTab, setDisableInventoryTab] = useState<boolean>(true);
  const [disableSEO, setDisableSEO] = useState<boolean>(true);
  const [disableShippingTab, setDisableShippingTab] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidPriceDiscount, setInvalidPriceDiscount] =
    useState<boolean>(false);
  useEffect(() => {
    if (editMode) {
      const obj: IProductProperties = {
        name: editItem.name,
        price: editItem.price,
        availableQuantity: editItem.availableQuantity,
        maxQuantityAllowedInSignleCart: editItem.maxQuantityAllowedInSignleCart,
        discount: editItem.discount,
        categories: editItem.categories,
        whatIsIt: editItem.cms?.whatIsIt,
        whatsInIt: editItem.cms?.whatsInIt,
        howToUse: editItem.cms?.howToUse,
        subTitle: editItem.cms?.subTitle,
        image: editItem?.image,
        media: editItem.media,
        length: editItem.size?.length,
        weight: editItem.size?.weight,
        breadth: editItem.size?.breadth,
        height: editItem.size?.height,
        title: editItem?.metaData?.title,
        description: editItem?.metaData?.description,
        keywords: editItem?.metaData?.keywords,
        sku: editItem?.sku,
        properties: editItem?.properties,
        faqs: editItem.cms?.faqs,
        benefits: editItem.cms?.benefits,
        whatExpertHasToSay: editItem.cms?.whatExpertHasToSay,
      };
      setDisable(false);
      setDisableImageTab(false);
      setDisableInventoryTab(false);
      setDisableSEO(false);
      setDisableShippingTab(false);
      setSimpleProductProperties(obj);
    }
  }, [editMode, editItem]);

  // const inputEl = useRef(null);
  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  // function getProgress() {
  //   var topPos = document.documentElement.scrollTop;
  // }
  // useEffect(() => {
  //   document.addEventListener("scroll", getProgress);
  // }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnBlur = async (obj) => {
    setInvalidPriceDiscount(false);
    setSimpleProductProperties((prevVal) => ({ ...prevVal, ...obj }));
    const newSimpleProperties = { ...simpleProductProperties, ...obj };
    handleTabs(newSimpleProperties);
  };
  const handleTabs = (newSimpleProperties) => {
    if (
      newSimpleProperties.name != "" &&
      newSimpleProperties.price != "" &&
      newSimpleProperties.categories[0] != "" &&
      newSimpleProperties.whatIsIt != "<p><br></p>"
    ) {
      setDisableImageTab(false);
    } else {
      setDisableImageTab(true);
    }
    if (newSimpleProperties.image.file) {
      setDisableShippingTab(false);
    }
    if (
      newSimpleProperties.length !== "" &&
      newSimpleProperties.weight !== "" &&
      newSimpleProperties.height !== "" &&
      newSimpleProperties.breadth !== ""
    ) {
      setDisableSEO(false);
      setDisableInventoryTab(false);
    } else {
      setDisableSEO(true);
      setDisableInventoryTab(true);
    }
    if (
      newSimpleProperties.availableQuantity != "" &&
      newSimpleProperties.sku != ""
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };
  const handleGenralTabValues = () => {
    setDisableImageTab(false);
    setValue(1);
  };

  const handleSEOProperties = () => {
    setValue(4);
  };
  const handleShippingProperties = () => {
    setValue(3);
    setDisableSEO(false);
    setDisableInventoryTab(false);
  };
  const handleInventoryProperties = () => {
    setDisable(false);
    setValue(5);
  };
  const handleImagesProperties = (obj) => {
    setSimpleProductProperties((prevVal) => ({ ...prevVal, ...obj }));
    setDisableShippingTab(false);
    setValue(2);
  };

  const handleSaveProduct = (obj) => {
    setSimpleProductProperties((prevVal) => ({ ...prevVal, ...obj }));
    const object = {
      submit: "true",
      name: `${simpleProductProperties.name}(${Object.values(obj.properties)})`,
      price: simpleProductProperties.price,
      availableQuantity: simpleProductProperties.availableQuantity,
      maxQuantityAllowedInSignleCart:
        simpleProductProperties.maxQuantityAllowedInSignleCart,
      media: simpleProductProperties.media,
      image: {
        file: simpleProductProperties.image.file,
        fileName: simpleProductProperties.image.fileName,
      },
      categories: simpleProductProperties.categories,
      sku: simpleProductProperties.sku,
      discount: simpleProductProperties.discount,
      properties: obj.properties,
      type: "Simple",
      cms: {
        howToUse: simpleProductProperties.howToUse,
        whatsInIt: simpleProductProperties.whatsInIt,
        subTitle: simpleProductProperties.subTitle,
        whatIsIt: simpleProductProperties.whatIsIt,
        faqs: simpleProductProperties.faqs,
        benefits: simpleProductProperties.benefits,
        whatExpertHasToSay: simpleProductProperties.whatExpertHasToSay,
      },
      size: {
        length: simpleProductProperties.length,
        breadth: simpleProductProperties.breadth,
        height: simpleProductProperties.height,
        weight: simpleProductProperties.weight,
      },
      metaData: {
        title: simpleProductProperties.title,
        description: simpleProductProperties.description,
        keywords: simpleProductProperties.keywords,
      },
    };
    if (simpleProductProperties.categories[0] == "") {
      setIsInvalidCategory(true);
    } else {
      setLoading(true);
      postProduct(object);
    }
  };

  const postProduct = async (data) => {
    try {
      const response = await products.post(data);
      setLoading(false);
      if (response) {
        onClose();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleEditProduct = (obj) => {
    setLoading(true);
    const id = editItem._id;

    const object = {
      submit: "true",
      name: simpleProductProperties.name,
      // name: `${simpleProductProperties.name}${obj.properties}`,
      price: simpleProductProperties.price,
      availableQuantity: simpleProductProperties.availableQuantity,
      discount: simpleProductProperties.discount,
      maxQuantityAllowedInSignleCart:
        simpleProductProperties.maxQuantityAllowedInSignleCart,
      sku: simpleProductProperties.sku,
      properties: obj.properties,
      cms: {
        howToUse: simpleProductProperties.howToUse,
        whatsInIt: simpleProductProperties.whatsInIt,
        subTitle: simpleProductProperties.subTitle,
        whatIsIt: simpleProductProperties.whatIsIt,
        faqs: simpleProductProperties.faqs,
        benefits: simpleProductProperties.benefits,
        whatExpertHasToSay: simpleProductProperties.whatExpertHasToSay,
      },
      size: {
        length: simpleProductProperties.length,
        breadth: simpleProductProperties.breadth,
        height: simpleProductProperties.height,
        weight: simpleProductProperties.weight,
      },
      metaData: {
        title: simpleProductProperties.title,
        description: simpleProductProperties.description,
        keywords: simpleProductProperties.keywords,
      },
    };
    putProduct(id, object);
  };
  const putProduct = async (id, object) => {
    try {
      // mandatory input fields
      if (
        object.name &&
        object.price &&
        object.availableQuantity &&
        object.cms.whatIsIt &&
        (!object.discount || parseInt(object.price) > parseInt(object.discount))
      ) {
        const response = await products.updateProductDetails(id, object);
        if (response) {
          onClose();
        }
      } else {
        console.log("can't update");
        setInvalidPriceDiscount(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      {isInvalidCategory && (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            position: "fixed",
            top: { sm: "2px", xs: "3.2rem" },
            right: "5px",
            zIndex: "10000",
            background: "red",
            textTransform: "capitalize",
            width: "20rem",
          }}
        >
          Category is required{" "}
        </Alert>
      )}
      {invalidPriceDiscount && (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            position: "fixed",
            top: { sm: "2px", xs: "3.2rem" },
            right: "5px",
            zIndex: "10000",
            background: "red",
            textTransform: "capitalize",
            width: "20rem",
          }}
        >
          Product Discount Price must be less than Product Price
        </Alert>
      )}
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          // ref={inputEl}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            label="General"
            {...a11yProps(0)}
            style={{ alignItems: "flex-end" }}
          />
          <Tab
            label="Images"
            {...a11yProps(1)}
            style={{ alignItems: "flex-end" }}
            disabled={disableImageTab}
          />
          <Tab
            label="Shipping"
            {...a11yProps(2)}
            style={{ alignItems: "flex-end" }}
            disabled={disableShippingTab}
          />
          <Tab
            label="SEO"
            {...a11yProps(3)}
            style={{ alignItems: "flex-end" }}
            disabled={disableSEO}
          />
          <Tab
            label="Inventory"
            {...a11yProps(4)}
            style={{ alignItems: "flex-end" }}
            disabled={disableInventortTab}
          />
          <Tab
            label="Properties"
            {...a11yProps(5)}
            disabled={disable}
            style={{ alignItems: "flex-end" }}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <General
            handleGenralTabValues={handleGenralTabValues}
            productProperties={simpleProductProperties}
            allCategories={allCategories}
            handleOnBlur={handleOnBlur}
            editItem={editItem}
            editMode={editMode}
            handleEditProduct={handleEditProduct}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ImagesComponent
            handleImagesProperties={handleImagesProperties}
            productProperties={simpleProductProperties}
            handleOnBlur={handleOnBlur}
            editItem={editItem}
            editMode={editMode}
            handleEditProduct={handleEditProduct}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ShippingComponent
            handleShippingProperties={handleShippingProperties}
            productProperties={simpleProductProperties}
            handleOnBlur={handleOnBlur}
            editItem={editItem}
            editMode={editMode}
            handleEditProduct={handleEditProduct}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SEOComponent
            handleSEOProperties={handleSEOProperties}
            productProperties={simpleProductProperties}
            handleOnBlur={handleOnBlur}
            editItem={editItem}
            editMode={editMode}
            handleEditProduct={handleEditProduct}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Inventory
            handleInventoryProperties={handleInventoryProperties}
            productProperties={simpleProductProperties}
            handleOnBlur={handleOnBlur}
            editItem={editItem}
            editMode={editMode}
            handleEditProduct={handleEditProduct}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Properties
            allCategories={allCategories}
            handleSaveSimpleProduct={handleSaveProduct}
            onCancel={onCancel}
            productProperties={simpleProductProperties}
            editItem={editItem}
            editMode={editMode}
            handleEditProduct={handleEditProduct}
            handleOnBlur={handleOnBlur}
            loading={loading}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default Interceptor(SimpleComponent);
