import { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomSelect from "components/CustomSelect/CustomSelect";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import { ICategory } from "Interfaces/Api/Category";
import Loader from "components/Loader";
import { FormHelperText } from "@mui/material";
import classes from "./Product.module.scss";

interface PropertiesProps {
  allCategories: ICategory[];
  editMode: boolean;
  onCancel: Function;
  handleSaveSimpleProduct: Function;
  productProperties: Record<string, any>;
  handleEditProduct: Function;
  loading: Boolean;
}

const Properties = ({
  allCategories,
  handleSaveSimpleProduct,
  productProperties,
  onCancel,
  editMode,
  handleEditProduct,
  loading,
}: PropertiesProps) => {
  const [allFilters, setAllFilters] = useState([]);
  const [selectFilters, setSelectFilters] = useState<any>([]);
  const [properties, setProperties] = useState({});
  const [propertyError, setPropertyError] = useState<boolean>(false);

  useEffect(() => {
    if (productProperties?.categories) {
      allCategories.map((item) => {
        if (item.category == productProperties?.categories) {
          setAllFilters(item.filters);
        }
      });
    }
  }, [productProperties?.category]);

  useEffect(() => {
    if (allFilters.length > 0 && editMode && productProperties?.properties) {
      {
        for (const [key, value] of Object.entries(
          productProperties?.properties
        )) {
          allFilters?.forEach((item) => {
            if (item.name == key) {
              const newSelectFilters = [...selectFilters];
              newSelectFilters[key] = value;
              setSelectFilters((preVal) => ({
                ...preVal,
                ...newSelectFilters,
              }));
            }
          });
        }
      }
    }
  }, [allFilters, editMode]);

  const handleFiltersdropDown = (event) => {
    let obj = {
      [event.target.name]: event.target.value,
    };

    setSelectFilters((preVal) => ({ ...preVal, ...obj }));
    setProperties((preVal) => ({ ...preVal, ...obj }));
  };
  const handleFiltersBlur = () => {
    setPropertyError(true);
  };

  const onSaveProduct = async () => {
    if (allFilters.length > 0) {
      if (Object.keys(properties).length > 0) {
        const obj = {
          properties: properties,
        };
        handleSaveSimpleProduct(obj);
      } else {
        setPropertyError(true);
      }
    } else {
      const obj = {
        properties: properties,
      };
      handleSaveSimpleProduct(obj);
    }
  };

  const onEditProduct = () => {
    const obj = {
      properties: properties,
    };
    handleEditProduct(obj);
  };

  const onCancelItem = () => {
    onCancel();
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          {allFilters.length == 0 ? (
            <div style={{ fontSize: "1.2rem", padding: "0.9375rem 20px" }}>
              Note:there are no filters added for{" "}
              <span style={{ fontWeight: "bold" }}>
                "{productProperties?.categories}"
              </span>{" "}
              category.
            </div>
          ) : (
            ""
          )}
          {/* <CardBody> */}
          <GridContainer style={{ padding: "0.9375rem 20px" }}>
            {allFilters &&
              allFilters.map((item, index) => {
                return (
                  <GridItem xs={12} sm={6} md={4} key={index}>
                    <CustomSelect
                      labelText={item.name}
                      // native
                      formControlProps={{
                        className: classes.formControl,
                      }}
                      id="age-native-required"
                      inputProps={{
                        value: selectFilters[item.name],
                        onChange: handleFiltersdropDown,
                        onBlur: handleFiltersBlur,
                        disabled: editMode ? true : false,
                        name: item.name,
                        native: true,
                      }}
                    >
                      <option value="/"> </option>
                      {item.options.map((option, index) => {
                        return (
                          <option value={option} key={index}>
                            {option}
                          </option>
                        );
                      })}
                    </CustomSelect>
                    {Object.keys(properties).length == 0 && propertyError ? (
                      <FormHelperText style={{ color: "red" }}>
                        {item.name} Required
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </GridItem>
                );
              })}
          </GridContainer>

          <GridContainer
            style={{
              position: "absolute",
              bottom: "15px",
              right: "0",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <Button color="gray" onClick={onCancelItem}>
                Cancel
              </Button>
            </div>
            <div style={{ marginRight: "1rem", position: "relative" }}>
              <Button
                color="success"
                onClick={editMode ? onEditProduct : onSaveProduct}
              >
                Submit
              </Button>

              {loading && <Loader />}
              {/* {loading && <div style={{ position: "absolute", top: "30%", right: "5px", opacity: "1" }}>{<CircularProgress size="20px" style={{ color: "#8D6D4D" }} />}</div>} */}
            </div>
          </GridContainer>

          {/* </CardBody> */}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Interceptor(Properties);
