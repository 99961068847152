import { taskI } from "../../../../Interfaces";
import { ApiCore } from "../../Core";

const url = "user";

export const apiTasks = new ApiCore<taskI>({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: true,
  url: url,
});

export const login = new ApiCore<taskI>({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: false,
  url: "user/admin/login",
});
export const logout = new ApiCore<taskI>({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: false,
  url: "user/logout",
});
export const refresh = new ApiCore<taskI>({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: false,
  url: "user/refresh",
});
export const verify = new ApiCore<taskI>({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: false,
  url: "user/verify?kind=login",
});
export const loginWithOtp = new ApiCore<taskI>({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: false,
  url: "user/login/sendOtp",
});
