import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Categories as categoriesApi } from "../../Services/API/IndividualApis/Category";
import Button from "components/CustomButtons/Button";
import { FormControl, MenuItem, Modal } from "@mui/material";
import CustomInput from "components/CustomInput/CustomInput";
import { attribute } from "Services/API/IndividualApis/Attribute";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import { useNavigate, useParams } from "react-router-dom";
import { ICategory } from "Interfaces/Api/Category";
import Loader from "components/Loader";

const AddOrEditAttribute = () => {
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [categoryId, setCategoryId] = useState<string>("");
  const [allAttributes, setAllAttributes] = useState<any>([]);
  const [selectAttributes, setSelectAttributes] = useState<string>("");
  const [allOptions, setAllOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showAddItem, setShowAddItem] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [addOptionModel, setAddOptionModel] = React.useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [displayType, setDisplayType] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [selectedAttributes, setSelctedAttribute] = useState([]);
  const [attributeDetails, setAttributeDetails] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();

  useEffect(() => {
    if (window.location.pathname == "/admin/attributes/add") {
      setEditMode(false);
    } else {
      setEditMode(true);
      getAttributeDetailsById(id);
    }
  }, [allCategories]);

  const getAttributeDetailsById = async (id: string) => {
    try {
      const response = await attribute.getSingle(id);
      setAttributeDetails(response);
      let newSelectedOptions = response.options;
      setSelectedCategory(response.category.category);
      setSelectAttributes(response.name);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (attributeDetails && allOptions.length > 0) {
      setSelectedOption(attributeDetails.options);
    }
  }, [attributeDetails, allOptions]);

  const handleSelecteCategoryChange = async (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedCategory(event.target.value);
    const categoryId = allCategories.filter((item) => {
      if (item.category === event.target.value) {
        fetchAttributeById(item._id);
      }
    });
  };

  const fetchAttributeById = async (id: string) => {
    try {
      const response = await attribute.getAttributes(id);
      await setSelctedAttribute(response.data);
    } catch (err) {}
  };

  useEffect(() => {
    if (selectedCategory) {
      allCategories.map((item) => {
        if (item.category == selectedCategory) {
          setCategoryId(item._id);
          setAllAttributes(item.filters);
        }
      });
    }
  }, [allCategories, selectedCategory]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  useEffect(() => {
    if (allAttributes) {
      {
        allAttributes.map((val) => {
          setLevel("Category");
        });
      }
    }
  });

  const onSaveAttribute = async () => {
    const obj = {
      submit: "true",
      name: name,
      level: level,
      displayType: displayType,
      categoryId: categoryId,
      options: selectedOption,
    };
    postAttribute(obj);
    setLoading(true);
  };

  const postAttribute = async (data) => {
    try {
      const response = await attribute.post(data);
      Navigate(-1);
      setLoading(false);
      setShowAddItem(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const onEditAttribute = async () => {
    const obj = {
      submit: "true",
      name: selectAttributes,
      displayType: displayType,
      options: selectedOption,
    };
    putAttribute(id, obj);
    setLoading(true);
  };

  const Navigate = useNavigate();

  const putAttribute = async (id, data) => {
    try {
      const response = await attribute.put(id, data);
      Navigate(-1);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectAttributes) {
      allAttributes.map((item) => {
        if (item.name == selectAttributes) {
          setName(item.name);
          setAllOptions(item.options);
          setDisplayType(item.displayType);
        }
      });
    }
  }, [selectAttributes, allAttributes]);

  const handleOptionChange = (e) => {
    setCurrentOption(e.target.value);
  };
  const onAddOption = async (e) => {
    const addNewOption = [...allOptions, currentOption];
    setCurrentOption("");
    const obj = {
      filters: [
        {
          displayType: displayType,
          name: name,
          options: addNewOption,
        },
      ],
    };
    await putCategory(categoryId, obj);
  };

  const putCategory = async (item, obj) => {
    try {
      const response = await categoriesApi.patch(item, obj);
      setAddOptionModel(false);
      fetchAllCategories();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await categoriesApi.getAll(1, 1000);
      setAllCategories(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (allAttributes && selectedAttributes) {
      const newAttributes = [];
      for (let key of selectedAttributes) {
        newAttributes.push(key.name);
        const newSelectedAttribute = allAttributes.filter((item) => {
          return newAttributes.indexOf(item.name) === -1;
        });
        setAllAttributes(newSelectedAttribute);
      }
    }
  }, [selectedAttributes]);

  const handleFiltersdropDown = (event) => {
    setSelectAttributes(event.target.value);
    setSelectedOption([]);
  };
  const handleOptionsDropdown = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const onCancel = () => {
    Navigate(-1);
  };

  const addOptions = () => {
    setAddOptionModel(true);
  };

  const addAttributes = () => {
    Navigate(`/admin/categories/${categoryId}`);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            {/* <CardHeader color="primary"> */}
            <CardHeader className="header">
              {editMode ? <h4>Edit Attribute</h4> : <h4>Add Attribute</h4>}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomSelect
                    labelText="Category*"
                    // native
                    formControlProps={{
                      style: { minWidth: "200px" },
                      // fullWidth: true,
                    }}
                    id="age-native-required"
                    inputProps={{
                      value: selectedCategory,
                      onChange: handleSelecteCategoryChange,
                      name: "Select Parent Category",
                      native: true,
                      multiple: false,
                    }}
                  >
                    <option value="">{""}</option>
                    {allCategories.map((item) => {
                      return (
                        <option key={item._id} value={item.category}>
                          {item.category}
                        </option>
                      );
                    })}
                  </CustomSelect>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomSelect
                    labelText="Attribute*"
                    // native
                    formControlProps={{
                      style: { minWidth: "200px" },
                      // fullWidth: true,
                    }}
                    id="age-native-required"
                    inputProps={{
                      value: selectAttributes,
                      onChange: handleFiltersdropDown,
                      name: "Select Attribute",
                      native: allAttributes.length == 0 ? false : true,
                      multiple: false,
                    }}
                  >
                    <option value=""> </option>
                    {allAttributes.length > 0 &&
                      allAttributes.map((item) => {
                        return (
                          <option key={item._id} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    {allAttributes.length == 0 && (
                      <>
                        <div onClick={addAttributes}>Add attribute</div>
                      </>
                    )}
                  </CustomSelect>
                  {/* {allAttributes.length == 0 && <><button onClick={addAttributes}>Add attribute</button></>} */}
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomSelect
                    labelText="Options*"
                    // native
                    formControlProps={{
                      style: { minWidth: "200px" },
                      // fullWidth: true,
                    }}
                    id="age-native-required"
                    inputProps={{
                      multiple: true,
                      value: selectedOption,
                      onChange: handleOptionsDropdown,
                      name: "Select Options",
                    }}
                  >
                    {allOptions &&
                      allOptions.map((item) => {
                        return (
                          <MenuItem key={item._id} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    {allOptions && allOptions.length > 0 ? (
                      <>
                        <div onClick={addOptions}>Add Option</div>
                      </>
                    ) : (
                      ""
                    )}
                  </CustomSelect>
                  {/* <button onClick={addOptions}>Add Option</button> */}
                </GridItem>
              </GridContainer>
            </CardBody>
            <GridContainer
              style={{
                justifyContent: "end",
                padding: "1rem",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                <Button color="gray" onClick={() => onCancel()}>
                  Cancel
                </Button>
              </div>
              <div style={{ marginRight: "1rem", position: "relative" }}>
                <Button
                  color="success"
                  onClick={editMode ? onEditAttribute : onSaveAttribute}
                >
                  {/* // onClick={() => onEditAttribute()}> */}
                  Submit
                </Button>
                {loading && <Loader />}
                {/* {loading && <div style={{ position: "absolute", top: "30%", right: "5px", opacity: "1" }}>{<CircularProgress size="20px" style={{ color: "#8D6D4D" }} />}</div>} */}
                {/* :
                <Button color="success" onClick={() => onSaveAttribute()}>
                  Submit
                </Button>} */}
              </div>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>

      {addOptionModel && (
        <div style={{ width: "50%" }}>
          <Modal
            open={addOptionModel}
            onClose={() => setAddOptionModel(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GridContainer sm={5}>
              <Card>
                <GridItem>
                  <CardBody>
                    <GridItem>
                      <h2 style={{ textAlign: "center" }}>Add New Option</h2>
                      <GridContainer
                        style={{
                          justifyContent: "center",
                          paddingTop: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <GridItem>
                          <FormControl>
                            <CustomInput
                              labelText="Option"
                              //   categoryId="company-disabled"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: currentOption,
                                onChange: handleOptionChange,
                                selected: "selected",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <Button
                            color="success"
                            onClick={(e) => onAddOption(e)}
                          >
                            Add
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </CardBody>
                </GridItem>
              </Card>
            </GridContainer>
          </Modal>
        </div>
      )}
    </>
  );
};
export default Interceptor(AddOrEditAttribute);
