import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { attribute } from "Services/API/IndividualApis/Attribute";
import { Variant } from "Services/API/IndividualApis/Variant";
import Variants from "../VariantComponent/product";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import GridItem from "components/Grid/GridItem";
import classes from "./Product.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { products } from "Services/API/IndividualApis/Product";
import DeleteModal from "components/CustomDeleteModal";
import { ICategory } from "Interfaces/Api/Category";
import { IProductProperties } from "Interfaces/Pages/Products";
import { IProduct } from "Interfaces/Api/Product";
import Loader from "components/Loader";
import VariantModal from "./VariantModal";

interface AttributeProps {
  allCategories: ICategory[];
  productProperties: IProductProperties;
  editMode: boolean;
  onCancel: Function;
  editItem: IProduct;
  onClose: Function;
}

const Attribute = ({
  allCategories,
  productProperties,
  onCancel,
  editMode,
  editItem,
  onClose,
}: AttributeProps) => {
  const [allFilters, setAllFilters] = useState([]);
  const [selectFilters, setSelectFilters] = useState<any>([]);
  const [properties, setProperties] = useState({});
  const [selectedAttributes, setSelectedAttribute] = useState([]);
  const [allAttributes, setAllAttributes] = useState([]);
  const [selectedAttributesDetails, setSelectedAttributesDetails] = useState(
    []
  );
  const [selectedAttributesOptions, setSelectedAttributesOptions] =
    useState<any>({});
  const [showUnselectedFilters, setShowUnselectedFilters] = useState([]);
  const [createdAttributeIds, setCreatedAttributeIds] = useState([]);
  const [createdAttribute, setCreatedAttribute] = useState([]);
  const [allVariations, setAllVariations] = useState([]);
  const [variantOf, setVariantOf] = useState<string>("");
  const [variationDetails, setVariationDetails] = useState<any>([]);
  const [accordianProperties, setAccordianProperties] = useState([]);
  const [selectedAttributeForBackup, setSelectedAttributeForBackup] = useState(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [variationLoading, setVariationLoading] = useState<boolean>(false);
  const [
    selectedAttributesOptionsForBackup,
    setSelectedAttributesOptionsForBackup,
  ] = useState({});
  const [newSelectedAttributes, setNewSelectedAttributes] = useState([]);
  const [attributeTouched, setAttributeTouched] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(true);
  const [openDeleteVariantModal, setOpenDeleteVariantModal] =
    useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<any>("");
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [itemInModal, setItemInModal] = useState<any>();
  const [disableCreateVariantButton, setDisableCreateVariantButton] =
    useState<boolean>(true);
  useEffect(() => {
    if (allFilters) {
      setShowUnselectedFilters(allFilters);
    }
  }, [allFilters]);

  useEffect(() => {
    if (productProperties?.categories) {
      allCategories.map((item) => {
        const newProperties = productProperties?.categories.findIndex((val) => {
          if (item.category === val) {
            setAllFilters(item.filters);
            fetchAttributeById(item._id);
          }
        });

        // if (item.category == productProperties?.categories[0]) {
        //   setAllFilters(item.filters);
        //   fetchAttributeById(item._id);
        // }
      });
    }
  }, [productProperties?.categories]);

  useEffect(() => {
    if (editMode) {
      getVariantById(editItem.variantOf);
    }
  }, [editItem]);

  useEffect(() => {
    if (variationDetails?.attributes) {
      const newSelectedAttribute = [];
      const selectedAttributesWithOptionObj = {};
      variationDetails?.attributes.map((item) => {
        newSelectedAttribute.push(item.attribute.name);

        let options = [];
        if (selectedAttributesOptions[item.attribute.name])
          options = [...selectedAttributesOptions[item.attribute.name].options];
        item.values.map((value) => {
          if (options.indexOf(value) === -1)
            options.push(value.attributePropertyValue);
        });
        selectedAttributesWithOptionObj[item.attribute.name] = {
          options: options,
          displayType: item.attribute.displayType,
        };
      });

      setSelectedAttribute(newSelectedAttribute);
      setSelectedAttributesOptions(selectedAttributesWithOptionObj);
      setSelectedAttributeForBackup(newSelectedAttribute);
      setSelectedAttributesOptionsForBackup(selectedAttributesWithOptionObj);

      const newAllVariations = [...allVariations];

      for (let product of variationDetails.products) {
        const attributesObj = {};
        variationDetails.attributes.map((item) => {
          attributesObj[item.attribute.name] =
            product.properties[item.attribute.name];
        });
        const obj = {
          id: product._id,
          propertiesDetails: {
            id: product._id,
            name: product.name,
            availableQuantity: product.availableQuantity,
            price: product.price,
            discount: product.discount,
            subTitle: product.cms.subTitle,
            maxQuantityAllowedInSignleCart:
              product.maxQuantityAllowedInSignleCart,
            categories: product.categories,
            sku: product.sku,
            image: product.image,
            media: product.media,
            // size: {
            length: product.size.length,
            breadth: product.size.breadth,
            height: product.size.height,
            weight: product.size.weight,
            // },
            // metaData: {
            title: product.metaData.title,
            description: product.metaData.description,
            keywords: product.metaData.keywords,
            howToUse: product.cms.howToUse,
            whatIsIt: product.cms.whatIsIt,
            whatsInIt: product.cms.whatsInIt,
            faqs: product.cms.faqs,
            benefits: product.cms.benefits,
            whatExpertHasToSay: product.cms.whatExpertHasToSay,
            properties: product.properties,
            // }
          },
          ...attributesObj,
        };

        if (
          newAllVariations.findIndex((item) => item.id === product._id) === -1
        ) {
          newAllVariations.push(obj);
        }
      }
      setAllVariations(newAllVariations);
    }
  }, [variationDetails]);
  const getVariantById = async (variantOf) => {
    try {
      const response = await Variant.getSingle(
        variantOf,
        "populateProducts=true"
      );
      setVariationDetails(response);
    } catch (err) {}
  };

  useEffect(() => {
    if (showUnselectedFilters) {
      for (const [key, value] of Object.entries(productProperties.properties)) {
        let obj = {};
        const newUnselectedFilters = showUnselectedFilters.filter((item) => {
          if (item.name == key) {
            obj[item.name] = value;
          }
          setSelectFilters((prev) => ({ ...prev, ...obj }));
          // setAccordianProperties((prev) => ({ ...prev, ...obj }));
        });
      }
    }
  }, [showUnselectedFilters]);

  useEffect(() => {
    if (selectedAttributes && allAttributes && allFilters) {
      const newSelectedAttribute = allAttributes.filter((item) => {
        if (selectedAttributes.indexOf(item.name) === -1) {
          return false;
        } else {
          return true;
        }
      });
      setSelectedAttributesDetails(newSelectedAttribute);

      const newSelectedAttributesOPtions = { ...selectedAttributesOptions };
      for (let name of selectedAttributes) {
        if (
          !newSelectedAttributesOPtions[name] ||
          (newSelectedAttributesOPtions[name] &&
            (!newSelectedAttributesOPtions[name].options ||
              newSelectedAttributesOPtions[name].options.length === 0))
        ) {
          if (selectFilters[name] && !newSelectedAttributesOPtions[name]) {
            newSelectedAttributesOPtions[name] = {
              options: [selectFilters[name]],
            };
          }
        }
      }
      setSelectedAttributesOptions((preValue) => ({
        ...preValue,
        ...newSelectedAttributesOPtions,
      }));

      const newSelectedFilters = allFilters.filter((item) => {
        return selectedAttributes.indexOf(item.name) === -1;
      });
      setShowUnselectedFilters(newSelectedFilters);
      setAccordianProperties(newSelectedFilters);
    }
  }, [selectedAttributes, allAttributes, allFilters]);

  const fetchAttributeById = async (id) => {
    try {
      const response = await attribute.getAttributes(id);
      await setAllAttributes(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleFilterschanges = (event) => {
    let obj = {
      [event.target.name]: event.target.value,
    };

    let obj1 = {
      [event.target.name]: event.target.value,
    };
    setSelectFilters((preVal) => ({ ...preVal, ...obj }));
    setProperties((preVal) => ({ ...preVal, ...obj1 }));
  };

  const onSaveProduct = () => {
    const invalidVariantValues = allVariations.filter(
      (item) =>
        item.propertiesDetails.price === "" ||
        item.propertiesDetails.price === null ||
        item.propertiesDetails.whatIsIt === "<p><br></p>" ||
        item.propertiesDetails.name === "" ||
        item.propertiesDetails.availableQuantity === ""
    );
    if (invalidVariantValues.length === 0) {
      const allVariationValidMedia = allVariations.map((item) => {
        const filterMedia = item.propertiesDetails.media.filter(
          (file) => file.fileLimitExceed !== true
        );
        const newProperties = {
          ...item,
          propertiesDetails: { ...item.propertiesDetails, media: filterMedia },
        };
        return newProperties;
      });

      handleSaveVariantProduct(allVariationValidMedia, variantOf);
    } else {
      console.log("cannot save");
    }
  };

  const handleAttributeChange = (event) => {
    setSelectedAttribute(event.target.value);

    const selectedAttributesOptionsArraysOfKeyForBackup = Object.keys(
      selectedAttributesOptionsForBackup
    );
    const attributes = event.target.value.filter(
      (item) =>
        selectedAttributesOptionsArraysOfKeyForBackup.indexOf(item) === -1
    );
    setNewSelectedAttributes(attributes);
  };

  const handleAttriburBlur = (e) => {
    if (e.target.value == "") {
      setError(true);
    } else {
      setError(false);
    }
    setAttributeTouched(true);
  };

  const handleSelectedAttributesOptionsChange = (event, displayType) => {
    const newselectedAttributesOptions = { ...selectedAttributesOptions };
    newselectedAttributesOptions[event.target.name] = {
      options: event.target.value,
      displayType,
    };

    if (newselectedAttributesOptions[event.target.name].options.length > 0) {
      setDisableCreateVariantButton(false);
    } else {
      setDisableCreateVariantButton(true);
    }
    setSelectedAttributesOptions(newselectedAttributesOptions);
  };
  const onCancelItem = () => {
    onCancel();
  };
  const onCreateAttribute = async () => {
    // const selectedAttributesOptionsArraysOfKey = Object.keys(
    //   selectedAttributesOptions
    // );
    setVariationLoading(true);
    const attributesToCreate = [...newSelectedAttributes];

    for (const [i, v] of attributesToCreate.entries()) {
      const selectedFilter = selectedAttributesDetails
        .filter((item) => item.name === attributesToCreate[i])
        .pop();

      const obj = {
        name: selectedFilter.name,
        level: "Product",
        displayType: selectedFilter.displayType,
        options: selectedAttributesOptions[selectedFilter.name]?.options || [],
      };

      // if (selectedAttributesOptions[selectedFilter.name]?.options.length == [] || selectedAttributesOptions == {}) {
      //   setAttributeTouched(true)
      //   setError(true)
      // }
      // else {
      await postAttribute(obj);
      // }
    }
    setNewSelectedAttributes([]);
    setVariationLoading(false);
  };

  const handleSaveVariantProduct = async (allVariations, variantOf) => {
    setLoading(true);
    let allNewVaritaions = [...allVariations];
    try {
      for (let key of allVariations) {
        if (!key.propertiesDetails.id) {
          const obj: any = {
            submit: "true",
            properties: key.propertiesDetails.properties,
            name: key.propertiesDetails.name,
            price: key.propertiesDetails.price,
            availableQuantity: key.propertiesDetails.availableQuantity,
            maxQuantityAllowedInSignleCart:
              key.propertiesDetails.maxQuantityAllowedInSignleCart,
            inStock: key.propertiesDetails.stock,
            media: key.propertiesDetails.media,
            image: key.propertiesDetails.image,
            categories: key.propertiesDetails.categories,
            discount: key.propertiesDetails.discount,
            sku: key.propertiesDetails.sku,
            variantOf: variantOf || editItem.variantOf,
            allVariationProperties: key[0],
            type: "Variant",
            size: {
              length: key.propertiesDetails.length,
              breadth: key.propertiesDetails.breadth,
              height: key.propertiesDetails.height,
              weight: key.propertiesDetails.weight,
            },
            metaData: {
              title: key.propertiesDetails.title,
              description: key.propertiesDetails.description,
              keywords: key.propertiesDetails.keywords,
            },
            cms: {
              howToUse: key.propertiesDetails?.howToUse,
              whatIsIt: key.propertiesDetails?.whatIsIt,
              whatsInIt: key.propertiesDetails?.whatsInIt,
              subTitle: key.propertiesDetails?.subTitle,
              faqs: key.propertiesDetails?.faqs,
              benefits: key.propertiesDetails?.benefits,
              whatExpertHasToSay: key.propertiesDetails.whatExpertHasToSay,
            },
          };
          if (key.propertiesDetails.id) {
            obj["id"] = key.propertiesDetails.id;
          }
          allNewVaritaions = await postVariantProduct(obj, allNewVaritaions);
        }
      }
      onClose();
    } catch (err) {
      setAllVariations(allNewVaritaions);
      setLoading(false);
      console.log(err);
    }
  };
  const postVariantProduct = async (data, allVariations) => {
    let response;
    response = await products.post(data);
    if (response) {
      const allNewVariations = variantProduct(
        response._id,
        response.sku,
        allVariations
      );
      return allNewVariations;
    }
  };

  const variantProduct = (id, sku, allVariations) => {
    const newAllVariations = [];
    for (let product of allVariations) {
      if (product.propertiesDetails.sku == sku) {
        const obj = {
          submit: "true",
          ...product,
          propertiesDetails: {
            id: id,
            name: product.propertiesDetails.name,
            availableQuantity: product.propertiesDetails.availableQuantity,
            price: product.propertiesDetails.price,
            discount: product.propertiesDetails.discount,
            subTitle: product.propertiesDetails.subTitle,
            maxQuantityAllowedInSignleCart:
              product.propertiesDetails.maxQuantityAllowedInSignleCart,
            categories: product.propertiesDetails.categories,
            sku: product.propertiesDetails.sku,
            image: product.propertiesDetails.image,
            media: product.propertiesDetails.media,
            // size: {
            length: product.propertiesDetails?.length,
            breadth: product.propertiesDetails?.breadth,
            height: product.propertiesDetails?.weight,
            // },
            // metaData: {
            title: product.propertiesDetails?.title,
            description: product.propertiesDetails?.description,
            keywords: product.propertiesDetails.metaData?.keywords,
            howToUse: product.propertiesDetails?.howToUse,
            whatIsIt: product.propertiesDetails?.whatIsIt,
            whatsInIt: product.propertiesDetails?.whatsInIt,
            properties: product.propertiesDetails.properties,
            faqs: product.propertiesDetails?.faqs,
            benefits: product.propertiesDetails?.benefits,
            whatExpertHasToSay: product.propertiesDetails?.whatExpertHasToSay,
            // }
          },
        };
        newAllVariations.push(obj);
      } else {
        const obj = {
          submit: "true",
          ...product,
        };
        newAllVariations.push(obj);
      }
    }
    return newAllVariations;
  };

  const postAttribute = async (data) => {
    try {
      const response = await attribute.post(data);
      let val = response._id;
      let object = {
        name: response.name,
        options: response.options,
      };

      // setSelectedAttributeForBackup((preVal) => [...preVal, response.name]);
      const newselectedAttributesOptions = {
        ...selectedAttributesOptionsForBackup,
      };
      newselectedAttributesOptions[response.name] = {
        options: response.options,
        displayType: response.displayType,
      };
      setSelectedAttributesOptionsForBackup((preValue) => ({
        ...preValue,
        ...newselectedAttributesOptions,
      }));
      setCreatedAttribute((preVal) => [...preVal, object]);
      setCreatedAttributeIds((preVal) => [...preVal, val]);
    } catch (err) {
      console.log(err);
    }
  };
  // const putAttribute = async (data) => {
  //   try {
  //     const response = await attribute.put(id,data);
  //     let val = response._id;
  //     let object = {
  //       name: response.name,
  //       options: response.options,
  //     };
  //     setCreatedAttribute((preVal) => [...preVal, object]);
  //     setCreatedAttributeIds((preVal) => [...preVal, val]);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const onCreateVariant = async () => {
    let obj = {
      attributes: createdAttributeIds,
    };
    if ((variantOf || editItem?.variantOf) && createdAttribute.length > 0) {
      await patchVariant(obj);
    } else if (!variantOf && !editItem?.variantOf) {
      await postVariant(obj);
    }

    const newAllVariations = [...allVariations];
    for (let variant of allVariations) {
      createdAttribute.forEach((item) => {
        if (variant.propertiesDetails.properties[item.name]) {
          variant[item.name] = variant.propertiesDetails.properties[item.name];
        }
      });
    }
    setAllVariations(newAllVariations);
    const arr = [];
    for (let name in selectedAttributesOptionsForBackup) {
      // if (selectedAttributesOptions[name]?.options.length > 0) {
      const obj = {
        name,
        options: selectedAttributesOptions[name]?.options,
        displayType: selectedAttributesOptions[name]?.displayType,
      };
      arr.push(obj);
      // }
    }
    const result = await getAllVariants([...arr]);
    // const remainingCombination = await clearDuplicateCombintaion(result)
    setAllVariations((preValue) => [...result, ...preValue]);
    setCreatedAttribute([]);
  };
  const getAllVariants = (array) => {
    const max = array.length - 1;
    let counter = allVariations.length;
    const combinations = [];
    const helper = (obj, i) => {
      for (let j = 0, l = array[i].options.length; j < l; j++) {
        let copy = { ...obj };

        copy[array[i].name] = array[i].options[j];
        copy["id"] = counter;
        if (i == max) {
          let copy2 = { ...copy };
          delete copy2.id;
          const copyValueArray = Object.values(copy2);
          let nameSuffixString = "";
          for (let key of copyValueArray) {
            if (nameSuffixString !== "") {
              nameSuffixString = nameSuffixString + "-";
            }
            nameSuffixString = nameSuffixString + key;
          }
          const copyKeyValuePair = copy2;

          let obj = { ...selectFilters };
          for (const [key, value] of Object.entries(copyKeyValuePair)) {
            obj[key] = value;
          }
          copy["propertiesDetails"] = {
            availableQuantity: productProperties?.availableQuantity,
            price: productProperties?.price,
            discount: productProperties?.discount,
            subTitle: productProperties?.subTitle,
            maxQuantityAllowedInSignleCart:
              productProperties?.maxQuantityAllowedInSignleCart,
            categories: productProperties.categories,
            sku: productProperties.sku,
            image: productProperties.image,
            media: productProperties.media,
            // size: {
            length: productProperties?.length,
            breadth: productProperties?.breadth,
            height: productProperties?.height,
            weight: productProperties?.weight,
            // },
            // metaData: {
            title: productProperties.title,
            description: productProperties.description,
            keywords: productProperties.keywords,
            howToUse: productProperties.howToUse,
            whatIsIt: productProperties.whatIsIt,
            whatsInIt: productProperties.whatsInIt,
            faqs: productProperties?.faqs,
            benefits: productProperties?.benefits,
            whatExpertHasToSay: productProperties?.whatExpertHasToSay,
            properties: { ...obj },
            // }
          };

          const idx = allVariations.findIndex((variant) => {
            const copy2 = { ...copy };
            delete copy2.propertiesDetails;
            delete copy2.id;
            const product = variant.propertiesDetails;
            const totalLenth = Object.keys(copy2).length;
            let matchCount = 0;
            for (let key in copy2) {
              if (copy2[key] === product.properties[key]) {
                matchCount += 1;
              }
            }

            if (totalLenth === matchCount) return true;
            else return false;
          });

          if (idx === -1) {
            let name = "";
            if (
              productProperties.name.includes("(") &&
              productProperties.name.includes(")")
            ) {
              name = ` ${
                productProperties.name.split("(")[0]
              }(${nameSuffixString})`;
            } else {
              name = `${productProperties.name} (${nameSuffixString})`;
            }
            copy.propertiesDetails["name"] = name;
            combinations.push(copy);
            counter++;
          } else {
          }
          // combinations.push(copy);
        } else helper(copy, i + 1);
      }
    };
    helper({}, 0);
    return combinations;
  };

  const postVariant = async (data) => {
    try {
      const response = await Variant.post(data);
      setVariantOf(response._id);
    } catch (err) {
      console.log(err);
    }
  };

  const patchVariant = async (data) => {
    try {
      const response = await Variant.addNewAttributes(editItem.variantOf, data);
      setVariantOf(response._id);
    } catch (err) {
      console.log(err);
    }
  };

  // const clearDuplicateCombintaion = async (data) => {
  //   for(const newVariation of data){

  //   }
  // };

  const handleVariantProperties = (name, value, id) => {
    let arr = [...allVariations];

    const index = arr.findIndex((item) => {
      return item.id === id;
    });
    let item = {
      ...arr[index],
      propertiesDetails: {
        ...arr[index]["propertiesDetails"],
        [name]: value,
      },
    };

    arr[index] = item;
    setAllVariations(arr);
  };

  const handleDeleteVariant = (e, item) => {
    e.stopPropagation();
    setExpanded(false);
    if (item.propertiesDetails.id) {
      setExpanded(false);
      setOpenDeleteVariantModal(true);
    } else {
      setExpanded(false);
      const newAllVariation = allVariations.filter((variation) => {
        if (variation.id !== item.id) {
          return item;
        }
      });
      for (let property in item.propertiesDetails.properties) {
        if (property in selectedAttributesOptions) {
          const newSelectedAttributesOPtions = selectedAttributesOptions[
            property
          ].options.filter(
            (option) => option !== item.propertiesDetails.properties[property]
          );
          setSelectedAttributesOptions((preValue) => ({
            ...preValue,
            [property]: {
              options: newSelectedAttributesOPtions,
              displayType: preValue[property].displayType,
            },
          }));
        }
      }
      setAllVariations(newAllVariation);
    }
  };

  const removeItems = async () => {
    try {
      const response = await products.remove(itemToBeDeleted.id);
      setOpenDeleteVariantModal(false);
      if (response) {
        const newAllVariation = allVariations.filter((item) => {
          return item.id !== response._id;
        });
        const filterProducts = variationDetails.products.filter((item) => {
          return item._id !== itemToBeDeleted.id;
        });
        let newVariationDetails = { ...variationDetails };
        newVariationDetails.products = filterProducts;

        for (let property in itemToBeDeleted.propertiesDetails.properties) {
          if (property in selectedAttributesOptions) {
            const newSelectedAttributesOPtions = selectedAttributesOptions[
              property
            ].options.filter(
              (option) =>
                option !==
                itemToBeDeleted.propertiesDetails.properties[property]
            );

            setSelectedAttributesOptions((preValue) => ({
              ...preValue,
              [property]: {
                options: newSelectedAttributesOPtions,
                displayType: preValue[property].displayType,
              },
            }));
          }
        }
        setAllVariations(newAllVariation);
        getVariantById(editItem.variantOf);

        // setVariationDetails(newVariationDetails);
        // setSelectedAttributesOptions(itemToBeDeleted.propertiesDetails.properties[])
      }
    } catch (err) {
      console.log(err);
    }
  };
  const toggleAcordion = () => {
    setExpanded((prev) => !prev);
  };
  const handleModal = (item) => {
    setItemInModal(item);
    setShowModal(true);
  };
  const [showEditModal, setShowEditModal] = useState<boolean>(true);
  useEffect(() => {
    allVariations.map((item) => {
      if (editItem && item.propertiesDetails.id && showEditModal) {
        if (item.propertiesDetails.id == editItem._id) {
          setShowModal(true);
          setItemInModal(item);
          setShowEditModal(false);
        }
      }
    });
  }, [allVariations]);

  const handleVariantSave = (data) => {
    const newAllVariants = [...allVariations];
    const indexOfSavedVariant = newAllVariants.findIndex(
      (variant) => variant.id === data.id
    );
    newAllVariants[indexOfSavedVariant] = data;
    setAllVariations(newAllVariants);
  };

  return (
    <div>
      {openDeleteVariantModal ? (
        <DeleteModal
          show={openDeleteVariantModal}
          onHide={() => setOpenDeleteVariantModal(false)}
          deleteItem={() => removeItems()}
          itemName={itemToBeDeleted.propertiesDetails.name}
        />
      ) : (
        ""
      )}
      {/* there are no filters added for */}
      <GridContainer>
        {allAttributes.length == 0 && allFilters.length == 0 ? (
          <div style={{ fontSize: "1.2rem", padding: "0.9375rem 20px" }}>
            Note:To add variant product add a filters for
            <span style={{ fontWeight: "bold" }}>
              "{productProperties?.categories}"
            </span>
            category.and create attribute for{" "}
            <span style={{ fontWeight: "bold" }}>
              "{productProperties?.categories}"
            </span>{" "}
            category.
          </div>
        ) : (
          ""
        )}
        {allAttributes.length == 0 && allFilters.length > 0 ? (
          <div style={{ fontSize: "1.2rem", padding: "0.9375rem 20px" }}>
            Note:To add variant product create attribute for{" "}
            <span style={{ fontWeight: "bold" }}>
              "{productProperties?.categories}"
            </span>{" "}
            category.
          </div>
        ) : (
          ""
        )}
        <GridItem xs={12}>
          <Typography variant="h6" style={{ marginBottom: "1rem" }}>
            Create variant products
          </Typography>
        </GridItem>
        <GridItem xs={12}>
          <Box style={{ padding: "0.9375rem 20px" }}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                {allAttributes.length > 0 && (
                  <CustomSelect
                    labelText="Select Attribute*"
                    // native
                    formControlProps={{
                      className: classes.formControl,

                      // fullWidth: true,
                    }}
                    id="age-native-required"
                    inputProps={{
                      required: true,
                      multiple: true,
                      value: selectedAttributes,
                      onChange: handleAttributeChange,
                      name: "Select Attribute",
                    }}
                  >
                    <option value=""> </option>
                    {allAttributes.map((item) => {
                      return (
                        <MenuItem key={item._id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                )}
              </GridItem>
              {showUnselectedFilters &&
                showUnselectedFilters.map((item) => {
                  return (
                    <GridItem xs={12} sm={6} md={4} key={item.name}>
                      <CustomSelect
                        labelText={item.name + `*`}
                        // native
                        formControlProps={{
                          className: classes.formControl,

                          // fullWidth: true,
                        }}
                        id="age-native-required"
                        inputProps={{
                          required: true,
                          value: selectFilters[item.name],
                          onChange: handleFilterschanges,
                          name: item.name,
                          native: true,
                        }}
                      >
                        <option value="/"> </option>
                        {item.options.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </CustomSelect>
                    </GridItem>
                  );
                })}
              {selectedAttributesDetails &&
                selectedAttributesDetails.map((item) => {
                  // if (selectedAttributesOptions[item.name]?.options.length === 0) {
                  //   setAttributeTouched(true)
                  // } else {
                  //   setAttributeTouched(false)
                  //   setError(false)
                  // }
                  return (
                    <GridItem xs={12} sm={6} md={4} key={item.name}>
                      <CustomSelect
                        labelText={item.name + `*`}
                        // native
                        formControlProps={{
                          className: classes.formControl,

                          // fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          multiple: true,
                          value:
                            selectedAttributesOptions[item.name]?.options || [],
                          onChange: (e) =>
                            handleSelectedAttributesOptionsChange(
                              e,
                              item.displayType
                            ),
                          onBlur: handleAttriburBlur,
                          name: item.name,
                          // native: true,
                        }}
                      >
                        {/* <MenuItem value="/"> </MenuItem> */}
                        {item.options.map((val, index) => {
                          return (
                            <MenuItem key={index} value={val}>
                              {val}
                            </MenuItem>
                          );
                        })}
                      </CustomSelect>
                      <FormHelperText style={{ color: "red" }}>
                        {error &&
                          attributeTouched &&
                          `${item.name + " " + "Required"}`}
                      </FormHelperText>
                    </GridItem>
                  );
                })}
            </GridContainer>

            <GridContainer>
              <GridItem style={{ margin: "27px 0 0 0" }}>
                <Button
                  color="success"
                  onClick={onCreateAttribute}
                  disabled={newSelectedAttributes.length === 0}
                >
                  Create Attribute
                </Button>
                {/* </GridItem>
              <GridItem> */}
                {variationLoading ? (
                  <p>Loading....</p>
                ) : createdAttribute.length > 0 || editItem?.variantOf ? (
                  <Button
                    color="success"
                    disabled={
                      // newSelectedAttributes.length !== 0 ||
                      disableCreateVariantButton
                    }
                    onClick={onCreateVariant}
                  >
                    {/* {editMode ? "Edit Variant" : "Create Variant"} */}
                    Create Variants
                  </Button>
                ) : (
                  ""
                )}
              </GridItem>
            </GridContainer>

            {allVariations && allVariations.length > 0 && (
              <GridContainer style={{ marginTop: "27px" }}>
                {allVariations?.map((item, index) => {
                  const selectedVariation = Object.keys(item);
                  const createdVariations = [];
                  for (let key of selectedVariation) {
                    const value = item[key];
                    if (key !== "id" && key !== "propertiesDetails") {
                      const res = {
                        name: key,
                        value: value || "any",
                        // || item.propertiesDetails.properties[key],
                      };
                      createdVariations.push(res);
                    }
                  }

                  const isLineSeparatorVisible =
                    allVariations.length - index ===
                    variationDetails.products?.length;

                  return (
                    <div style={{ display: "block", width: "100%" }}>
                      {isLineSeparatorVisible && (
                        <div style={{ width: "100%", margin: "1rem 0" }}>
                          <div>
                            <hr />
                            <Typography variant="h6" textAlign="center">
                              Variant products
                            </Typography>
                          </div>
                        </div>
                      )}

                      <div
                        key={item._id}
                        style={{
                          display: "flex",
                          padding: "10px 0",
                          alignItems: "top",
                          //  justifyContent: "space-between",
                        }}
                      >
                        {!item.propertiesDetails.id ? (
                          <Accordion
                            style={{ width: "100%" }}
                            onClick={toggleAcordion}
                            defaultExpanded={editItem?._id === item.id}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon onClick={toggleAcordion} />
                              }
                            >
                              {createdVariations.map((createdVariation) => {
                                return (
                                  <>
                                    <Typography
                                      sx={{
                                        paddingRight: "10px",
                                        flexShrink: 0,
                                      }}
                                    >
                                      {createdVariation.name}=
                                      {createdVariation.value}
                                    </Typography>
                                  </>
                                );
                              })}

                              {/* <DeleteIcon onClick={() => {
                            handleDeleteVariant()
                            setItemToBeDeleted(item)
                          }} /> */}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Variants
                                variants={item}
                                handleVariantProperties={
                                  handleVariantProperties
                                }
                                editMode={editMode}
                                showUnselectedFilters={accordianProperties}
                              />
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          <Accordion
                            style={{
                              width: "90%",
                              display: "flex",
                              alignItems: "center",
                              padding: "12px 1rem",
                            }}
                            onClick={toggleAcordion}
                            defaultExpanded={editItem?._id === item.id}
                          >
                            {createdVariations.map((createdVariation) => {
                              return (
                                <Typography
                                  sx={{
                                    flexShrink: 0,
                                  }}
                                >
                                  {createdVariation.name}=
                                  {createdVariation.value}
                                </Typography>
                              );
                            })}
                          </Accordion>
                        )}
                        <div style={{ marginLeft: "1rem" }}>
                          {item.propertiesDetails.id && (
                            <EditIcon
                              onClick={() => handleModal(item)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                          <DeleteIcon
                            onClick={(e) => {
                              handleDeleteVariant(e, item);
                              setExpanded(false);
                              setItemToBeDeleted(item);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <VariantModal
                    item={itemInModal}
                    showModal={showModal}
                    handleVariantSave={handleVariantSave}
                    closeModal={() => {
                      setShowModal(false);
                    }}
                    editMode={editMode}
                    showUnselectedFilters={accordianProperties}
                  />
                </div>
              </GridContainer>
            )}

            <GridContainer
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                <Button color="gray" onClick={onCancelItem}>
                  Cancel
                </Button>
              </div>
              <div style={{ marginRight: "1rem", position: "relative" }}>
                <Button
                  color="success"
                  onClick={onSaveProduct}
                  disabled={
                    allVariations.length <= variationDetails.products?.length ||
                    allVariations.length == 0
                  }
                >
                  Submit
                </Button>
                {loading && <Loader />}
                {/* {loading && <div style={{ position: "absolute", top: "30%", right: "5px", opacity: "1" }}>{<CircularProgress size="20px" style={{ color: "#8D6D4D" }} />}</div>} */}
              </div>
            </GridContainer>
          </Box>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Interceptor(Attribute);
