import { GridColDef } from "@mui/x-data-grid";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CommonTable from "components/CommonTable/CommonTable";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Categories as categoriesApi } from "../../Services/API/IndividualApis/Category";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "components/CustomButtons/Button";
import { attribute } from "Services/API/IndividualApis/Attribute";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import AddOrEditAttribute from "./AddOrEditAttribute";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteModal from "components/CustomDeleteModal";
import { ICategory } from "Interfaces/Api/Category";
import { IPagination } from "Interfaces/Api/Pagination";
import { usePagination } from "hooks/tablePaginationHook";
import { filterCols } from "Services/Helper";

const Attributes = () => {
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState<any>();
  const [allFilters, setAllFilters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [page, handleChangePage] = usePagination();
  const [selectAttributes, setSelectAttributes] = useState<string>("");
  const [showAttributeTable, setShowAttributeTable] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [selectCategory, setSelectCategory] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemName, setItemName] = useState<string>("");
  const [showAddButton, setShowAddButton] = useState<boolean>(true);
  const [deleteItem, setDeleteItem] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation() as any;

  const handleSelectedParentCategoryChange = async (event) => {
    setSelectedParentCategory(event.target.value);
    await fetchAttributeById(event.target.value);
    setShowAttributeTable(true);
  };

  const fetchAttributeById = async (id) => {
    setLoading(true);
    try {
      const response = await attribute.getAttributes(id);
      setLoading(false);
      setAllFilters(response.data);
      setShowPagination(response.pagination);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const Navigate = useNavigate();

  const handleEditAttribute = async (item) => {
    Navigate(`/admin/attributes/${item._id}`);
    setEditMode(true);
  };

  const resetDefault = () => {
    setSelectAttributes("");
    setSelectCategory([]);
  };

  const rowsData = allFilters?.map((item, index) => {
    return {
      name: item?.name,
      id: index,
      actions: item,
      options: item.options,
    };
  });

  const cols: GridColDef[] = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "options", headerName: "Options", width: 280 },
    {
      field: "actions",
      headerName: "Actions",
      width: 280,
      renderCell: (params) => {
        return (
          <>
            {state?.access.write && (
              <EditIcon
                onClick={() => handleEditAttribute(params.value)}
                style={{ marginRight: "10px", cursor: "pointer" }}
              />
            )}
            {state?.access.delete && (
              <DeleteIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowDeleteModal(true);
                  setDeleteItem(params.value._id);
                  setItemName(params.value.name);
                }}
              />
            )}
          </>
        );
      },
    },
  ];
  filterCols(state, cols);

  const fetchAllCategories = async () => {
    try {
      const response = await categoriesApi.getAll(1, 1000);
      setAllCategories(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const removeItems = async () => {
    setShowDeleteModal(false);
    try {
      const response = await attribute.deleteAttribute(itemName, deleteItem);
      fetchAttributeById(selectedParentCategory);
    } catch (err) {
      console.log(err);
    }
  };

  const addItem = () => {
    resetDefault();
    setAddMode((prev) => !prev);
    Navigate(`/admin/attributes/add`);
  };

  return (
    <>
      {editMode || addMode ? (
        <>
          <AddOrEditAttribute />
        </>
      ) : (
        <GridContainer style={{ overflow: "auto" }}>
          <GridItem sm={12}>
            <Card>
              <CardHeader className="header">
                {/* <CardHeader color="primary"> */}
                <h4>Attributes</h4>
              </CardHeader>
              <CardBody>
                <GridContainer
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <GridItem xs={12} sm={6} md={4}>
                    <CustomSelect
                      labelText="Category*"
                      // native
                      formControlProps={
                        {
                          // fullWidth: true,
                        }
                      }
                      id="age-native-required"
                      inputProps={{
                        value: selectedParentCategory,
                        onChange: handleSelectedParentCategoryChange,
                        name: "Select Parent Category",
                        native: true,
                        multiple: false,
                      }}
                    >
                      <option value="/">{"/"}</option>
                      {allCategories.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.category}
                          </option>
                        );
                      })}
                    </CustomSelect>
                  </GridItem>
                  {state?.access.write && (
                    <GridItem style={{ display: "flex", alignItems: "center" }}>
                      <Button color="success" onClick={() => addItem()}>
                        Add
                      </Button>
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
              {showAttributeTable ? (
                <CommonTable
                  totalRows={showPagination?.totalCount}
                  Title="Attribute"
                  button="Add Category"
                  page={page}
                  handleChangePage={handleChangePage}
                  addItems=""
                  handleSearchTextChanged={() => console.log()}
                  searchText=""
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  rows={rowsData}
                  columns={cols}
                  handleFilter={() => console.log()}
                  showAttributeTable={showAttributeTable}
                  showAddButton={state?.access.write}
                  loading={loading}
                />
              ) : (
                ""
              )}
            </Card>
          </GridItem>
        </GridContainer>
      )}
      {showDeleteModal ? (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems()}
          itemName={itemName}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default Interceptor(Attributes);
