export enum cancelOrderStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
export interface ICancelOrder {
  _id: string;
  order: string;
  reason: string;
  status?: cancelOrderStatus;
}
