import Box from "@mui/material/Box";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { apiBanner } from "Services/API/IndividualApis/Cms/Banner";
import { IBannerContent } from "Interfaces/Api/cms/Banner";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "components/CustomDeleteModal";
import { useLocation, useNavigate } from "react-router-dom";

export enum modeType {
  ADD = "ADD",
  EDIT = "EDIT",
}
interface IBannerProps {
  allBaners: IBannerContent[];
  getData: Function;
}
export const Banner = ({ allBaners, getData }: IBannerProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<IBannerContent>(null);
  // const [allBaners, setAllBanners] = useState<IBannerContent[]>([]);
  const { state } = useLocation() as any;

  const navigate = useNavigate();

  // useEffect(() => {
  //   getAllBanners();
  // }, []);

  // const getAllBanners = async () => {
  //   try {
  //     const response = await apiBanner.getAll();
  //     setAllBanners(response.data[0].banner);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleDelete = async () => {
    try {
      const response = await apiBanner.remove(itemToBeDeleted._id);
      // await getAllBanners();
      await getData();
      setIsDeleteModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getRows = () => {
    return allBaners?.map((banner, index) => ({
      index: index + 1,
      name: banner.name,
      url: banner.url,
      mobileImage: banner?.media?.mobile,
      desktopImage: banner?.media?.desktop,
      actions: (
        <Box>
          {state?.access.write && (
            <Icon
              style={{ cursor: "pointer" }}
              component={EditIcon}
              onClick={() => navigate(`/admin/dynamic-content/${banner._id}`)}
            ></Icon>
          )}
          {state?.access.delete && (
            <Icon
              style={{ cursor: "pointer" }}
              component={DeleteIcon}
              onClick={() => {
                setItemToBeDeleted(banner);
                setIsDeleteModalOpen(true);
              }}
            ></Icon>
          )}
        </Box>
      ),
    }));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <Icon
          sx={{ cursor: "pointer" }}
          fontSize="large"
          component={AddBoxIcon}
          onClick={() => {
            navigate("/admin/dynamic-content/add");
          }}
        ></Icon>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Index</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Url</TableCell>
                <TableCell align="left">Mobile Media</TableCell>
                <TableCell align="left">Desktop Media</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.access.read &&
                getRows()?.map((row) => (
                  <TableRow
                    key={row.index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.index}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.url}</TableCell>
                    <TableCell align="left">
                      {row.mobileImage.url?.includes('.mp4') || row.mobileImage.url?.includes('.webm') ?
                        <video width="50" height="50" autoPlay muted>
                          <source src={row.mobileImage.url} type={row.mobileImage.url?.includes('.webm') ? "video/webm" : "video/mp4"} />
                          Your browser does not support the video tag.
                        </video>
                        :
                        <img
                          width={50}
                          height={50}
                          alt={row.name}
                          src={row.mobileImage.url}
                        />
                      }
                    </TableCell>
                    <TableCell align="left">
                      {row.desktopImage.url?.includes('.mp4') || row.desktopImage.url?.includes('.webm') ?
                        <video width="50" height="50" autoPlay muted>
                          <source src={row.desktopImage.url} type={row.desktopImage.url?.includes('.webm') ? "video/webm" : "video/mp4"} />
                          Your browser does not support the video tag.
                        </video>
                        :
                        <img
                          width={50}
                          height={50}
                          alt={row.name}
                          src={row.desktopImage.url}
                        />
                      }
                    </TableCell>
                    <TableCell align="left">{row.actions}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {allBaners.map((banner) => {
          return (
            <>
              <h1>{banner.name}</h1>
              <h1>{banner.url}</h1>
              <img width={100} src={banner.image.mobile.url} />
              <img width={100} src={banner.image.desktop.url} />
            </>
          );
        })} */}
      </Box>

      {itemToBeDeleted && (
        <DeleteModal
          show={isDeleteModalOpen}
          onHide={() => setIsDeleteModalOpen(false)}
          deleteItem={handleDelete}
          itemName={itemToBeDeleted.name}
        />
      )}
    </Box>
  );
};
