import { ICms } from "Interfaces/Api/cms/Banner";
import { ApiCore } from "../../../Core";

const url = "cms/banner";
const apiBanner = new ApiCore<ICms>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

export { apiBanner };
