export enum IPolicyModuleOptions {
  PRODUCT = "Product",
  ORDER = "Order",
  SETTING = "Setting",
  COUPON = "Coupon",
  USER = "User",
  REVIEW = "Review",
  CONTENT = "Content",
  ATTRIBUTE = "Attribute",
  CATEGORY = "Category",
  POLICY = "Policy",
  INQUIRY = "Inquiry",
}

export enum IPolicyAccessMethodOptions {
  READ = "read",
  WRITE = "write",
  DELETE = "delete",
}

export interface IPolicyAccessMethods {
  read: boolean;
  write: boolean;
  delete: boolean;
}

export interface IPolicyStatement {
  target: string;
  access: IPolicyAccessMethods;
}

export interface IPolicy {
  name: string;
  statements: IPolicyStatement[];
  _id?: string;
}

export interface IInquiry {
  _id: string;
  name: string;
  email: string;
  mobileNumber: string;
  subject: string;
  message: string;
}
