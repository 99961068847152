import { ICoupon } from "Interfaces/Api/Coupons";
import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import { ApiCore } from "../../Core";

const url = "coupon";
const coupons = new ApiCore<ICoupon>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

coupons.search = (name, page, perPage) => {
  return apiProvider.getAll(`${url}/search?searchText=${name}&page=${page}&perPage=${perPage}`);
};
export { coupons };
