import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TabPanel, a11yProps } from "components/TabPanel/TabPanel";
import React, { useState, useEffect } from "react";
import { apiConfig } from "Services/API/IndividualApis/Config";
import OtherConfig from "./Other";
import ShippingConfig from "./Shipping";
import ShippingPickuoDetails from "./ShippingPickuoDetails";

export default function OrderConfig() {
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [configData, setConfigData] = useState({});

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    getOrderConfig();
  }, []);

  async function getOrderConfig() {
    try {
      const response = await apiConfig.getAll();
      if (response) {
        setConfigData(response.data[0]);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className="header">Order Config</CardHeader>

            <CardBody>
              <Box>
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Shipping" {...a11yProps(0)} />
                  <Tab label="Shipping pickup details" {...a11yProps(1)} />
                  <Tab label="Other" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                  <ShippingConfig
                    configData={configData}
                    getData={getOrderConfig}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <ShippingPickuoDetails
                    configData={configData}
                    getData={getOrderConfig}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <OtherConfig
                    configData={configData}
                    getData={getOrderConfig}
                  />
                </TabPanel>
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
