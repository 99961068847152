import React, { useEffect, useState } from "react";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { products } from "Services/API/IndividualApis/Product";
import General from "../CommonComponents/General";
import ImagesComponent from "../CommonComponents/ImagesComponent";
import ShippingComponent from "../CommonComponents/ShippingComponent";
import SEOComponent from "../CommonComponents/SEOComponent";
import Attributes from "../CommonComponents/Attribute";
import { IProductProperties } from "Interfaces/Pages/Products";
import { IProduct } from "Interfaces/Api/Product";
import { ICategory } from "Interfaces/Api/Category";
import Inventory from "../CommonComponents/Inventory";

function TabPanel(props: any) {
  const { children, value, index, sx, ...other } = props as any;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%", position: "relative", paddingBottom: "3rem" }}
      sx={sx}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const properties = {
  name: "",
  price: "",
  availableQuantity: "",
  maxQuantityAllowedInSignleCart: "100",
  subTitle: "",
  discount: "",
  categories: ["/"],
  whatIsIt: "<p><br></p>",
  whatsInIt: "",
  howToUse: "",
  image: [],
  media: [],
  length: "",
  weight: "",
  breadth: "",
  height: "",
  title: "",
  description: "",
  keywords: [],
  sku: "",
  properties: {},
};

interface GeneralProps {
  allCategories: ICategory[];
  editMode: boolean;
  editItem: IProduct;
  onCancel: Function;
  onClose: Function;
}

const VariantComponent = ({
  allCategories,
  onCancel,
  editItem,
  editMode,
  onClose,
}) => {
  const [value, setValue] = React.useState(0);
  const [variantProductProperties, setVariantProductProperties] =
    useState<Record<string, any>>(properties);
  const [disable, setDisable] = useState<boolean>(true);
  const [disableImageTab, setDisableImageTab] = useState<boolean>(true);
  const [disableSEOTab, setDisableSEOTab] = useState<boolean>(true);
  const [disableShippingTab, setDisableShippingTab] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveVariantProduct, setSaveVariantProduct] = useState<any>([]);
  const [disableInventortTab, setDisableInventoryTab] = useState<boolean>(true);
  const [disableAttributeTab, setDisableAttributeTab] = useState<boolean>(true);

  useEffect(() => {
    if (editMode) {
      const obj: IProductProperties = {
        name: editItem.name,
        price: editItem.price,
        availableQuantity: editItem.availableQuantity,
        maxQuantityAllowedInSignleCart: editItem.maxQuantityAllowedInSignleCart,
        discount: editItem.discount,
        categories: editItem.categories,
        whatIsIt: editItem.cms?.whatIsIt,
        whatsInIt: editItem.cms?.whatsInIt,
        howToUse: editItem.cms?.howToUse,
        subTitle: editItem.cms?.subTitle,
        image: editItem?.image,
        media: editItem.media,
        length: editItem.size?.length,
        weight: editItem.size?.weight,
        breadth: editItem.size?.breadth,
        height: editItem.size?.height,
        title: editItem?.metaData?.title,
        description: editItem?.metaData?.description,
        keywords: editItem?.metaData?.keywords,
        sku: editItem?.sku,
        properties: editItem?.properties,
      };
      setDisable(false);
      setDisableImageTab(false);
      setDisableInventoryTab(false);
      setDisableAttributeTab(false);
      setDisableSEOTab(false);
      setDisableShippingTab(false);
      setVariantProductProperties(obj);
    }
  }, [editMode, editItem]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleOnBlur = (obj: Record<string, any>) => {
    setVariantProductProperties((prevVal) => ({ ...prevVal, ...obj }));
    const newVariantProperties = { ...variantProductProperties, ...obj };
    handleTabs(newVariantProperties);
  };
  const handleTabs = (newVariantProperties) => {
    if (
      newVariantProperties.name != "" &&
      newVariantProperties.price != "" &&
      newVariantProperties.categories[0] != "/" &&
      newVariantProperties.whatIsIt != "<p><br></p>"
    ) {
      setDisableImageTab(false);
    } else {
      setDisableImageTab(true);
    }
    if (newVariantProperties.image.file) {
      setDisableShippingTab(false);
    }
    if (
      newVariantProperties.length !== "" &&
      newVariantProperties.weight !== "" &&
      newVariantProperties.height !== "" &&
      newVariantProperties.breadth !== ""
    ) {
      setDisableSEOTab(false);
      setDisableInventoryTab(false);
    } else {
      setDisableSEOTab(true);
      setDisableInventoryTab(true);
    }
    if (newVariantProperties.availableQuantity != "") {
      setDisableAttributeTab(false);
    } else {
      setDisableAttributeTab(true);
    }
  };

  const handleGenralTabValues = () => {
    setDisableImageTab(false);
    setValue(1);
  };

  const handleInventoryProperties = () => {
    setDisableAttributeTab(false);
    setValue(5);
  };
  const handleSEOProperties = () => {
    setValue(4);
  };
  const handleShippingProperties = () => {
    setDisableInventoryTab(false);
    setValue(3);
  };
  const handleImagesProperties = (obj: Record<string, any>) => {
    setVariantProductProperties((prevVal) => ({ ...prevVal, ...obj }));
    setDisableShippingTab(false);
    setValue(2);
  };

  const label = (
    <p>
      Properties/
      <br />
      Attributes
    </p>
  );
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
          hidden={editMode}
        >
          <Tab
            label="General"
            {...a11yProps(0)}
            style={{ alignItems: "flex-end" }}
          />
          <Tab
            label="Images"
            {...a11yProps(1)}
            style={{ alignItems: "flex-end" }}
            disabled={disableImageTab}
          />
          <Tab
            label="Shipping"
            {...a11yProps(2)}
            style={{ alignItems: "flex-end" }}
            disabled={disableShippingTab}
          />
          <Tab
            label="SEO"
            {...a11yProps(3)}
            style={{ alignItems: "flex-end" }}
            disabled={disableSEOTab}
          />
          <Tab
            label="Inventory"
            {...a11yProps(4)}
            style={{ alignItems: "flex-end" }}
            disabled={disableInventortTab}
          />
          <Tab
            label={label}
            {...a11yProps(5)}
            style={{ alignItems: "flex-end" }}
            disabled={disableAttributeTab}
          />
        </Tabs>
        <TabPanel value={value} index={editMode ? 10 : 0}>
          <General
            // disabled={disableAttributeTab}
            handleGenralTabValues={handleGenralTabValues}
            productProperties={variantProductProperties}
            allCategories={allCategories}
            handleOnBlur={handleOnBlur}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ImagesComponent
            handleImagesProperties={handleImagesProperties}
            productProperties={variantProductProperties}
            handleOnBlur={handleOnBlur}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ShippingComponent
            handleShippingProperties={handleShippingProperties}
            productProperties={variantProductProperties}
            handleOnBlur={handleOnBlur}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SEOComponent
            handleSEOProperties={handleSEOProperties}
            productProperties={variantProductProperties}
            handleOnBlur={handleOnBlur}
            onCancel={onCancel}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Inventory
            handleInventoryProperties={handleInventoryProperties}
            productProperties={variantProductProperties}
            handleOnBlur={handleOnBlur}
            editItem={editItem}
            editMode={editMode}
            onCancel={onCancel}
            loading={loading}
            variant="true"
          />
        </TabPanel>
        <TabPanel value={value} index={editMode ? 0 : 5}>
          <Attributes
            allCategories={allCategories}
            // handleSaveProduct={handleSaveVariantProduct}
            productProperties={variantProductProperties}
            onCancel={onCancel}
            editItem={editItem}
            editMode={editMode}
            handleOnBlur={handleOnBlur}
            loading={loading}
            onClose={onClose}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default Interceptor(VariantComponent);
