import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonTable from "components/CommonTable/CommonTable";
import { usePagination } from "hooks/tablePaginationHook";
import { cart } from "Services/API/IndividualApis/Cart";
import { cancelorder } from "Services/API/IndividualApis/CancelOrders/indes";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";
import CancelledOrderDetail from "./CancelledOrderDetail";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Modal from "@mui/material/Modal/Modal";
import { ICancelOrder } from "Interfaces/Api/CancelOrder";
import { IOrder } from "Interfaces/Api/Orders";
import { IPagination } from "Interfaces/Api/Pagination";
import CancelRequestStatusModal from "./CancelRequestStatusModal";

const CanceledOrderList = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [page, handleChangePage] = usePagination();
  const [allCancelledOrderList, setAllCancelledOrderList] = useState<
    ICancelOrder[]
  >([]);
  const [orderDetailPage, setOrderDetailPage] = useState<boolean>(false);
  const [orderDetail, setOrderDetail] = useState<IOrder>();
  const [rejectState, setRejectState] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    fetchAllAbandonedList();
  }, [page, rowsPerPage]);

  const fetchAllAbandonedList = async () => {
    try {
      const response = await cancelorder.getAll(page, rowsPerPage);
      setAllCancelledOrderList(response.data);
      setShowPagination(response.pagination);
    } catch (err) {
      console.log(err);
    }
  };

  const rowsData = allCancelledOrderList?.map((item) => {
    return {
      order: item?.order,
      name: item?.order,
      reason: item?.reason,
      status: item?.status,
      id: item?._id,
      actions: item,
    };
  });

  const cols = [
    { field: "order", headerName: "Order", width: 250 },
    { field: "reason", headerName: "Reason", width: 350 },

    {
      field: "status",
      headerName: "Status",
      width: 250,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            <VisibilityIcon
              onClick={() => handleOrderDetails(params.value)}
              style={{ marginRight: "10px" }}
            />
            <CheckCircleIcon
              onClick={() => {
                setOrderDetail(params.value);
                setOpenModal(true);
                setRejectState(false);
              }}
              style={{ marginRight: "10px" }}
            />
            <CancelIcon
              onClick={() => {
                setOrderDetail(params.value);
                setOpenModal(true);
                setRejectState(true);
              }}
              style={{ marginRight: "10px" }}
            />
          </div>
        );
      },
    },
  ];

  const Navigate = useNavigate();

  const handleOrderDetails = async (item) => {
    Navigate(`/admin/orders/cancelRequests/${item.order}`, { state: item });
  };

  const addItems = () => { };

  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => { };

  const onSubmitCancelRequestStatus = async () => {
    if (rejectState) {
      let obj = {
        status: "REJECTED",
      };
      await handleOrderStatusChange(orderDetail._id, obj);
    } else {
      let obj = {
        status: "APPROVED",
      };
      await handleOrderStatusChange(orderDetail._id, obj);
    }
  };

  const handleOrderStatusChange = async (id, obj) => {
    try {
      const response = await cancelorder.patch(id, obj);
      setOpenModal(false);
      fetchAllAbandonedList();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {orderDetailPage ? (
        <>
          <CancelledOrderDetail />
        </>
      ) : (
        <CommonTable
          Title="Cancel requests"
          button="Add"
          handleChangePage={handleChangePage}
          addItems={addItems}
          totalRows={showPagination?.totalCount}
          handleSearchTextChanged={(value) => console.log(value)}
          searchText=""
          page={page}
          showFilter={false}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rows={rowsData}
          columns={cols}
          handleFilter={handleFilter}
        />
      )}
      {openModal ? <CancelRequestStatusModal onSubmitCancelRequestStatus={onSubmitCancelRequestStatus} openModal={openModal} onHide={() => setOpenModal(false)} rejectState={rejectState} /> : ""}    </>
  );
};

export default CanceledOrderList;
