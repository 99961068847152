export const INCREMENT = "INCREMENT";
export const LOGIN = "LOGIN";
export const CHECK_AUTH_TOKEN = "CHECK_AUTH";
export const CHECK_REFRESH_TOKEN = "CHECK_REFRESH_TOKEN";
export const REFRESH_AUTH_TOKEN = "REFRESH_AUTH_TOKEN";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGOUT = "LOGOUT";
export const SAVE_MY_DETAILS = "SAVE_MY_DETAILS";
export const UPDATE_USER = "UPDATE_USER";
export const STOP_LOADING = "STOP_LOADING";
export const SHOWERROR = "SHOWERROR";
export const HIDEERROR = "HIDEERROR";
