import React, { useEffect, useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CommonTable from "components/CommonTable/CommonTable";
import { usePagination } from "hooks/tablePaginationHook";
import { cart } from "Services/API/IndividualApis/Cart";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ICart } from "Interfaces/Api/Cart";
import { IPagination } from "Interfaces/Api/Pagination";

const Abandoned = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [page, handleChangePage] = usePagination();
  const [allAbandoned, setAllAbandoned] = useState<ICart[]>([]);
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  const [searchText, setSearchText] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchAllAbandonedList();
  }, [page, extraQueryParam, rowsPerPage]);

  useEffect(() => {
    if (searchText != undefined) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, page, rowsPerPage]);

  const handleSearch = async () => {
    if (searchText == "") {
      await fetchAllAbandonedList();
    } else {
      try {
        const response = await cart.search(searchText, page, rowsPerPage);
        setAllAbandoned(response.data);
        setShowPagination(response.pagination);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const fetchAllAbandonedList = async () => {
    setLoading(true);
    try {
      let query = `&filter={"$and":[{"status":"ABANDONED"}]}`;
      const response = await cart.getAll(page, rowsPerPage, query);
      setLoading(false);
      setAllAbandoned(response.data);
      setShowPagination(response.pagination);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const rowsData = allAbandoned?.map((item) => {
    let date = item.createdAt;
    let newDate = new Date(date);

    const newDates = moment(newDate).calendar(null, {
      sameDay: "[today]",
      nextDay: "[Tomorrow]",
      nextWeek: "DD/MM/YYYY",
      lastDay: "DD/MM/YYYY",
      lastWeek: "DD/MM/YYYY",
      sameElse: "DD/MM/YYYY",
    });
    return {
      name: item?.user,
      totalPrice: item?.totalPrice,
      date: newDates,
      status: item?.status,
      id: item?._id,
      actions: item,
    };
  });

  const cols = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        filterable: true,
        renderCell: (params) => (
          <div>
            <Link to={`/admin/users/${params?.row?.name?._id}`}>
              {params.row.name ? <div>{params.row.name.name}</div> : "- - "}
            </Link>
          </div>
        ),
      },
      {
        field: "totalPrice",
        headerName: "Total Price",
        width: 200,
        renderCell: (params) => <div>&#8377; {params.value}</div>,
      },
      {
        field: "date",
        headerName: "Date",
        width: 200,
        renderCell: (params) => {
          if (params.row.date === "Today") {
            let date = params.row.order?.createdAt;
            let newDate = new Date(date);
            let today = new Date();
            var start = moment(newDate);
            var end = moment(today);
            return <>{start.from(end)}</>;
          } else {
            return <>{params.row.date}</>;
          }
        },
      },
      { field: "status", headerName: "Status", width: 260 },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => {
          return (
            <div>
              <VisibilityIcon
                onClick={() => handleAbandonedCartDetails(params.value)}
                style={{ marginRight: "10px", cursor: "pointer" }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const Navigate = useNavigate();

  const handleAbandonedCartDetails = async (item) => {
    Navigate(`/admin/orders/cart_abandoned/${item._id}`, { state: item });
  };

  const addItems = () => {};

  const handleFilter = (filterRows, linkedOperator) => {
    const filterArr = [];
    filterRows.forEach((element) => {
      const obj = {
        [element.name]: {
          [element.operator]: element.value,
        },
      };
      filterArr.push(obj);
    });
    const filterObj = {
      [linkedOperator]: filterArr,
    };
    const queryParam = `&filter=${JSON.stringify(filterObj)}`;
    setExtraQueryParam(queryParam);
  };

  return (
    <>
      <CommonTable
        Title="Abandoned"
        button="Add Coupon"
        handleChangePage={handleChangePage}
        addItems={addItems}
        totalRows={showPagination?.totalCount}
        handleSearchTextChanged={(value) => setSearchText(value)}
        searchText={searchText}
        page={page}
        showFilter={false}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(value) => setRowsPerPage(value)}
        rows={rowsData}
        columns={cols}
        handleFilter={handleFilter}
        loading={loading}
      />
    </>
  );
};

export default Abandoned;
