import React from "react";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import ClearIcon from "@mui/icons-material/Clear";
import makeStyles from "@mui/styles/makeStyles";
import { grayColor } from "assets/jss/material-dashboard-react";
import { ColorPicker, createColor } from "material-ui-color";

const FilterOption = ({ item, removeOption, displayType }) => {
  const useStyles = makeStyles((theme) => ({
    grid: {
      position: "relative",
      padding: "5px 20px 5px 5px",
      color: "white",
      // background: grayColor[0],
      background: displayType == "color" ? item : grayColor[0],
      cursor: "pointer",
      fontSize: "1rem",
      margin: "1rem 0",
    },
    clearIcon: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "15px",
      height: "15px",
      margin: "2px",
    },
  }));
  const handleChange = () => {};
  const classes = useStyles();
  return (
    <GridItem onClick={() => removeOption(item)}>
      <div className={classes.grid}>
        <ClearIcon className={classes.clearIcon} />
        {/* <ColorPicker value={item} onChange={handleChange} /> */}
        <span>{item}</span>
      </div>
    </GridItem>
  );
};

export default FilterOption;
