import { Autocomplete, Modal, Select, TextField } from "@mui/material";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./userDetails.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { user } from "Services/API/IndividualApis/Users";
import { Roles } from "Interfaces/Api/User";
import { useSelector } from "react-redux";
import { IPolicy } from "Interfaces/Api/Policy";
import { apiPolicy } from "Services/API/IndividualApis/Policy";
import ChangeModal from "./ChangeModal";
import AttachPolicyModal from "./AttchPolicyModal";
interface RoleChangeData {
  role: Roles;
  policy?: string;
}

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState<any>("");
  const [changeRole, setChangeRole] = useState<any>("");
  const [changeRoleModel, setChangeRoleModel] = useState<boolean>(false);
  const [policyChangeModel, setPolicyChangeModel] = useState<boolean>(false);
  const [roleChangeEvent, setRoleChangeEvent] = useState<any>("");
  const [allPolicies, setAllPolicies] = useState<IPolicy[]>([]);
  const [selectedPolicy, setSelectedPolicy] = useState<any>();
  const [selectedPolicyInput, setSelectedPolicyInput] = useState<string>();
  const [
    userDataWhoseRoleToBeChangedModel,
    setUserDataWhoseRoleToBeChangedModal,
  ] = useState<boolean>(false);
  const [isPolicyModalVisible, setIsPolicyModalVisible] =
    useState<boolean>(false);
  const Navigate = useNavigate();

  const handleBackButton = () => {
    Navigate(-1);
  };

  useEffect(() => {
    if (userDetails && userDetails.profile.role == "Admin") {
      fetchAllPolicies();
    }
  }, [userDetails]);

  const fetchAllPolicies = async () => {
    try {
      const response = await apiPolicy.getAll(1, 50);
      setAllPolicies(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePolicyChange = async () => {
    setPolicyChangeModel(true);
  };
  const HandlePolicy = async () => {
    const updateData: RoleChangeData = {
      role: userDetails.profile.role,
      policy: selectedPolicy._id,
    };

    await user.updateUserRole(userDetails._id, updateData);
    setPolicyChangeModel(false);
    getUserDetailsById(id);
  };

  // const handleRoleChange = async (event, param) => {
  //   setChangeRoleModel(true);
  //   setChangeRole(param);
  //   setRoleChangeEvent(event.target.value);
  //   await HandleChangeRole();
  // };
  const [userId, setUserId] = useState<string>("");

  const handleRoleChange = async (event, param) => {
    setUserId(param);
    setRoleChangeEvent(event.target.value);
    if (event.target.value === Roles.ADMIN) {
      setIsPolicyModalVisible(true);
    } else {
      setChangeRoleModel(true);
    }
  };

  // const HandleChangeRole = async () => {
  //   await user.updateUserRole(id, { role: roleChangeEvent });
  //   setChangeRoleModel(false);
  //   getUserDetailsById(id);
  // };
  const userDetail = useSelector((state: any) => state.auth.user);

  const HandleChangeRole = async (policyId?: string) => {
    const updateData: RoleChangeData = {
      role: roleChangeEvent,
    };
    if (userDetail.role === Roles.SUPER_ADMIN && policyId) {
      updateData.policy = policyId;
    }

    await user.updateUserRole(userDetails._id, updateData);
    setUserDataWhoseRoleToBeChangedModal(false);
    setChangeRoleModel(false);
    await getUserDetailsById(id);
  };

  const { id } = useParams();
  const currentUser = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    getUserDetailsById(id);
  }, []);

  const getUserDetailsById = async (id: string) => {
    try {
      const response = await user.getSingle(id);
      setUserDetails(response);
    } catch (err) {
      console.log(err);
    }
  };

  const address = userDetails?.profile?.shippingAddresses;

  const onHide = () => {
    setChangeRoleModel(false);
  };

  return (
    <GridContainer>
      <GridItem sm={12}>
        {userDetails && (
          <Card>
            <CardHeader className="header">
              {/* <CardHeader color="primary"> */}
              <h4>{`${userDetails?.name}`}</h4>
            </CardHeader>
            <CardBody>
              <Container style={{ textTransform: "capitalize" }}>
                <Row>
                  <Col sm="6" className={classes.keyValue}>
                    <h3> Name:</h3>
                    {userDetails.name ? (
                      <p>{`${userDetails.name}`}</p>
                    ) : (
                      <p>Not Set</p>
                    )}
                  </Col>
                  <Col sm="6" className={classes.keyValue}>
                    <h3> Email:</h3>
                    {userDetails.profile.email ? (
                      <p>{userDetails.profile.email}</p>
                    ) : (
                      <p>Not Set</p>
                    )}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm="6" className={classes.keyValue}>
                    <h3> Address</h3>

                    {address ? (
                      address.map(
                        (
                          item: {
                            name: any;
                            isDefault: any;
                            line1: any;
                            line2: any;
                            city: any;
                            zipCode: any;
                            state: any;
                            country: any;
                            phoneNumber: any;
                          },
                          index: React.Key
                        ) => {
                          return (
                            <p key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span> {`${item.name}`}</span>
                                {item.isDefault && (
                                  <span
                                    style={{
                                      padding: "0px 10px",
                                      background: "#d9d9d9",
                                      fontSize: "1rem",
                                      marginRight: "2rem",
                                    }}
                                  >
                                    Default
                                  </span>
                                )}
                              </div>
                              <div>
                                {" "}
                                {`${item.line1}, ${item.line2}, ${item.city}-${item.zipCode}, ${item.state}, ${item.country}`}
                              </div>
                              <div>{`${item.phoneNumber}`}</div>
                            </p>
                          );
                        }
                      )
                    ) : (
                      <p>Not Set</p>
                    )}
                  </Col>
                  <Col sm="6" className={classes.keyValue}>
                    <h3>Gender</h3>
                    {userDetails.profile.gender ? (
                      <p>{userDetails.profile.gender}</p>
                    ) : (
                      <p>Not Set</p>
                    )}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm="6" className={classes.keyValue}>
                    <h3> Phone Number</h3>
                    {userDetails.phoneNumber ? (
                      <p>{userDetails.phoneNumber}</p>
                    ) : (
                      <p>Not Set</p>
                    )}
                  </Col>
                  <Col sm="6" className={classes.keyValue}>
                    <h3>Role</h3>
                    <Select
                      native
                      value={userDetails.profile.role}
                      onChange={(e) => handleRoleChange(e, userDetails._id)}
                      disabled={
                        currentUser.role === Roles.SUPER_ADMIN ? false : true
                      }
                      name="Select Role"
                      inputProps={{
                        id: "age-native-required",
                      }}
                    >
                      {Object.values(Roles).map((role, index) => {
                        return (
                          <option
                            key={index}
                            value={role}
                            disabled={role == Roles.SUPER_ADMIN ? true : false}
                          >
                            {role}
                          </option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
                <hr />

                {userDetails.profile.role == "Admin" && (
                  <>
                    {" "}
                    <Row>
                      <Col sm="6" className={classes.keyValue}>
                        <h3>Policy</h3>

                        <Autocomplete
                          id="tags-outlined"
                          disabled={currentUser.role == "Admin" ? true : false}
                          options={allPolicies.map((option, index) => option)}
                          getOptionLabel={(option) => option.name}
                          onInputChange={(event, newValue) => {
                            setSelectedPolicyInput(newValue);
                          }}
                          onChange={(event, newValue) => {
                            handlePolicyChange();
                            setSelectedPolicy(newValue);
                          }}
                          value={userDetails?.policy}
                          filterSelectedOptions
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Policy" />
                          )}
                        />
                      </Col>
                    </Row>
                    <hr />
                  </>
                )}
              </Container>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "2rem 1rem",
                }}
              >
                <Button color="success" onClick={handleBackButton}>
                  Back
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </GridItem>
      {isPolicyModalVisible && (
        <AttachPolicyModal
          userId={userId}
          show={isPolicyModalVisible}
          onHide={() => setIsPolicyModalVisible(false)}
          handleChangeRole={HandleChangeRole}
          editMode={true}
        />
      )}
      <ChangeModal
        openModal={policyChangeModel}
        onHide={() => setPolicyChangeModel(false)}
        handleChange={HandlePolicy}
        userDetails={userDetails}
      />
      {changeRoleModel ? (
        <Modal
          open={changeRoleModel}
          onClose={onHide}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridContainer sm={5}>
            <Card>
              <GridItem>
                <CardBody>
                  <GridItem>
                    <h2 style={{ textAlign: "center" }}>
                      Do you want to change the role of "{userDetails.name}"?
                    </h2>
                    <GridContainer
                      style={{ justifyContent: "center", paddingTop: "10px" }}
                    >
                      <GridItem>
                        <Button
                          color="danger"
                          onClick={() => setChangeRoleModel(false)}
                        >
                          Cancel
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button
                          color="success"
                          onClick={() => HandleChangeRole()}
                        >
                          Change
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </CardBody>
              </GridItem>
            </Card>
          </GridContainer>
        </Modal>
      ) : (
        ""
      )}
    </GridContainer>
  );
};

export default UserDetails;
