import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

import Button from "components/CustomButtons/Button";

import Grid from "components/Grid/Grid";

import {
  IPostshippingConfigData,
  IShippingConfig,
} from "Interfaces/Api/config";
import { useEffect, useState } from "react";
import { apiConfig } from "Services/API/IndividualApis/Config";
import AddOrEditShippingRange from "./AddOrEditShippingRange";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CustomDeleteModal from "components/CustomDeleteModal";
import { isEmpty } from "Services/Helper";
import { useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

export default function ShippingConfig({ configData, getData }) {
  const { state } = useLocation() as any;
  const [defaultShippingRate, setDefaultShippingRate] = useState<number>(0);
  const [isAddRangeModalOpen, setIsAddRangeModalOpen] =
    useState<boolean>(false);
  const [shippingRanges, setShippingRanges] = useState<IShippingConfig[]>([]);
  const [toBeChangedShippingDefaultRate, setToBeChangedShippingDefaultRate] =
    useState<boolean>(false);
  const [tempDefaultShippingRate, setTempDefaultShippingRate] = useState<any>();
  const [editData, setEditData] = useState<IShippingConfig | null>(null);

  const [deleteData, setDeleteData] = useState<IShippingConfig | null>(null);
  async function handleSave(data: IShippingConfig, _id: string | null) {
    let postData: IPostshippingConfigData;
    if (_id) {
      const shipingConfigRangeData = shippingRanges;
      const idx = shippingRanges.findIndex((item) => item._id === _id);
      shipingConfigRangeData[idx] = data;
      postData = {
        shippingConfigData: [...shipingConfigRangeData],
        defaultShippingCharge: defaultShippingRate,
      };
    } else {
      postData = {
        shippingConfigData: [...shippingRanges, data],
        defaultShippingCharge: defaultShippingRate,
      };
    }

    await saveShippingConfig(postData);
    handleClose();
  }

  async function handleDeleteRange() {
    const shippingRangeData = shippingRanges.filter(
      (item) => item._id !== deleteData._id
    );
    const postData: IPostshippingConfigData = {
      shippingConfigData: shippingRangeData,
      defaultShippingCharge: tempDefaultShippingRate,
    };
    await saveShippingConfig(postData);
    setDeleteData(null);
  }
  function handleClose() {
    setIsAddRangeModalOpen(false);
    setEditData(null);
  }

  // after unmounted
  useEffect(() => {
    if (configData && !isEmpty(configData)) {
      setShippingRanges(configData.shippingConfgRanges);
      setDefaultShippingRate(configData.defaultShippingCharge);
      setTempDefaultShippingRate(configData.defaultShippingCharge);
    }
  }, [configData]);

  async function saveShippingConfig(postData: IPostshippingConfigData) {
    try {
      const response = await apiConfig.storeShippingConfig(postData);
      getData();
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDefaultShippingRateChange() {
    const postData: IPostshippingConfigData = {
      shippingConfigData: shippingRanges,
      defaultShippingCharge: tempDefaultShippingRate,
    };
    await saveShippingConfig(postData);
    setToBeChangedShippingDefaultRate(false);
  }

  function rangeEditIconClickHandler(data: IShippingConfig) {
    setEditData(data);
    setIsAddRangeModalOpen(true);
  }
  const getRows = () => {
    return shippingRanges.map((range, index) => ({
      index: index + 1,
      minimumShippingCharge: range.minimumShippingCharge,
      maximumShippingCharge: range.maximumShippingCharge,
      minimumOrderValue: range.minimumOrderValue,
      maximumOrderValue: range.maximumOrderValue,
      shippingChargeToTakeFromCustomer: range.shippingChargeToTakeFromCustomer,
      actions: (
        <Box>
          {state?.access.write && (
            <Icon
              component={EditIcon}
              onClick={() => rangeEditIconClickHandler(range)}
            ></Icon>
          )}
          {state?.access.delete && (
            <Icon
              component={DeleteIcon}
              onClick={() => {
                setDeleteData(range);
              }}
            ></Icon>
          )}
        </Box>
      ),
    }));
  };

  function handleCancel() {
    setTempDefaultShippingRate(defaultShippingRate);
    setToBeChangedShippingDefaultRate(false);
  }

  return (
    <Box>
      <Grid container>
        {toBeChangedShippingDefaultRate ? (
          <>
            <Grid item style={{ position: "relative" }}>
              <TextField
                label="Default shipping rate"
                //   categoryId="company-disabled"

                inputProps={{
                  value: tempDefaultShippingRate
                    ? parseInt(tempDefaultShippingRate)
                    : tempDefaultShippingRate,
                  // type: "number",
                  // value: tempDefaultShippingRate,
                }}
                onChange={(e) => {
                  if (e.target.value && !Number(e.target.value)) return;
                  setTempDefaultShippingRate(e.target.value);
                }}
              />
              <Tooltip
                style={{ position: "absolute", right: "-10px", top: "-15px" }}
                title="Default shipping rate is applicable if Shipping Charge To Be Taken From Customer is not applicable."
              >
                <InfoIcon />
              </Tooltip>
            </Grid>
            <Grid item>
              <Button color="grey" onClick={handleCancel}>
                Cancel
              </Button>
              <Button color="success" onClick={handleDefaultShippingRateChange}>
                Submit
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Typography>
                Default Shipping Rate: {defaultShippingRate}
              </Typography>
            </Grid>
            {state?.access.write && (
              <Grid item>
                <Button
                  color="success"
                  onClick={() => setToBeChangedShippingDefaultRate(true)}
                >
                  Change
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <AddOrEditShippingRange
        editData={editData}
        handleClose={handleClose}
        handleSave={handleSave}
        isAddRangeModalOpen={isAddRangeModalOpen}
      />

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          {state?.access.write && (
            <Icon
              sx={{ cursor: "pointer" }}
              fontSize="large"
              component={AddBoxIcon}
              onClick={() => setIsAddRangeModalOpen(true)}
            ></Icon>
          )}
        </Box>
        {shippingRanges.length > 0 ? (
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell align="left">Shipping Charge From</TableCell>
                    <TableCell align="left">Shipping Charge To</TableCell>
                    <TableCell align="left">Order Value From</TableCell>
                    <TableCell align="left">Order Value To</TableCell>
                    <TableCell align="left">
                      Shipping Charge To Be Taken From Customer
                    </TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRows() &&
                    getRows().map((row) => (
                      <TableRow
                        key={row.index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.index}
                        </TableCell>
                        <TableCell align="left">
                          {row.minimumShippingCharge}
                        </TableCell>
                        <TableCell align="left">
                          {row.maximumShippingCharge}
                        </TableCell>
                        <TableCell align="left">
                          {row.minimumOrderValue}
                        </TableCell>
                        <TableCell align="left">
                          {row.maximumOrderValue}
                        </TableCell>
                        <TableCell align="left">
                          {row.shippingChargeToTakeFromCustomer}
                        </TableCell>

                        <TableCell align="left">{row.actions}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* {allBaners.map((banner) => {
          return (
            <>
              <h1>{banner.name}</h1>
              <h1>{banner.url}</h1>
              <img width={100} src={banner.image.mobile.url} />
              <img width={100} src={banner.image.desktop.url} />
            </>
          );
        })} */}
          </Box>
        ) : (
          <Box>No Shipping Config Ranges Added</Box>
        )}
      </Box>
      {deleteData && (
        <CustomDeleteModal
          onHide={() => setDeleteData(null)}
          show={!!deleteData}
          itemName={`range config with shipping range: ${deleteData.minimumShippingCharge}-${deleteData.maximumShippingCharge} & order value range: ${deleteData.minimumOrderValue}-${deleteData.maximumOrderValue} & Shipping charge to be taken from the customer:${deleteData.shippingChargeToTakeFromCustomer}`}
          deleteItem={handleDeleteRange}
        />
      )}
    </Box>
  );
}
