import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import EditIcon from "@mui/icons-material/Edit";
import classes from "./Orders.module.scss";
import Button from "components/CustomButtons/Button";
import { IOrder } from "Interfaces/Api/Orders";

interface ShippingAddressProps {
  orderDetails: IOrder;
  onSaveOrderDetails: Function;
}
const ShippingAddress = ({ orderDetails, onSaveOrderDetails }: ShippingAddressProps) => {

  const [name, setName] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [line1, setLine1] = useState<string>("");
  const [line2, setLine2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [zipCode, setZipCode] = useState<number>()
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [transactionId, setTransactionId] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false)
  const [stateByPinCode, setStateByPinCode] = useState<any>("")

  useEffect(() => {
    if (stateByPinCode) {
      const newPinCode = stateByPinCode[0]?.PostOffice[0]
      setState(newPinCode.State)
      setCity(newPinCode.District)
    }
  }, [stateByPinCode])

  const handlePostalCode = () => {
    fetch(`https://api.postalpincode.in/pincode/${zipCode}`)
      .then(response => {
        if (response) {
          return response.json()
        }
      })
      .then(data => {
        setStateByPinCode(data)
      })
  }

  useEffect(() => {
    if (orderDetails) {
      setName(orderDetails.shippingAddress?.name)
      setCountry(orderDetails.shippingAddress?.country)
      setCity(orderDetails.shippingAddress?.city)
      setLine1(orderDetails.shippingAddress?.line1)
      setLine2(orderDetails.shippingAddress?.line2)
      setState(orderDetails.shippingAddress?.state)
      setZipCode(orderDetails.shippingAddress?.zipCode)
      setEmail(orderDetails.user.email)
      setPhoneNumber(orderDetails.user.phoneNumber)
      setPaymentMethod(orderDetails.paymentDetails?.paymentMethod)
      setTransactionId(orderDetails.paymentDetails?.cfDetails?.cfTransactionDetails?.referenceId)
    }
  }, [orderDetails])

  const editShippingAddress = () => {
    setEditMode((prevState) => !prevState)

  }
  const onSaveShppingAddress = () => {
    let obj = {
      name: name,
      city: city,
      country: country,
      state: state,
      zipCode: zipCode,
      email: email,
      phoneNumber: phoneNumber,
      line1: line1,
      line2: line2,
    }
    onSaveOrderDetails(obj)
    setEditMode(false)
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <div className={classes.container} style={{ display: "flex", alignItems: "center", marginTop: "27px" }}>
            <h4 style={{ marginRight: "1rem" }}>Shipping Address</h4>
            <EditIcon onClick={editShippingAddress} />
          </div>
        </GridItem>
      </GridContainer >

      {editMode ?
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Full name*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: name,
                onChange: (e) => { setName(e.target.value) },
              }}
            />

          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Address Line1*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: line1,
                onChange: (e) => setLine1(e.target.value)
              }}
            />

          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Address Line2*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: line2,
                onChange: (e) => setLine2(e.target.value)

              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="City*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: city,
                onChange: (e) => setCity(e.target.value)
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="zipCode/ZIP*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: zipCode,
                onChange: (e) => setZipCode(e.target.value),
                // onkeydown: handlePostalCode,
                onBlur: handlePostalCode

              }}
            />

          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Country*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: country,
                onChange: (e) => setCountry(e.target.value)
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="State*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: state,
                onChange: (e) => setState(e.target.value)
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Email*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: email,
                onChange: (e) => setEmail(e.target.value)
              }}
            />

          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Phone Number*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                value: phoneNumber,
                onChange: (e) => setPhoneNumber(e.target.value)
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Payment method*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                disabled: disabled,
                value: paymentMethod,
                onChange: (e) => setPaymentMethod(e.target.value)
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText="Transaction ID*"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                disabled: disabled,
                value: transactionId,
                onChange: (e) => setTransactionId(e.target.value)
              }}
            />
          </GridItem>
          <GridItem style={{ margin: "27px 0px 0px 0px" }}>
            <Button color="success" onClick={onSaveShppingAddress}>
              save
            </Button>
          </GridItem>
        </GridContainer> :
        <GridContainer>
          <GridItem>
            <div className={classes.container}>
              <div>{name}</div>
              <div>{line1}</div>
              <div>{line2}</div>
              <div>{city} {" "}{zipCode}</div>
              <div>{state}</div>


            </div>
          </GridItem>

        </GridContainer>}

    </>
  )
}
export default ShippingAddress;