/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
// core components
import LogoutIcon from "@mui/icons-material/Logout";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import { connect, useSelector } from "react-redux";
import * as createActions from "../../store/actions";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Roles } from "Interfaces/Api/User";
import { Authorize } from "store/helper";
import { IPolicyAccessMethodOptions } from "Interfaces/Api/Policy";

const useStyles = makeStyles(styles);

function Sidebar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = React.useState({});
  const anchorRef = React.useRef(null);
  const otherRef = React.useRef(null);
  const Navigate = useNavigate();

  const handleToggle = (name) => {
    // close all other menu and open current
    let obj = {};

    Object.keys(open).forEach((item) => {
      obj[item] = false;
    });
    setOpen({
      ...obj,
      [name]: true,
    });
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        let accessdata = null;
        if (user) {
          if (user.role === Roles.SUPER_ADMIN) {
            accessdata = {
              [IPolicyAccessMethodOptions.READ]: true,
              [IPolicyAccessMethodOptions.WRITE]: true,
              [IPolicyAccessMethodOptions.DELETE]: true,
            };
          } else {
            accessdata = Authorize(user.policy, prop.module);
          }
        }

        if (!accessdata) return;
        var activePro = " ";
        var listItemClasses;

        if (!prop.subMenu) {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + "/" + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + "/" + prop.path),
        });

        if (props.subMenu && !open.hasOwnProperty(prop.name)) {
          // make the entry of this prop name in the open state with state equal to false
          setOpen((preState) => {
            return {
              ...preState,
              [props.name]: false,
            };
          });
        }
        return (
          <div>
            <NavLink
              to={
                prop.subMenu
                  ? prop.layout + "/" + prop.path + "/" + prop.subMenu[0].path
                  : prop.layout + "/" + prop.path
              }
              state={{ access: accessdata }}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem
                button
                className={
                  !prop.subMenu
                    ? classes.itemLink + listItemClasses
                    : classes.itemLink
                }
              >
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    ref={anchorRef}
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  // }}
                  onClick={() => handleToggle(prop.name)}
                ></ListItemText>{" "}
                {prop.subMenu && open[prop.name] && (
                  <Paper sx={{ background: "rgba(0,0,0,0.3)" }}>
                    {/* <ClickAwayListener
                    // onClickAway={() => handleToggle(prop.name)}
                    > */}
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {prop.subMenu.map((item) => {
                        const listItemSubMenuClasses = classNames({
                          [" " + classes[color]]: activeRoute(
                            prop.layout + "/" + prop.path + "/" + item.path
                          ),
                        });
                        return (
                          <NavLink
                            to={`${prop.layout}/${prop.path}/${item.path}`}
                            state={{ access: accessdata }}
                          >
                            <MenuItem
                              // onClick={handleClose}
                              sx={{ color: "white" }}
                              className={
                                classes.itemLink + listItemSubMenuClasses
                              }
                            >
                              {item.name}
                            </MenuItem>
                          </NavLink>
                        );
                      })}
                    </MenuList>
                    {/* </ClickAwayListener> */}
                  </Paper>
                )}
              </ListItem>
            </NavLink>
          </div>
        );
      })}

      <ListItem
        button
        className={classes.itemLink}
        // className={classes.itemLink + listItemClasses}
      >
        <Icon
          // style={{ color: "white" }}
          className={classes.itemIcon1}
          // className={classNames(classes.itemIcon, whiteFontClasses, {
          //   [classes.itemIconRTL]: props.rtlActive,
          // })}
        >
          <LogoutIcon />
        </Icon>
        <ListItemText
          // style={{ color: "white" }}
          primary={props.rtlActive ? props.rtlName : "Logout"}
          onClick={props.logout}
          className={classes.itemText}
          // className={classNames(classes.itemText, whiteFontClasses, {
          //   [classes.itemTextRTL]: props.rtlActive,
          // })}
          disableTypography={true}
        />
      </ListItem>
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      {/* <a
        href="https://www.creative-tim.com?ref=mdr-sidebar"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      > */}
      <div
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </div>
      {/* </a> */}
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.tokenId,
    isError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(createActions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
