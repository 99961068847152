import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IOrder } from "Interfaces/Api/Orders";
import { ICart, ICartItem } from "Interfaces/Api/Cart";
import GridItem from "components/Grid/GridItem";
import classes from "views/Orders/Orders.module.scss";

export interface ICustomProductDetailsTable {
  orderDetails?: IOrder;
  abandonedDetails?: ICart;
}
const CustomProductDetailsTable = ({
  orderDetails,
  abandonedDetails,
}: ICustomProductDetailsTable) => {
  const [subTotal, setSubTotal] = useState<number>(0);
  const [abandonedSubTotal, setAbandonedSubTotal] = useState<number>(0);

  useEffect(() => {
    let totalDiscount = 0;
    if (orderDetails) {
      orderDetails.products?.forEach((product) => {
        totalDiscount += product.finalPrice * product.quantity;
      });
      setSubTotal(totalDiscount);
    }
  }, [orderDetails]);

  useEffect(() => {
    let totalDiscount = 0;
    if (abandonedDetails) {
      abandonedDetails.cartItems?.forEach((item) => {
        totalDiscount += item.product?.finalPrice * item.quantity;
      });
      setAbandonedSubTotal(totalDiscount);
    }
  }, [abandonedDetails]);

  return (
    <>
      <TableContainer component={Paper} style={{ margin: "27px 0px 0px 0px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell align="right">Cost</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          {orderDetails &&
            orderDetails?.products.map((row) => (
              <TableBody key={row._id}>
                <TableRow>
                  <TableCell>
                    <img
                      src={row.image.url}
                      style={{ width: "100px", marginRight: "10px" }}
                    />
                    <span>{row?.name}</span>
                  </TableCell>
                  <TableCell align="right">{row.finalPrice}</TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">
                    {row.finalPrice * row.quantity}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          {abandonedDetails &&
            abandonedDetails.cartItems.map((item) => {
              return (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <img
                        src={item.product?.image?.url}
                        style={{ width: "100px", marginRight: "10px" }}
                      />
                      <span>{item.product?.name}</span>
                    </TableCell>
                    <TableCell align="right">
                      {item.product?.finalPrice}
                    </TableCell>
                    <TableCell align="right">{item?.quantity}</TableCell>
                    <TableCell align="right">
                      {item.product?.finalPrice * item?.quantity}
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
        </Table>
      </TableContainer>
      <GridContainer></GridContainer>
      <GridContainer>
        {orderDetails && (
          <GridItem
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "column",
              alignItems: "end",
              marginTop: "2rem",
            }}
          >
            <div className={classes.container}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                Item Subtotal:{"  "}&#8377; {subTotal}
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                Shipping:{"  "}&#8377;{" "}
                {orderDetails?.shippingChargeFromCustomer}
              </div>
              {orderDetails.appliedCoupons.map((coupon) => {
                return (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    Coupon({`${coupon.coupon.code}`}):&#8377;{" "}
                    {`${coupon.totalDiscount}`}
                  </div>
                );
              })}
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                Order Total:{"  "}&#8377; {orderDetails?.totalPrice}
              </div>
            </div>
          </GridItem>
        )}

        {abandonedDetails && (
          <GridItem
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "column",
              alignItems: "end",
              marginTop: "2rem",
            }}
          >
            <div className={classes.container}>
              <div>
                {" "}
                Item Subtotal:{"  "}&#8377;{abandonedSubTotal}
              </div>

              {abandonedDetails?.appliedCoupons &&
                abandonedDetails.appliedCoupons.map((coupon) => {
                  return (
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      Coupon({`${coupon?.coupon?.code}`}):&#8377;
                      {`${coupon?.totalDiscount}`}
                    </div>
                  );
                })}
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                Order Total:{"  "}&#8377;{abandonedDetails?.totalPrice}
              </div>
            </div>
          </GridItem>
        )}
      </GridContainer>
    </>
  );
};

export default CustomProductDetailsTable;
