import { Modal, Tooltip } from "@mui/material";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IShippingConfig } from "Interfaces/Api/config";
import { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";

const initialRangeData = {
  minimumOrderValue: 0,
  maximumOrderValue: 0,
  minimumShippingCharge: 0,
  maximumShippingCharge: 0,
  shippingChargeToTakeFromCustomer: 0,
};

interface IAddOrEditShippingRangeProps {
  handleSave: Function;
  handleClose: Function;
  isAddRangeModalOpen: boolean;
  editData: IShippingConfig | null;
}

export default function AddOrEditShippingRange({
  handleSave,
  handleClose,
  isAddRangeModalOpen,
  editData,
}: IAddOrEditShippingRangeProps) {
  const [rangeData, setRangeData] = useState<IShippingConfig>(initialRangeData);

  function rangeDataChangeHandler(key: keyof IShippingConfig, value: string) {
    setRangeData((preValue) => ({
      ...preValue,
      [key]: value,
    }));
  }

  useEffect(() => {
    if (editData) {
      setRangeData(editData);
    }
  }, [editData]);

  function handleCancel() {
    setRangeData(initialRangeData);
    handleClose();
  }
  return (
    <Modal
      open={isAddRangeModalOpen}
      onClose={() => console.log("hello")}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GridContainer sm={8}>
        <Card>
          <GridItem>
            <CardHeader className="header">
              <h4>Add a new Shipping Range</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem sm={6} style={{ position: "relative" }}>
                  <CustomInput
                    labelText="Minimum shipping Charge*"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "minimumShippingCharge",
                      // disabled: disabled,
                      value: rangeData.minimumShippingCharge,
                      type: "number",
                      onChange: (e) =>
                        rangeDataChangeHandler(
                          "minimumShippingCharge",
                          e.target.value
                        ),
                    }}
                  />
                  <Tooltip
                    style={{ position: "absolute", right: "0" }}
                    title="Minimum shipping charge ≤ Pickrr shipping charge."
                  >
                    <InfoIcon />
                  </Tooltip>
                </GridItem>
                <GridItem sm={6} style={{ position: "relative" }}>
                  <CustomInput
                    labelText="Maximum shipping charge*"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "maximumShippingCharge",
                      // disabled: disabled,
                      value: rangeData.maximumShippingCharge,
                      type: "number",
                      onChange: (e) =>
                        rangeDataChangeHandler(
                          "maximumShippingCharge",
                          e.target.value
                        ),
                    }}
                  />
                  <Tooltip
                    style={{ position: "absolute", right: "0" }}
                    title=" Maximum shipping charge ≥ Pickrr shipping charge."
                  >
                    <InfoIcon />
                  </Tooltip>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6} style={{ position: "relative" }}>
                  <CustomInput
                    labelText="Minimum order value*"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "minimumOrderValue",
                      // disabled: disabled,
                      value: rangeData.minimumOrderValue,
                      type: "number",
                      onChange: (e) =>
                        rangeDataChangeHandler(
                          "minimumOrderValue",
                          e.target.value
                        ),
                    }}
                  />
                  <Tooltip
                    style={{ position: "absolute", right: "0" }}
                    title="Order value/cart value ≥ minimum order value."
                  >
                    <InfoIcon />
                  </Tooltip>
                </GridItem>
                <GridItem sm={6} style={{ position: "relative" }}>
                  <CustomInput
                    labelText="Maximum order value*"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "maximumOrderValue",
                      // disabled: disabled,
                      value: rangeData.maximumOrderValue,
                      type: "number",
                      onChange: (e) =>
                        rangeDataChangeHandler(
                          "maximumOrderValue",
                          e.target.value
                        ),
                    }}
                  />
                  <Tooltip
                    style={{ position: "absolute", right: "0" }}
                    title="Order value/cart value ≤ maximum order value."
                  >
                    <InfoIcon />
                  </Tooltip>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6} style={{ position: "relative" }}>
                  <CustomInput
                    labelText="Flat shipping charge*"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "shippingChargeToTakeFromCustomer",
                      // disabled: disabled,
                      value: rangeData.shippingChargeToTakeFromCustomer,
                      type: "number",
                      onChange: (e) =>
                        rangeDataChangeHandler(
                          "shippingChargeToTakeFromCustomer",
                          e.target.value
                        ),
                    }}
                  />
                  <Tooltip
                    style={{ position: "absolute", right: "0" }}
                    title="This charge is applicable when these two conditions are satisfied.
1.whose total order price comes under  Minimum order value to Maximum order value .
2.Pickrr shipping charge comes under Minimum shipping Charge to Maximum shipping charge.
"
                  >
                    <InfoIcon />
                  </Tooltip>
                </GridItem>
              </GridContainer>

              <hr />
              <GridContainer style={{ justifyContent: "flex-end" }}>
                <GridItem>
                  <Button color="grey" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    onClick={() => {
                      handleSave(rangeData, editData ? editData._id : null);
                      handleCancel();
                    }}
                  >
                    Save
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </GridItem>
        </Card>
      </GridContainer>
    </Modal>
  );
}
