import React, { useCallback, useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import { Search } from "@mui/icons-material";
import classes from "./commonTable.module.css";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import "./commonTable.scss";
import {
  DataGrid,
  getGridNumericColumnOperators,
  GridColTypeDef,
  GridFilterModel,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CustomSearch from "components/CustomSearch/CustomSearch";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const CommonTable = ({
  Title,
  button,
  addItems,
  page,
  rowsPerPage,
  setRowsPerPage,
  totalRows,
  handleChangePage,
  handleExportCsv = () => console.log("hello"),
  isExportCSV = false,
  rows,
  columns,
  showAttributeTable = false,
  showAddButton = false,
  showFilter = true,
  loading = false,
  linkUrl = "",
  searchPlaceHolder = "Search by name",
  handleSearchTextChanged = (value) => console.log(value),
  searchText = undefined,
  handleFilter = (a, b, c = false) => console.log("hello"),
}) => {
  const operatorOptions = [
    { label: "Equal", value: "$eq" },
    { label: "Greater Than", value: "$gt" },
    { label: "Greater Than Equal", value: "$gte" },
    { label: "Less Than", value: "$lt" },
    { label: "Less Than Equal", value: "$lte" },
    { label: "In", value: "$in" },
  ];
  // const operator = [
  //   { label: "Equal", value: "$eq" },
  //   // { label: "Greater Than", value: "$gt" },
  //   // { label: "Greater Than Equal", value: "$gte" },
  //   // { label: "Less Than", value: "$lt" },
  //   // { label: "Less Than Equal", value: "$lte" },
  //   { label: "In", value: "$in" },
  // ];
  const [operator, setOperator] = useState<any>(operatorOptions);
  const [showFilterDropDown, setShowFilterDropDown] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(true)
  const [filterRows, setFilterRows] = useState<any>([
    {
      id: 0,
      name: columns[0].field,
      operator: "$eq",
      value: "",
    },
  ]);
  const [linkedOperator, setLinkedOperator] = useState("$and");

  useEffect(() => {
    if (columns) {
      const newFilterRow = [];
      const newFilterableOption = [];

      for (let key of columns) {
        if (key.filterable) {
          let obj = {
            name: key.field,
            operator: "$eq",
            value: key.options || "",
          };
          newFilterRow.push(obj);
          let obj1 = {
            name: key.field,
            operator: key.operator,
            value: key.options || "",
          };
          newFilterableOption.push(obj1);
        }
      }
      setFilterRows([newFilterRow[0]]);
      setOperator(newFilterableOption);
    }
    // setOperator
  }, [columns]);

  const handleAddNewFilterRow = () => {
    setFilterRows((preValue) => [
      ...preValue,
      {
        id: preValue.pop().id + 1,
        name: columns[0].field,
        operator: "$eq",
        value: "",
      },
    ]);
  };

  // const handleExportCsv=()=>{

  // }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        {/* <GridToolbarExport onClick={handleExportCsv} /> */}
        {isExportCSV ? (
          <span
            onClick={handleExportCsv}
            style={{
              color: "#1976d2",
              cursor: "pointer",
              fontSize: "0.8125rem",
            }}
          >
            <FileDownloadIcon />
            Export
          </span>
        ) : (
          ""
        )}
      </GridToolbarContainer>
    );
  }

  const handleDeleteFilter = (index) => {
    if (filterRows.length === 1) {
      setFilterRows((preValue) => [
        {
          ...preValue[0],
          operator: "$eq",
          value: "",
        },
      ]);
      handleFilter(filterRows, linkedOperator, true);
    } else {
      const newFilterRows = [...filterRows];
      newFilterRows.splice(index, 1);
      setFilterRows(newFilterRows);
    }
  };

  const handleChangeRowsPerPage = (newPageSize) => {
    setRowsPerPage(newPageSize);
  };

  const handleFilterPropertyChange = (index, property, value) => {
    const newFilterRows = [...filterRows];
    newFilterRows[index][property] = value;
    if (property == "operator" && value != "$in") {
      setFilterRows(() => [
        {
          name: filterRows[0].name,
          operator: filterRows[0].operator,
          value: "",
        },
      ]);
    } else {
      setFilterRows(newFilterRows);
    }
  };

  const handleFilterGo = () => {
    handleFilter(filterRows, linkedOperator);
  };

  const priceColumnType: GridColTypeDef = {
    extendType: "number",
    filterOperators: getGridNumericColumnOperators()
      .filter((operator) => operator.value === ">" || operator.value === "<")
      .map((operator) => {
        return {
          ...operator,
          InputComponentProps: {
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
        };
      }),
  };

  const getIsValueSelectable = (index) => {
    const name = filterRows[index].name;
    const options = filterRows[index].options;
    const targetItem = columns.filter((item) => item.field === name).pop();
    return {
      isValueSelectable: targetItem.isValueSelectable,
      options: options || targetItem.options,
      nameKey: targetItem.nameKey || "name",
    };
  };

  return (
    <>
      <GridContainer style={{ justifyContent: "flex-end" }}></GridContainer>
      <GridContainer style={{ overflow: "auto" }}>
        <GridItem sm={12}>
          <Card>
            {!showAttributeTable ? (
              <>
                {/* <CardHeader color="primary"> */}
                <CardHeader className="header">
                  <h4>{Title}</h4>
                </CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "1rem",
                  }}
                >
                  <div>
                    <div className="ml-4 mt-5" style={{ cursor: "pointer" }}>
                      {showFilter && (
                        <FilterAltIcon
                          onClick={() =>
                            setShowFilterDropDown((preValue) => !preValue)
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <CustomSearch
                      formControlProps={{ width: "300px" }}
                      inputProps={{
                        placeholder: searchPlaceHolder,
                        inputProps: {
                          "aria-label": "Search",
                        },
                        sx: { width: { md: "300px" } },
                        value: searchText,
                        onChange: (e) =>
                          handleSearchTextChanged(e.target.value),
                      }}
                    />
                    <div style={{ marginTop: "2rem", marginRight: "10px" }}>
                      <Button color="white" aria-label="edit" justIcon round>
                        <Search />
                      </Button>
                    </div>
                    <div style={{ marginTop: "2rem" }}>
                      {showAddButton ? (
                        <Link to={linkUrl}>
                          <Button color="success" onClick={addItems}>
                            {button}
                          </Button>
                        </Link>
                      ) : (
                        " "
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {showFilterDropDown && (
              <CardBody className={classes.filterCarbody}>
                {filterRows.map((item, index) => {
                  const {
                    isValueSelectable,
                    options: selectValueOptions,
                    nameKey,
                  } = getIsValueSelectable(index);
                  return (
                    <Row key={item.id} className="mt-2">
                      <Col sm={1} className="d-flex align-items-center">
                        <ClearIcon onClick={() => handleDeleteFilter(index)} />
                      </Col>
                      {filterRows.length > 1 && index === 0 && <Col sm={2} />}
                      {index > 0 && (
                        <Col sm={2}>
                          <InputLabel
                            id="demo-simple-select-label"
                            className={classes.filterLabel}
                          >
                            Link Operator
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            native
                            id="demo-simple-select"
                            label="Name"
                            variant="standard"
                            value={linkedOperator}
                            onChange={(e) => setLinkedOperator(e.target.value)}
                          >
                            <option value={"$or"}>or</option>
                            <option value={"$and"}>and</option>
                            ))
                          </Select>
                        </Col>
                      )}
                      <Col>
                        {/* <div>
                          <small>Name</small>
                        </div> */}
                        <InputLabel
                          id="demo-simple-select-label"
                          className={classes.filterLabel}
                        >
                          Columns
                        </InputLabel>
                        <div>
                          <Select
                            labelId="demo-simple-select-label"
                            native
                            id="demo-simple-select"
                            label="Columns"
                            variant="standard"
                            value={filterRows[index].name}
                            onChange={(e) =>
                              handleFilterPropertyChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          >
                            {columns.map((item, index) => {
                              if (item.filterable) {
                                return (
                                  <option value={item.field}>
                                    {item.field}
                                  </option>
                                );
                              }
                            })}
                          </Select>
                        </div>
                      </Col>
                      <Col style={{ width: "100%" }}>
                        {/* <div>
                          <small>Name</small>
                        </div> */}
                        <InputLabel
                          id="demo-simple-select-label"
                          className={classes.filterLabel}
                        >
                          Operators
                        </InputLabel>
                        <div>
                          <Select
                            labelId="demo-simple-select-label"
                            native
                            id="demo-simple-select"
                            label="Operators"
                            variant="standard"
                            value={filterRows[index].operator}
                            onChange={(e) =>
                              handleFilterPropertyChange(
                                index,
                                "operator",
                                e.target.value
                              )
                            }
                          >
                            {operator &&
                              operator.map((val) => {
                                if (val.name == filterRows[index].name) {
                                  return val.operator.map((item) => {
                                    return (
                                      <option value={item.value}>
                                        {item.label}
                                      </option>
                                    );
                                  });
                                }
                              })}
                            {/* {operator &&
                              operator.map((val, index) =>
                                val.operator.map((item, index) => (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                ))
                              )} */}
                            {/* {operatorOptions.map((item, index) => (
                              <option value={item.value}>{item.label}</option>
                            ))} */}
                          </Select>
                        </div>
                      </Col>
                      <Col>
                        {/* <div>
                          <small>Name</small>
                        </div> */}
                        <InputLabel
                          id="demo-simple-select-label"
                          className={classes.filterLabel}
                        >
                          Value
                        </InputLabel>

                        {isValueSelectable ? (
                          <Select
                            labelId="demo-simple-select-label"
                            // native
                            id="demo-simple-select"
                            // label="Columns"
                            variant="standard"
                            value={
                              filterRows[index].operator === "$in" &&
                                typeof filterRows[index].value === "string"
                                ? [filterRows[index].value || ""]
                                : filterRows[index].value
                            }
                            // value={[]}
                            // input={<OutlinedInput label="Columns" />}
                            multiple={filterRows[index].operator === "$in"}
                            onChange={(e) =>
                              handleFilterPropertyChange(
                                index,
                                "value",
                                e.target.value
                              )
                            }
                          >
                            {selectValueOptions.map((item, index) => {
                              return (
                                <MenuItem value={item[nameKey]} key={index}>
                                  {item[nameKey]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        ) : filterRows[index].operator === "$in" ? (
                          <Autocomplete
                            multiple
                            id="tags-filled"
                            fullWidth={true}
                            value={
                              typeof filterRows[index].value === "string" &&
                                filterRows[index].value
                                ? [filterRows[index].value]
                                : filterRows[index].value
                            }
                            onChange={(e, value) =>
                              handleFilterPropertyChange(index, "value", value)
                            }
                            options={[]}
                            // defaultValue={[top100Films[13].title]}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                // label="freeSolo"
                                placeholder="Type And Press Enter"
                              />
                            )}
                          />
                        ) : (
                          <Input
                            value={filterRows[index].value}
                            onChange={(e) =>
                              handleFilterPropertyChange(
                                index,
                                "value",
                                e.target.value
                              )
                            }
                          // inputProps={{
                          //   onChange: (e: any) =>
                          //     handleFilterPropertyChange(
                          //       index,
                          //       "operator",
                          //       e.target.value
                          //     ),
                          // }}
                          />
                        )}
                      </Col>
                    </Row>
                  );
                })}
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button color="success" onClick={handleAddNewFilterRow}>
                    Add New
                  </Button>
                  <Button color="success" onClick={handleFilterGo}>
                    Go
                  </Button>
                </div>
              </CardBody>
            )}
            <div style={{ height: "100vh", width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                density="comfortable"
                components={{
                  Toolbar: CustomToolbar,
                  // Toolbar: GridToolbar,
                  NoRowsOverlay: () => (
                    <GridOverlay>
                      <div style={{ fontSize: "1.5rem" }}>No Data</div>
                    </GridOverlay>
                  ),
                }}
                pagination
                onError={(arg) => console.log(arg)}
                rowCount={totalRows}
                loading={loading}
                onPageChange={handleChangePage}
                pageSize={rowsPerPage}
                rowsPerPageOptions={[2, 5, 10, 20]}
                paginationMode="server"
                page={page - 1}
                filterMode="server"
                onPageSizeChange={handleChangeRowsPerPage}
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableSelectionOnClick={true}
              />
            </div>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default CommonTable;
