import CommonTable from "components/CommonTable/CommonTable";
import { usePagination } from "hooks/tablePaginationHook";
import React, { useEffect, useState } from "react";
import { orders } from "Services/API/IndividualApis/Orders";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import OrderDetails from "./OrderDetails";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DeleteModal from "components/CustomDeleteModal";
import { IOrder } from "Interfaces/Api/Orders";
import { IPagination } from "Interfaces/Api/Pagination";
import { filterCols } from "Services/Helper";

export enum orderStatus {
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PROCESSING = "PROCESSING",
  SHIPPED = "SHIPPED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

const Orders = () => {
  const [page, handleChangePage] = usePagination();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allOrders, setAllOrders] = useState<IOrder[]>([]);
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  // const [orderDetails, setOrderDetails] = useState<IOrder>();
  const [showOrderDetailPage, setShowOrderDetailPage] =
    useState<boolean>(false);
  // const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [searchText, setSearchText] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const Navigate = useNavigate();
  const { state } = useLocation() as any;
  useEffect(() => {
    fetchAllOrders();
  }, [page, extraQueryParam, rowsPerPage]);

  useEffect(() => {
    if (
      (searchText != undefined && searchText.length == 24) ||
      searchText === ""
    ) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, page, rowsPerPage]);

  const handleSearch = async () => {
    if (searchText === "") {
      await fetchAllOrders();
    } else {
      try {
        const response = await orders.search(searchText, page, rowsPerPage);
        setAllOrders(response.data);
        setShowPagination(response.pagination);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const fetchAllOrders = async () => {
    setLoading(true);
    try {
      const response = await orders.getAll(page, rowsPerPage, extraQueryParam);
      setAllOrders(response.data);
      setLoading(false);
      setShowPagination(response.pagination);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleOrderDetails = async (id: string) => {
    Navigate(`/admin/orders/list/${id}`);
    // try {
    //   const response = await orders.getSingle(id);
    //   setShowOrderDetailPage(true);
    //   // setOrderDetails(response);
    // } catch (err) {
    //   console.log(err);
    // }
  };
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleExportCsv = async () => {
    window.open(`${BASE_URL}/order/csvFile`);
  };
  const rowsData = allOrders?.map((item) => {
    let date = item.createdAt;
    let newDate = new Date(date);

    const newDates = moment(newDate).calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "DD/MM/YYYY",
      lastDay: "DD/MM/YYYY",
      lastWeek: "DD/MM/YYYY",
      sameElse: "DD/MM/YYYY",
    });

    return {
      order: item,
      name: item?.user,
      date: newDates,
      status: item?.status,
      total: item?.totalPrice,
      id: item?._id,
      actions: item,
    };
  });

  const cols = React.useMemo(
    () => [
      {
        field: "order",
        headerName: "Order",
        width: 220,
        renderCell: (params) => {
          return <div>{`${params.value._id}`}</div>;
        },
      },
      {
        field: "name",
        headerName: "Name",
        width: 160,
        renderCell: (params) => {
          return (
            <Link to={`/admin/users/${params.value.id}`}>
              <div>{params.value.name}</div>
            </Link>
          );
        },
      },
      {
        field: "date",
        headerName: "Date",
        width: 150,
        renderCell: (params) => {
          const todays = "";
          if (params.row.date === "Today") {
            let date = params.row.order.createdAt;
            let newDate = new Date(date);
            let today = new Date();
            var start = moment(newDate);
            var end = moment(today);
            return <>{start.from(end)}</>;
          } else {
            return <>{params.row.date}</>;
          }
        },
      },
      {
        field: "status",
        filterable: true,
        isValueSelectable: true,
        headerName: "Status",
        options: Object.values(orderStatus).map((item) => {
          return {
            name: item,
          };
        }),
        width: 160,
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "total",
        headerName: "Total",
        width: 160,
        renderCell: (params) => <div>&#8377; {params.value}</div>,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => {
          return (
            <div>
              {state?.access.write && (
                <VisibilityIcon
                  onClick={() => {
                    handleOrderDetails(params.value._id);
                  }}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              )}
              {/* {state?.access.delete && (
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setItemToBeDeleted(params.value);
                  }}
                />
              )} */}
            </div>
          );
        },
      },
    ],
    [state]
  );
  filterCols(state, cols);
  const removeItems = async () => {};

  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => {
    if (clearFilter) {
      setExtraQueryParam("");
      return;
    }
    const filterArr = [];
    filterRows.forEach((element) => {
      let value = element.value;
      if (value instanceof Array) {
        value = value.filter((item) => item !== "");
      }
      const obj = {
        [element.name]: {
          [element.operator]: value,
        },
      };
      filterArr.push(obj);
    });
    const filterObj = {
      [linkedOperator]: filterArr,
    };
    let queryParam = `&filter=${JSON.stringify(filterObj)}`;

    setExtraQueryParam(queryParam);
  };

  return (
    <div>
      {/* {showDeleteModal ? (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems()}
          itemName={itemToBeDeleted.name}
        />
      ) : (
        ""
      )} */}

      {showOrderDetailPage ? (
        <>
          <OrderDetails />
        </>
      ) : (
        <CommonTable
          Title="Orders"
          button="Add order"
          handleChangePage={handleChangePage}
          addItems={() => console.log("hello")}
          totalRows={showPagination?.totalCount}
          searchText={searchText}
          handleSearchTextChanged={(value) => {
            if (value.length <= 24) {
              setSearchText(value);
            }
          }}
          searchPlaceHolder="Search by order id"
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rows={rowsData}
          columns={cols}
          handleFilter={handleFilter}
          loading={loading}
          handleExportCsv={handleExportCsv}
          isExportCSV={true}
        />
      )}
    </div>
  );
};
export default Interceptor(Orders);
