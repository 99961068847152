import { ApiOptions } from "Interfaces/Api";
import { ICms } from "Interfaces/Api/cms/Banner";
import {
  IConfig,
  IOtherConfig,
  IPostshippingConfigData,
  IShippingPickupDetail,
} from "Interfaces/Api/config";
import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import { ApiCore } from "../../Core";

const url = "config";
class ConfigCore extends ApiCore<IConfig> {
  storeShippingConfig: (modal: IPostshippingConfigData) => Promise<IConfig>;
  constructor(options: ApiOptions) {
    super(options);

    this.storeShippingConfig = (modal: IPostshippingConfigData) => {
      return apiProvider.post(`${options.url}/shipping`, modal);
    };

    this.postOtherConfig = (modal: IOtherConfig) => {
      return apiProvider.post(`${options.url}/other`, modal);
    };

    this.postShippingPickupConfig = (modal: IShippingPickupDetail) => {
      return apiProvider.post(`${options.url}/shipping_pickup_details`, modal);
    };
  }
}
const apiConfig = new ConfigCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

export { apiConfig };
