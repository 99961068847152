import CommonTable from "components/CommonTable/CommonTable";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePagination } from "hooks/tablePaginationHook";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Typography } from "@mui/material";
import DeleteModal from "components/CustomDeleteModal";
import { IPagination } from "Interfaces/Api/Pagination";
import { apiPolicy } from "Services/API/IndividualApis/Policy";
import { IPolicy } from "Interfaces/Api/Policy";
import AddOrEditPolicy from "./AddOrEditPolicyPage";
import EditIcon from "@mui/icons-material/Edit";
import { filterCols } from "Services/Helper";

const Attributes = () => {
  const [allPolicies, setAllPolicies] = useState<IPolicy[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, handleChangePage] = usePagination();
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [searchText, setSearchText] = useState<string>(undefined);
  const [isMessageDialogOpen, setIsMessageDialogOpen] =
    useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [addPolicyMode, setAddPolicyMode] = useState<boolean>(false);
  const [editPolicyMode, setEditPolicyMode] = useState<boolean>(false);
  const Navigate = useNavigate();
  const { state } = useLocation() as any;

  useEffect(() => {
    fetchAllPolicies();
  }, [page, extraQueryParam, rowsPerPage]);

  useEffect(() => {
    if (searchText != undefined) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, page, rowsPerPage]);

  const handleSearch = async () => {
    if (searchText == "") {
      await fetchAllPolicies();
    } else {
      try {
        const response = await apiPolicy.search(searchText, page, rowsPerPage);
        setAllPolicies(response.data);
        setShowPagination(response.pagination);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleEditPolicy = async (_id: string) => {
    Navigate(`/admin/policies/${_id}`);
    setEditPolicyMode(true);
  };

  const rowsData = allPolicies?.map((item) => {
    return {
      id: item._id,
      name: item.name,
      read: item.statements.map((val) => {
        if (val.access.read == true) {
          return val.target;
        }
      }),
      write: item.statements.map((val) => {
        if (val.access.write == true) {
          return val.target;
        }
      }),
      delete: item.statements.map((val) => {
        if (val.access.delete == true) {
          return val.target;
        } else {
          return;
        }
      }),
    };
  });

  const cols = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        renderCell: ({ value }) => {
          return <Link to={`/admin/users/${value}`}>{value}</Link>;
        },
      },
      {
        field: "read",
        headerName: "Read",
        width: 250,
        renderCell: (param) => {
          return (
            <div
              style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
            >
              {param.row.read ? <>{param.row.read.join(" , ")}</> : "- -"}
            </div>
          );
        },
      },
      {
        field: "write",
        headerName: "Write",
        width: 250,
        renderCell: (param) => {
          return (
            <div
              style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
            >
              {param.row.write ? <>{param.row.write.join(" , ")}</> : "- -"}
            </div>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        width: 250,
        renderCell: (param) => {
          return (
            <div
              style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
            >
              {param.row.delete ? <>{param.row.delete.join(" , ")}</> : "- -"}
            </div>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => {
          return (
            <div>
              {state?.access.write && (
                <EditIcon
                  onClick={() => handleEditPolicy(params.id)}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              )}
              {state?.access.delete && (
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setItemToBeDeleted(params);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [state]
  );

  filterCols(state, cols);

  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => {
    if (clearFilter) {
      setExtraQueryParam("");
      return;
    }
    const filterArr = [];
    filterRows.forEach((element) => {
      let value = element.value;
      if (value instanceof Array) {
        value = value.filter((item) => item !== "");
      }
      const obj = {
        [element.name]: {
          [element.operator]: value,
        },
      };
      filterArr.push(obj);
    });
    const filterObj = {
      [linkedOperator]: filterArr,
    };
    let queryParam = `&filter=${JSON.stringify(filterObj)}`;

    setExtraQueryParam(queryParam);
  };

  const fetchAllPolicies = async () => {
    try {
      const response = await apiPolicy.getAll(
        page,
        rowsPerPage,
        extraQueryParam
      );
      setAllPolicies(response.data);
      setShowPagination(response.pagination);
    } catch (err) {
      console.log(err);
    }
  };

  const removeItems = async () => {
    try {
      const response = await apiPolicy.remove(itemToBeDeleted.id);
      setShowDeleteModal(false);
      setItemToBeDeleted(null);
      await fetchAllPolicies();
    } catch (err) {
      console.log(err);
    }
  };

  const addItems = () => {
    setAddPolicyMode((prev) => !prev);
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems()}
          itemName={`"${itemToBeDeleted.row.name}"`}
        />
      )}

      {addPolicyMode || editPolicyMode ? (
        <>
          <AddOrEditPolicy />
        </>
      ) : (
        <>
          <CommonTable
            linkUrl={
              editPolicyMode ? "/admin/policies/:id" : "/admin/policies/add"
            }
            Title="Policies"
            button="Add Policy"
            page={page}
            handleChangePage={handleChangePage}
            totalRows={showPagination?.totalCount}
            addItems={addItems}
            searchText={searchText}
            handleSearchTextChanged={(value) => setSearchText(value)}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(value) => setRowsPerPage(value)}
            rows={rowsData}
            showFilter={false}
            columns={cols}
            handleFilter={handleFilter}
            showAddButton={state?.access.write}
          />
        </>
      )}
      <Dialog
        onClose={() => setIsMessageDialogOpen(false)}
        open={isMessageDialogOpen}
      >
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Interceptor(Attributes);
