import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import CustomSelect from "components/CustomSelect/CustomSelect";
import UploadImage from "components/CustomUpload/UploadImage";
import withErrorHandler from "Services/hoc/Interceptor/Interceptor";
import Loader from "components/Loader";

interface InventoryProps {
  editMode: boolean;
  onCancel: Function;
  handleInventoryProperties: Function;
  productProperties: Record<string, any>;
  handleEditProduct: Function;
  handleOnBlur: Function;
  loading: Boolean;
  variant?: Boolean;
}

const Inventory = ({
  handleInventoryProperties,
  productProperties,
  handleOnBlur,
  onCancel,
  editMode,
  handleEditProduct,
  loading,
  variant,
}: InventoryProps) => {
  const [productSku, setProductSku] = useState<string>("");
  const [skuError, setSkuError] = useState<string>("Sku is Required");
  const [skuTouched, setSkuTouched] = useState<boolean>(false);
  const [error, setError] = useState({
    sku: true,
    quantity: true,
  });
  const [showError, setShowError] = useState<boolean>(false);
  const [quantityTouched, setQuantityTouched] = useState<boolean>(false);
  const [productQuantity, setProductQuantity] = useState<string>("");
  const [quantityError, setQuantityError] = useState<string>(
    "Quantity is Required"
  );

  const onNextPage = () => {
    if (variant) {
      if (productProperties.availableQuantity === "") {
        setShowError(true);
        setSkuTouched(true);
        setQuantityTouched(true);
      } else {
        handleInventoryProperties();
      }
    } else if (
      productProperties.sku === "" ||
      productProperties.availableQuantity === ""
    ) {
      setShowError(true);
      setSkuTouched(true);
      setQuantityTouched(true);
    } else {
      handleInventoryProperties();
    }
  };

  const handleQuantityBlur = (e) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setQuantityTouched(true);
    let obj = {
      availableQuantity: productQuantity,
    };
    handleOnBlur(obj);
  };

  const handleSkuBlur = (e) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setSkuTouched(true);
    const obj = {
      sku: productSku,
    };
    handleOnBlur(obj);
  };

  useEffect(() => {
    if (productProperties) {
      setProductSku(productProperties.sku);
      setProductQuantity(productProperties.availableQuantity);
    }
  }, [productProperties]);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          {/* <CardBody> */}
          <Box style={{ paddingBottom: "3rem" }}>
            <GridContainer style={{ padding: "0.9375rem 20px" }}>
              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Quantity"
                  id="quantity"
                  formControlProps={{
                    style: { margin: " 0" },
                  }}
                  inputProps={{
                    required: true,
                    name: "quantity",
                    // type: "Number",
                    value: productQuantity,
                    onChange: (e) => {
                      // if (!Number(e.target.value) || e.target.value > -1) return;
                      setProductQuantity(e.target.value);
                    },
                    onBlur: handleQuantityBlur,
                    // helperText: error.quantity && quantityTouched && (quantityError)
                  }}
                />
                <FormHelperText style={{ color: "red" }}>
                  {error.quantity && quantityTouched && quantityError}
                </FormHelperText>
              </GridItem>
              {!variant && (
                <GridItem
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ marginBottom: "27px" }}
                >
                  <CustomInput
                    labelText="Sku*"
                    //   id="company-disabled"
                    formControlProps={{
                      style: { margin: "0" },
                    }}
                    inputProps={{
                      value: productSku,
                      name: "sku",
                      onChange: (e) => setProductSku(e.target.value),
                      onBlur: handleSkuBlur,
                    }}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {error.sku && skuTouched && skuError}
                  </FormHelperText>
                </GridItem>
              )}
            </GridContainer>
          </Box>
          <GridContainer
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <Button color="gray" onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <Button color="success" onClick={onNextPage}>
                Next
              </Button>
            </div>
            {editMode && (
              <div style={{ marginRight: "1rem", position: "relative" }}>
                <Button color="success" onClick={handleEditProduct}>
                  Submit
                </Button>
                {loading && <Loader />}
                {/* {loading && <div style={{ position: "absolute", top: "30%", right: "5px", opacity: "1" }}>{<CircularProgress size="20px" style={{ color: "#8D6D4D" }} />}</div>} */}
              </div>
            )}
          </GridContainer>
          {/* </CardBody> */}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default withErrorHandler(Inventory);
