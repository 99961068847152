import { IPolicy } from "Interfaces/Api/Policy";
import { IMeResponse } from "Interfaces/Api/User";
import * as actionTypes from "../actions/actionsTypes";

export interface IStateProps {
  user: IMeResponse | null;
  auth: null;
  isLoggedIn: Boolean;
  loading: Boolean;
  error: Boolean;
  policy: IPolicy | null;
  loader: Boolean;
}

const initialState: IStateProps = {
  user: null,
  auth: null,
  isLoggedIn: false,
  loading: false,
  error: false,
  policy: null,
  loader: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
        loading: false,
        error: false,
      };

    case actionTypes.AUTH_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        user: {},
      };
    case actionTypes.SHOWERROR:
      return {
        ...state,
        isError: true,
      };

    case actionTypes.HIDEERROR:
      return {
        ...state,
        isError: false,
      };
    default:
      return state;
  }
};

export default reducer;
