import RegistrationLayout from "./RagistrationLayout";
import { Button, styled, Typography } from "@mui/material";
import React, { MouseEventHandler, useEffect } from "react";
import { useState } from "react";
import OtpInput from "react-otp-input-rc-17";
import Spinner from "./Spinner";
import { Form } from "react-bootstrap";
import styles from "./signin.module.scss";
import { useDispatch } from "react-redux";
import * as actionTypes from "store/actions/actionsTypes";

const renderButton = (buttonProps: any) => {
  return (
    <Button {...buttonProps} style={{ margin: "0.5rem 0 0.5rem 0" }}>
      {buttonProps.remainingTime !== 0
        ? `Resend otp in ${buttonProps.remainingTime}sec`
        : "Resend"}
    </Button>
  );
};
const renderTime = () => React.Fragment;

interface OtpVerificationComponentProps {
  title?: string;
  handleSubmit: Function;
  handleResendAnOtp?: Function;
  loginModal?: boolean;
  spinner: Boolean;
  isError: String;
}
export default function OtpVerificationComponent({
  title,
  handleSubmit,
  handleResendAnOtp,
  spinner,
  isError,
}: OtpVerificationComponentProps) {
  const countDown = 59;
  const [counter, setCounter] = useState<number>(countDown);
  const [OTP, setOTP] = useState<string>("");

  const handleOtpSubmit = () => {
    handleSubmit(OTP);
  };

  const handleResendOtpSubmit = () => {
    setCounter(countDown);
    handleResendAnOtp!();
  };

  React.useEffect(() => {
    let Timer: any;
    Timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval!(Timer);
  }, [counter]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actionTypes.HIDEERROR });
  }, [OTP]);

  return (
    <RegistrationLayout title={title ? title : "Enter OTP"} subTitle={""}>
      <Form>
        <OtpInput
          value={OTP}
          onChange={setOTP}
          isInputNum={true}
          numInputs={4}
          containerStyle={{ justifyContent: "center", display: "flex" }}
          inputStyle={{ width: "2.5em", height: "2.5em", margin: "10px" }}
        />
        <>
          {counter !== 0 ? (
            <div
              style={{
                margin: "1rem 0 1rem 0",
                float: "right",
                color: "#00844a",
                fontSize: "14px",
              }}
            >
              RESEND OTP IN {counter} SEC
            </div>
          ) : (
            <Button
              style={{ margin: "0.5rem 0 0.5rem 0", float: "right" }}
              onClick={handleResendOtpSubmit}
            >
              Resend
            </Button>
          )}
        </>

        <br />
        <Button
          className={styles.verify}
          // style={{
          //   width: "100%",
          //   fontSize: "1rem",
          //   color: "white",
          //   backgroundColor: "#4caf50",
          // }}
          onClick={handleOtpSubmit}
          disabled={OTP.length == 4 ? false : true}
        >
          {spinner ? <Spinner /> : ""} Verify
        </Button>

        {isError && (
          <Form.Text className="text-danger mb-2 text-center">
            You have entered incorrect OTP{" "}
          </Form.Text>
        )}
      </Form>
    </RegistrationLayout>
  );
}
