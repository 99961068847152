export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

export function filterCols(state, cols) {
  if (!state?.access.delete && !state?.access.write) {
    cols.splice(
      0,
      cols.length,
      ...cols.filter((item) => item.field !== "actions")
    );
  }
}
