import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  IPolicyModuleOptions,
  IPolicyStatement,
  IPolicy,
} from "Interfaces/Api/Policy";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import { apiPolicy } from "Services/API/IndividualApis/Policy";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface Column {
  id: string;
  label: string;
  width?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "target", label: "Module", width: 150 },

  {
    id: "read",
    label: "Read",
    width: 150,
  },
  {
    id: "write",
    label: "write",
    width: 150,
  },
  { id: "delete", label: "Delete", width: 150 },
];

interface Data {
  target: string;
  read: boolean;
  write: boolean;
  delete: boolean;
}

function createData({ target, access }: IPolicyStatement): Data {
  return {
    target,
    read: access.read,
    write: access.write,
    delete: access.delete,
  };
}

export default function AddOrEditPolicy({ editData }: { editData?: IPolicy }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [selectedData, setSelectedData] = useState<IPolicyStatement[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const Navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isBackToUserQueryExists = searchParams.get("backToUser");

  const [policyName, setPolicyName] = useState<string>(
    editData ? editData.name : ""
  );
  React.useEffect(() => {
    if (editData) {
      setSelectedData([...editData.statements]);
      setPolicyName(editData.name);
    }
  }, [editData]);
  React.useEffect(() => {
    setRows([
      ...Object.values(IPolicyModuleOptions).map((module) => {
        if (selectedData) {
          const idx = selectedData.findIndex((item) => item.target === module);

          if (idx !== -1) {
            const moduleAccessData = selectedData[idx].access;
            return createData({
              target: module,
              access: {
                read: moduleAccessData.read,
                write: moduleAccessData.write,
                delete: moduleAccessData.delete,
              },
            });
          } else {
            return createData({
              target: module,
              access: {
                read: false,
                write: false,
                delete: false,
              },
            });
          }
        } else {
          return createData({
            target: module,
            access: {
              read: false,
              write: false,
              delete: false,
            },
          });
        }
      }),
    ]);
  }, []);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  function handleInputValueChange(
    target,
    accessKey,
    { target: { value, checked, name } }: React.ChangeEvent<HTMLInputElement>
  ) {
    let newSelectedData = [...selectedData];
    const idx = selectedData.findIndex((item) => item.target === target);

    if (idx !== -1) {
      setSelectAll(false);
      const newAccessData = { ...selectedData[idx].access };
      newAccessData[accessKey] = checked;
      newSelectedData[idx].access = newAccessData;
      // delete this module from selectedData if all the access keys are false
      if (
        !newAccessData.read &&
        !newAccessData.write &&
        !newAccessData.delete
      ) {
        newSelectedData.splice(idx, 1);
      }
    } else {
      newSelectedData.push({
        target: target,
        access: {
          read: false,
          write: false,
          delete: false,
          [accessKey]: checked,
        },
      });
    }
    setSelectedData(newSelectedData);
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  async function handleChangePolicy() {
    try {
      const data: IPolicy = {
        name: policyName,
        statements: selectedData,
      };
      if (editData) {
        const response = await apiPolicy.patch(editData._id, data);
      } else {
        const response = await apiPolicy.post(data);
      }
      onClose();
    } catch (err) {
      console.log(err);
    }
  }

  const location = useLocation();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const onClose = () => {
    const userId = location.search;
    if (userId) {
      // if (isEditMode == true) {
      //   Navigate(`/admin/user/${newUserId}${userId}`);
      // } else {
      Navigate(`/admin/users${userId}`);
      // }
    } else {
      Navigate(-1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const newSelectedData = [];
    // selectedData.forEach((item) => {});
    // const idx = selectedData.findIndex((item) => {
    //   return item.target === row.target;
    // });
    if (isChecked) {
      rows.forEach((row) => {
        newSelectedData.push({
          target: row.target,
          access: {
            read: true,
            write: true,
            delete: true,
          },
        });
      });
    }
    setSelectedData(newSelectedData);
  };

  return (
    <>
      <GridItem
        xs={12}
        sm={6}
        md={6}
        lg={4}
        sx={{ marginBottom: "0.5rem", padding: "0 !important" }}
        style={{ padding: "0 !important" }}
      >
        <CustomInput
          labelText="Policy Name"
          formControlProps={
            {
              // fullWidth: true,
            }
          }
          inputProps={{
            value: policyName,
            type: "text",
            onChange: (e) => setPolicyName(e.target.value),
          }}
        />
      </GridItem>
      <GridItem>
        <Checkbox
          checked={selectAll}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          style={{ paddingLeft: "0px" }}
        />
        Select all
      </GridItem>
      <GridItem>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 320 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      // key={column.id}
                      align={column.align}
                      style={{
                        width: column.width,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        // key={row.target}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          const idx = selectedData.findIndex((item) => {
                            return item.target === row.target;
                          });
                          let isChecked = selectedData[idx]?.access[column.id];
                          if (isChecked === undefined) isChecked = false;
                          // if (isChecked == true) setSelectAll(false);
                          return (
                            <React.Fragment>
                              {column.id !== "target" ? (
                                <TableCell align={column.align}>
                                  <Checkbox
                                    checked={isChecked}
                                    onChange={(e) =>
                                      handleInputValueChange(
                                        row.target,
                                        column.id,
                                        e
                                      )
                                    }
                                  />
                                </TableCell>
                              ) : (
                                <TableCell align={column.align}>
                                  {value}
                                </TableCell>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </Paper>
        <GridContainer
          style={{
            justifyContent: "end",
            padding: "1rem",
          }}
        >
          <div style={{ marginRight: "1rem" }}>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div style={{ marginRight: "1rem" }}>
            <Button
              color="success"
              disabled={
                selectedData.length > 0 && policyName != "" ? false : true
              }
              onClick={handleChangePolicy}
            >
              Submit
            </Button>
          </div>
        </GridContainer>
      </GridItem>
    </>
  );
}
