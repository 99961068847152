import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  FormHelperText,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import CustomSelect from "components/CustomSelect/CustomSelect";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import ReactQuill from "react-quill";
import GridItem from "components/Grid/GridItem";
import classes from "./Product.module.scss";
import { ICategory } from "Interfaces/Api/Category";
import { IProduct } from "Interfaces/Api/Product";
import Loader from "components/Loader";

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "background",
      "color",
      "font",
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

interface GeneralProps {
  allCategories: ICategory[];
  editMode: boolean;
  editItem: IProduct;
  onCancel: Function;
  handleGenralTabValues: Function;
  productProperties: Record<string, any>;
  handleEditProduct: Function;
  handleOnBlur: Function;
  loading: Boolean;
}

const General = ({
  handleGenralTabValues,
  productProperties,
  allCategories,
  handleOnBlur,
  onCancel,
  editMode,
  handleEditProduct,
  loading,
}: GeneralProps) => {
  const [productName, setProductName] = useState<string>("");
  const [productPrice, setProductPrice] = useState<string>("");
  const [productSubTitle, setProductSubTitle] = useState<string>("");
  const [maxQuantityLimit, setMaxQuantityLimit] = useState<number>(100);
  const [productDiscount, setProductDiscount] = useState<string>("");
  const [benefits, setBenefits] = useState<string>("");
  const [faqs, setFaqs] = useState<string>("");
  const [whatExpertHasToSay, setWhatExpertHasToSay] = useState<string>("");
  const [whatIsIt, setWhatIsIt] = useState<string>("");
  const [whatsInIt, setWhatsInIt] = useState<string>("");
  const [howToUse, sethowToUse] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [priceError, setPriceError] = useState<string>("Price is Required");
  const [nameError, setNameError] = useState<string>("Name is Required");
  const [categoryError, setCategoryError] = useState<string>(
    "Category is Required"
  );
  const [whatIsItError, setWhatIsItError] = useState<string>(
    "Description Required"
  );

  const [expand, setExpand] = React.useState<boolean>(true);
  const [nameTouched, setNameTouched] = useState<boolean>(false);
  const [invalidPriceDiscount, setInvalidPriceDiscount] =
    useState<boolean>(false);
  const [priceTouched, setPriceTouched] = useState<boolean>(false);
  const [categoryTouched, setCategoryTouched] = useState<boolean>(false);
  const [whatIsItTouched, setWhatIsItTouches] = useState<boolean>(false);

  const [error, setError] = useState({
    name: true,
    price: true,
    quantity: true,
    category: true,
    whatIsIt: true,
  });
  const [showError, setShowError] = useState(false);
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const onNextPage = () => {
    if (parseFloat(productDiscount) >= parseFloat(productProperties.price)) {
      setInvalidPriceDiscount(true);
    } else {
      if (productProperties.name === "") {
        setNameTouched(true);
      }
      if (productProperties.price === "") {
        setPriceTouched(true);
      }
      if (productProperties.categories[0] == "") {
        setCategoryTouched(true);
      }
      if (
        productProperties.whatIsIt === "" ||
        productProperties.whatIsIt === "<p><br></p>"
      ) {
        setWhatIsItTouches(true);
      }
      if (
        productProperties.name === "" ||
        productProperties.price === "" ||
        productProperties.categories[0] == "" ||
        productProperties.whatIsIt === "<p><br></p>" ||
        productProperties.whatIsIt === ""
      ) {
        setShowError(true);
        // setPriceTouched(true);
        // setNameTouched(true);
        // setCategoryTouched(true);
        // setWhatIsItTouches(true);
      } else {
        handleGenralTabValues();
      }
    }
  };
  const handleNameBlur = (e) => {
    if (e.target.value === "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setNameTouched(true);
    let obj = {
      name: productName,
    };
    handleOnBlur(obj);
  };

  const handlePriceBlur = (e) => {
    if (e.target.value === "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setPriceTouched(true);
    let obj = {
      price: productPrice,
    };
    handleOnBlur(obj);
  };
  const handleCategoryBlur = (e) => {
    if (e.target.value === "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setCategoryTouched(true);
    let obj = {
      categories: selectedCategory,
    };
    handleOnBlur(obj);
  };

  const onWhatIsItBlur = () => {
    if (whatIsIt === "<p><br></p>") {
      setError((prevVal) => ({ ...prevVal, whatIsIt: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, whatIsIt: false }));
    }
    setWhatIsItTouches(true);
    let obj = {
      whatIsIt: whatIsIt,
    };
    handleOnBlur(obj);
  };

  const handleOnchangeWhatIsIt = (value) => {
    setWhatIsIt(value);
    let obj = {
      whatIsIt: value,
    };
    handleOnBlur(obj);
  };

  const onBlur = (e) => {
    const obj = {
      // availableQuantity: productQuantity,
      maxQuantityAllowedInSignleCart: maxQuantityLimit,
      subTitle: productSubTitle,
      discount: productDiscount,
      name: productName,
      price: productPrice,
      whatIsIt: whatIsIt,
      whatsInIt: whatsInIt,
      howToUse: howToUse,
      categories: selectedCategory,
      faqs: faqs,
      whatExpertHasToSay: whatExpertHasToSay,
      benefits: benefits,
    };
    handleOnBlur(obj);
  };

  useEffect(() => {
    if (productProperties) {
      setProductName(productProperties.name);
      setProductPrice(productProperties.price);
      setProductSubTitle(productProperties.subTitle);
      setMaxQuantityLimit(productProperties.maxQuantityAllowedInSignleCart);
      setProductDiscount(productProperties.discount);
      setSelectedCategory(productProperties?.categories);
      setWhatIsIt(productProperties?.whatIsIt);
      setWhatsInIt(productProperties?.whatsInIt);
      sethowToUse(productProperties?.howToUse);
      setBenefits(productProperties?.benefits);
      setWhatExpertHasToSay(productProperties.whatExpertHasToSay);
      setFaqs(productProperties?.faqs);
    }
  }, [productProperties]);

  const handleSelectedParentCategoryChange = (event) => {
    setSelectedCategory([event.target.value]);
  };

  return (
    <div>
      {/* <GridContainer> */}
      {invalidPriceDiscount && (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            position: "fixed",
            top: { sm: "2px", xs: "3.2rem" },
            right: "5px",
            zIndex: "10000",
            background: "red",
            textTransform: "capitalize",
            width: "20rem",
          }}
        >
          Product Discount Price must be less than Product Price
        </Alert>
      )}
      <GridItem xs={12}>
        <GridContainer style={{ padding: "0.9375rem 20px" }}>
          <Box style={{ paddingBottom: "3rem" }}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Name"
                  id="name"
                  formControlProps={{
                    style: { margin: " 0" },
                  }}
                  inputProps={{
                    required: true,
                    value: productName,
                    name: "name",
                    disabled: editMode ? true : false,
                    onChange: (e) => setProductName(e.target.value),
                    onBlur: handleNameBlur,
                  }}
                />
                <FormHelperText style={{ color: "red" }}>
                  {error.name && nameTouched && nameError}
                </FormHelperText>
              </GridItem>

              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Price"
                  id="price"
                  formControlProps={{
                    style: { margin: " 0" },
                  }}
                  inputProps={{
                    // pattern: "^[0-9]*",
                    // inputMode: "numeric",
                    required: true,
                    value: productPrice,
                    name: "price",
                    onChange: (e) => {
                      if (e.target.value && !Number(e.target.value)) return;
                      setInvalidPriceDiscount(false);
                      setProductPrice(e.target.value);
                    },
                    onBlur: handlePriceBlur,
                  }}
                />
                <FormHelperText style={{ color: "red" }}>
                  {error.price && priceTouched && priceError}
                </FormHelperText>
              </GridItem>
              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Discount (Price)"
                  id="discount"
                  formControlProps={{
                    style: { margin: " 0" },
                  }}
                  inputProps={{
                    name: "discount",
                    value: productDiscount,
                    onChange: (e) => {
                      if (e.target.value && !Number(e.target.value)) return;
                      setInvalidPriceDiscount(false);
                      setProductDiscount(e.target.value);
                    },
                    onBlur: onBlur,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <CustomSelect
                  labelText="Category*"
                  // native
                  formControlProps={{
                    className: classes.formControl,
                  }}
                  id="age-native-required"
                  inputProps={{
                    required: true,
                    disabled: editMode ? true : false,
                    value: selectedCategory,
                    onChange: handleSelectedParentCategoryChange,
                    name: "category",
                    native: true,
                    onBlur: handleCategoryBlur,
                    multiple: false,
                    // helperText: error.category && categoryTouched && (categoryError)
                  }}
                >
                  <option value="">{""}</option>
                  {allCategories.map((item) => {
                    return (
                      <option key={item._id} value={item.category}>
                        {item.category}
                      </option>
                    );
                  })}
                </CustomSelect>

                <FormHelperText style={{ color: "red" }}>
                  {error.category && categoryTouched && categoryError}
                </FormHelperText>
              </GridItem>
              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="SubTitle"
                  //   id="company-disabled"
                  formControlProps={{
                    style: { margin: "0" },
                  }}
                  inputProps={{
                    value: productSubTitle,
                    onChange: (e) => {
                      setProductSubTitle(e.target.value);
                    },
                    onBlur: onBlur,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Max Order Qty Limit"
                  //   id="company-disabled"
                  formControlProps={{
                    style: { margin: "0" },
                  }}
                  inputProps={{
                    type: "number",
                    min: 1,
                    value: maxQuantityLimit,
                    onChange: (e) => {
                      if (
                        e.target.value == "" ||
                        (e.target.value && e.target.value > 0)
                      )
                        setMaxQuantityLimit(e.target.value);
                    },
                    onBlur: onBlur,
                  }}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }} expanded={expand}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            onClick={(event) => {
                              setExpand((prev) => !prev);
                            }}
                          />
                        }
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          Description*
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          value={whatIsIt}
                          modules={modules}
                          id="WhatIsIt"
                          onChange={(value) => {
                            handleOnchangeWhatIsIt(value);
                          }}
                          onBlur={onWhatIsItBlur}
                        />
                        <FormHelperText style={{ color: "red" }}>
                          {error.whatIsIt && whatIsItTouched && whatIsItError}
                        </FormHelperText>
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          Ingredients
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="ingredients"
                          value={whatsInIt}
                          modules={modules}
                          onChange={(value) => {
                            setWhatsInIt(value);
                          }}
                          onBlur={() => handleOnBlur({ whatsInIt })}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          How to use
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="howToUse"
                          value={howToUse}
                          modules={modules}
                          onChange={(value) => {
                            sethowToUse(value);
                          }}
                          onBlur={() => handleOnBlur({ howToUse })}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          Benefits
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="benefits"
                          value={benefits}
                          modules={modules}
                          onChange={(value) => {
                            setBenefits(value);
                          }}
                          onBlur={() => handleOnBlur({ benefits })}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          FAQ's
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="faqs"
                          value={faqs}
                          modules={modules}
                          onChange={(value) => {
                            setFaqs(value);
                          }}
                          onBlur={() => handleOnBlur({ faqs })}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          What expert has to say
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="whatExpertHasToSay"
                          value={whatExpertHasToSay}
                          modules={modules}
                          onChange={(value) => {
                            setWhatExpertHasToSay(value);
                          }}
                          onBlur={() => handleOnBlur({ whatExpertHasToSay })}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
          </Box>
          <GridContainer
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <Button color="gray" onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <Button color="success" onClick={onNextPage}>
                Next
              </Button>
            </div>
            {editMode && (
              <div style={{ marginRight: "1rem", position: "relative" }}>
                <Button color="success" onClick={handleEditProduct}>
                  Submit
                </Button>
                {loading && <Loader />}
              </div>
            )}
          </GridContainer>
        </GridContainer>
      </GridItem>
    </div>
  );
};
export default Interceptor(General);
