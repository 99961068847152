import Person from "@mui/icons-material/Person";
// core components/views for Admin layout
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import CancelIcon from "@mui/icons-material/Cancel";
import UserProfile from "views/UserProfile/UserProfile";
import CategoryIcon from "@mui/icons-material/Category";
import Categories from "views/Categories";
import Attributes from "views/Attributes";
import Products from "views/Products";
import Coupons from "views/Coupons/index";
import Orders from "views/Orders";
import Reviews from "views/Reviews";
import Abandoned from "views/Abandoned";
import Policy from "views/Policy";

import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ReviewsIcon from "@mui/icons-material/Reviews";
import SellSharpIcon from "@mui/icons-material/SellSharp";
import GroupIcon from "@mui/icons-material/Group";
import PercentIcon from "@mui/icons-material/Percent";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

// core components/views for RTL layout

import Users from "views/Users";

import CanceledOrderList from "views/Cancelled-Order";
import { DynamicContent } from "views/DynamicContent";
import OrderConfig from "views/Orders/Config";
import { IPolicyModuleOptions } from "Interfaces/Api/Policy";
import SettingsIcon from "@mui/icons-material/Settings";
import PolicyIcon from "@mui/icons-material/Policy";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Enquiry from "views/Inquiry";

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/admin",
  // },
  {
    path: "users",
    name: "Users",
    rtlName: "ملف تعريفي للمستخدم",
    icon: GroupIcon,
    component: Users,
    layout: "/admin",
    module: IPolicyModuleOptions.USER,
  },

  {
    path: "categories",
    name: "Categories",
    rtlName: "don't know",
    icon: CategoryIcon,
    component: Categories,
    layout: "/admin",
    module: IPolicyModuleOptions.CATEGORY,
  },

  {
    path: "products",
    name: "Products",
    rtlName: "don't know",
    icon: SellSharpIcon,
    component: Products,
    layout: "/admin",
    module: IPolicyModuleOptions.PRODUCT,
  },
  {
    path: "reviews",
    name: "Reviews",
    rtlName: "don't know",
    icon: ReviewsIcon,
    component: Reviews,
    layout: "/admin",
    module: IPolicyModuleOptions.REVIEW,
  },
  {
    path: "coupons",
    name: "Coupons",
    rtlName: "don't know",
    icon: PercentIcon,
    component: Coupons,
    layout: "/admin",
    module: IPolicyModuleOptions.COUPON,
  },
  {
    path: "attributes",
    name: "Attributes",
    rtlName: "don't know",
    icon: FormatListBulletedIcon,
    component: Attributes,
    layout: "/admin",
    module: IPolicyModuleOptions.ATTRIBUTE,
  },
  {
    path: "orders",
    name: "Orders",
    rtlName: "don't know",
    icon: ShoppingCartIcon,
    component: Orders,
    layout: "/admin",
    module: IPolicyModuleOptions.ORDER,
    subMenu: [
      {
        path: "list",
        name: "List",
        rtlName: "don't know",
        icon: ShoppingCartIcon,
        component: Orders,
        layout: "/admin",
        module: IPolicyModuleOptions.ORDER,
      },
      // {
      //   path: "cancelRequests",
      //   name: "Cancelled Request",
      //   rtlName: "don't know",
      //   icon: CancelIcon,
      //   component: CanceledOrderList,
      //   layout: "/admin",
      // },
      {
        path: "cart_abandoned",
        name: "Abandoned",
        rtlName: "don't know",
        icon: RemoveShoppingCartIcon,
        component: Abandoned,
        layout: "/admin",
        module: IPolicyModuleOptions.ORDER,
      },
    ],
  },
  // {
  //   path: "my-profile",
  //   name: "My Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  {
    path: "dynamic-content",
    name: "Dynamic Content",
    rtlName: "ملف تعريفي للمستخدم",
    icon: DynamicFormIcon,
    component: DynamicContent,
    layout: "/admin",
    module: IPolicyModuleOptions.CONTENT,
  },
  {
    path: "setting",
    name: "Config",
    rtlName: "don't know",
    icon: SettingsIcon,
    component: OrderConfig,
    layout: "/admin",
    module: IPolicyModuleOptions.SETTING,
  },
  {
    path: "policies",
    name: "Policies",
    rtlName: "ملف تعريفي للمستخدم",
    icon: PolicyIcon,
    component: Policy,
    layout: "/admin",
    module: IPolicyModuleOptions.POLICY,
  },
  {
    path: "inquiry",
    name: "Inquiries",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ContactSupportIcon,
    component: Enquiry,
    layout: "/admin",
    module: IPolicyModuleOptions.INQUIRY,
  },
];

export default dashboardRoutes;
