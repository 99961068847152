import React from "react";
import classNames from "classnames";
import PropTypes, { any } from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
// @mui/icons-material
import Clear from "@mui/icons-material/Clear";
import Check from "@mui/icons-material/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import Select from "@mui/material/Select";
import CustomInput from "components/CustomInput/CustomInput";
const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    children,
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    // [classes.underlineError]: error,
    // [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,

  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      style={{ minWidth: "100%" }}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
          id={id}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        className={classes.underline}
        id={id}
        // input={
        //   <CustomInput
        //     className={{
        //       underline: styles.underline,
        //     }}
        //   />
        // }
        // onChange={() => console.log("hello")}
        {...inputProps}
        label={labelText}
      >
        {children}
      </Select>
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.node,
};
