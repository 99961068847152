/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/Auth";
// import categoriesReducer from './store/reducers/Categories'
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "assets/css/material-dashboard-react.css";

// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : null || compose;
// it could be your App.tsx file or theme file that is included in your tsconfig.json

const rootReducer = combineReducers({
  auth: authReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    // MuiTextField: {
    //   defaultProps: {
    //     variant: "standard",
    //   },
    // },
    // MuiSelect: {
    //   defaultProps: {
    //     variant: "standard",
    //   },
    // },
  },
});

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    {/* <StyledEngineProvider injectFirst> */}
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
    {/* </StyledEngineProvider> */}
  </Provider>,
  document.getElementById("root")
);
