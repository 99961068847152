import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput.js";
import Modal from "@mui/material/Modal";
import Button from "components/CustomButtons/Button";
import Spinner from "layouts/signIn/Spinner";

interface CancelOrderProps {
  onHide: Function;
  onSave: Function;
  show: boolean;
  loader: boolean;
}

const CancelOrder = ({ show, onHide, onSave, loader }: CancelOrderProps) => {
  const [cancellationReason, setCancellationReason] = useState<string>("");

  const onSubmit = () => {
    let obj = {
      cancelNote: cancellationReason,
      status: "CANCELLED",
    };
    onSave(obj);
  };

  return (
    <>
      <div style={{ width: "50%" }}>
        <Modal
          open={show}
          onClose={() => onHide()}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridContainer sm={5}>
            <Card>
              <GridItem>
                <CardBody>
                  <GridItem>
                    <h2 style={{ textAlign: "center" }}>
                      Are you sure you want to cancel your order?
                    </h2>
                    <GridItem>
                      <CustomInput
                        labelText="Cancellation reason"
                        //   id="company-disabled"
                        formControlProps={
                          {
                            // fullWidth: true,
                          }
                        }
                        inputProps={{
                          value: cancellationReason,
                          onChange: (e) =>
                            setCancellationReason(e.target.value),
                        }}
                      />
                    </GridItem>
                    <GridContainer
                      style={{ justifyContent: "center", paddingTop: "10px" }}
                    >
                      <GridItem>
                        <Button color="gray" onClick={() => onHide()}>
                          No
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button color="success" onClick={() => onSubmit()}>
                          {loader ? <Spinner spinColor={true} /> : ""}
                          Yes
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </CardBody>
              </GridItem>
            </Card>
          </GridContainer>
        </Modal>
      </div>
    </>
  );
};

export default CancelOrder;
