import React, { useEffect, useState } from "react";
import { coupons } from "Services/API/IndividualApis/Coupons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Box, Tab, Tabs } from "@mui/material";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import General from "./Tabs/General";
import UsageRestriction from "./Tabs/UsageRestiction";
import UsageLimit from "./Tabs/UsageLimit";
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel, a11yProps } from "components/TabPanel/TabPanel";
import { ICoupon } from "Interfaces/Api/Coupons";

const properties = {
  couponDescription: "",
  couponExpiryDate: "",
  excludeProductIds: "",
  allowedProductsIds: "",
  couponAmount: "",
  _id: "",
  name: "",
  code: "",
  discountType: "",
  discount: "",
  description: [],
  allowedCategories: [],
  excludedCategories: [],
  allowedProducts: [],
  excludedProducts: [],
  allowedPhoneNumbers: "",
  allowedEmails: [],
  couponUsageLimit: "",
  numberOfUsersLimit: "",
  expiryDate: "",
  singleUserUseLimit: "",
  usageDetails: [],
  status: "",
  minimumSpend: "",
  xItemLimit: "",
  isForIndividualUseOnly: Boolean,
};
const AddOrEditCoupon = () => {
  const [editCouponsMode, setEditCouponsMode] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [couponProperties, setCouponProperties] =
    useState<Record<string, any>>(properties);
  // const [couponProperties, setCouponProperties] = useState<ICoupon>();
  const [disable, setDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const Navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (window.location.pathname == "/admin/coupons/add") {
      setEditCouponsMode(false);
    } else {
      setEditCouponsMode(true);
      getCouponDetailsById(id);
    }
  }, []);

  const getCouponDetailsById = async (id: string) => {
    try {
      const response = await coupons.getSingle(id);
      if (response.status == "Expired") {
        setDisable(true);
      }
      setCouponProperties({
        name: response?.name,
        code: response?.code,
        couponAmount: response?.discount,
        discountType: response?.discountType,
        couponExpiryDate: response?.expiryDate,
        couponDescription: response?.description,
        minimumSpend: response?.minimumSpend,
        allowedProducts: response?.allowedProducts,
        excludedProducts: response?.excludedProducts,
        allowedCategories: response?.allowedCategories,
        excludedCategories: response?.excludedCategories,
        couponUsageLimit: response.couponUsageLimit,
        xItemLimit: response.xItemLimit,
        singleUserUseLimit: response.singleUserUseLimit,
        allowedPhoneNumbers: response.allowedPhoneNumbers,
        isForIndividualUseOnly: response?.isForIndividualUseOnly,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    Navigate(-1);
  };

  const handleOnBlur = (obj: ICoupon) => {
    setCouponProperties((prevVal) => ({ ...prevVal, ...obj }));
  };
  const nextPageHandler = () => {
    setTabValue(1);
  };

  const handleRestictionChange = (obj: ICoupon) => {
    setCouponProperties((prevVal) => ({ ...prevVal, ...obj }));
    setTabValue(2);
  };

  const onHandleSaveCoupon = async (obj) => {
    setCouponProperties((prevVal) => ({ ...prevVal, ...obj }));
    let object = {
      submit: "true",
      name: couponProperties.name,
      code: couponProperties.code,
      discountType: couponProperties.discountType,
      discount: couponProperties.couponAmount,
      expiryDate: couponProperties?.couponExpiryDate || undefined,
      description: couponProperties.couponDescription || undefined,
      minimumSpend: couponProperties?.minimumSpend,
      allowedProducts:
        couponProperties?.allowedProductsIds?.length > 0
          ? couponProperties?.allowedProductsIds
          : undefined,
      excludedProducts:
        couponProperties.excludeProductIds?.length > 0
          ? couponProperties.excludeProductIds
          : undefined,
      allowedCategories:
        couponProperties.allowedCategories?.length > 0
          ? couponProperties.allowedCategories
          : undefined,
      excludedCategories:
        couponProperties.excludedCategories?.length > 0
          ? couponProperties.excludedCategories
          : undefined,
      couponUsageLimit: couponProperties.couponUsageLimit,
      xItemLimit: couponProperties?.xItemLimit,
      singleUserUseLimit: couponProperties?.singleUserUseLimit,
      isForIndividualUseOnly: couponProperties?.isForIndividualUseOnly,
      allowedPhoneNumbers:
        couponProperties?.allowedPhoneNumbers?.length > 0
          ? couponProperties.allowedPhoneNumbers
          : undefined,
    };
    setLoading(true);
    await postCoupon(object);
  };

  const postCoupon = async (data: object) => {
    try {
      const response = await coupons.post(data);
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onHandleEditCoupon = async (obj) => {
    setCouponProperties((prevVal) => ({ ...prevVal, ...obj }));
    setLoading(true);
    let object = {
      submit: "true",
      name: couponProperties.name,
      code: couponProperties.code,
      discountType: couponProperties.discountType,
      discount: couponProperties.couponAmount,
      expiryDate: couponProperties?.couponExpiryDate || undefined,
      description: couponProperties?.couponDescription || undefined,
      minimumSpend: couponProperties?.minimumSpend
        ? couponProperties?.minimumSpend
        : null,
      allowedProducts:
        couponProperties?.allowedProducts?.length > 0
          ? couponProperties.allowedProducts
          : null,
      excludedProducts:
        couponProperties?.excludedProducts?.length > 0
          ? couponProperties.excludedProducts
          : null,
      allowedCategories:
        couponProperties?.allowedCategories?.length > 0
          ? couponProperties.allowedCategories
          : null,
      excludedCategories:
        couponProperties.excludedCategories?.length > 0
          ? couponProperties.excludedCategories
          : null,
      couponUsageLimit: couponProperties?.couponUsageLimit,
      xItemLimit: couponProperties?.xItemLimit,
      singleUserUseLimit: couponProperties?.singleUserUseLimit,
      isForIndividualUseOnly: couponProperties?.isForIndividualUseOnly,
      allowedPhoneNumbers:
        couponProperties?.allowedPhoneNumbers?.length > 0
          ? couponProperties.allowedPhoneNumbers
          : null,
    };
    try {
      const response = await coupons.put(id, object);
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader className="header">
            {editCouponsMode ? <h4> Edit Coupons</h4> : <h4> Add Coupons</h4>}
          </CardHeader>
          <CardBody>
            <Box>
              {/* < Box > */}
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="General" {...a11yProps(0)} />
                <Tab label="Usage restriction" {...a11yProps(1)} />
                <Tab label="Usage limits" {...a11yProps(2)} />
              </Tabs>
              {/* </Box> */}
              <TabPanel value={tabValue} index={0}>
                <General
                  nextPageHandler={nextPageHandler}
                  couponProperties={couponProperties!}
                  handleOnBlur={handleOnBlur}
                  disabled={disable}
                  editCouponsMode={editCouponsMode}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <UsageRestriction
                  disabled={disable}
                  nextPageHandler={handleRestictionChange}
                  couponProperties={couponProperties}
                  handleOnBlur={handleOnBlur}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <UsageLimit
                  onSaveCoupon={
                    editCouponsMode ? onHandleEditCoupon : onHandleSaveCoupon
                  }
                  disabled={disable}
                  onClose={onClose}
                  couponProperties={couponProperties}
                  handleOnBlur={handleOnBlur}
                  loading={loading}
                />
              </TabPanel>
            </Box>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AddOrEditCoupon;
