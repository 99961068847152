import { Box, CircularProgress } from "@mui/material";

export interface ISpinner {
  spinColor?: boolean | false;
  resize?: string;
}

function Spinner({ spinColor, resize }: ISpinner) {
  return (
    <Box style={{ display: "flex", marginRight: "1rem" }}>
      <CircularProgress
        style={{ color: "#fff" }}
        size={resize ? resize : "20px"}
      />
    </Box>
  );
}
export default Spinner;
