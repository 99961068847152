import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonTable from "components/CommonTable/CommonTable";
import { usePagination } from "hooks/tablePaginationHook";
import { coupons } from "Services/API/IndividualApis/Coupons";
import AddOrEditCoupon from "./AddOrEditCoupon";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "components/CustomDeleteModal";
import { ICoupon } from "Interfaces/Api/Coupons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IPagination } from "Interfaces/Api/Pagination";
import { filterCols } from "Services/Helper";

const Coupons = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [searchText, setSearchText] = useState<string>(undefined);
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [page, handleChangePage] = usePagination();
  const [allCoupons, setAllCoupons] = useState<ICoupon[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [addCouponMode, setAddCouponMode] = useState<boolean>(false);
  const [editCouponMode, setEditCouponMode] = useState<boolean>(false);
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  const [disable, setDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation() as any;
  const Navigate = useNavigate();

  useEffect(() => {
    fetchAllCoupons();
  }, [extraQueryParam, page, rowsPerPage]);

  useEffect(() => {
    if (searchText != undefined) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, page]);

  const handleSearch = async () => {
    if (searchText == "") {
      await fetchAllCoupons();
    } else {
      try {
        const response = await coupons.search(searchText, page, rowsPerPage);
        setLoading(false);
        await setAllCoupons(response.data);
        setShowPagination(response.pagination);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const fetchAllCoupons = async () => {
    setLoading(true);
    try {
      const response = await coupons.getAll(page, rowsPerPage, extraQueryParam);
      setLoading(false);
      await setAllCoupons(response.data);
      setShowPagination(response.pagination);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const rowsData = allCoupons?.map((item) => {
    return {
      name: item?.name,
      Code: item?.code,
      status: item?.status,
      description: item,
      discountType: item?.discountType,
      discount: item?.discount,
      expiryDate: item?.expiryDate,
      couponUsageLimit: item?.couponUsageLimit,
      id: item?._id,
      actions: item,
      allowedProducts: item.allowedProducts,
      usesConut: item.usageDetails?.length || 0,
    };
  });

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleExportCsv = async () => {
    window.open(`${BASE_URL}/coupon/csvFile`);
  };

  const cols = useMemo(
    () => [
      { field: "name", headerName: "Name" },
      { field: "Code", headerName: "Code" },
      {
        field: "discountType",
        headerName: "Discount Type",
        width: 150,
        filterable: true,
        isValueSelectable: true,
        options: [
          { name: "Percentage" },
          { name: "FixedProductDiscount" },
          { name: "FixedCartDiscount" },
        ],
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "discount",
        headerName: "Discount",
      },
      {
        field: "usesConut",
        headerName: "Uses Conut",
      },
      {
        field: "allowedProducts",
        headerName: "Allowed Products",
        width: 200,
        renderCell: (params) => {
          return (
            <div style={{ lineHeight: "20px" }}>
              {params.row.allowedProducts ? (
                <>
                  {params.row.allowedProducts.map((val) => {
                    return (
                      <>
                        <>
                          {val.name ? (
                            <span>{`${val.name}`}</span>
                          ) : (
                            <span>-- </span>
                          )}
                        </>
                        <br />
                      </>
                    );
                  })}
                </>
              ) : (
                "- -  "
              )}
            </div>
          );
        },
      },
      {
        field: "description",
        headerName: "Description",
        width: "150",
        renderCell: (params) => {
          return (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {params.row.description.description ? (
                <>{`${params.row.description.description}`}</>
              ) : (
                `- - `
              )}
            </div>
          );
        },
      },

      {
        field: "expiryDate",
        headerName: "Expiry Date",
        width: 180,
        renderCell: (params) => {
          {
            const d = new Date(params.row.expiryDate);
            let day = d.getDate();
            let year = d.getFullYear();
            let month = d.getMonth();
            return (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {params?.row.expiryDate ? (
                  <>
                    {day}/{month}/{year}
                  </>
                ) : (
                  "- -"
                )}
              </div>
            );
          }
        },
      },
      {
        field: "status",
        headerName: "Status",
        filterable: true,
        isValueSelectable: true,
        options: [
          { name: "Active" },
          { name: "Inactive" },
          { name: "Expired" },
          { name: "Consumed" },
        ],
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },

      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params) => {
          return (
            <div>
              {params.row.status === "Active" && state?.access.write ? (
                <EditIcon
                  onClick={() => handleEditProduct(params.value)}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              ) : (
                <VisibilityIcon
                  onClick={() => handleVisibleProduct(params.value)}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              )}
              {state?.access.delete && (
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setItemToBeDeleted(params.value);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [state]
  );
  filterCols(state, cols);
  const handleEditProduct = async (item) => {
    Navigate(`/admin/coupons/${item._id}`);
    setEditCouponMode(true);
  };

  const handleVisibleProduct = (item) => {
    Navigate(`/admin/coupons/${item._id}`);
    setDisable(true);
    setEditCouponMode(true);
  };

  const addItems = () => {
    setAddCouponMode((prev) => !prev);
  };

  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => {
    if (clearFilter) {
      setExtraQueryParam("");
      return;
    }
    const filterArr = [];
    filterRows.forEach((element) => {
      let value = element.value;
      if (value instanceof Array) {
        value = value.filter((item) => item !== "");
      }
      const obj = {
        [element.name]: {
          [element.operator]: value,
        },
      };
      filterArr.push(obj);
    });
    const filterObj = {
      [linkedOperator]: filterArr,
    };

    let queryParam = `&filter=${JSON.stringify(filterObj)}`;

    setExtraQueryParam(queryParam);
  };

  const removeItems = async () => {
    try {
      const response = await coupons.remove(itemToBeDeleted._id);
      setShowDeleteModal(false);
      setItemToBeDeleted(null);
      await fetchAllCoupons();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {showDeleteModal ? (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems()}
          itemName={itemToBeDeleted.name}
        />
      ) : (
        ""
      )}
      {addCouponMode || editCouponMode ? (
        <>
          <AddOrEditCoupon />
        </>
      ) : (
        <CommonTable
          linkUrl={editCouponMode ? "/admin/coupons/:id" : "/admin/coupons/add"}
          Title="Coupons"
          button="Add Coupon"
          handleChangePage={handleChangePage}
          addItems={addItems}
          totalRows={showPagination?.totalCount}
          handleSearchTextChanged={(value) => setSearchText(value)}
          searchPlaceHolder="Search by name or code"
          searchText={searchText}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rows={rowsData}
          columns={cols}
          handleFilter={handleFilter}
          showAddButton={state?.access.write}
          loading={loading}
          handleExportCsv={handleExportCsv}
          isExportCSV={true}
        />
      )}
    </>
  );
};

export default Coupons;
