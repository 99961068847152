import { Modal } from "@mui/material";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IShippingPickupDetail } from "Interfaces/Api/config";
import { useEffect, useState } from "react";

const initialRangeData = {
  mobile: "",
  name: "",
  email: "",
  pinCode: "",
  address: "",
};

interface IAddOrEditShippingRangeProps {
  handleSave: Function;
  handleClose: Function;
  isAddShippingPicupModalOpen: boolean;
  editData: IShippingPickupDetail | null;
}

export default function AddOrEditShippingPickupDetails({
  handleSave,
  handleClose,
  isAddShippingPicupModalOpen,
  editData,
}: IAddOrEditShippingRangeProps) {
  const [pickupDetails, setPickupDetails] = useState<any>(initialRangeData);

  function pickupDetailsChangeHandler(key, value: string) {
    setPickupDetails((preValue) => ({
      ...preValue,
      [key]: value,
    }));
  }

  useEffect(() => {
    if (editData) {
      setPickupDetails(editData);
    }
  }, [editData]);

  function handleCancel() {
    setPickupDetails(initialRangeData);
    handleClose();
  }
  return (
    <Modal
      open={isAddShippingPicupModalOpen}
      onClose={() => console.log("hello")}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GridContainer sm={8}>
        <Card>
          <GridItem>
            <CardHeader className="header">
              {/* <CardHeader color="primary"> */}
              <h4>Add a new Pickup details</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem sm={6}>
                  <CustomInput
                    labelText="Mobile no"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "mobileNo",
                      // disabled: disabled,
                      value: pickupDetails.mobile,
                      type: "number",
                      onChange: (e) =>
                        pickupDetailsChangeHandler("mobile", e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem sm={6}>
                  <CustomInput
                    labelText="Name"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "name",
                      // disabled: disabled,
                      value: pickupDetails.name,
                      type: "text",
                      onChange: (e) =>
                        pickupDetailsChangeHandler("name", e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6}>
                  <CustomInput
                    labelText="Email"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "email",
                      // disabled: disabled,
                      value: pickupDetails.email,
                      type: "email",
                      onChange: (e) =>
                        pickupDetailsChangeHandler("email", e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem sm={6}>
                  <CustomInput
                    labelText="PinCode"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "pinCode",
                      // disabled: disabled,
                      value: pickupDetails.pinCode,
                      type: "number",
                      onChange: (e) =>
                        pickupDetailsChangeHandler("pinCode", e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6}>
                  <CustomInput
                    labelText="Address"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      name: "address",
                      // disabled: disabled,
                      value: pickupDetails.address,
                      type: "textarea",
                      onChange: (e) =>
                        pickupDetailsChangeHandler("address", e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>

              <hr />
              <GridContainer style={{ justifyContent: "flex-end" }}>
                <GridItem>
                  <Button color="grey" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    onClick={() => {
                      handleSave(pickupDetails, editData ? editData._id : null);
                      handleCancel();
                    }}
                  >
                    Save
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </GridItem>
        </Card>
      </GridContainer>
    </Modal>
  );
}
