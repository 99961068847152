import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Banner } from "./Banner";
import { ICms } from "Interfaces/Api/cms/Banner";
import { FooterNotification } from "./Banner/Notification/FooterNotification";
import { HeaderNotification } from "./Banner/Notification/HeaderNotification";
import { apiCms } from "Services/API/IndividualApis/Cms";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const DynamicContent = () => {
  const [value, setValue] = useState<number>(0);
  const [dynamicContent, setDynamicContent] = useState<ICms>();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getAllDynamicContent();
  }, []);

  const getAllDynamicContent = async () => {
    try {
      const response = await apiCms.GetDynamicContent();
      setDynamicContent(response.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <GridContainer>
      <GridItem sm={12}>
        <Card>
          <CardHeader className="header">
            {/* <CardHeader color="primary"> */}
            <h4>Dynamic Content</h4>
          </CardHeader>
          <CardBody>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Banner" {...a11yProps(0)} />
                  <Tab label="Header Notification" {...a11yProps(1)} />
                  <Tab label="Footer Notification" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Banner
                  allBaners={dynamicContent?.banner}
                  getData={getAllDynamicContent}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <HeaderNotification
                  allNotification={dynamicContent?.headerNotification}
                  getData={getAllDynamicContent}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <FooterNotification
                  allNotification={dynamicContent?.footerNotification}
                  getData={getAllDynamicContent}
                />
              </TabPanel>
            </Box>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
