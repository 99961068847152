import { apiProvider } from "Services/API/Provider";
import { taskI } from "../../../../Interfaces";
import { ApiCore } from "../../Core";

const url = "variation";
const auth = JSON.parse(localStorage.getItem("auth"));
// const token = auth.token
const Variant = new ApiCore<any>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});
Variant.addNewAttributes = (id, data) => {
  return apiProvider.post(`${url}/addNewAttributes/${id}`, data);
};

export { Variant };
