import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import UploadImage, {
  acceptFileType,
} from "components/CustomUpload/UploadImage";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import {
  deleteProductImage,
  products,
} from "Services/API/IndividualApis/Product";
import { v4 as uuidv4 } from "uuid";
import { IProduct } from "Interfaces/Api/Product";
import Loader from "components/Loader";

interface ImageComponentProps {
  editMode: boolean;
  editItem: IProduct;
  onCancel: Function;
  handleImagesProperties: Function;
  productProperties: Record<string, any>;
  handleEditProduct: Function;
  handleOnBlur: Function;
  loading: Boolean;
}

const ImageComponent = ({
  handleImagesProperties,
  productProperties,
  editItem,
  handleOnBlur,
  onCancel,
  editMode,
  handleEditProduct,
  loading,
}: ImageComponentProps) => {
  const [images, setImages] = useState([]);
  const [allMedia, setAllMedia] = useState([]);
  const [primaryImage, setPrimaryImage] = useState<any>("");
  const [primaryImageWithNoLimit, setPrimaryImageWithNoLimit] =
    useState<any>("");
  const [imageError, setImageError] = useState<string>(
    "Primary Image is Required"
  );
  const [imageTouched, setImageTouched] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(true);

  useEffect(() => {
    if (productProperties?.image || productProperties?.Media) {
      setPrimaryImage(productProperties?.image);
      setPrimaryImageWithNoLimit(productProperties.image);
      setImages(productProperties?.media);
      setAllMedia(productProperties?.media);
    }
  }, [productProperties]);

  const checkFileSize = (file) => {
    if ("fileLimitExceed" in file) {
      if (file.fileLimitExceed) return true;
    } else {
      return false;
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const addPrimaryImage = async (acceptedFiles) => {
    handleOnBlur({ media: allMedia });
    try {
      const result = (await toBase64(acceptedFiles[0])) as string;
      const fileSizeLarge = checkFileSize(acceptedFiles);

      let obj = {
        file: result,
        fileName: acceptedFiles[0].name,
      };
      let object = {
        image: obj,
      };

      let objectWithNoLimit = {
        file: result,
        fileName: acceptedFiles[0].name,
        fileLimitExceed: !fileSizeLarge ? false : true,
      };

      if (!fileSizeLarge) {
        setPrimaryImage(obj);
        handleOnBlur(object);
        setImageTouched(true);
      } else {
        setPrimaryImageWithNoLimit(objectWithNoLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (primaryImage == "") {
      setError(true);
    } else {
      setError(false);
    }
  }, [primaryImage]);

  const addMultipleImage = async (files) => {
    if (files) {
      var filesArray = [].slice.call(files);
      const filePathsPromises = [];
      const allMediasFiles = [];

      for (let media of filesArray) {
        const fileSizeLarge = checkFileSize(media);
        if (!fileSizeLarge) {
          let obj = {
            _id: uuidv4(),
            fileName: media.file.name,
            file: await toBase64(media.file),
            fileLimitExceed: !fileSizeLarge ? false : true,
          };
          filePathsPromises.push(obj);
        }
        let imageObjectForNoLimit = {
          _id: uuidv4(),
          fileName: media.file.name,
          file: await toBase64(media.file),
          fileLimitExceed: !fileSizeLarge ? false : true,
        };
        allMediasFiles.push(imageObjectForNoLimit);
      }
      const filePaths = await Promise.all(filePathsPromises);
      const allMediasFilePath = await Promise.all(allMediasFiles);

      setImages((prevImg) => prevImg.concat(filePaths));
      setAllMedia((prevImg) => prevImg.concat(allMediasFilePath));
    }
  };

  const deleteselectedImage = async (item) => {
    let obj = {
      key: item.key,
    };
    if (editMode) {
      try {
        if (item.key) {
          const response = await deleteProductImage(editItem._id, obj);
          handleOnBlur({ media: response.media });
        } else {
          const newFilter = allMedia.filter((val) => {
            return val._id && item._id !== val._id;
          });
          setAllMedia(newFilter);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      const newFilters = images.filter((val, index) => {
        if (item._id != val._id) {
          return val;
        }
      });
      setImages(newFilters);

      const newMedia = allMedia.filter((val, index) => {
        if (item._id != val._id) {
          return val;
        }
      });
      setAllMedia(newMedia);
      handleOnBlur({ media: newMedia });
    }
  };

  const handleAddNewImage = async (files) => {
    if (files) {
      var mediaArray = [].slice.call(files);
      let newAllMedia = [];

      for (let media of mediaArray) {
        const fileSizeLarge = checkFileSize(media);
        if (!fileSizeLarge) {
          let obj = {
            productId: editItem._id,
            fileName: media.file.name,
            file: await toBase64(media.file),
          };
          try {
            const response = await products.addNewProductMedia(obj);
            setImages(response.media);
            setAllMedia(response.media);
            handleOnBlur({ media: response.media });
          } catch (err) {
            console.log(err);
          }
        } else {
          let newobj = {
            _id: uuidv4(),
            productId: editItem._id,
            fileName: media.file.name,
            file: await toBase64(media.file),
            fileLimitExceed: !fileSizeLarge ? false : true,
          };
          newAllMedia.push(newobj);
        }
      }
      setAllMedia((prev) => prev.concat(newAllMedia));
    }
  };

  const onNextPage = () => {
    const obj = {
      image: primaryImage,
      media: images,
    };
    if (primaryImage == "") {
      setImageTouched(true);
    } else {
      handleImagesProperties(obj);
    }
  };

  const handleFileUpload = async (selectedFile, previousFile) => {
    // add mode
    if (!editMode) {
      if (!previousFile._id) {
        //  previous file does not have id if not uploaded, set it as primary image
        setPrimaryImage(selectedFile);
        handleOnBlur({ image: selectedFile });
      } else {
        const newUpdatedMedia = [...allMedia];
        // find file to be updated from allmedia
        const allMediaIndex = allMedia.findIndex((item, index) => {
          return item._id === previousFile._id;
        });
        newUpdatedMedia[allMediaIndex] = selectedFile;
        setImages(newUpdatedMedia);
        setAllMedia(newUpdatedMedia);
        handleOnBlur({ media: newUpdatedMedia });
      }
    } else {
      // EDIT MODE

      // find if the file has a key, is already uploaded media
      const findIndexOfMedia = allMedia.find((item) => {
        return item.key && item.key === previousFile.key;
      });

      // newly added media will not have key check for productId
      const findIndexOfNotUploaded = allMedia.find((item) => {
        return item.productId && item.productId === previousFile.productId;
      });

      if (findIndexOfMedia) {
        let newObject = { ...selectedFile, previousImageKey: previousFile.key };
        try {
          const response = await products.updateProductMediaImage(
            editItem._id,
            newObject
          );
          setAllMedia(response.media);
          setImages(response.media);
          handleOnBlur({ media: response.media });
        } catch (error) {
          console.log("error upload", error);
        }
      } else if (findIndexOfNotUploaded) {
        try {
          const postMedia = { ...selectedFile, productId: editItem._id };
          const res = await products.addNewProductMedia(postMedia);
          setImages(res.media);
        } catch (err) {
          console.log(err);
        }
      } else {
        // if any key or product key not found, then it is primary image / single image
        try {
          const response = await products.updateProductMainImage(
            editItem._id,
            selectedFile
          );
          if (response) {
            setPrimaryImage(response.image);
            handleOnBlur({ image: response.image });
          }
        } catch (error) { }
      }
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          {/* <CardBody > */}
          <Box style={{ paddingBottom: "3rem" }}>
            <GridContainer style={{ padding: "0.9375rem 20px" }}>
              <GridItem xs={12} sm={6} md={4}>
                <UploadImage
                  id={editMode ? "editedId" : "Primary-primaryImage-files"}
                  label=" Primary Image*"
                  useFileType={acceptFileType.ONLY_IMAGE}
                  imageSize={500}
                  addImageOrImages={addPrimaryImage}
                  selectedImage={primaryImage}
                  editMode={editMode}
                  handleFile={handleFileUpload}
                //  handleUpdateImages={updatePrimaryImage}
                />
                <div style={{ color: "red" }}>
                  <span>{error && imageTouched && imageError}</span>
                </div>
              </GridItem>

              <GridItem xs={12} sm={6} md={8}>
                <UploadImage
                  id={editMode ? "add new product media" : "Media-files"}
                  // id="contained-button-files"
                  label=" Images / Videos"
                  videoSize={5048}
                  imageSize={500}
                  multiple={true}
                  useFileType={acceptFileType.BOTH}
                  addImageOrImages={addMultipleImage}
                  selectedImage={allMedia}
                  editMode={editMode}
                  deleteImage={deleteselectedImage}
                  // handleUpdateImages={updateMediaImage}
                  handleFile={handleFileUpload}
                  handleAddNewProductMedia={handleAddNewImage}
                />
              </GridItem>
            </GridContainer>
          </Box>
          <GridContainer
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <Button color="gray" onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <Button color="success" onClick={onNextPage}>
                Next
              </Button>
            </div>
            {editMode && (
              <div style={{ marginRight: "1rem", position: "relative" }}>
                <Button color="success" onClick={handleEditProduct}>
                  Submit
                </Button>
                {loading && <Loader />}
                {/* {loading && <div style={{ position: "absolute", top: "30%", right: "5px", opacity: "1" }}>{<CircularProgress size="20px" style={{ color: "#8D6D4D" }} />}</div>} */}
              </div>
            )}
          </GridContainer>
          {/* </CardBody> */}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Interceptor(ImageComponent);
