import CommonTable from "components/CommonTable/CommonTable";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePagination } from "hooks/tablePaginationHook";
import Dialog from "@mui/material/Dialog";
import DeleteModal from "components/CustomDeleteModal";
import { IPagination } from "Interfaces/Api/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import { apiInquiry } from "Services/API/IndividualApis/Inquiry";
import { apiPolicy } from "Services/API/IndividualApis/Policy";
import { IInquiry } from "Interfaces/Api/Policy";

const Inquiry = () => {
  const [allContact, setAllContacts] = useState<IInquiry[]>([]);
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, handleChangePage] = usePagination();
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [searchText, setSearchText] = useState<string>(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const { state } = useLocation() as any;

  const rowsData = allContact?.map((item) => {
    return {
      id: item._id,
      name: item.name,
      mobileNumber: item.mobileNumber,
      subject: item.subject,
      message: item.message,
    };
  });

  const cols = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        renderCell: ({ value }) => {
          return <Link to={`/admin/users/${value}`}>{value}</Link>;
        },
      },
      {
        field: "mobileNumber",
        headerName: "Mobile Number",
        width: 150,
      },
      {
        field: "subject",
        headerName: "Subject",
        width: 250,
      },
      {
        field: "message",
        headerName: "Message",
        width: 300,
        renderCell: (param) => {
          return (
            <div>{param.row.message ? <>{param.row.message}</> : " - -"}</div>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 100,
        renderCell: (params) => {
          return (
            <div>
              {state?.access.delete && (
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setItemToBeDeleted(params);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getAllContactDetails();
  }, []);

  useEffect(() => {
    if (searchText != undefined) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, page]);

  const handleSearch = async () => {
    if (searchText == "") {
      await getAllContactDetails();
    } else {
      try {
        const response = await apiInquiry.search(searchText, page, rowsPerPage);
        setAllContacts(response.data);
        setShowPagination(response.pagination);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getAllContactDetails = async () => {
    try {
      const response = await apiInquiry.getAll(page, rowsPerPage);
      setAllContacts(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => {};

  const removeItems = async () => {
    try {
      const response = await apiInquiry.remove(itemToBeDeleted.id);
      setShowDeleteModal(false);
      setItemToBeDeleted(null);
      await getAllContactDetails();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems()}
          itemName={`"${itemToBeDeleted.row.name}" inquiry`}
        />
      )}
      <CommonTable
        // linkUrl={editPolicyMode ? "/admin/policies/:id" : "/admin/policies/add"}
        Title="Inquiries"
        button="Add Policy"
        page={page}
        handleChangePage={handleChangePage}
        totalRows={showPagination?.totalCount}
        addItems={() => console.log("hello")}
        searchText={searchText}
        handleSearchTextChanged={(value) => setSearchText(value)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(value) => setRowsPerPage(value)}
        rows={rowsData}
        showFilter={false}
        columns={cols}
        handleFilter={handleFilter}
      />
    </>
  );
};
export default Interceptor(Inquiry);
