import React, { useEffect, useState } from "react";
import { cart } from "Services/API/IndividualApis/Cart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import CustomProductDetailsTable from "components/CustomProductDetailTable/CustomProductDetailsTable";
import GenralDetails from "views/Orders/GenralDetails";
import { ICart } from "Interfaces/Api/Cart";

const AbandonedDetails = () => {
  const [abandonedDetails, setAbandonedDetails] = useState<ICart>();
  const [date, setDate] = useState<string>("");

  const Navigate = useNavigate();

  useEffect(() => {
    fetchAbandonedDetailById();
  }, []);

  useEffect(() => {
    if (abandonedDetails) {
      const newDate1 = abandonedDetails.createdAt;
      const newDate = new Date(newDate1).toLocaleDateString();
      setDate(newDate);
    }
  }, [abandonedDetails]);

  const { id } = useParams();

  const { state } = useLocation();

  const fetchAbandonedDetailById = async () => {
    try {
      const response = await cart.getSingle(id);
      setAbandonedDetails(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onCancel = () => {
    Navigate(-1);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className="header">
              <h4>Abandoned details</h4>
            </CardHeader>

            <CardBody>
              {abandonedDetails && (
                <GenralDetails details={abandonedDetails} isAbandoned={true} />
              )}

              {abandonedDetails && (
                <CustomProductDetailsTable
                  abandonedDetails={abandonedDetails}
                />
              )}

              <GridContainer
                style={{
                  justifyContent: "end",
                  paddingTop: "4rem",
                  paddingRight: "2rem",
                  paddingBottom: "2rem",
                }}
              >
                <div style={{ marginRight: "1rem" }}>
                  <Button color="gray" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                </div>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default AbandonedDetails;
