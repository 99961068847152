import { useState } from "react";
import styles from "./signin.module.scss";
import { Form } from "react-bootstrap";
import Button from "../../components/CustomButtons/Button.js";
import { useFormik } from "formik";
import { Navigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as createActions from "../../store/actions";
import logo from "assets/img/econut-logo1.png";
import { Grid, Typography } from "@mui/material";
import { login } from "Services/API/IndividualApis/User";
import OtpVerificationComponent from "./OtpVerificationComponent";
import { loginWithOtp } from "Services/API/IndividualApis/User";
import { authFail, authStart, loginAction } from "store/actions/Auth";
import * as actionTypes from "store/actions/actionsTypes";
import Spinner from "./Spinner";
import CryptoJS from "crypto-js";

const SignIn = (props) => {
  const [isOtpScreenVisible, setIsOtpScreenVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch();
  const CRYPTO_SECRET_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY;

  const loginWithPassword = async (phoneNumber, password) => {
    try {
      const aesEncTrans = CryptoJS.AES.encrypt(
        JSON.stringify(password),
        CRYPTO_SECRET_KEY
      ).toString();
      dispatch(authStart());
      const result = await login.post({ phoneNumber, password: aesEncTrans });
      setPhoneNumber(phoneNumber);
      setPassword(password);
      setIsOtpScreenVisible(true);
      dispatch({ type: actionTypes.STOP_LOADING });
    } catch (err) {
      dispatch(authFail(err.response.data.errors));
    }
  };

  const handleResendAnOtp = async () => {
    const obj = {
      phoneNumber: phoneNumber,
    };
    const response = await loginWithOtp.post(obj);
  };

  const handleOtpSubmit = async (OTP) => {
    dispatch(loginAction(phoneNumber, OTP));
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },
    onSubmit: (values) => {
      loginWithPassword(values.phoneNumber, values.password);
    },

    validate: (values) => {
      // console.log(values);
      // let errors = {};
      // if (!values.phoneNumber) {
      //   errors.phoneNumber = "Required";
      // } else if (
      //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.phoneNumber)
      // ) {
      //   errors.phoneNumber = "Invalid email address";
      // }
      // return errors;
    },
  });

  if (props.isAuthenticated) return <Navigate to="/" replace />;
  if (isOtpScreenVisible) {
    return (
      <OtpVerificationComponent
        handleSubmit={handleOtpSubmit}
        handleResendAnOtp={handleResendAnOtp}
        spinner={props.loading}
        isError={props.error}
      />
    );
  }
  return (
    <div
      style={{
        margin: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
      }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <img className={styles.logo} src={logo} alt="logo" />
        <Typography style={{ paddingTop: "10px" }}>ADMIN PANEL</Typography>

        <Grid
          item
          xs={12}
          sx={{
            boxShadow: 2,
            backgroundColor: "#fff",
            padding: "1rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            marginTop: "1rem",
            marginBottom: "1rem",
            width: { xs: "100%", sm: "60%", md: "40%", lg: "30%" },
          }}
        >
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group controlId="phoneNumber">
              <Form.Label className="text-dark">Mobile Number</Form.Label>
              <Form.Control
                // style={{ width: "30%" }}
                placeholder="Mobile Number"
                // onChange={formik.handleChange}
                onChange={(e) => {
                  e.target.value = e.target.value.slice(0, 10);
                  if (e.target.value && !Number(e.target.value)) return;
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                // value={formik.values.phoneNumber}
                value={
                  formik.values.phoneNumber
                    ? parseInt(formik.values.phoneNumber)
                    : formik.values.phoneNumber
                }
                isInvalid={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
              <Form.Text className="text-dark">
                We'll never share your phone number with anyone else.
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {formik.errors.phoneNumber}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label className="text-dark">Password</Form.Label>
              <Form.Control
                // style={{ width: "30%" }}
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder="Password"
              />
            </Form.Group>
            {props.isError ? (
              <Form.Text className="text-danger mb-2">
                {props.isError}
              </Form.Text>
            ) : (
              ""
            )}
            <Button
              color="success"
              type="submit"
              style={{ width: "100%", fontSize: "1rem" }}
            >
              {props.loading && <Spinner />} LOGIN
            </Button>
          </Form>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isLoggedIn,
    isError: state.auth.error,
    loading: state.auth.loading,
    error: state.auth.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (phoneNumber, otp) =>
      dispatch(createActions.loginAction(phoneNumber, otp)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
