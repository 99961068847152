import { Box, Typography } from "@mui/material";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import { useEffect, useState } from "react";
import { apiConfig } from "Services/API/IndividualApis/Config";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useLocation } from "react-router-dom";
import { isEmpty } from "Services/Helper";

const OtherConfig = ({ configData, getData }) => {
  const { state } = useLocation() as any;
  const [editable, isEditable] = useState<boolean>(false);
  const [limitCOD, setLimitCOD] = useState<number>(0);
  const [limitCart, setLimitCart] = useState<number>(0);
  const [minLimitCOD, setMinLimitCOD] = useState<number>(0);

  // set and handle API Data
  const [maxCOD, setMaxCOD] = useState<number>(0);
  const [maxCart, setMaxCart] = useState<number>(0);
  const [minCOD, setMinCOD] = useState<number>(0);

  useEffect(() => {
    if (configData && !isEmpty(configData)) {
      setMaxCOD(configData?.maxCODPriceLimit);
      setMaxCart(configData?.maxCartPriceLimit);
      setLimitCOD(configData?.maxCODPriceLimit);
      setMinCOD(configData?.minLimitCOD);
      setMinLimitCOD(configData?.minLimitCOD)
    }
  }, [configData]);

  function handleCancel() {
    setMinLimitCOD(minCOD)
    setLimitCOD(maxCOD);
    setLimitCart(maxCart);
    isEditable(false);
  }

  async function saveConfigData(data) {
    try {
      const response = await apiConfig.postOtherConfig(data);
      if (response) {
        getData();
        isEditable(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      minCODPriceLimit: minLimitCOD,
      maxCODPriceLimit: limitCOD,
      maxCartPriceLimit: limitCart,
    };
    saveConfigData(data);
  };

  return (
    <Box>
      <Typography>Set Price Limits (per order)</Typography>
      <GridContainer>
        <GridItem>
          <CustomInput
            labelText="Minimum Price Limit for COD"
            formControlProps={{}}
            inputProps={{
              name: "minCODPriceLimit",
              value: minLimitCOD,
              type: "number",
              disabled: !editable,
              onChange: (e) => {
                setMinLimitCOD(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            labelText="Maximum Price Limit for COD"
            formControlProps={{}}
            inputProps={{
              name: "maxCODPriceLimit",
              value: limitCOD,
              type: "number",
              disabled: !editable,
              onChange: (e) => {
                setLimitCOD(+e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            labelText="Maximum Price Limit for Cart"
            formControlProps={{}}
            inputProps={{
              name: "maxCartPriceLimit",
              value: limitCart,
              disabled: !editable,
              type: "number",
              onChange: (e) => {
                setLimitCart(e.target.value);
              },
            }}
          />
        </GridItem>
      </GridContainer>

      {!editable && state?.access.write && (
        <Button color="success" onClick={() => isEditable(true)}>
          Edit
        </Button>
      )}
      {editable && state?.access.write && (
        <>
          <Button color="grey" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="success" onClick={handleSubmit}>
            Save
          </Button>
        </>
      )}
    </Box>
  );
};

export default OtherConfig;
