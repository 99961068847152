import { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import Loader from "components/Loader";
import { ICoupon } from "Interfaces/Api/Coupons";
import CustomSelect from "components/CustomSelect/CustomSelect";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface UsageLimitProps {
  couponProperties: ICoupon;
  onSaveCoupon: Function;
  handleOnBlur: Function;
  onClose: Function;
  disabled: Boolean;
  loading: Boolean;
}

const UsageLimit = ({
  onSaveCoupon,
  onClose,
  couponProperties,
  handleOnBlur,
  disabled,
  loading,
}: UsageLimitProps) => {
  const [limitPerCoupon, setLimitPerCoupon] = useState<any>("");
  const [singleUserUseLimit, setSingleUserUseLimit] = useState<any>("");
  const [xItemLimit, setXItemLimit] = useState<any>("");
  const [forIndividualUseOnly, setForIndividualUseOnly] = useState<boolean>();
  const IndividualUseOnly = [
    { id: "1", name: "TRUE", value: "true" },
    { id: "3", name: "FALSE", value: "false" },
  ];

  useEffect(() => {
    if (couponProperties) {
      console.log(couponProperties);
      setLimitPerCoupon(couponProperties?.couponUsageLimit);
      setSingleUserUseLimit(couponProperties?.singleUserUseLimit);
      setXItemLimit(couponProperties?.xItemLimit);
      setForIndividualUseOnly(couponProperties.isForIndividualUseOnly);
    }
  }, []);
  const onHandleSaveCoupon = () => {
    let obj = {
      couponUsageLimit: limitPerCoupon,
      singleUserUseLimit: singleUserUseLimit,
      xItemLimit: xItemLimit,
    };
    onSaveCoupon(obj);
  };
  const onCancel = () => {
    onClose();
  };

  const handleLimitCouponBlur = () => {
    let obj = {
      couponUsageLimit: limitPerCoupon,
    };
    handleOnBlur(obj);
  };

  const handleLimitForXitemBlur = () => {
    let obj = {
      xItemLimit: xItemLimit,
    };
    handleOnBlur(obj);
  };

  const handleLimitPerUserBlur = () => {
    let obj = {
      singleUserUseLimit: singleUserUseLimit,
    };
    handleOnBlur(obj);
  };

  const handleIndividualUseBlur = () => {
    let obj = {
      isForIndividualUseOnly: forIndividualUseOnly,
    };
    handleOnBlur(obj);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4} style={{ position: "relative" }}>
          <CustomInput
            labelText="Usage limit per coupon"
            //   id="company-disabled"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: disabled,
              value: limitPerCoupon,
              onChange: (e) => {
                if (e.target.value && !Number(e.target.value)) return;

                setLimitPerCoupon(e.target.value);
              },
              onBlur: handleLimitCouponBlur,
              // type: "Number",
            }}
          />
          <Tooltip
            style={{ position: "absolute", right: "0" }}
            title="How many times a coupon can be used by all customers before being invalid."
          >
            <InfoIcon />
          </Tooltip>
        </GridItem>
        {(couponProperties &&
          couponProperties?.discountType == "Percentage" &&
          couponProperties?.allowedProducts?.length > 0) ||
        (couponProperties?.discountType == "Percentage" &&
          couponProperties?.excludedProducts?.length > 0) ||
        (couponProperties?.discountType == "Percentage" &&
          couponProperties?.allowedCategories?.length > 0) ||
        (couponProperties?.discountType == "Percentage" &&
          couponProperties?.excludedCategories?.length > 0) ||
        (couponProperties?.discountType == "FixedProductDiscount" &&
          couponProperties?.excludedProducts?.length > 0) ||
        (couponProperties?.discountType == "FixedProductDiscount" &&
          couponProperties?.allowedProducts?.length > 0) ||
        (couponProperties?.discountType == "FixedProductDiscount" &&
          couponProperties?.allowedCategories?.length > 0) ||
        (couponProperties?.discountType == "FixedProductDiscount" &&
          couponProperties?.excludedCategories?.length > 0) ? (
          <GridItem xs={12} sm={6} md={4} style={{ position: "relative" }}>
            <CustomInput
              labelText="Limit usage to x item"
              //   id="company-disabled"
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              inputProps={{
                // disabled:
                //   couponProperties?.allowedProducts?.length > 0 ||
                //   couponProperties?.allowedCategories?.length > 0
                //     ? disabled
                //     : true,
                value: xItemLimit,
                onChange: (e) => {
                  if (e.target.value && !Number(e.target.value)) return;
                  if (e.target.value.charCodeAt(0) !== 47) {
                    setXItemLimit(e.target.value);
                  }
                },
                onBlur: handleLimitForXitemBlur,
                // type: "Number",
              }}
            />
            <Tooltip
              style={{ position: "absolute", right: "0" }}
              title="How many items the coupon can be applied to before being invalid. This field is only displayed if there is one or more products that the coupon can be used with, and is configured under the Usage Restrictions."
            >
              <InfoIcon />
            </Tooltip>
          </GridItem>
        ) : (
          ""
        )}
        <GridItem xs={12} sm={6} md={4} style={{ position: "relative" }}>
          <CustomInput
            labelText="Usage limit per user"
            //   id="company-disabled"
            id="Usage limit per user"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: disabled,
              value: singleUserUseLimit,
              onChange: (e) => {
                if (e.target.value && !Number(e.target.value)) return;
                if (e.target.value.charCodeAt(0) !== 47) {
                  setSingleUserUseLimit(e.target.value);
                }
              },
              onBlur: handleLimitPerUserBlur,
              // type: "Number",
              name: "Usage limit per user",
            }}
          />
          <Tooltip
            style={{ position: "absolute", right: "0" }}
            title=" How many times a coupon can be used by each customer before being invalid for that customer."
          >
            <InfoIcon />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} style={{ position: "relative" }}>
          <CustomSelect
            //Coupon type(discountType)
            labelText="Individual use"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            id="age-native-required"
            inputProps={{
              disabled: disabled,
              required: true,
              value: forIndividualUseOnly,
              onChange: (e) => setForIndividualUseOnly(e.target.value),
              onBlur: handleIndividualUseBlur,
              name: "discountType",
              native: true,
            }}
          >
            <option value="">{""}</option>
            {IndividualUseOnly.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </CustomSelect>
          <Tooltip
            style={{ position: "absolute", right: "0" }}
            title="Select true if you don’t want this coupon to be used in combination with other coupons."
          >
            <InfoIcon />
          </Tooltip>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          justifyContent: "end",
          padding: "1rem",
        }}
      >
        <div style={{ marginRight: "1rem" }}>
          <Button color="gray" onClick={onCancel}>
            Cancel
          </Button>
        </div>
        <div style={{ marginRight: "1rem", position: "relative" }}>
          <Button
            color="success"
            onClick={onHandleSaveCoupon}
            disabled={disabled ? true : false}
          >
            Submit
          </Button>
          {loading && <Loader />}
          {/* {loading && <div style={{ position: "absolute", top: "30%", right: "5px", opacity: "1" }}>{<CircularProgress size="20px" style={{ color: "#8D6D4D" }} />}</div>} */}
        </div>
      </GridContainer>
    </>
  );
};

export default Interceptor(UsageLimit);
