import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { FormHelperText, TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

interface GeneralProps {
  couponProperties: Record<string, any>;
  nextPageHandler: Function;
  handleOnBlur: Function;
  disabled: Boolean;
  editCouponsMode: Boolean;
}

const General = ({
  couponProperties,
  nextPageHandler,
  handleOnBlur,
  disabled,
  editCouponsMode,
}: GeneralProps) => {
  const [couponName, setCouponName] = useState<string>("");
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponAmount, setCouponAmount] = useState<any>("");
  const [discountType, setDiscountType] = useState<string>("");
  const [couponExpiryDate, setCouponExpiryDate] = useState<Date>(null);
  const [couponDescription, setCouponDescription] = useState<string>("");
  const [couponNameTouched, setCouponNameTouched] = useState<boolean>(false);
  const [nameTouched, setNameTouched] = useState<boolean>(false);
  const [discountTypeTouched, setDiscountTypeTouched] =
    useState<boolean>(false);
  const [couponAmountTouched, setCouponAmountTouched] =
    useState<boolean>(false);
  const [couponCodeTouched, setCouponCodeTouched] = useState<boolean>(false);
  const [couponNameError, setCouponNameError] =
    useState<string>("Name is Required");
  const [couponCodeError, setCouponCodeError] = useState<string>(
    "Coupon code is Required"
  );
  const [couponAmountError, setCouponAmoubntError] = useState<string>(
    "Coupon amount Required"
  );
  const [discountTypeError, setDiscountTypeError] = useState<string>(
    "Coupon type is Required"
  );

  const [error, setError] = useState({
    name: true,
    code: true,
    amount: true,
    description: true,
    expiryDate: true,
    discountType: true,
  });
  const discountTypeOtions = [
    { id: "1", name: "Percentage" },
    { id: "3", name: "FixedProductDiscount" },
    { id: "4", name: "FixedCartDiscount" },
  ];

  // const { id } = useParams();

  function handleTextChange(event) {
    const couponCode = event.target.value.toUpperCase();
    setCouponCode(couponCode.replace(/\s/g, ""));
  }

  useEffect(() => {
    if (couponProperties) {
      setCouponName(couponProperties?.name);
      setCouponCode(couponProperties?.code);
      setCouponDescription(couponProperties?.couponDescription);
      setCouponExpiryDate(couponProperties?.couponExpiryDate || null);
      setDiscountType(couponProperties?.discountType);
      setCouponAmount(couponProperties?.couponAmount);
    }
  }, [couponProperties]);

  const onNextPage = () => {
    nextPageHandler();
  };

  const handleCouponNameBlur = (e: {
    target: { value: string; name: any };
  }) => {
    if (e.target.value == "") {
      setNameTouched(true);
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setCouponNameTouched(true);

    let obj = {
      name: couponName,
    };
    handleOnBlur(obj);
  };

  const handleCouponTypeBlur = (e: {
    target: { value: string; name: any };
  }) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setDiscountTypeTouched(true);

    let obj = {
      discountType: discountType,
    };
    handleOnBlur(obj);
  };

  const handleCouponCodeBlur = (e: {
    target: { value: string; name: any };
  }) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setCouponCodeTouched(true);

    let obj = {
      code: couponCode,
    };
    handleOnBlur(obj);
  };
  const handleCouponAmountBlur = (e: {
    target: { value: string; name: any };
  }) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setCouponAmountTouched(true);

    let obj = {
      couponAmount: couponAmount,
    };
    handleOnBlur(obj);
  };

  const handleCouponDescriptionBlur = (e: {
    target: { value: string; name: any };
  }) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    let obj = {
      couponDescription: couponDescription,
    };
    handleOnBlur(obj);
  };

  const handleExpiryDateChange = (newValue: React.SetStateAction<Date>) => {
    setCouponExpiryDate(newValue);
    let obj = {
      couponExpiryDate: newValue,
    };
    handleOnBlur(obj);
  };
  return (
    <div>
      {/* <GridItem xs={12}> */}
      {/* <CardBody> */}
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Coupon name"
            id="name"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: disabled,
              required: true,
              name: "name",
              value: couponName,
              onChange: (e) => setCouponName(e.target.value),
              onBlur: handleCouponNameBlur,
            }}
          />
          <FormHelperText style={{ color: "red" }}>
            {error.name && couponNameTouched && couponNameError}
          </FormHelperText>
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Coupon code"
            id="code"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: editCouponsMode ? true : disabled,
              required: true,
              value: couponCode,
              onChange: handleTextChange,

              // onChange: (e) => setCouponCode(e.target.value.toUpperCase()),
              onBlur: handleCouponCodeBlur,
              name: "code",
            }}
          />
          <FormHelperText style={{ color: "red" }}>
            {error.code && couponCodeTouched && couponCodeError}
          </FormHelperText>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CustomSelect
            //Coupon type(discountType)
            labelText="Coupon type*"
            formControlProps={
              {
                // fullWidt h: true,
              }
            }
            id="age-native-required"
            inputProps={{
              disabled: disabled,
              required: true,
              value: discountType,
              onChange: (e) => setDiscountType(e.target.value),
              onBlur: handleCouponTypeBlur,
              // onChange: (e) => handleChange(e),
              name: "discountType",
              native: true,
            }}
          >
            <option value="">{""}</option>
            {discountTypeOtions.map((item) => {
              return (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </CustomSelect>
          <FormHelperText style={{ color: "red" }}>
            {error.discountType && discountTypeTouched && discountTypeError}
          </FormHelperText>
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Coupon amount"
            //   id="company-disabled"
            id="Coupon amount"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: disabled,
              required: true,
              value: couponAmount,
              onChange: (e) => {
                if (e.target.value && !Number(e.target.value)) return;
                setCouponAmount(e.target.value);
              },
              onBlur: handleCouponAmountBlur,
              // onChange: (e) => handleChange(e),
              // type: "Number",
              name: "amount",
            }}
          />
          <FormHelperText style={{ color: "red" }}>
            {error.amount && couponAmountTouched && couponAmountError}
          </FormHelperText>
        </GridItem>
        {/* </GridContainer>
      <GridContainer> */}

        <GridItem xs={12} sm={6} md={4} style={{ margin: "27px 0px 0px 0px" }}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            style={{ width: "100%" }}
          >
            <DatePicker
              label="Coupon expiry date"
              value={couponExpiryDate}
              disabled={disabled ? true : false}
              disablePast
              onChange={(newValue) => {
                handleExpiryDateChange(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} style={{ width: "100%" }} />
              )}
            />
          </LocalizationProvider>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Description"
            id="Description"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: disabled,
              value: couponDescription,
              onChange: (e) => setCouponDescription(e.target.value),
              onBlur: handleCouponDescriptionBlur,
              // onChange: (e) => handleChange(e),
              name: "description",
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          justifyContent: "end",
          padding: "1rem",
        }}
      >
        <div style={{ marginRight: "1rem" }}>
          <Button color="success" onClick={onNextPage}>
            Next
          </Button>
        </div>
      </GridContainer>
      {/* </CardBody> */}
      {/* </GridItem> */}
    </div>
  );
};

export default React.memo(General);
