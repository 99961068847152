import axios from "axios";
import {
  IPolicy,
  IPolicyAccessMethodOptions,
  IPolicyModuleOptions,
} from "Interfaces/Api/Policy";

export const getRemainingTime = (time) => {
  return new Date(time).getTime() - new Date().getTime();
};
export const isImage = (fileType) => {
  if (!fileType) return false;
  if (!fileType.match(/.(jpg|jpeg|png|gif|svg)$/i)) return false;
  else return true;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function makeEllipses(name, toLength) {
  return `${name.slice(0, toLength)}...`;
}

export function Authorize(
  policy: IPolicy,
  module: IPolicyModuleOptions,
  accessType?: IPolicyAccessMethodOptions
) {
  let isAccessAllowed = false;
  if (policy && module) {
    const statements = policy.statements;
    const idx = statements.findIndex((item) => item.target === module);
    if (idx !== -1) {
      // module allowed
      if (!accessType) {
        // Just checking for module
        return statements[idx].access;
      } else if (statements[idx].access[accessType]) {
        // module and accesstype both allowed
        isAccessAllowed = true;
      }
    }
  }
  return isAccessAllowed;
}
