import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { coupons } from "Services/API/IndividualApis/Coupons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel, a11yProps } from "components/TabPanel/TabPanel";
import { ICoupon } from "Interfaces/Api/Coupons";
import AddOrEditPolicy from "./AddOrEditPolicy";
import EditIcon from "@mui/icons-material/Edit";
import { apiPolicy } from "Services/API/IndividualApis/Policy";
import { IPolicy } from "Interfaces/Api/Policy";

const AddOrEditPolicyPage = () => {
  const [editPolicysMode, setEditPolicysMode] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [couponProperties, setCouponProperties] = useState<ICoupon>();
  const [policyData, setPolicyData] = useState<IPolicy>();
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();

  useEffect(() => {
    if (window.location.pathname === "/admin/policies/add") {
      setEditPolicysMode(false);
    } else {
      setEditPolicysMode(true);
      getPolicyDetailsById(id);
    }
  }, [id]);

  const getPolicyDetailsById = async (id: string) => {
    try {
      const response = await apiPolicy.getSingle(id);
      setPolicyData(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader className="header">
            {editPolicysMode ? <h4> Edit Policy</h4> : <h4> Add Policy</h4>}
          </CardHeader>
          <CardBody>
            <AddOrEditPolicy editData={policyData} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AddOrEditPolicyPage;
