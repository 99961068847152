import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Chip,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import UploadImage, {
  acceptFileType,
} from "components/CustomUpload/UploadImage";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  deleteProductImage,
  products,
} from "Services/API/IndividualApis/Product";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import { modules } from "../CommonComponents/General";
import Loader from "components/Loader";

interface CreateVariantProductProps {
  editMode: boolean;
  handleVariantProperties: Function;
  variants: any;
  showUnselectedFilters: any;
  closeModal?: Function;
  saveVariant?: Function;
}

const CreateVariantProduct = ({
  variants,
  saveVariant,
  handleVariantProperties,
  editMode,
  closeModal,
  showUnselectedFilters,
}: CreateVariantProductProps) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [media, setMedia] = useState([]);
  const [disable, setDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [weightLimitExceeds, setWeightLimitExceeds] = useState<boolean>(false);
  const [error, setError] = useState({
    name: false,
    price: false,
    quantity: false,
    whatIsIt: false,
    length: false,
    breadth: false,
    weight: false,
    height: false,
    sku: false,
  });

  const checkFileSize = (file) => {
    if ("fileLimitExceed" in file) {
      if (file.fileLimitExceed) return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (showUnselectedFilters) {
      if (variants.propertiesDetails.properties) {
        const newFilters = [];
        for (const [key, value] of Object.entries(
          variants.propertiesDetails.properties
        )) {
          let obj = {};
          const newUnselectedFilters = showUnselectedFilters.filter((item) => {
            if (item.name == key) {
              obj[key] = value;
              newFilters.push(obj);
            }
          });
          const newSelectedValues = [...newFilters];
        }
        const newSelectedProperties = [];
        for (const key of newFilters) {
          let obj = {
            name: Object.keys(key),
            value: Object.values(key),
          };
          newSelectedProperties.push(obj);
        }
        setSelectedFilters(newSelectedProperties);
      }
    }
  }, [variants.propertiesDetails.properties, showUnselectedFilters]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const addPrimaryImage = async (acceptedFiles) => {
    if (editMode) {
      try {
        const response = await products.updateProductMainImage(
          variants.id,
          acceptedFiles
        );
        handleVariantProperties("image", response.image, variants.id);
      } catch (err) {}
    } else {
    }
  };

  const deleteselectedImage = async (item: { key: any; _id: any }) => {
    // delete preview product media

    if (item.key === undefined) {
      let newVariantData = variants.propertiesDetails.media.filter(
        (media) => media !== item
      );
      handleVariantProperties("media", newVariantData, variants.id);
    } else {
      let obj = {
        key: item.key,
      };
      if (editMode && variants.propertiesDetails.id) {
        try {
          const response = await deleteProductImage(variants.id, obj);
          handleVariantProperties("media", response.media, variants.id);
        } catch (err) {
          console.log(err);
        }
      }
      if (!editMode) {
        const newFilters = variants.propertiesDetails.media.filter(
          (val, index) => {
            if (item != index) {
              return val;
            }
          }
        );
        handleVariantProperties("media", newFilters, variants.id);
      }
      if (editMode && !variants.propertiesDetails.id) {
        const newFilters = variants.propertiesDetails.media.filter(
          (val, index) => {
            if (item._id != val._id) {
              return val;
            }
          }
        );
        handleVariantProperties("media", newFilters, variants.id);
      }
    }
  };
  const addMultipleImage = async (files) => {
    if (files) {
      var filesArray = [].slice.call(files);
      const allMediasFiles = [];

      for (let media of filesArray) {
        const fileSizeLarge = checkFileSize(media);
        let imageObjectNoLimit = {
          _id: uuidv4(),
          fileName: media.file.name,
          file: await toBase64(media.file),
          fileLimitExceed: !fileSizeLarge ? false : true,
        };
        allMediasFiles.push(imageObjectNoLimit);
      }

      handleVariantProperties(
        "media",
        [...variants.propertiesDetails.media, ...allMediasFiles],
        variants.id
      );
      // setAllMedia((prevImg) => prevImg.concat(allMediasFilePath));
    }
  };

  const updateMediaImage = async (acceptedFiles, previousFile) => {
    if (!editMode) {
      if (!previousFile._id) {
        //  previous file does not have id if not uploaded, set it as primary image
        handleVariantProperties("image", acceptedFiles, variants.id);
        // handleOnBlur({ image: acceptedFiles });
      } else {
        const newUpdatedMedia = [...variants.propertiesDetails.media];
        // find file to be updated from allmedia
        const allMediaIndex = variants.propertiesDetails.media.findIndex(
          (item, index) => {
            return item._id === previousFile._id;
          }
        );
        newUpdatedMedia[allMediaIndex] = acceptedFiles;
        handleVariantProperties("media", newUpdatedMedia, variants.id);
      }
    } else {
      // EDIT MODE

      if (variants.propertiesDetails.id) {
        // find if the file has a key, is already uploaded media
        const findIndexOfMedia = variants.propertiesDetails.media.find(
          (item) => {
            return item.key && item.key === previousFile.key;
          }
        );

        // newly added media will not have key check for productId
        const findIndexOfNotUploaded = variants.propertiesDetails.media.find(
          (item) => {
            return item.productId && item.productId === previousFile.productId;
          }
        );

        if (findIndexOfMedia && variants.propertiesDetails.id) {
          let newObject = {
            ...acceptedFiles,
            previousImageKey: previousFile.key,
          };
          try {
            const response = await products.updateProductMediaImage(
              variants.id,
              newObject
            );
            handleVariantProperties("media", response.media, variants.id);
          } catch (error) {
            console.log("error upload", error);
          }
        } else if (findIndexOfNotUploaded && variants.propertiesDetails.id) {
          try {
            const postMedia = { ...acceptedFiles, productId: variants.id };

            const res = await products.addNewProductMedia(postMedia);
            handleVariantProperties("media", res.media, variants.id);
            // setImages(res.media);
          } catch (err) {
            console.log(err);
          }
        } else {
          console.log("Else block");
        }
      } else {
        const newUpdatedMedia = [...variants.propertiesDetails.media];
        const indexOfFile = variants.propertiesDetails.media.findIndex(
          (item) => {
            return item._id && item._id === previousFile._id;
          }
        );
        newUpdatedMedia[indexOfFile] = acceptedFiles;
        handleVariantProperties("media", newUpdatedMedia, variants.id);
      }
    }
  };

  const handleAddNewImage = async (acceptFiles) => {
    if (acceptFiles) {
      var mediaArray = [].slice.call(acceptFiles);

      // For Products already existing and having ID
      let uploadedFiles = [...variants.propertiesDetails.media];
      let notUploadedFiles = [];

      // For newly created variants not having ID
      let uploadFileNew = [...variants.propertiesDetails.media];

      if (variants.propertiesDetails.id) {
        for (let media of mediaArray) {
          const fileSizeLarge = checkFileSize(media);

          if (!fileSizeLarge) {
            let obj = {
              productId: variants.id,
              fileName: media.file.name,
              file: await toBase64(media.file),
            };
            try {
              // issue attribute is re-rendered
              const response = await products.addNewProductMedia(obj);
              uploadedFiles = response.media;
              // uploadedFiles.push(obj);
              // handleVariantProperties("media", response.media, variants.id);
            } catch (err) {
              console.log(err);
            }
          } else {
            // not displayed files
            let mediaObject = {
              _id: uuidv4(),
              productId: variants.id,
              fileName: media.file.name,
              file: await toBase64(media.file),
              fileLimitExceed: true,
            };
            notUploadedFiles.push(mediaObject);
          }
        }
        const newMedia = [...uploadedFiles, ...notUploadedFiles];
        handleVariantProperties("media", newMedia, variants.id);
      } else {
        for (let media of mediaArray) {
          const fileSizeLarge = checkFileSize(media);
          let obj = {
            _id: uuidv4(),
            fileName: media.file.name,
            file: await toBase64(media.file),
            fileLimitExceed: fileSizeLarge,
          };
          uploadFileNew.push(obj);
        }
        handleVariantProperties("media", uploadFileNew, variants.id);
      }
    }
  };

  const updatePrimaryImage = async (acceptedFile) => {
    if (variants.propertiesDetails.id) {
      try {
        const response = await products.updateProductMainImage(
          variants.id,
          acceptedFile
        );
        if (response)
          handleVariantProperties("image", response.image, variants.id);
        else {
          handleVariantProperties("image", acceptedFile, variants.id);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      handleVariantProperties("image", acceptedFile, variants.id);
    }
  };
  const [invalidPriceDiscount, setInvalidPriceDiscount] =
    useState<boolean>(false);

  const handleSaveSingleVariant = () => {
    if (
      parseFloat(variants.propertiesDetails.discount) >=
      parseFloat(variants.propertiesDetails.price)
    ) {
      setInvalidPriceDiscount(true);
    } else {
      let data = { submit: "true", ...variants.propertiesDetails };
      data["size"] = {
        length: variants.propertiesDetails.length,
        weight: variants.propertiesDetails.weight,
        height: variants.propertiesDetails.height,
        breadth: variants.propertiesDetails.breadth,
      };
      data["metaData"] = {
        title: variants.propertiesDetails.title,
        description: variants.propertiesDetails.description,
        keywords: variants.propertiesDetails.keywords,
      };
      data["cms"] = {
        howToUse: variants.propertiesDetails.howToUse,
        whatIsIt: variants.propertiesDetails?.whatIsIt,
        whatsInIt: variants.propertiesDetails?.whatsInIt,
        subTitle: variants.propertiesDetails?.subTitle,
        faqs: variants.propertiesDetails?.faqs,
        benefits: variants.propertiesDetails?.benefits,
        whatExpertHasToSay: variants.propertiesDetails.whatExpertHasToSay,
      };
      const requiredValuesFilled = Object.keys(error).every(
        (k) => error[k] === false
      );

      let filterMedia = variants.propertiesDetails.media.filter(
        (item) => item.fileLimitExceed !== true
      );
      let variantData = {
        ...variants,
        propertiesDetails: {
          ...variants.propertiesDetails,
          media: filterMedia,
        },
      };

      if (requiredValuesFilled) {
        setLoading(true);
        updateVariantProduct(data, variantData);
      }
    }
  };

  const updateVariantProduct = async (data, variantData) => {
    try {
      let response = await products.updateProductDetails(data.id, data);
      if (response) {
        setLoading(false);
        saveVariant(variantData);
        closeModal();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleBlur = (e) => {
    // whatIsIt
    if (variants.propertiesDetails.whatIsIt === "<p><br></p>") {
      setError({ ...error, whatIsIt: true });
    } else {
      setError({ ...error, whatIsIt: false });
    }

    if (!e.target.value) setError({ ...error, [e.target.name]: true });
    else setError({ ...error, [e.target.name]: false });
  };

  const handleWeight = (name, value, id) => {
    if (value < 500 && value >= 0) {
      handleVariantProperties(name, value, id);
    } else {
      setWeightLimitExceeds(true);
    }
  };

  const handleLength = (name, value, id) => {
    if (value <= 50) {
      handleVariantProperties(name, value, id);
    }
  };

  const handleBreadth = (name, value, id) => {
    if (value <= 50) {
      handleVariantProperties(name, value, id);
    }
  };
  const handleHeight = (name, value, id) => {
    if (value <= 50) {
      handleVariantProperties(name, value, id);
    }
  };
  return (
    <div>
      {invalidPriceDiscount && (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            position: "fixed",
            top: { sm: "2px", xs: "3.2rem" },
            right: "5px",
            zIndex: "10000",
            background: "red",
            textTransform: "capitalize",
            width: "20rem",
          }}
        >
          Product Discount Price must be less than Product Price
        </Alert>
      )}
      <GridContainer>
        <GridItem xs={12}>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Name"
                  id="name"
                  formControlProps={{}}
                  inputProps={{
                    required: true,
                    onBlur: handleBlur,
                    value: variants.propertiesDetails?.name,
                    name: "name",
                    // onChange: (e) => handleVariantProperties(e, variants.id),
                    onChange: (e) =>
                      handleVariantProperties(
                        e.target.name,
                        e.target.value,
                        variants.id
                      ),
                  }}
                />
                {error.name && (
                  <FormHelperText style={{ color: "red" }}>
                    Name is required
                  </FormHelperText>
                )}
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Available Quantity"
                  id="quantity"
                  formControlProps={{}}
                  inputProps={{
                    type: "number",
                    required: true,
                    onBlur: handleBlur,
                    value: variants.propertiesDetails?.availableQuantity,
                    name: "availableQuantity",
                    onChange: (e) =>
                      handleVariantProperties(
                        e.target.name,
                        e.target.value,
                        variants.id
                      ),
                  }}
                />
                {error.quantity && (
                  <FormHelperText style={{ color: "red" }}>
                    Quantity is required
                  </FormHelperText>
                )}
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Price"
                  formControlProps={{}}
                  id="price"
                  inputProps={{
                    onBlur: handleBlur,
                    // type: "number",
                    required: true,
                    value: variants.propertiesDetails?.price,
                    name: "price",
                    onChange: (e) => {
                      setInvalidPriceDiscount(false);
                      if (e.target.value && !Number(e.target.value)) return;
                      handleVariantProperties(
                        e.target.name,
                        e.target.value,
                        variants.id
                      );
                    },
                  }}
                />
                {error.price && (
                  <FormHelperText style={{ color: "red" }}>
                    Price is required
                  </FormHelperText>
                )}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  formControlProps={{}}
                  labelText="Category"
                  inputProps={{
                    name: "Category",
                    disabled: true,
                    value: variants.propertiesDetails?.categories[0],
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Discount (Price)"
                  formControlProps={{}}
                  inputProps={{
                    name: "discount",
                    // type: "number",
                    value: variants.propertiesDetails?.discount,
                    onChange: (e) => {
                      setInvalidPriceDiscount(false);
                      if (e.target.value && !Number(e.target.value)) return;
                      handleVariantProperties(
                        e.target.name,
                        e.target.value,
                        variants.id
                      );
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="SubTitle"
                  //   id="company-disabled"
                  formControlProps={{}}
                  inputProps={{
                    value: variants.propertiesDetails.subTitle,
                    onChange: (e) =>
                      handleVariantProperties(
                        "subTitle",
                        e.target.value,
                        variants.id
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Max Order Qty Limit"
                  formControlProps={{}}
                  inputProps={{
                    type: "number",
                    value:
                      variants.propertiesDetails.maxQuantityAllowedInSignleCart,
                    onChange: (e) => {
                      if (e.target.value === "" || e.target.value > 0)
                        handleVariantProperties(
                          "maxQuantityAllowedInSignleCart",
                          e.target.value,
                          variants.id
                        );
                    },
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          Description*{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="variant-WhatIsIt"
                          value={variants.propertiesDetails.whatIsIt}
                          modules={modules}
                          // onBlur={handleBlur}
                          onChange={(value) => {
                            if (variants.propertiesDetails.whatIsIt != value) {
                              handleVariantProperties(
                                "whatIsIt",
                                value,
                                variants.id
                              );
                            }
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
                {error.whatIsIt && (
                  <FormHelperText style={{ color: "red" }}>
                    WhatIsIt is required
                  </FormHelperText>
                )}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          Ingredients{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="variant-whatsInIt"
                          value={variants.propertiesDetails.whatsInIt}
                          modules={modules}
                          onChange={(value) => {
                            if (variants.propertiesDetails.whatsInIt != value) {
                              handleVariantProperties(
                                "whatsInIt",
                                value,
                                variants.id
                              );
                            }
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          How to use
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="variant-howToUse"
                          value={variants.propertiesDetails.howToUse}
                          modules={modules}
                          onChange={(value) => {
                            if (variants.propertiesDetails.howToUse != value) {
                              handleVariantProperties(
                                "howToUse",
                                value,
                                variants.id
                              );
                            }
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          Benefits
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="variant-benefits"
                          value={variants.propertiesDetails.benefits}
                          // modules={modules}
                          onChange={(value) => {
                            if (variants.propertiesDetails.benefits != value) {
                              handleVariantProperties(
                                "benefits",
                                value,
                                variants.id
                              );
                            }
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          FAQ's
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="variant-faqs"
                          value={variants.propertiesDetails.faqs}
                          // modules={modules}
                          onChange={(value) => {
                            if (variants.propertiesDetails.faqs != value) {
                              handleVariantProperties(
                                "faqs",
                                value,
                                variants.id
                              );
                            }
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CardBody>
                  <GridContainer>
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={(event) => {}} />}
                      >
                        <Typography
                          sx={{ paddingRight: "10px", flexShrink: 0 }}
                        >
                          What expert has to say
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReactQuill
                          id="variant-whatExpertHasToSay"
                          value={variants.propertiesDetails.whatExpertHasToSay}
                          // modules={modules}
                          onChange={(value) => {
                            if (
                              variants.propertiesDetails.whatExpertHasToSay !=
                              value
                            ) {
                              handleVariantProperties(
                                "whatExpertHasToSay",
                                value,
                                variants.id
                              );
                            }
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </GridContainer>
                </CardBody>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Sku *"
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    name: "sku",
                    value: variants.propertiesDetails?.sku,
                    onBlur: handleBlur,
                    onChange: (e) =>
                      handleVariantProperties(
                        e.target.name,
                        e.target.value,
                        variants.id
                      ),
                  }}
                />
                {error.sku && (
                  <FormHelperText style={{ color: "red" }}>
                    SKU is required
                  </FormHelperText>
                )}
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Product length *"
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    // type: "number",
                    value: variants.propertiesDetails?.length,
                    onBlur: handleBlur,
                    name: "length",
                    onChange: (e) => {
                      if (e.target.value && !Number(e.target.value)) return;
                      handleLength(e.target.name, e.target.value, variants.id);
                    },
                  }}
                />
                {error.length && (
                  <FormHelperText style={{ color: "red" }}>
                    Length is required
                  </FormHelperText>
                )}
                <FormHelperText style={{ marginTop: "0px" }}>
                  Note:Length should be ≤ 50cm
                </FormHelperText>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Product breadth *"
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    // type: "number",
                    value: variants.propertiesDetails?.breadth,
                    name: "breadth",
                    onBlur: handleBlur,
                    onChange: (e) => {
                      if (e.target.value && !Number(e.target.value)) return;
                      handleBreadth(e.target.name, e.target.value, variants.id);
                    },
                  }}
                />
                {error.breadth && (
                  <FormHelperText style={{ color: "red" }}>
                    Breadth is required
                  </FormHelperText>
                )}
                <FormHelperText style={{ marginTop: "0px" }}>
                  Note:Breadth should be ≤ 50cm
                </FormHelperText>
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Weight *"
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    // type: "Number",
                    value: variants.propertiesDetails?.weight,
                    name: "weight",
                    onChange: (e) => {
                      // if (e.target.value && !Number(e.target.value)) return;
                      handleWeight(e.target.name, e.target.value, variants.id);
                    },
                  }}
                />
                <FormHelperText>Note:Weight should be ≤ 500kg </FormHelperText>
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="Height *"
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    // type: "Number",
                    value: variants.propertiesDetails?.height,
                    name: "height",
                    onBlur: handleBlur,
                    onChange: (e) => {
                      if (e.target.value && !Number(e.target.value)) return;
                      handleHeight(e.target.name, e.target.value, variants.id);
                    },
                  }}
                />
                {error.height && (
                  <FormHelperText style={{ color: "red" }}>
                    height is required
                  </FormHelperText>
                )}
                <FormHelperText style={{ marginTop: "0px" }}>
                  Note:Height should be ≤ 50cm
                </FormHelperText>
              </GridItem>
              {selectedFilters &&
                selectedFilters.map((item) => {
                  return (
                    <GridItem xs={12} sm={6} md={4} key={item.name}>
                      <CustomInput
                        labelText={item.name}
                        //   id="company-disabled"
                        formControlProps={
                          {
                            // fullWidth: true,
                          }
                        }
                        inputProps={{
                          disabled: disable,
                          value: item.value,
                          onChange: (e) =>
                            handleVariantProperties(
                              e.target.name,
                              e.target.value,
                              variants.id
                            ),
                          name: item.name,
                        }}
                      />
                    </GridItem>
                  );
                })}
            </GridContainer>
            <GridContainer style={{ paddingTop: "15px" }}>
              <GridItem xs={12} sm={6} md={4}>
                <UploadImage
                  id={editMode ? `edit/${variants.id}` : `add/${variants.id}`}
                  useFileType={acceptFileType.ONLY_IMAGE}
                  label="Primary Image *"
                  // handleFile={updateMediaImage}
                  selectedImage={variants.propertiesDetails.image}
                  editMode={editMode}
                  handleFile={updatePrimaryImage}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={8}>
                <UploadImage
                  id={
                    editMode
                      ? `edit/media/${variants.id}`
                      : `add/media/${variants.id}`
                  }
                  // id={variants.id}
                  label="Images / videos "
                  multiple={true}
                  useFileType={acceptFileType.BOTH}
                  addImageOrImages={addMultipleImage}
                  selectedImage={variants?.propertiesDetails?.media || media}
                  editMode={editMode}
                  handleFile={updateMediaImage}
                  deleteImage={deleteselectedImage}
                  // handleUpdateImages={handleAddNewImage}

                  handleAddNewProductMedia={handleAddNewImage}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Meta title "
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    value: variants.propertiesDetails?.title,
                    // onChange: (e) => handleVariantProperties(e, variants.id, e.target.name),
                    onChange: (e) =>
                      handleVariantProperties(
                        e.target.name,
                        e.target.value,
                        variants.id
                      ),

                    name: "title",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Meta description "
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    value: variants.propertiesDetails?.description,
                    // onChange: (e) => handleVariantProperties(e, variants.id, e.target.name),
                    onChange: (e) =>
                      handleVariantProperties(
                        e.target.name,
                        e.target.value,
                        variants.id
                      ),

                    name: "description",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <Autocomplete
                  multiple
                  fullWidth={true}
                  id="tags-outlined"
                  value={variants.propertiesDetails?.keywords}
                  onChange={(e, value) => {
                    handleVariantProperties("keywords", value, variants.id);
                  }}
                  options={[]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Meta keywords"
                      placeholder="Type And Press Enter"
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
            <br />

            {variants.propertiesDetails.id && (
              <div
                style={{
                  float: "right",
                }}
              >
                <Button color="gray" onClick={() => closeModal()}>
                  Cancel
                </Button>{" "}
                &nbsp;
                <Button color="success" onClick={handleSaveSingleVariant}>
                  Save {loading && <Loader />}
                </Button>
              </div>
            )}
          </CardBody>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Interceptor(CreateVariantProduct);
