import { IAttribute } from "Interfaces/Api/Attribute";
import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import { ApiCore } from "../../Core";

const url = "attribute";
const attribute = new ApiCore<IAttribute>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});
attribute.getAttributes = (id) => {
  return apiProvider.getAll(`${url}?category=${id}`);
};
attribute.deleteAttribute = (name, id) => {
  return apiProvider.remove(`${url}/${id}`, name);
};
export { attribute };
