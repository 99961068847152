import { IReview, reviewStatus } from "Interfaces/Api/Reviews";
import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import { ApiCore } from "../../Core";

const url = "product/review";
const apiReview = new ApiCore<IReview>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

apiReview.getAllByProductId = (
  productId: string | number,
  page: number = 1,
  perPage: number = 10,
  otherQueryParam: string = ""
) => {
  return apiProvider.getAll(
    `${url}/${productId}?page=${page}&perPage=${perPage}${otherQueryParam}`
  );
};

apiReview.search = (text, page, perPage) => {
  return apiProvider.getAll(`${url}/search?text=${text}&page=${page}&perPage=${perPage}`);
};

apiReview.changesReviewStatus = (
  reviewId: string,
  modal: { status: reviewStatus }
) => {
  return apiProvider.patch(`${url}/changesReviewStatus`, reviewId, modal);
};
export { apiReview };
