import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";

const styles = (theme) => ({
  grid: {
    [theme.breakpoints.up('md')]: {
      padding: "0 15px !important",
    },
  },
});

const useStyles = makeStyles(styles);

export default function GridMain(props) {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
}
