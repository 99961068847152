import { IPolicy } from "./Policy";

export enum TokenKind {
  REFRESH = "Refresh",
}
export enum CodeKind {
  FORGOT = "Forgot",
}
export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
  NULL = "",
}
export enum Roles {
  SUPER_ADMIN = "Super_Admin",
  ADMIN = "Admin",
  USER = "User",
  VIEWER = "Viewer",
}

export interface IVerification {
  code?: string;
  codeExpiryDate?: Date;
  lastCodeSend?: Date;
  codeSendCount?: number;

  verified?: boolean;
  verificationDate?: Date;
}

export interface ITokens {
  token: string;
  status: boolean;
  kind: TokenKind;
  issued: Date;
}

export interface ICodes {
  code: string | number;
  codeExpiryDate: Date;
  lastCodeSend?: Date;
  codeSendCount?: number;
  status?: boolean;
  useDate?: Date;
  kind: CodeKind;
}

export interface IAddress {
  email: any;
  name: string;
  phoneNumber: string;
  line1: string;
  line2: string;
  zipCode: number;
  city: string;
  state: string;
  country: string;
  isDefault: boolean;
}

export interface Profile {
  firstName: string;
  lastName: string;
  gender: Gender;
  shippingAddresses: IAddress[];
  phoneNumber: string;
  role: Roles;
}
export interface IMeResponse {
  id: string;
  phoneNumber: string;
  email: string;
  name: string;
  role: Roles;
  shippingAddresses: IAddress[];
  policy: IPolicy | string | null;
}
export interface IUser {
  phoneNumber: string;
  _id: string;
  name: string;
  password: string;
  profile?: Profile;
  tokens?: ITokens[];
  verfication?: IVerification;
  forgotCode?: ICodes;
  agreeToTerms?: boolean;
  googleUserId?: string;
  facebookUserId?: string;
  policy?: {
    _id: string;
    name: string;
  };
}
