import React, { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import Button from "components/CustomButtons/Button";
import { Box, CircularProgress, FormHelperText } from "@mui/material";
import Loader from "components/Loader";

interface ShippinComponentProps {
  editMode: boolean;
  onCancel: Function;
  handleShippingProperties: Function;
  productProperties: Record<string, any>;
  handleEditProduct: Function;
  handleOnBlur: Function;
  loading: Boolean;
}

const ShippinComponent = ({
  handleShippingProperties,
  productProperties,
  handleOnBlur,
  onCancel,
  editMode,
  handleEditProduct,
  loading,
}: ShippinComponentProps) => {
  const [productLength, setProductLength] = useState<string>("");
  const [productBreadth, setProductBreadth] = useState<string>("");
  const [productHeight, setProductHeight] = useState<string>("");
  const [productWeight, setProductWeight] = useState<string>("");
  const [heightError, setPHeightError] = useState<string>("Height is Required");
  const [weigthError, setWeightError] = useState<string>("Weight is Required");
  const [lengthError, setLengthError] = useState<string>("Length is Required");
  const [breadthError, setbreadthError] = useState<string>(
    "Breadth is Required"
  );
  const [heightTouched, setHeightTouched] = useState<boolean>(false);
  const [weightTouched, setWeightTouched] = useState<boolean>(false);
  const [breadthTouched, setBreadthTouched] = useState<boolean>(false);
  const [lengthTouched, setLengthTouched] = useState<boolean>(false);
  const [error, setError] = useState({
    length: true,
    breadth: true,
    height: true,
    weight: true,
  });
  const [showError, setShowError] = useState(false);

  const onNextPage = () => {
    if (
      productProperties.length === "" ||
      productProperties.breadth === "" ||
      productProperties.height === "" ||
      productProperties.weight === ""
    ) {
      setShowError(true);
      setWeightTouched(true);
      setHeightTouched(true);
      setLengthTouched(true);
      setBreadthTouched(true);
    } else {
      handleShippingProperties();
    }
  };

  const handleHeightBlur = (e) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setHeightTouched(true);
    const obj = {
      height: productHeight,
    };
    handleOnBlur(obj);
  };

  const handleLengthBlur = (e) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setLengthTouched(true);
    const obj = {
      length: productLength,
    };
    handleOnBlur(obj);
  };

  const handleBreadthBlur = (e) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setBreadthTouched(true);
    const obj = {
      breadth: productBreadth,
    };
    handleOnBlur(obj);
  };

  const handleWeightBlur = (e) => {
    if (e.target.value == "") {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: true }));
    } else {
      setError((prevVal) => ({ ...prevVal, [e.target.name]: false }));
    }
    setWeightTouched(true);
    const obj = {
      weight: productWeight,
    };

    handleOnBlur(obj);
  };
  useEffect(() => {
    if (productProperties) {
      setProductLength(productProperties?.length);
      setProductWeight(productProperties?.weight);
      setProductHeight(productProperties?.height);
      setProductBreadth(productProperties?.breadth);
    }
  }, [productProperties]);

  const handleWeightChange = (e) => {
    // if (e.target.value && !Number(e.target.value)) return;
    if (e.target.value < 500 && e.target.value >= 0) {
      setProductWeight(e.target.value);
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          {/* <CardBody> */}
          <Box style={{ paddingBottom: "3rem" }}>
            <GridContainer style={{ padding: "0.9375rem 20px" }}>
              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Length (cm)*"
                  //   id="company-disabled"
                  formControlProps={{
                    style: { margin: "0" },
                    // fullWidth: true,
                  }}
                  inputProps={{
                    // type: "Number",
                    name: "length",
                    value: productLength,
                    onChange: (e) => {
                      if (e.target.value && !Number(e.target.value)) return;
                      if (e.target.value <= 50 && e.target.value >= 0) {
                        setProductLength(e.target.value);
                      }
                    },
                    onBlur: handleLengthBlur,
                  }}
                />
                <FormHelperText style={{ color: "red" }}>
                  {error.length && lengthTouched && lengthError}
                </FormHelperText>
                <FormHelperText style={{ marginTop: "0px" }}>
                  Note:Length should be ≤ 50cm
                </FormHelperText>
              </GridItem>
              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Breadth (cm)*"
                  //   id="company-disabled"
                  formControlProps={{
                    style: { margin: "0" },

                    // fullWidth: true,
                  }}
                  inputProps={{
                    // type: "Number",
                    name: "breadth",
                    value: productBreadth,
                    onChange: (e) => {
                      if (e.target.value && !Number(e.target.value)) return;
                      if (e.target.value <= 50 && e.target.value >= 0) {
                        setProductBreadth(e.target.value);
                      }
                    },
                    onBlur: handleBreadthBlur,
                  }}
                />
                <FormHelperText style={{ color: "red" }}>
                  {error.breadth && breadthTouched && breadthError}
                </FormHelperText>
                <FormHelperText style={{ marginTop: "0px" }}>
                  Note:Breadth should ≤ 50cm
                </FormHelperText>
              </GridItem>

              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Height (cm)*"
                  //   id="company-disabled"
                  formControlProps={{
                    style: { margin: "0" },

                    // fullWidth: true,
                  }}
                  inputProps={{
                    // type: "Number",
                    name: "height",
                    value: productHeight,
                    // onChange: (e) => setProductHeight(e.target.value),
                    onChange: (e) => {
                      if (e.target.value && !Number(e.target.value)) return;
                      if (e.target.value <= 50 && e.target.value >= 0) {
                        setProductHeight(e.target.value);
                      }
                    },
                    onBlur: handleHeightBlur,
                  }}
                />
                <FormHelperText style={{ color: "red" }}>
                  {error.height && heightTouched && heightError}
                </FormHelperText>
                <FormHelperText style={{ marginTop: "0px" }}>
                  Note:Height should be ≤ 50cm
                </FormHelperText>
              </GridItem>

              <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "27px" }}>
                <CustomInput
                  labelText="Weight (kg)*"
                  //   id="company-disabled"
                  formControlProps={{
                    style: { margin: "0" },

                    // fullWidth: true,
                  }}
                  inputProps={{
                    min: "0",
                    name: "weight",
                    value: productWeight,
                    onChange: handleWeightChange,
                    // onChange: (e) => {
                    //   if (e.target.value && !Number(e.target.value)) return;
                    //   handleWeightChange;
                    // },
                    // setProductWeight(e.target.value),
                    onBlur: handleWeightBlur,
                  }}
                />
                <FormHelperText style={{ color: "red" }}>
                  {error.weight && weightTouched && weigthError}
                </FormHelperText>
                <FormHelperText style={{ marginTop: "0px" }}>
                  Note:Weight should be less then 500kg
                </FormHelperText>
              </GridItem>
            </GridContainer>
          </Box>
          <GridContainer
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <Button color="gray" onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <Button color="success" onClick={onNextPage}>
                Next
              </Button>
            </div>
            {editMode && (
              <div style={{ marginRight: "1rem", position: "relative" }}>
                <Button color="success" onClick={handleEditProduct}>
                  Submit
                </Button>
                {loading && <Loader />}
                {/* {loading && <div style={{ position: "absolute", top: "30%", right: "5px", opacity: "1" }}>{<CircularProgress size="20px" style={{ color: "#8D6D4D" }} />}</div>} */}
              </div>
            )}
          </GridContainer>
          {/* </CardBody> */}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Interceptor(ShippinComponent);
