import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddOrEditAttribute from "views/Attributes/AddOrEditAttribute";
import Attributes from "views/Attributes";
import AddOrEditCategory from "views/Categories/AddOrEditCategory";
import Categories from "views/Categories";
import AddOrEditCoupon from "views/Coupons/AddOrEditCoupon";
import Coupons from "views/Coupons/index";
import OrderDetails from "views/Orders/OrderDetails";
import Orders from "views/Orders";
import AddorEditProducts from "views/Products/AddorEditProducts";
import Product from "views/Products";
import SimpleComponent from "views/Products/SimpleComponent";
import VariantComponent from "views/Products/VariantComponent";
import Users from "views/Users";
import UserDetails from "views/Users/userDetails";
import CanceledOrderList from "views/Cancelled-Order";
import CancelledOrderDetail from "views/Cancelled-Order/CancelledOrderDetail";
import Reviews from "views/Reviews";
import { useAuthorization } from "hooks/autorizationHook";
import { IPolicyModuleOptions } from "Interfaces/Api/Policy";

const AppRoutes = () => {
  const isOrderAccessAllowed = useAuthorization(IPolicyModuleOptions.ORDER);
  return (
    <div>
      {/* <BrowserRouter>
        <Routes> */}
      {isOrderAccessAllowed && (
        <Route path="/admin/orders" element={<Orders />} />
      )}
      <Route path="/admin/orders/:id" element={<OrderDetails />} />

      <Route path="/admin/cancelOrders" element={<CanceledOrderList />} />
      <Route path="/admin/cancelOrder/:id" element={<CancelledOrderDetail />} />

      <Route path="/admin/categories" element={<Categories />} />
      <Route path="/admin/categories/add" element={<AddOrEditCategory />} />
      <Route path="/admin/categories/:id" element={<AddOrEditCategory />} />

      <Route path="/admin/coupons" element={<Coupons />} />
      <Route path="/admin/coupons/add" element={<AddOrEditCoupon />} />
      <Route path="/admin/coupons/:id" element={<AddOrEditCoupon />} />

      <Route path="/admin/attributes" element={<Attributes />} />
      <Route path="/admin/attribute/add" element={<AddOrEditAttribute />} />
      <Route path="/admin/attribute/:id" element={<AddOrEditAttribute />} />

      <Route path="/admin/users/:id" element={<UserDetails />} />
      <Route path="/admin/users" element={<Users />} />

      <Route path="/admin/products/:id" element={<AddorEditProducts />} />
      <Route path="/admin/products/add" element={<AddorEditProducts />} />
      <Route path="/admin/products" element={<Product />} />

      <Route path="/admin/reviews" element={<Reviews />} />

      <Route path="/admin/users" element={<Users />} />
      <Route element={<Users />} />
      {/* </Routes>
      </BrowserRouter> */}
    </div>
  );
};
export default AppRoutes;
