import { Icon, Modal } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import Variants from "../VariantComponent/product";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";
import { useEffect, useState, memo } from "react";
import CloseIcon from "@mui/icons-material/Close";

function VariantModal({
  item,
  editMode,
  closeModal,
  handleVariantSave,
  showModal,
  showUnselectedFilters,
}) {
  const [variantData, setVariantData] = useState<any>(null);

  useEffect(() => {
    if (item) setVariantData(item);
  }, [item, showModal]);

  const handleVariant = (name, value) => {
    let temp = { ...variantData.propertiesDetails };
    temp[name] = value;
    const newData = {
      ...variantData,
      propertiesDetails: temp,
    };
    setVariantData(newData);
  };

  if (variantData) {
    return (
      <Modal
        open={showModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <GridContainer
          style={{
            width: "90%",
          }}
        >
          <Card>
            <CardHeader className="header">
              <h4>
                Edit : {item.propertiesDetails.name}
                <span style={{ float: "right" }} onClick={closeModal}>
                  <Icon
                    component={CloseIcon}
                    color="inherit"
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "30rem",
                }}
              >
                <GridItem>
                  {/* {item.propertiesDetails.name} */}
                  <Variants
                    closeModal={closeModal}
                    saveVariant={handleVariantSave}
                    variants={variantData}
                    handleVariantProperties={handleVariant}
                    editMode={editMode}
                    showUnselectedFilters={showUnselectedFilters}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridContainer>
      </Modal>
    );
  }
  return null;
}

export default memo(VariantModal);
