import { IProduct } from "Interfaces/Api/Product";
import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import Products from "views/Products";
import { ApiCore } from "../../Core";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const url = "product";
const products = new ApiCore<IProduct>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

products.getCsvFiles = () => {
  return apiProvider.getAll(`${url}/csvFile`);
};

products.updateProductDetails = (id, model) => {
  return apiProvider.put(`${url}/updateProductDetails`, id, model);
};

products.search = (name, page, perPage) => {
  return apiProvider.getAll(
    `${url}/search?name=${name}&page=${page}&perPage=${perPage}`
  );
};

products.updateProductMainImage = (id, model) => {
  return apiProvider.put(`${url}/updateProductMainImage`, id, model);
};
products.updateProductMediaImage = (id, model) => {
  return apiProvider.put(`${url}/updateProductMedia`, id, model);
};
products.addNewProductMedia = (model) => {
  return apiProvider.post(`${url}/addNewProductMedia`, model);
};
export { products };

export const deleteProductImage = (id: string, model: object) => {
  return axios
    .delete(`${BASE_URL}/${url}/deleteProductImage/${id}`, {
      data: model,
    })
    .then(handleResponse);
  // .catch(handleError);
};
