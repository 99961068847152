import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";

import { IShippingPickupDetail } from "Interfaces/Api/config";
import { useEffect, useState } from "react";
import { apiConfig } from "Services/API/IndividualApis/Config";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CustomDeleteModal from "components/CustomDeleteModal";
import { isEmpty } from "Services/Helper";
import { useLocation } from "react-router-dom";
import AddOrEditShippingPickupDetails from "./AddOrEditShippingPickupDetails";

export default function ShippingConfig({ configData, getData }) {
  const { state } = useLocation() as any;

  const [isAddShippingPicupModalOpen, setIsAddShippingPicupModalOpen] =
    useState<boolean>(false);
  const [shippingPickupDetails, setShippingPickupDetails] = useState<
    IShippingPickupDetail[]
  >([]);
  const [deleteData, setDeleteData] = useState<IShippingPickupDetail | null>(
    null
  );
  const [editData, setEditData] = useState<IShippingPickupDetail | null>(null);

  useEffect(() => {
    if (configData && !isEmpty(configData)) {
      setShippingPickupDetails(configData.shipping_pickup_details);
    }
  }, [configData]);

  function shippinPickupEditIconClickHandler(data: IShippingPickupDetail) {
    setEditData(data);
    setIsAddShippingPicupModalOpen(true);
  }

  const getRows = () => {
    return shippingPickupDetails?.map((range, index) => ({
      index: index + 1,
      mobile: range.mobile,
      name: range.name,
      pinCode: range.pinCode,
      email: range.email,
      address: range.address,
      actions: (
        <Box>
          {state?.access.write && (
            <Icon
              component={EditIcon}
              onClick={() => shippinPickupEditIconClickHandler(range)}
            ></Icon>
          )}
          {state?.access.delete && (
            <Icon
              component={DeleteIcon}
              onClick={() => {
                setDeleteData(range);
              }}
            ></Icon>
          )}
        </Box>
      ),
    }));
  };

  function handleClose() {
    setIsAddShippingPicupModalOpen(false);
    setEditData(null);
  }

  async function handleSave(data: IShippingPickupDetail, _id: string | null) {
    let postData: {
      shippingPickupDetails: IShippingPickupDetail[];
    };
    if (_id) {
      const newShippingPickupDetails = shippingPickupDetails;
      const idx = shippingPickupDetails.findIndex((item) => item._id === _id);
      newShippingPickupDetails[idx] = data;
      postData = {
        shippingPickupDetails: [...shippingPickupDetails],
      };
    } else {
      postData = {
        shippingPickupDetails: [...shippingPickupDetails, data],
      };
    }

    await saveShippingPickup(postData);
    handleClose();
  }
  const saveShippingPickup = async (postData) => {
    try {
      const response = await apiConfig.postShippingPickupConfig(postData);
      getData();
    } catch (err) {
      console.log(err);
    }
  };

  async function handleDeletePickupDetails() {
    const pickupDetails = shippingPickupDetails.filter(
      (item) => item._id !== deleteData._id
    );
    const postData = {
      shippingPickupDetails: pickupDetails,
    };
    await saveShippingPickup(postData);
    setDeleteData(null);
  }
  return (
    <Box>
      <AddOrEditShippingPickupDetails
        editData={editData}
        handleClose={handleClose}
        handleSave={handleSave}
        isAddShippingPicupModalOpen={isAddShippingPicupModalOpen}
      />

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          {state?.access.write && (
            <Icon
              sx={{ cursor: "pointer" }}
              fontSize="large"
              component={AddBoxIcon}
              onClick={() => setIsAddShippingPicupModalOpen(true)}
            ></Icon>
          )}
        </Box>
        {shippingPickupDetails && shippingPickupDetails.length > 0 && (
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell align="left">Mobile no</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">PinCode </TableCell>
                    <TableCell align="left">Address</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRows() &&
                    getRows().map((row) => (
                      <TableRow
                        key={row.index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.index}
                        </TableCell>
                        <TableCell align="left">{row.mobile}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.pinCode}</TableCell>
                        <TableCell align="left">{row.address}</TableCell>
                        <TableCell align="left">{row.actions}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      {deleteData && (
        <CustomDeleteModal
          onHide={() => setDeleteData(null)}
          show={!!deleteData}
          itemName={`pickup address`}
          deleteItem={handleDeletePickupDetails}
        />
      )}
    </Box>
  );
}
