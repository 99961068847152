import { IPolicy } from "Interfaces/Api/Policy";
import { apiProvider } from "Services/API/Provider";
import { ApiCore } from "../../Core";

const url = "policy";
const apiPolicy = new ApiCore<IPolicy>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});
apiPolicy.search = (searchText, page, perPage) => {
  return apiProvider.getAll(
    `${url}/search?searchText=${searchText}&page=${page}&perPage=${perPage}`
  );
};
export { apiPolicy };
