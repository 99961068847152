import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  products,
  deleteProductImage,
} from "Services/API/IndividualApis/Product";
import { Categories as categoriesApi } from "../../Services/API/IndividualApis/Category";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useNavigate, useParams } from "react-router-dom";
import SimpleComponent from "./SimpleComponent";
import VariantComponent from "./VariantComponent/index";
import { ICategory } from "Interfaces/Api/Category";
import { IProduct } from "Interfaces/Api/Product";
import { TabPanel, a11yProps } from "components/TabPanel/TabPanel";

const AddOrEditProduct = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [editItem, setEditItem] = useState<IProduct>();
  const [tabValue, setTabValue] = React.useState<number>(0);

  const { id } = useParams();

  useEffect(() => {
    if (window.location.pathname == "/admin/products/add") {
      setEditMode(false);
      fetchAllCategories();
    } else {
      getPrductDetails(id);
      setEditMode(true);
    }
  }, []);

  const getPrductDetails = async (id: string) => {
    try {
      const response = await products.getSingle(id);
      await fetchAllCategories();
      await setEditItem(response);
      setEditMode(true);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await categoriesApi.getAll(1, 1000);
      setAllCategories(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const Navigate = useNavigate();

  const onCancel = () => {
    Navigate(-1);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className="header">
              {editMode ? <h4>Edit Products</h4> : <h4>Add Products</h4>}
            </CardHeader>

            <CardBody>
              <Box>
                {addMode || !editMode ? (
                  <Box>
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Simple Product" {...a11yProps(0)} />
                      <Tab label="Variant Product" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                      <SimpleComponent
                        allCategories={allCategories}
                        editItem={editItem}
                        editMode={editMode}
                        onCancel={onCancel}
                        onClose={onCancel}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={editMode ? 0 : 1}>
                      <VariantComponent
                        allCategories={allCategories}
                        editItem={editItem}
                        editMode={editMode}
                        onCancel={onCancel}
                        onClose={onCancel}
                      />
                    </TabPanel>
                  </Box>
                ) : (
                  ""
                )}

                {editMode && editItem?.type === "Simple" ? (
                  <TabPanel value={tabValue} index={0}>
                    <SimpleComponent
                      allCategories={allCategories}
                      editItem={editItem}
                      editMode={editMode}
                      onCancel={onCancel}
                      onClose={onCancel}
                    />
                  </TabPanel>
                ) : (
                  ""
                )}
                {editMode && editItem?.type === "Variant" ? (
                  <TabPanel value={tabValue} index={editMode ? 0 : 1}>
                    <VariantComponent
                      allCategories={allCategories}
                      editItem={editItem}
                      editMode={editMode}
                      onCancel={onCancel}
                      onClose={onCancel}
                    />
                  </TabPanel>
                ) : (
                  ""
                )}
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddOrEditProduct;
