import { ICancelOrder } from "Interfaces/Api/CancelOrder";
import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import { ApiCore } from "../../Core";

const url = "order/cancel";
const cancelorder = new ApiCore<ICancelOrder>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

export { cancelorder };
