import CommonTable from "components/CommonTable/CommonTable";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import { useLocation } from "react-router-dom";
import { apiReview } from "Services/API/IndividualApis/Product/Review";
import { IReview, reviewStatus } from "Interfaces/Api/Reviews";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { usePagination } from "hooks/tablePaginationHook";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Typography } from "@mui/material";
import DeleteModal from "components/CustomDeleteModal";
import { IPagination } from "Interfaces/Api/Pagination";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";
import { filterCols } from "Services/Helper";

const Attributes = () => {
  const [allReivews, setAllReviews] = useState<IReview[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, handleChangePage] = usePagination();
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [searchText, setSearchText] = useState<string>(undefined);
  const [isMessageDialogOpen, setIsMessageDialogOpen] =
    useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation() as any;

  useEffect(() => {
    fetchAllReviews();
  }, [extraQueryParam, page, rowsPerPage]);

  useEffect(() => {
    if (searchText !== undefined) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, page, rowsPerPage]);

  const handleSearch = async () => {
    if (searchText == "") {
      await fetchAllReviews();
    } else {
      try {
        const response = await apiReview.search(searchText, page, rowsPerPage);
        setLoading(false);
        setAllReviews(response.data);
        setShowPagination(response.pagination);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleEditReview = async (item: IReview, status: reviewStatus) => {
    try {
      const iditObj: Partial<IReview> = {
        status,
      };
      const resposne = await apiReview.changesReviewStatus(item._id, iditObj);
      fetchAllReviews();
    } catch (err) {
      console.log(err);
    }
  };

  const rowsData = allReivews?.map((item) => {
    let date = item.createdAt;
    let newDate = new Date(date);

    const newDates = moment(newDate).calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "DD/MM/YYYY",
      lastDay: "DD/MM/YYYY",
      lastWeek: "DD/MM/YYYY",
      sameElse: "DD/MM/YYYY",
    });

    return {
      id: item?._id,
      status: item?.status,
      date: newDates,
      message: item?.message,
      title: item?.title,
      rating: item.rating,
      user: item.user,
      product: item.product,
      actions: item,
    };
  });

  const cols = React.useMemo(
    () => [
      {
        field: "user",
        headerName: "Name",
        renderCell: ({ value }) => {
          return (
            <Link to={`/admin/users/${value.id}`}>
              <div style={{ whiteSpace: "pre-wrap" }}>{value.name}</div>
            </Link>
          );
        },
      },
      {
        field: "product",
        headerName: "Product",
        width: 150,
        renderCell: ({ value }) => {
          return (
            <Link to={`/admin/products/${value._id}`}>
              <div style={{ whiteSpace: "pre-wrap" }}>{value.name}</div>
            </Link>
          );
        },
      },
      {
        field: "date",
        headerName: "Date",
        renderCell: (params) => {
          const todays = "";
          if (params.row.date == "today") {
            let date = params.row.order.createdAt;
            let newDate = new Date(date);
            let today = new Date();
            var start = moment(newDate);
            var end = moment(today);
            return <>{start.from(end)}</>;
          } else {
            return <>{params.row.date}</>;
          }
        },
      },
      {
        field: "rating",
        headerName: "Rating",
        renderCell: (params) => (
          <div>
            {params.value} <StarIcon color="info" sx={{ fontSize: "1rem" }} />{" "}
          </div>
        ),
      },
      {
        field: "title",
        headerName: "Title",
        width: 150,
        renderCell: (params) => (
          <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
        ),
      },

      {
        field: "message",
        headerName: "Message",
        width: 170,
        renderCell: ({ value }) => {
          return (
            <>
              {value && (
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {value.substring(0, 30)}
                  {value.length > 30 && (
                    <Icon
                      fontSize="small"
                      sx={{ marginLeft: "0.5rem", cursor: "pointer" }}
                      component={InfoIcon}
                      onClick={() => {
                        setDialogMessage(value);
                        setIsMessageDialogOpen(true);
                      }}
                    />
                  )}
                </div>
              )}
            </>
          );
        },
      },
      {
        field: "status",
        filterable: true,
        isValueSelectable: true,
        options: Object.values(reviewStatus).map((item) => ({ name: item })),
        headerName: "Status",
        width: 150,
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params) => {
          return (
            <>
              {state?.access.write && (
                <>
                  <Icon
                    component={DoneIcon}
                    color="success"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleEditReview(params.value, reviewStatus.APPROVED)
                    }
                  />

                  <Icon
                    component={CloseIcon}
                    color="action"
                    sx={{ marginLeft: "0.5rem", cursor: "pointer" }}
                    onClick={() =>
                      handleEditReview(params.value, reviewStatus.REJECTED)
                    }
                  />
                </>
              )}
              {state?.access.delete && (
                <Icon
                  component={DeleteIcon}
                  sx={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setItemToBeDeleted(params.value);
                  }}
                />
              )}
            </>
          );
        },
      },
    ],
    []
  );
  filterCols(state, cols);

  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => {
    if (clearFilter) {
      setExtraQueryParam("");
      return;
    }
    const filterArr = [];
    filterRows.forEach((element) => {
      let value = element.value;
      if (value instanceof Array) {
        value = value.filter((item) => item !== "");
      }
      const obj = {
        [element.name]: {
          [element.operator]: value,
        },
      };
      filterArr.push(obj);
    });
    const filterObj = {
      [linkedOperator]: filterArr,
    };
    let queryParam = `&filter=${JSON.stringify(filterObj)}`;

    setExtraQueryParam(queryParam);
  };

  const fetchAllReviews = async () => {
    setLoading(true);
    try {
      const response = await apiReview.getAll(
        page,
        rowsPerPage,
        extraQueryParam
      );
      setLoading(false);
      setAllReviews(response.data);
      setShowPagination(response.pagination);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const removeItems = async () => {
    try {
      const response = await apiReview.remove(itemToBeDeleted._id);
      setShowDeleteModal(false);
      setItemToBeDeleted(null);
      await fetchAllReviews();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>
        {showDeleteModal && (
          <DeleteModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            deleteItem={() => removeItems()}
            itemName={`${itemToBeDeleted.product.name} review`}
          />
        )}
        <>
          <CommonTable
            Title="Reviews"
            button="Add Category"
            page={page}
            handleChangePage={handleChangePage}
            totalRows={showPagination?.totalCount}
            addItems=""
            searchText={searchText}
            handleSearchTextChanged={(value) => setSearchText(value)}
            rowsPerPage={rowsPerPage}
            searchPlaceHolder="Search by name or product name"
            setRowsPerPage={setRowsPerPage}
            rows={rowsData}
            columns={cols}
            handleFilter={handleFilter}
            loading={loading}
          />
        </>
      </div>
      <Dialog
        onClose={() => setIsMessageDialogOpen(false)}
        open={isMessageDialogOpen}
      >
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Interceptor(Attributes);
