import { Modal, Select } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { user } from "Services/API/IndividualApis/Users";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonTable from "components/CommonTable/CommonTable";
import UserDetails from "./userDetails";
import { usePagination } from "hooks/tablePaginationHook";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { IUser, Roles } from "Interfaces/Api/User";
import DeleteModal from "components/CustomDeleteModal";
import { IPagination } from "Interfaces/Api/Pagination";
import { useSelector } from "react-redux";
import AttachPolicyModal from "./AttchPolicyModal";
import { filterCols } from "Services/Helper";
import EditIcon from "@mui/icons-material/Edit";
import Admin from "layouts/Admin";

interface RoleChangeData {
  role: Roles;
  policy?: string;
}
const Users = () => {
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [searchText, setSearchText] = useState<string>(undefined);
  const [listingPage, setListingPage] = useState<boolean>(true);
  const [itemTobeSeenInDetail, setItemTobeSeenInDetail] = useState(null);
  const [page, handleChangePage] = usePagination();
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [userDataWhoseRoleToBeChanged, setUserDataWhoseRoleToBeChanged] =
    useState<any>("");
  const [
    userDataWhoseRoleToBeChangedModel,
    setUserDataWhoseRoleToBeChangedModal,
  ] = useState<boolean>(false);
  const [isPolicyModalVisible, setIsPolicyModalVisible] =
    useState<boolean>(false);
  const [roleChangeEvent, setRoleChangeEvent] = useState<any>("");
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation() as any;

  useEffect(() => {
    if (searchText !== undefined) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, page, rowsPerPage]);

  const [userId, setUserId] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const userId = location.search.slice(8);
    if (userId) {
      setIsPolicyModalVisible(true);
      setUserId(userId);
      setRoleChangeEvent(Roles.ADMIN);
    }
  }, [location]);

  useEffect(() => {
    fetchAllUsers();
  }, [page, extraQueryParam, rowsPerPage]);

  const onHide = () => {
    setUserDataWhoseRoleToBeChangedModal(false);
  };

  const userDetail = useSelector((state: any) => state.auth.user);

  const handleRoleChange = async (event, param) => {
    setUserId(param._id);
    setUserDataWhoseRoleToBeChanged(param);
    setRoleChangeEvent(event.target.value);
    if (event.target.value === Roles.ADMIN) {
      setIsPolicyModalVisible(true);
    } else {
      setUserDataWhoseRoleToBeChangedModal(true);
    }
  };

  const handleChangeRole = async (policyId?: string) => {
    const updateData: RoleChangeData = {
      role: roleChangeEvent,
    };

    if (userDetail.role === Roles.SUPER_ADMIN && policyId) {
      updateData.policy = policyId;
    }

    await user.updateUserRole(userId, updateData);
    setUserDataWhoseRoleToBeChangedModal(false);
    await fetchAllUsers();
  };

  const Navigate = useNavigate();

  const handleUserDetails = (item) => {
    setListingPage(false);
    Navigate(`/admin/users/${item._id}`);

    setItemTobeSeenInDetail(item.value);
  };

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleExportCsv = async () => {
    window.open(`${BASE_URL}/user/csvFile`);
  };

  const rowsData = allUsers?.map((item) => {
    return {
      email: item?.profile,
      name: item?.name,
      phoneNumber: item?.phoneNumber,
      address: item?.profile,
      role: item,
      id: item?._id,
      policy: item?.policy,
      actions: item,
    };
  });

  const cols = useMemo(
    () => [
      {
        field: "email",
        headerName: "Email",
        width: 200,
        renderCell: (params) => {
          return (
            <>
              {params.value.email ? (
                <div>{`${params.value.email}`}</div>
              ) : (
                `- -`
              )}
            </>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        width: 150,
        filterable: true,
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
        width: 150,
      },
      {
        field: "policy",
        headerName: "Policy",
        width: 150,
        renderCell: (params) => {
          return (
            <>
              {params.row.policy ? (
                <div>{`${params.row.policy.name}`}</div>
              ) : (
                `- -`
              )}
            </>
          );
        },
      },
      {
        field: "address",
        headerName: "Address",
        width: 250,
        renderCell: (params) => {
          return (
            <>
              <div style={{ whiteSpace: "break-spaces" }}>
                {params.value.shippingAddresses.map((item) => {
                  return (
                    <>
                      {" "}
                      {item.isDefault === true ? (
                        <div style={{ overflow: "wrap" }}>
                          {`${item.name}`} {`${item?.city}`}
                          <br />
                          {`${item?.zipCode}, ${item?.state}, ${item?.country}`}
                        </div>
                      ) : (
                        <div>- - </div>
                      )}
                    </>
                  );
                })}
              </div>
              {params.value.shippingAddresses.length == 0 && <div>- - </div>}
            </>
          );
        },
      },
      {
        field: "role",
        headerName: "Role",
        width: 150,
        renderCell: (params) => {
          return (
            <Select
              native
              value={params.value.profile.role}
              onChange={(e) => {
                handleRoleChange(e, params.value);
              }}
              disabled={userDetail.role === Roles.SUPER_ADMIN ? false : true}
              name="Select Role"
              inputProps={{
                id: "age-native-required",
              }}
            >
              {Object.values(Roles).map((role, index) => {
                // if (role !== Roles.SUPER_ADMIN) {
                return (
                  <option
                    key={index}
                    value={role}
                    disabled={role == Roles.SUPER_ADMIN ? true : false}
                  >
                    {role}
                  </option>
                );
                // } else return null;
              })}
            </Select>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params) => {
          return (
            <div>
              {" "}
              {state?.access.write && (
                <EditIcon
                  onClick={() => {
                    handleUserDetails(params.value);
                    // history.push(`admin/user/${params.value._id}`)
                    // setItemTobeSeenInDetail(params.value);
                    // setUserDetailPage(true)
                    // setListingPage(false);
                  }}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              )}
              {state?.access.delete &&
                params.row.role.profile.role != "Super_Admin" && (
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowDeleteModal(true);
                      setItemToBeDeleted(params.value);
                    }}
                  />
                )}
            </div>
          );
        },
      },
    ],
    [state]
  );
  filterCols(state, cols);
  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => {
    if (clearFilter) {
      setExtraQueryParam("");
      return;
    }
    const filterArr = [];
    filterRows.forEach((element) => {
      let value = element.value;
      if (value instanceof Array) {
        value = value.filter((item) => item !== "");
      }
      const obj = {
        [element.name]: {
          [element.operator]: value,
        },
      };
      filterArr.push(obj);
    });
    const filterObj = {
      [linkedOperator]: filterArr,
    };
    let queryParam = `&filter=${JSON.stringify(filterObj)}`;

    setExtraQueryParam(queryParam);
  };

  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      const response = await user.getAll(page, rowsPerPage, extraQueryParam);
      setLoading(false);
      setAllUsers(response.data);
      setShowPagination(response.pagination);
      if (!listingPage) {
        const newItemTobeSeenInDetail = response.data
          .filter((item) => item._id === itemTobeSeenInDetail._id)
          .pop();
        setItemTobeSeenInDetail(newItemTobeSeenInDetail);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const removeItems = async () => {
    try {
      const response = await user.remove(itemToBeDeleted._id);
      setShowDeleteModal(false);
      setItemToBeDeleted(null);
      await fetchAllUsers();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async () => {
    if (searchText == "") {
      await fetchAllUsers();
    } else {
      try {
        const response = await user.search(searchText, page, rowsPerPage);
        setAllUsers(response.data);
        setShowPagination(response.pagination);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const newPage = "";
  return (
    <div>
      {!listingPage ? (
        <>
          <UserDetails
          // user={itemTobeSeenInDetail}
          // handleRoleChange={handleRoleChange}
          // handleBackButton={() => setListingPage(true)}
          />
        </>
      ) : (
        <CommonTable
          Title="Users"
          button="Add User"
          addItems={() => console.log("hello")}
          handleSearchTextChanged={(value) => setSearchText(value)}
          searchText={searchText}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={(value) => setRowsPerPage(value)}
          totalRows={showPagination?.totalCount}
          handleChangePage={handleChangePage}
          rows={rowsData}
          columns={cols}
          handleFilter={handleFilter}
          loading={loading}
          handleExportCsv={handleExportCsv}
          isExportCSV={true}
          searchPlaceHolder="Search by name or phone no or email"
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems()}
          itemName={itemToBeDeleted.email}
        />
      )}
      {userDataWhoseRoleToBeChangedModel && (
        <Modal
          open={userDataWhoseRoleToBeChangedModel}
          onClose={onHide}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridContainer sm={5}>
            <Card>
              <GridItem>
                <CardBody>
                  <GridItem>
                    <h2 style={{ textAlign: "center" }}>
                      Do you want to change the role of "
                      {userDataWhoseRoleToBeChanged.profile.role}"?
                    </h2>
                    <GridContainer
                      style={{ justifyContent: "center", paddingTop: "10px" }}
                    >
                      <GridItem>
                        <Button
                          color="danger"
                          onClick={() =>
                            setUserDataWhoseRoleToBeChangedModal(false)
                          }
                        >
                          Cancel
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button
                          color="success"
                          onClick={() => handleChangeRole()}
                        >
                          Change
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </CardBody>
              </GridItem>
            </Card>
          </GridContainer>
        </Modal>
      )}

      {isPolicyModalVisible && (
        <AttachPolicyModal
          userId={userDataWhoseRoleToBeChanged._id}
          show={isPolicyModalVisible}
          onHide={() => setIsPolicyModalVisible(false)}
          handleChangeRole={handleChangeRole}
          editMode={false}
        />
      )}
    </div>
  );
};

export default Users;
