import { useEffect, useState } from "react";
import { orders } from "Services/API/IndividualApis/Orders";
import { Card } from "react-bootstrap";
import GridContainer from "components/Grid/GridContainer";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import ShippingAddress from "./ShippingAddress";
import Button from "components/CustomButtons/Button";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import CancelOrder from "./CancelOrderModal";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "components/Grid/Grid";
import CustomProductDetailsTable from "components/CustomProductDetailTable/CustomProductDetailsTable";
import { IOrder } from "Interfaces/Api/Orders";
import GenralDetails from "./GenralDetails";
import { IAddress } from "Interfaces/Api/User";
import { Box, Step, StepLabel, Stepper } from "@mui/material";

const OrderDetails = () => {
  const [date, setDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<IAddress>();
  const [orderDetails, setOrderDetails] = useState<IOrder>();
  const [noteKey, setNoteKey] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const Navigate = useNavigate();

  const onCancel = () => {
    Navigate(-1);
  };

  useEffect(() => {
    handleOrderDetails(id);
  }, []);

  const handleOrderDetails = async (id) => {
    try {
      const response = await orders.getSingle(id);
      setOrderDetails(response);
      setShippingAddress(response.shippingAddress);
    } catch (err) {
      console.log(err);
    }
  };
  const steps = [
    "Order Received",
    "Order Picked",
    "Order In Transit",
    "Out for delivery",
    "Reached Destination",
  ];

  const StatusData = [
    { id: "1", name: "PROCESSING" },
    { id: "2", name: "SHIPPED" },
    { id: "3", name: "PENDING_PAYMENT" },
    { id: "4", name: "COMPLETED" },
    { id: "5", name: "CANCELLED" },
    { id: "6", name: "FAILED" },
  ];

  useEffect(() => {
    if (orderDetails) {
      const newDate1 = orderDetails.createdAt;
      const newDate = new Date(newDate1).toLocaleDateString();
      if (orderDetails.status === "CANCELLED") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      const newStatus = StatusData.filter((val) => {
        if (orderDetails.status === val.name) {
          setStatus(val.name);
        }
      });
      setDate(newDate);
    }
  }, [orderDetails]);

  const closeModal = () => {
    setOpenModal(false);
  };
  const showModal = () => {
    setOpenModal(true);
  };
  const onSaveOrderDetails = (obj) => {
    setShippingAddress(obj);
  };

  const { id } = useParams();

  const onSaveEditedOrder = async () => {
    const obj = {
      submit: "true",
      status: status,
      cancelNote: noteKey,
      shippingAddress: {
        name: shippingAddress?.name,
        city: shippingAddress?.city,
        country: shippingAddress?.country,
        state: shippingAddress?.state,
        zipCode: shippingAddress?.zipCode,
        email: shippingAddress?.email,
        phoneNumber: shippingAddress?.phoneNumber,
        line1: shippingAddress?.line1,
        line2: shippingAddress?.line2,
      },
    };
    try {
      const response = await orders.patch(id, obj);
      Navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const onSaveCancelReason = async (obj) => {
    setLoader(true);
    setStatus(obj.status);
    setNoteKey(obj.cancellationReason);
    await onPostCancelReason(obj);
  };

  const onPostCancelReason = async (obj) => {
    try {
      const response = await orders.patch(id, obj);
      setLoader(false);
      setOpenModal(false);
      // Navigate("/admin/orders/list");
      handleOrderDetails(id);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  const handleOrderStautusChange = (val) => {
    setStatus(val);
  };

  const handlePlaceOrderOnPickkr = async () => {
    try {
      const response = await orders.placeOrderOnShiprocket(orderDetails);
      if (response) {
        handleOrderDetails(id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (orderDetails?.shippingDetails.shiprocket_order_id) getOrderDetails();
  }, [orderDetails]);

  const getOrderDetails = async () => {
    try {
      const response = await orders.getTrackDetailsById(
        orderDetails.shippingDetails.shiprocket_order_id
      );
    } catch (err) {
      console.log(err);
    }
  };

  const newDate = new Date(orderDetails?.createdAt);
  let time = newDate.toLocaleTimeString();
  return (
    <div>
      {openModal ? (
        <>
          <CancelOrder
            show={openModal}
            loader={loader}
            onHide={() => closeModal()}
            onSave={onSaveCancelReason}
          />
        </>
      ) : (
        ""
      )}
      <GridContainer>
        <Grid xs={12}>
          <Card>
            <CardHeader className="header">
              <h4>Edit order</h4>
            </CardHeader>
            <CardBody>
              <div style={{ display: "flex" }}>
                <GridItem sm={12} xs={12}>
                  <GenralDetails
                    details={orderDetails}
                    isAbandoned={false}
                    showModal={showModal}
                    handleOrderStautusChange={handleOrderStautusChange}
                  />
                  <h4>
                    Payment Method:{" "}
                    {orderDetails?.paymentDetails?.paymentMethod}
                  </h4>
                  <div style={{ display: "flex" }}>
                    {/* {orderDetails?.shippingDetails?.pickrrShippingDetails ==
                      null && ( */}
                    <div style={{ marginRight: "1rem" }}>
                      <Button
                        color="success"
                        onClick={handlePlaceOrderOnPickkr}
                        disabled={
                          orderDetails?.shippingDetails?.shiprocket_order_id ==
                            null && orderDetails?.status == "PROCESSING"
                            ? false
                            : true
                        }
                      >
                        Place Order on shiprocket
                      </Button>
                    </div>
                    {/* )} */}
                    {/* {orderDetails?.shippingDetails?.pickrrShippingDetails
                      ?.track_url &&
                      orderDetails.status == "PROCESSING" && ( */}
                    <div style={{ marginRight: "1rem" }}>
                      <a
                        target="_blank"
                        href={`${orderDetails?.shippingDetails?.trackingDetails?.track_url}`}
                      >
                        <Button
                          color="success"
                          disabled={
                            orderDetails?.shippingDetails?.trackingDetails
                              ?.track_url
                              ? // ?.track_url
                                false
                              : true
                          }
                        >
                          Track order
                        </Button>
                      </a>
                    </div>
                    {/* )} */}
                  </div>
                  {time && <span>{`Order time: ${time}`}</span>}
                  <ShippingAddress
                    orderDetails={orderDetails}
                    onSaveOrderDetails={onSaveOrderDetails}
                  />
                  {orderDetails && (
                    <CustomProductDetailsTable orderDetails={orderDetails} />
                  )}
                </GridItem>
              </div>
              <GridContainer
                style={{
                  justifyContent: "end",
                  paddingTop: "4rem",
                }}
              >
                <div style={{ marginRight: "1rem" }}>
                  <Button color="gray" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                </div>
                <div style={{ marginRight: "1rem" }}>
                  <Button color="success" onClick={onSaveEditedOrder}>
                    Submit
                  </Button>
                </div>
              </GridContainer>
            </CardBody>
          </Card>
        </Grid>
      </GridContainer>
    </div>
  );
};
export default Interceptor(OrderDetails);
