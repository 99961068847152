import React, { useCallback, useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Modal from "@mui/material/Modal";

import Button from "components/CustomButtons/Button";

interface DeleteModalProps {
  onHide: Function;
  onSave?: Function;
  show: boolean;
  itemName: string;
  deleteItem: Function;
}

const DeleteModal = ({
  show,
  onHide,
  deleteItem,
  itemName,
}: DeleteModalProps) => {
  return (
    <>
      <div style={{ width: "50%" }}>
        <Modal
          open={show}
          onClose={() => onHide()}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridContainer sm={5}>
            <Card>
              <GridItem>
                <CardBody>
                  <GridItem>
                    <h2 style={{ textAlign: "center" }}>
                      Do you want to delete {itemName}?
                    </h2>
                    <GridContainer
                      style={{ justifyContent: "center", paddingTop: "10px" }}
                    >
                      <GridItem>
                        <Button color="gray" onClick={onHide}>
                          Cancel
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button color="success" onClick={() => deleteItem()}>
                          Delete
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </CardBody>
              </GridItem>
            </Card>
          </GridContainer>
        </Modal>
      </div>
    </>
  );
};

export default DeleteModal;
