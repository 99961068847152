import React, { useEffect, useState } from "react";
import { orders } from "Services/API/IndividualApis/Orders";
import { Card } from "react-bootstrap";
import GridContainer from "components/Grid/GridContainer";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Grid from "components/Grid/Grid";
import ShippingAddress from "views/Orders/ShippingAddress";
import Modal from "@mui/material/Modal";
import { cancelorder } from "Services/API/IndividualApis/CancelOrders/indes";
import CustomProductDetailsTable from "components/CustomProductDetailTable/CustomProductDetailsTable";
import { IAddress } from "Interfaces/Api/User";
import { IOrder } from "Interfaces/Api/Orders";
import GenralDetails from "views/Orders/GenralDetails";

const steps = [
  "Order Received",
  "Order Picked",
  "Order In Transit",
  "Out for delivery",
  "Reached Destination",
  "Order Received",
  "Order Picked",
  "Order In Transit",
  "Out for delivery",
  "Reached Destination",
];

const CancelOrderDetails = ({
  onSubmitCancelRequestStatus,
  onHide,
  openModal,
  rejectState,
}) => {
  const onSubmit = async () => {
    console.log();
    onSubmitCancelRequestStatus();
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => onHide}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GridContainer sm={5}>
          <Card>
            <GridItem>
              <CardBody>
                <GridItem>
                  {rejectState ? (
                    <h2 style={{ textAlign: "center" }}>
                      Are you sure you want to reject this cancel request?
                    </h2>
                  ) : (
                    <h2 style={{ textAlign: "center" }}>
                      Are you sure you want to approve this cancel request?
                    </h2>
                  )}

                  <GridContainer
                    style={{ justifyContent: "center", paddingTop: "10px" }}
                  >
                    <GridItem>
                      <Button color="gray" onClick={onHide}>
                        No
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button color="success" onClick={() => onSubmit()}>
                        Yes
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </CardBody>
            </GridItem>
          </Card>
        </GridContainer>
      </Modal>
    </div>
  );
};
export default Interceptor(CancelOrderDetails);
