import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import DeleteModal from "components/CustomDeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function FilterTable({ rows, deleteFilters, editFilters }) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string>("");
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const rowsData = rows?.map((item, index) => {
    return {
      name: item?.name,
      comparisonType: item?.comparisonType,
      options: item?.options,
      id: index,
      actions: item,
    };
  });
  const cols: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "options",
      headerName: "options",
      width: 250,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <EditIcon
              onClick={(event) => handleEditClick(event, params.value.name)}
              style={{ marginRight: "10px" }}
            />
            <DeleteIcon
              onClick={(event) => {
                setShowDeleteModal(true);
                setSelected(params.value.name);
                // handleClick(event, params.value.name)
              }}
            />
          </>
        );
      },
    },
  ];

  const handleEditClick = (event, name) => {
    editFilters(name);
  };
  const removeItems = async (name) => {
    await deleteFilters(name);
    setShowDeleteModal(false);
  };

  // const handleClick = (event, name) => {
  // const newValue = rows.filter((val) => val.name != name)
  // deleteFilters(name)
  // }
  return (
    <div className={classes.root}>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={rowsData}
          columns={cols}
          autoHeight={true}
          disableSelectionOnClick={true}
          hideFooterPagination={true}
        />
      </div>
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems(selected)}
          itemName={selected}
        />
      )}
    </div>
  );
}
