import { ISize } from "./Product";
import { IAddress } from "./User";

export enum orderStatus {
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PROCESSING = "PROCESSING",
  SHIPPED = "SHIPPED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

export enum cfOrderStatus {
  ACTIVE = "ACTIVE",
  PAID = "PAID",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
}

export enum paymentMethod {
  PREPAID = "Prepaid",
  COD = "Cod",
}

export enum transactionStatus {
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}
export enum shippingStatus {
  SHIPPED = 6,
  DELIVERED = 7,
  CANCELLED = 8,
  LOST = 12,
  OUT_FOR_DELIVERY = 17,
  IN_TRANSIENT = 18,
  OUT_FOR_PICKUP = 19,
}

export interface IOrderItem {
  _id: string;
  sku: string;
  name: string;
  slug: String;
  image: {
    url: string;
    key: string;
  };
  description: string;
  discount: number;
  price: number;
  finalPrice: number;
  categories: string[];
  quantity: number;
  size: ISize;
}

export interface IOrderAppliedCoupons {
  coupon: {
    name: string;
    code: string;
    description: string;
  };
  totalDiscount: number;
}

interface tracking_data {
  track_status: number;
  shipment_status: number;
  shipment_track: [
    {
      id: number;
      awb_code: string;
      courier_company_id: number;
      shipment_id: number;
      order_id: number;
      pickup_date: null | number;
      delivered_date: null | number;
      weight: string;
      packages: number;
      current_status: string;
      delivered_to: string;
      destination: string;
      consignee_name: string;
      origin: string;
      courier_agent_details: null;
      edd: string;
    }
  ];
  shipment_track_activities: [
    {
      date: string;
      status: string;
      activity: string;
      location: string;
      "sr-status": "NA" | number;
      "sr-status-label": "NA" | string;
    }
  ];
  track_url: "https://shiprocket.co//tracking/SRTP3133827428";
  etd: "2022-04-17 13:11:08";
  qc_response: {
    qc_image: "";
    qc_failed_reason: "";
  };
}
export enum refundType {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  ONHOLD = "ONHOLD",
  CANCELLED = "CANCELLED",
}

export interface IRefundDetails {
  cf_refund_id: number;
  cf_payment_id: number;
  refund_id: string;
  order_id: string;
  refund_amount: number;
  refund_currency: string;
  entity: string;
  refund_type: string;
  refund_arn: string;
  refund_status: refundType;
  status_description: string;
  created_at: string;
  processed_at: string;
  refund_charge: number;
  refund_note: string;
  refund_splits: null;
  metadata: null;
  refund_mode: string;
}

interface IPickrrShippingDetails {
  success: true;
  order_id: string;
  track_url: string;
  order_pk: number;
  tracking_id: string;
  manifest_link: string;
  routing_code: string;
  client_order_id: string;
  courier: string;
  dispatch_mode: string;
  child_waybill_list: string[];
}

export interface IOrder {
  shippingChargeFromCustomer: number;
  products: IOrderItem[];
  createdAt: string;
  _id: string;
  user: {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
  };
  shippingAddress: IAddress;
  refundDetails?: IRefundDetails | null;
  totalPrice: number;
  status?: orderStatus;
  appliedCoupons?: IOrderAppliedCoupons[];
  shiprocket_order_id?: number | null;
  paymentDetails?: {
    paymentMethod?: paymentMethod;
    cfDetails?: {
      cfOrderDetail: {
        cf_order_id: string;
        order_token: string;
        order_status: string;
      };
      cfTransactionDetails: any;
    };
  };
  shippingDetails?: {
    pickrrShippingDetails?: IPickrrShippingDetails | null;
    shiprocket_order_id?: number | null;
    trackingDetails: tracking_data | null;
  };
  cancelNote?: string;
}
