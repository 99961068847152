import { Modal } from "@mui/material";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

function ChangeModal({ openModal, onHide, handleChange, userDetails }) {
  return (
    <Modal
      open={openModal}
      onClose={onHide}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GridContainer sm={5}>
        <Card>
          <GridItem>
            <CardBody>
              <GridItem>
                <h2 style={{ textAlign: "center" }}>
                  Do you want to change the policy ?
                </h2>
                <GridContainer
                  style={{ justifyContent: "center", paddingTop: "10px" }}
                >
                  <GridItem>
                    <Button color="danger" onClick={onHide}>
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button color="success" onClick={() => handleChange()}>
                      Change
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </CardBody>
          </GridItem>
        </Card>
      </GridContainer>
    </Modal>
  );
}
export default ChangeModal;
