import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput.js";
import Modal from "@mui/material/Modal";
import { Categories as categoriesApi } from "../../Services/API/IndividualApis/Category";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "components/CustomButtons/Button";
import FilterOption from "components/Category/FilterOption/index";
import UploadImage, {
  acceptFileType,
} from "components/CustomUpload/UploadImage";
import CustomSelect from "components/CustomSelect/CustomSelect";
import FilterTable from "components/Category/FilterTable";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import Grid from "components/Grid/Grid";
import { ICategory } from "Interfaces/Api/Category";
import Loader from "components/Loader";
import InfoIcon from "@mui/icons-material/Info";

export const options = [
  { label: "Equal", value: "$eq" },
  { label: "Greater Than", value: "$gt" },
  { label: "Greater Than Equal", value: "$gte" },
  { label: "Less Than", value: "$lt" },
  { label: "Less Than Equal", value: "$lte" },
];
export const displayOptions = [
  { label: "", value: "" },
  { label: "Color", value: "Color" },
  { label: "Select", value: "Select" },
];
const initialState = {
  image: {
    mobile: null,
    desktop: null,
  },
};

export enum imageSize {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}
const AddOrEditCategory = () => {
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState("/");
  const [showAddFilter, setShowAddFilter] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<any>("");
  const [optionList, setOptionList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [filters, setFilters] = useState([]);
  const [icon, setIcon] = useState<any>("");
  const [whiteIcon, setWhiteIcon] = useState<any>("");
  const [primaryImage, setPrimaryImage] = useState<any>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const [editFilterItem, setEditFilterItem] = useState<any>("");
  const [editFilterModal, setEditFilterModal] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [color, setColor] = useState("#000000");
  const [comparisonType, setComparisonType] = React.useState("$eq");
  const [displayType, setDisplayType] = React.useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [mobileBannerImage, setMobileBannerImage] = useState<any>("");
  const [desktopBannerImage, setDesktopBannerImage] = useState<any>("");
  const [errorRequired, setErrorRequired] = useState<{
    [key: string]: boolean;
  }>({
    name: false,
    primaryImg: false,
    iconImg: false,
    desktopBanner: false,
    mobileBanner: false,
  });
  const [categoryMetaKeyword, setCategoryMetaKeyword] = useState([]);
  const [categoryMetaTitle, setCategoryMetaTitle] = useState<string>("");
  const [categoryMetaDescription, setCategoryMetaDescription] =
    useState<string>("");
  const { id } = useParams();

  useEffect(() => {
    if (window.location.pathname == "/admin/categories/add") {
      setEditMode(false);
    } else {
      setEditMode(true);
      getDetailsById(id);
    }
  }, []);

  const getDetailsById = async (id) => {
    try {
      const response = await categoriesApi.getSingle(id);
      if (response) {
        setCategoryName(response.name);
        setSubtitle(response?.title);
        setFilters(response.filters);
        setSelectedParentCategory(response.parent);
        setPrimaryImage(response.image?.url);
        setIcon(response.icon?.url);
        setWhiteIcon(response.whiteIcon?.url);
        setDesktopBannerImage(response.bannerImage?.desktop);
        setMobileBannerImage(response.bannerImage?.mobile);
        setCategoryMetaDescription(response.metaData.description);
        setCategoryMetaTitle(response.metaData.title);
        setCategoryMetaKeyword(response.metaData.keywords);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await categoriesApi.getAll(1, 1000);
      setAllCategories(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const handleDisplayTypeChange = (event) => {
    const type = event.target.value;
    setDisplayType(type);
  };

  const handleSelectedParentCategoryChange = (event) => {
    setSelectedParentCategory(event.target.value);
  };

  const addFilterHandler = () => {
    resetFilterDefault();
    setShowAddFilter(true);
    setDisabled(false);
  };
  const handleColorChange = (e) => {
    setColor(e.target.value);
  };
  const onAddColor = (e) => {
    if (!color) return;
    if (e.key === "Enter") {
      setColorList((preVal) => [...preVal, color]);
      setColor("");
    }
  };
  const handleOptionChange = (e) => {
    setCurrentOption(e.target.value);
  };
  const onAddOption = (e) => {
    if (!currentOption) return;
    if (e.key === "Enter") {
      setOptionList((preVal) => [...preVal, currentOption]);
      setCurrentOption("");
    }
  };

  const removeOption = (name) => {
    const options = optionList.filter((item) => item !== name);
    setOptionList(options);
  };

  const removeColor = (name) => {
    const options = colorList.filter((item) => item !== name);
    setColorList(options);
  };
  const resetFilterDefault = () => {
    setFilterName("");
    setComparisonType("$eq");
    setDisplayType("");
    setOptionList([]);
  };

  const onSaveCategory = async () => {
    const obj: any = {
      submit: "true",
      name: categoryName,
      title: subtitle,
      parent: selectedParentCategory,
      image: primaryImage,
      metaData: {
        title: categoryMetaTitle,
        description: categoryMetaDescription,
        keywords: categoryMetaKeyword,
      },
      bannerImage: {
        mobile: mobileBannerImage,
        desktop: desktopBannerImage,
      },
      icon: icon,
      filters,
    };
    if (whiteIcon) {
      obj["whiteIcon"] = whiteIcon;
    }

    if (selectedParentCategory == "/") {
      if (
        categoryName &&
        selectedParentCategory &&
        primaryImage &&
        icon &&
        mobileBannerImage &&
        desktopBannerImage
      ) {
        setLoading(true);
        postCategory(obj);
      } else {
        setErrorRequired({
          name: categoryName ? false : true,
          primaryImg: primaryImage
            ? false
            : selectedParentCategory === "/"
            ? true
            : false,
          iconImg: icon ? false : selectedParentCategory === "/" ? true : false,
          desktopBanner: desktopBannerImage ? false : true,
          mobileBanner: mobileBannerImage ? false : true,
        });
      }
    } else {
      if (
        categoryName &&
        selectedParentCategory &&
        mobileBannerImage &&
        desktopBannerImage
      ) {
        setLoading(true);

        postCategory(obj);
      } else {
        setErrorRequired({
          name: categoryName ? false : true,
          primaryImg: primaryImage
            ? false
            : selectedParentCategory === "/"
            ? true
            : false,
          iconImg: icon ? false : selectedParentCategory === "/" ? true : false,
          desktopBanner: desktopBannerImage ? false : true,
          mobileBanner: mobileBannerImage ? false : true,
        });
      }
    }
  };

  const postCategory = async (data) => {
    try {
      const response = await categoriesApi.post(data);
      if (response) {
        setLoading(false);
        // fetchAllCategories();
        Navigate(-1);
        resetFilterDefault();
        // setImage("");
        setPrimaryImage("");
        setSelectedParentCategory("/");
        setCategoryName("");
        setMobileBannerImage("");
        setDesktopBannerImage("");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const saveEditChanges = async () => {
    const obj = {
      submit: "true",
      filters: filters,
      title: subtitle,
      metaData: {
        title: categoryMetaTitle,
        description: categoryMetaDescription,
        keywords: categoryMetaKeyword,
      },
    };
    setLoading(true);
    putCategory(id, obj);
  };
  const Navigate = useNavigate();

  const putCategory = async (item, data) => {
    try {
      const response = await categoriesApi.patch(item, data);
      setEditMode(false);
      setLoading(false);
      Navigate(-1);
      // await fetchAllCategories();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const deleteFilters = async (toBeDeletedItems) => {
    try {
      const response = await categoriesApi.DeleteFilter(toBeDeletedItems, id);
      setFilters(response.filters);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditFilter = async (toBeEditedItem) => {
    setEditFilterModal(true);
    setDisabled(true);
    const newFilter = filters.filter((item) => {
      return toBeEditedItem === item.name;
    });
    setEditFilterItem(newFilter[0]);
  };

  useEffect(() => {
    if (editFilterItem) {
      setFilterName(editFilterItem.name);
      setOptionList(editFilterItem.options);
      setComparisonType(editFilterItem.comparisonType);
      setDisplayType(editFilterItem.displayType);
      handleEditFilter(editFilterItem);
    }
  }, [editFilterItem]);

  const onEditFilter = async (item) => {
    const filterData = {
      name: filterName,
      // comparisonType,
      displayType,
      options: displayType === "Select" ? optionList : colorList,
    };
    // const idx = filters.findIndex((val) => {
    //   return val.name === filterName;
    // });
    // if (idx >= 0) {
    //   const newFilters = filters;
    //   newFilters[idx] = filterData;
    //   setFilters(newFilters);
    // }
    if (editMode) {
      try {
        const response = await categoriesApi.EditFilter(
          filterName,
          id,
          filterData
        );
        setFilters(response.filters);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
    setEditFilterModal(false);
  };

  const onAddFilter = async () => {
    resetFilterDefault();
    const filterData = {
      name: filterName,
      // comparisonType,
      displayType,
      options: displayType === "Select" ? optionList : colorList,
    };
    resetFilterDefault();
    // setFilters((preValue) => [...preValue, filterData]);
    if (editMode) {
      try {
        const response = await categoriesApi.AddFilter(id, filterData);
        setFilters(response.filters);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
    setShowAddFilter(false);
  };

  const onCancel = () => {
    Navigate(-1);
  };

  const onClose = () => {
    setShowAddFilter(false);
    setEditFilterModal(false);
  };

  const onImageChange = async (acceptedFiles, entity = "image") => {
    if (!id) {
      if (entity === "image") {
        setPrimaryImage(acceptedFiles);
        setErrorRequired({
          ...errorRequired,
          primaryImg: acceptedFiles
            ? false
            : selectedParentCategory === "/"
            ? true
            : false,
        });
      } else if (entity === "icon") {
        setIcon(acceptedFiles);
        setErrorRequired({
          ...errorRequired,
          iconImg: acceptedFiles
            ? false
            : selectedParentCategory === "/"
            ? true
            : false,
        });
      } else if (entity === "mobileBanner") {
        setMobileBannerImage(acceptedFiles);
        setErrorRequired({
          ...errorRequired,
          mobileBanner: acceptedFiles ? false : true,
        });
      } else if (entity === "desktopBanner") {
        setDesktopBannerImage(acceptedFiles);
        setErrorRequired({
          ...errorRequired,
          desktopBanner: acceptedFiles ? false : true,
        });
      } else {
        setWhiteIcon(acceptedFiles);
      }
    } else {
      try {
        if (entity === "image") {
          if (!acceptedFiles.fileLimitExceed) {
            const response = await categoriesApi.UpdateCategoryImage(
              id,
              acceptedFiles
            );
            if (response) getDetailsById(id);
          }
        } else if (entity === "icon") {
          if (!acceptedFiles.fileLimitExceed) {
            const response = await categoriesApi.UpdateCategoryIcon(
              id,
              acceptedFiles
            );
            if (response) getDetailsById(id);
          } else {
            setIcon("");
          }
        } else if (entity === "whiteIcon") {
          if (!acceptedFiles.fileLimitExceed) {
            const response = await categoriesApi.UpdateCategoryIcon(
              id,
              acceptedFiles,
              "type=white"
            );
            if (response) getDetailsById(id);
          } else {
            setWhiteIcon("");
          }
        } else if (entity === "mobileBanner") {
          if (!acceptedFiles.fileLimitExceed) {
            const response = await categoriesApi.UpdateCategoryBanner(id, {
              mobile: acceptedFiles,
              desktop: desktopBannerImage,
            });
            if (response) getDetailsById(id);
          } else {
            setMobileBannerImage("");
          }
        } else if (entity === "desktopBanner") {
          if (!acceptedFiles.fileLimitExceed) {
            const response = await categoriesApi.UpdateCategoryBanner(id, {
              mobile: mobileBannerImage,
              desktop: acceptedFiles,
            });
            if (response) getDetailsById(id);
          } else {
            setMobileBannerImage("");
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <form>
      <GridContainer>
        <Grid sm={12}>
          <Card>
            <CardHeader className="header">
              {editMode ? <h4>Edit Category</h4> : <h4>Add Category</h4>}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText="Name"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      required: true,
                      disabled: editMode ? true : false,
                      value: categoryName,
                      onBlur: (e) =>
                        setErrorRequired({
                          ...errorRequired,
                          name: !e.target.value ? true : false,
                        }),
                      onChange: (e) => setCategoryName(e.target.value),
                    }}
                  />
                  {errorRequired.name ? (
                    <FormHelperText style={{ color: "red" }}>
                      Name is required
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomSelect
                    labelText="Parent Category *"
                    // native
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    id="age-native-required"
                    inputProps={{
                      required: true,
                      disabled: editMode ? true : false,
                      value: selectedParentCategory,
                      onChange: handleSelectedParentCategoryChange,

                      name: "Select Parent Category",
                      native: true,
                    }}
                  >
                    <option value="/">{"/"}</option>
                    {allCategories.map((item) => {
                      return (
                        <option key={item?._id} value={item.category}>
                          {item.category}
                        </option>
                      );
                    })}
                  </CustomSelect>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText="Subtitle"
                    formControlProps={
                      {
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      onBlur: (e) =>
                        setErrorRequired({
                          ...errorRequired,
                          subtitle: !e.target.value ? true : false,
                        }),
                      value: subtitle,
                      onChange: (e) => setSubtitle(e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/* <Box style={{ paddingBottom: "3rem" }}> */}
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText="Meta Title"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // className: classes.formControlSeo,
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      value: categoryMetaTitle,
                      onChange: (e) => setCategoryMetaTitle(e.target.value),
                      // onBlur: onBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText="Meta Description"
                    //   id="company-disabled"
                    formControlProps={
                      {
                        // className: classes.formControlSeo,
                        // fullWidth: true,
                      }
                    }
                    inputProps={{
                      value: categoryMetaDescription,
                      onChange: (e) =>
                        setCategoryMetaDescription(e.target.value),
                      // onBlur: onBlur,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <Autocomplete
                    multiple
                    fullWidth={true}
                    id="tags-outlined"
                    value={categoryMetaKeyword}
                    onChange={(e, value) => {
                      setCategoryMetaKeyword(value);
                    }}
                    // onBlur={onBlur}
                    options={[]}
                    freeSolo
                    // fullWidth: true,
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Meta Keywords"
                        placeholder="Type And Press Enter"
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
              {/* </Box> */}

              {selectedParentCategory === "/" && (
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4} style={{ marginTop: "1rem" }}>
                    <UploadImage
                      id="image"
                      useFileType={acceptFileType.ONLY_IMAGE}
                      label="Category Image *"
                      selectedImage={primaryImage || ""}
                      editMode={editMode}
                      handleFile={(data) => onImageChange(data, "image")}
                    />
                    {errorRequired.primaryImg ? (
                      <FormHelperText style={{ color: "red" }}>
                        {" "}
                        Primary Image is required
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4} style={{ marginTop: "1rem" }}>
                    <UploadImage
                      id="icon"
                      label="Icon *"
                      imageSize={300}
                      useFileType={acceptFileType.ONLY_IMAGE}
                      selectedImage={icon || ""}
                      editMode={!!icon}
                      handleFile={(data) => onImageChange(data, "icon")}
                    />
                    {errorRequired.iconImg ? (
                      <FormHelperText style={{ color: "red" }}>
                        Icon is required
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4} style={{ marginTop: "1rem" }}>
                    <UploadImage
                      id="whiteIcon"
                      label="White Icon"
                      useFileType={acceptFileType.ONLY_IMAGE}
                      selectedImage={whiteIcon || ""}
                      editMode={!!whiteIcon}
                      handleFile={(data) => onImageChange(data, "whiteIcon")}
                      imgPrevBg="green"
                    />
                  </GridItem>
                </GridContainer>
              )}
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} style={{ marginTop: "1rem" }}>
                  <UploadImage
                    // id={editMode ? "add-banner-mobile-image" : "add-banner-mobile-image"}
                    id="add-banner-mobile-image"
                    label="Banner Image(Mobile) *"
                    imageSize={500}
                    videoSize={2048}
                    useFileType={acceptFileType.ONLY_IMAGE}
                    selectedImage={
                      mobileBannerImage.url || mobileBannerImage || ""
                    }
                    editMode={editMode}
                    handleFile={(data) => onImageChange(data, "mobileBanner")}
                  />
                  {errorRequired.mobileBanner && (
                    <FormHelperText style={{ color: "red" }}>
                      Mobile banner is required
                    </FormHelperText>
                  )}
                </GridItem>
                <GridItem xs={12} sm={6} md={4} style={{ marginTop: "1rem" }}>
                  <UploadImage
                    // id={
                    //   editMode
                    //     ? "edit-banner-desktop-image"
                    //     : "add-banner-desktop-image"
                    // }
                    id="add-banner-desktop-image"
                    label="Banner Image(Desktop)*"
                    imageSize={500}
                    videoSize={2048}
                    useFileType={acceptFileType.ONLY_IMAGE}
                    selectedImage={
                      desktopBannerImage.url || desktopBannerImage || ""
                    }
                    editMode={editMode}
                    handleFile={(data) => onImageChange(data, "desktopBanner")}
                  />
                  {errorRequired.desktopBanner && (
                    <FormHelperText style={{ color: "red" }}>
                      Desktop banner is required
                    </FormHelperText>
                  )}
                </GridItem>
              </GridContainer>
              {filters && filters.length > 0 && (
                <GridContainer>
                  <FilterTable
                    rows={filters}
                    deleteFilters={deleteFilters}
                    editFilters={handleEditFilter}
                  />
                </GridContainer>
              )}
              <Modal
                open={showAddFilter || editFilterModal}
                onClose={onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GridContainer sm={8}>
                  <Card>
                    <GridItem>
                      <CardHeader className="header">
                        {/* <CardHeader color="primary"> */}
                        <h4>Filter</h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem sm={6}>
                            <CustomInput
                              labelText="Name"
                              //   id="company-disabled"
                              formControlProps={
                                {
                                  // fullWidth: true,
                                }
                              }
                              inputProps={{
                                disabled: disabled,
                                value: filterName,
                                onChange: (e) => setFilterName(e.target.value),
                              }}
                            />
                          </GridItem>
                          <GridItem sm={6}>
                            <CustomSelect
                              labelText="  Display Type"
                              // native
                              formControlProps={
                                {
                                  // fullWidth: true,
                                }
                              }
                              id="age-native-required-hehehe"
                              inputProps={{
                                value: displayType,
                                // onChange: handleComparisonTypeChange,
                                onChange: handleDisplayTypeChange,

                                name: "display Type",
                                native: true,
                              }}
                            >
                              {displayOptions.map((item) => {
                                return (
                                  <option key={item.label} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </CustomSelect>
                          </GridItem>
                        </GridContainer>
                        {displayType === "Select" ? (
                          <>
                            <FormControl>
                              <CustomInput
                                labelText="Option"
                                //   id="company-disabled"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  value: currentOption,
                                  onChange: handleOptionChange,
                                  onKeyDown: onAddOption,
                                }}
                              />
                            </FormControl>
                            <Tooltip
                              title="Add option and hit enter"
                              style={{ margin: "27px 0 0 0" }}
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                            <GridItem>
                              <GridContainer>
                                {optionList.map((item, index) => {
                                  return (
                                    <FilterOption
                                      key={index}
                                      item={item}
                                      removeOption={removeOption}
                                      displayType="select"
                                    />
                                  );
                                })}
                              </GridContainer>
                            </GridItem>
                          </>
                        ) : (
                          ""
                        )}
                        {displayType === "Color" ? (
                          <>
                            <GridItem>
                              <div style={{ margin: "27px 0 0 0" }}>
                                <input
                                  type="color"
                                  value={color}
                                  onChange={(e) => {
                                    handleColorChange(e);
                                  }}
                                  onKeyDown={(e) => {
                                    onAddColor(e);
                                  }}
                                  // onKeyDown={onAddColor}
                                  // onChange={e => setColor(e.target.value)}
                                />{" "}
                                {color}
                                <Tooltip title="Add option and hit double enter">
                                  <IconButton>
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </GridItem>
                            <GridItem>
                              <GridContainer>
                                {colorList.map((item, index) => {
                                  return (
                                    <FilterOption
                                      key={index}
                                      item={item}
                                      removeOption={removeColor}
                                      displayType="color"
                                    />
                                  );
                                })}
                              </GridContainer>
                            </GridItem>
                          </>
                        ) : (
                          ""
                        )}
                        <hr />
                        <GridContainer style={{ justifyContent: "flex-end" }}>
                          <GridItem>
                            {!editFilterModal ? (
                              <Button
                                color="grey"
                                onClick={() => {
                                  resetFilterDefault();
                                  setShowAddFilter(false);
                                }}
                              >
                                Cancel
                              </Button>
                            ) : (
                              <Button
                                color="grey"
                                onClick={() => {
                                  setEditFilterModal(false);
                                }}
                              >
                                Cancel
                              </Button>
                            )}
                          </GridItem>
                          <GridItem>
                            {!editFilterModal ? (
                              <Button color="success" onClick={onAddFilter}>
                                Add
                              </Button>
                            ) : (
                              <Button
                                color="success"
                                onClick={() => onEditFilter(editFilterItem)}
                              >
                                Update
                              </Button>
                            )}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </GridItem>
                  </Card>
                </GridContainer>
              </Modal>
              {!showAddFilter && (
                <GridContainer>
                  <GridItem style={{ marginTop: "2rem" }}>
                    <Button color="success" onClick={addFilterHandler}>
                      Add A Filter
                    </Button>
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
            <GridContainer
              style={{
                justifyContent: "end",
                padding: "1rem",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                <Button color="grey" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
              <div style={{ marginRight: "1rem", position: "relative" }}>
                <Button
                  color="success"
                  onClick={editMode ? saveEditChanges : onSaveCategory}
                >
                  Submit
                </Button>
                {loading && <Loader />}
              </div>
            </GridContainer>
          </Card>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Interceptor(AddOrEditCategory);
