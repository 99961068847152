import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import { ApiCore } from "../../Core";

const url = "cart";
const cart = new ApiCore<any>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
});

cart.search = (name, page, perPage) => {
  return apiProvider.getAll(`${url}/abandoned/search?text=${name}&page=${page}&perPage=${perPage}`);
};
export { cart };
