import { IUser } from "Interfaces/Api/User";
import { apiProvider } from "Services/API/Provider";
import { ApiCore } from "../../Core";

const url = "user";
const user = new ApiCore<IUser>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

user.updateUserRole = (id, model) => {
  return apiProvider.put(`${url}/updateUserRole`, id, model);
};

user.search = (text, page, perPage) => {
  return apiProvider.getAll(`${url}/search?text=${text}&page=${page}&perPage=${perPage}`);
};

user.getMYDetails = () => {
  return apiProvider.getAll(`${url}/me`);
};

export { user };
