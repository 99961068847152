import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import classes from "./Product.module.scss";
import Loader from "components/Loader";

interface SEOComponentProps {
  editMode: boolean;
  onCancel: Function;
  handleSEOProperties: Function;
  productProperties: Record<string, any>;
  handleEditProduct: Function;
  handleOnBlur: Function;
  loading: Boolean;
}

const SEOComponent = ({
  handleSEOProperties,
  productProperties,
  handleOnBlur,
  onCancel,
  editMode,
  handleEditProduct,
  loading,
}: SEOComponentProps) => {
  const [productMetaKeyword, setProductMetaKeyword] = useState([]);
  const [productMetaTitle, setProductMetaTitle] = useState<string>("");
  const [productMetaDescription, setProductMetaDesciption] =
    useState<string>("");

  const onNextPage = () => {
    handleSEOProperties();
  };

  const onBlur = () => {
    const obj = {
      title: productMetaTitle,
      description: productMetaDescription,
      keywords: productMetaKeyword,
    };
    handleOnBlur(obj);
  };
  useEffect(() => {
    if (productProperties) {
      setProductMetaTitle(productProperties?.title);
      setProductMetaDesciption(productProperties?.description);
      setProductMetaKeyword(productProperties?.keywords);
    }
  }, []);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          {/* <CardBody> */}
          <Box style={{ paddingBottom: "3rem" }}>
            <GridContainer style={{ padding: "0.9375rem 20px" }}>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Meta Title"
                  //   id="company-disabled"
                  formControlProps={{
                    className: classes.formControlSeo,

                    // fullWidth: true,
                  }}
                  inputProps={{
                    value: productMetaTitle,
                    onChange: (e) => setProductMetaTitle(e.target.value),
                    onBlur: onBlur,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Meta Description"
                  //   id="company-disabled"
                  formControlProps={{
                    className: classes.formControlSeo,

                    // fullWidth: true,
                  }}
                  inputProps={{
                    value: productMetaDescription,
                    onChange: (e) => setProductMetaDesciption(e.target.value),
                    onBlur: onBlur,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <Autocomplete
                  multiple
                  fullWidth={true}
                  id="tags-outlined"
                  value={productMetaKeyword}
                  onChange={(e, value) => {
                    setProductMetaKeyword(value);
                  }}
                  onBlur={onBlur}
                  options={[]}
                  freeSolo
                  // fullWidth: true,
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Meta Keywords"
                      placeholder="Type And Press Enter"
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
          </Box>
          <GridContainer
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <Button color="gray" onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <Button color="success" onClick={onNextPage}>
                Next
              </Button>
            </div>
            {editMode && (
              <div style={{ marginRight: "1rem", position: "relative" }}>
                <Button color="success" onClick={handleEditProduct}>
                  Submit
                </Button>
                {loading && <Loader />}
                {/* {loading && <div style={{ position: "absolute", top: "30%", right: "5px", opacity: "1" }}>{<CircularProgress size="20px" style={{ color: "#8D6D4D" }} />}</div>} */}
              </div>
            )}
          </GridContainer>
          {/* </CardBody> */}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Interceptor(SEOComponent);
