import React, { useEffect, useState } from "react";
import { products } from "Services/API/IndividualApis/Product";
import { Categories as categoriesApi } from "../../Services/API/IndividualApis/Category";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonTable from "components/CommonTable/CommonTable";
import { usePagination } from "hooks/tablePaginationHook";
import AddorEditProducts from "./AddorEditProducts";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "components/CustomDeleteModal";
import { IProduct } from "Interfaces/Api/Product";
import { ICategory } from "Interfaces/Api/Category";
import { IPagination } from "Interfaces/Api/Pagination";
import { filterCols } from "Services/Helper";

const Index = () => {
  const [allProduct, setAllProduct] = useState<IProduct[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [searchText, setSearchText] = useState<string>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showPagination, setShowPagination] = useState<IPagination>();
  const [addMode, setAddMode] = useState<boolean>(false);
  const [page, handleChangePage] = usePagination();
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [showAddButton, setShowAddButton] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [extraQueryParam, setExtraQueryParam] = useState("");

  const { state } = useLocation() as any;
  useEffect(() => {
    if (searchText != undefined) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, rowsPerPage, page]);

  let navigate = useNavigate();

  const handleEditProduct = async (item) => {
    navigate(`/admin/products/${item._id}`);
    await fetchAllProduct();
    setEditMode(true);
  };

  const rowsData = allProduct?.map((item) => {
    return {
      name: item?.name,
      availableQuantity: item,
      price: item?.price,
      inStock: item,
      categories: item?.categories,
      image: item.image?.url,
      id: item?._id,
      actions: item,
    };
  });
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleExportCsv = async () => {
    // window.open("http://localhost:8000/api/v1/product/csvFile");
    window.open(`${BASE_URL}/product/csvFile`);
  };
  const cols = React.useMemo(
    () => [
      {
        field: "image",
        headerName: "Image",
        minWidth: 150,
        // width: 200,
        renderCell: (params) => (
          <img src={params.value} style={{ width: "90px" }} />
        ),
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 220,
        filterable: true,
        renderCell: (params) => (
          <div style={{ whiteSpace: "pre-wrap" }}>{params.row.name}</div>
        ),
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "availableQuantity",
        headerName: "Quantity",
        width: 160,
        filterable: true,
        renderCell: (params) => (
          <div>{params.row.availableQuantity.availableQuantity}</div>
        ),
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "Greater Than", value: "$gt" },
          { label: "Greater Than Equal", value: "$gte" },
          { label: "Less Than", value: "$lt" },
          { label: "Less Than Equal", value: "$lte" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "inStock",
        headerName: "Stock",
        minWidth: 150,
        filterable: true,
        isValueSelectable: true,
        options: [{ name: "true" }, { name: "false" }],
        renderCell: (params) => (
          <div>
            {params.row.inStock.inStock ? (
              "IN STOCK"
            ) : (
              <span style={{ color: "red" }}>OUT OF STOCK</span>
            )}
          </div>
        ),
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "price",
        headerName: "Price",
        // width: 160,
        filterable: true,
        renderCell: (params) => <div>&#8377; {params.value}</div>,
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "Greater Than", value: "$gt" },
          { label: "Greater Than Equal", value: "$gte" },
          { label: "Less Than", value: "$lt" },
          { label: "Less Than Equal", value: "$lte" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "categories",
        headerName: "Category",
        minWidth: 160,
        filterable: true,
        isValueSelectable: true,
        nameKey: "category",
        options: allCategories,
        renderCell: (params) => (
          <div style={{ whiteSpace: "break-spaces" }}>
            {params.row.categories}
          </div>
        ),
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "actions",
        headerName: "Actions",
        // width: 200,
        renderCell: (params) => {
          return (
            <div>
              {state?.access.write && (
                <EditIcon
                  onClick={() => handleEditProduct(params.value)}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              )}
              {state?.access.delete && (
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setItemToBeDeleted(params.value);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [allCategories, state]
  );
  filterCols(state, cols);
  useEffect(() => {
    fetchAllCategories();
  }, []);

  useEffect(() => {
    fetchAllProduct();
  }, [extraQueryParam, page, rowsPerPage]);

  const fetchAllProduct = async () => {
    setLoading(true);
    try {
      const response = await products.getAll(
        page,
        rowsPerPage,
        extraQueryParam
      );
      setAllProduct(response.data);
      setLoading(false);
      setShowPagination(response.pagination);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const removeItems = async () => {
    try {
      const response = await products.remove(itemToBeDeleted._id);
      setShowDeleteModal(false);
      setItemToBeDeleted(null);
      await fetchAllProduct();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async () => {
    if (searchText == "") {
      await fetchAllProduct();
    } else {
      try {
        const response = await products.search(searchText, page, rowsPerPage);
        setAllProduct(response.data);
        setShowPagination(response.pagination);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const addItems = () => {
    setAddMode((prev) => !prev);
  };

  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => {
    if (clearFilter) {
      setExtraQueryParam("");
      return;
    }
    const filterArr = [];
    filterRows.forEach((element) => {
      let value = element.value;
      if (value instanceof Array) {
        value = value.filter((item) => item !== "");
      }
      const obj = {
        [element.name]: {
          [element.operator]: value,
        },
      };
      filterArr.push(obj);
    });
    const filterObj = {
      [linkedOperator]: filterArr,
    };
    let queryParam = `&filter=${JSON.stringify(filterObj)}`;

    setExtraQueryParam(queryParam);
  };

  const fetchAllCategories = async () => {
    try {
      const response = await categoriesApi.getAll(1, 1000);
      setAllCategories(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems()}
          itemName={itemToBeDeleted.name}
          onSave={undefined}
        />
      )}
      {editMode || addMode ? (
        <>
          <AddorEditProducts />
        </>
      ) : (
        <CommonTable
          linkUrl="/admin/products/add"
          Title="Products"
          button="Add Products"
          handleChangePage={handleChangePage}
          addItems={addItems}
          totalRows={showPagination?.totalCount}
          searchPlaceHolder="Search by name or category"
          handleSearchTextChanged={(value) => setSearchText(value)}
          searchText={searchText}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rows={rowsData}
          columns={cols}
          handleFilter={handleFilter}
          showAddButton={state?.access.write}
          handleExportCsv={handleExportCsv}
          isExportCSV={true}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Index;
