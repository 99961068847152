import { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import { products } from "Services/API/IndividualApis/Product";
import {
  Autocomplete,
  TextField,
  createFilterOptions,
  Box,
} from "@mui/material";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import { Categories } from "Services/API/IndividualApis/Category";
import { ICategory } from "Interfaces/Api/Category";
import { IProduct } from "Interfaces/Api/Product";
import { ICoupon } from "Interfaces/Api/Coupons";
import { user } from "Services/API/IndividualApis/Users";
import { IUser } from "Interfaces/Api/User";

const filter = createFilterOptions();
interface UsageRestrictionProps {
  couponProperties: ICoupon;
  nextPageHandler: Function;
  handleOnBlur: Function;
  disabled: Boolean;
}

const UsageRestriction = ({
  nextPageHandler,
  couponProperties,
  handleOnBlur,
  disabled,
}: UsageRestrictionProps) => {
  const [minimumSpend, setMinimumSpend] = useState<any>("");
  const [allProducts, setAllProduct] = useState<IProduct[]>([]);
  const [
    allowedProductsSearchTextChanged,
    setSelectedAllowedProductSearchTextChanged,
  ] = useState<any>([]);
  const [excludeProductSearchTextChanged, setExcludeProductSearchTextChanged] =
    useState<any>([]);
  const [
    allowedCategorySearchTextChanged,
    setAllowedCategorySearchTextChanged,
  ] = useState<any>([]);
  const [
    excludeCategorySearchTextChanged,
    setExcludeCategorySearchTextChanged,
  ] = useState([]);
  const [
    allowedPhoneNoSearchTextCharged,
    setAllowedPhoneNumberSearchTextChanged,
  ] = useState<any>("");

  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [selectedAllowedProducts, setSelectedAllowedProducts] = useState([]);
  const [selectedExcludeProduct, setSelectedExcludeProduct] = useState([]);
  const [selectedAllowedCategory, setSelectedAllowedCategory] = useState([]);
  const [selectedExcludeCategory, setSelectedExcludeCategory] = useState([]);
  const [allowedPhoneNo, setAllowedPhoneNo] = useState<any>([]);
  const [allUsers, setAllUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (couponProperties) {
      setMinimumSpend(couponProperties?.minimumSpend);
      if (
        couponProperties?.allowedProducts &&
        couponProperties?.allowedProducts.length > 0
      ) {
        setSelectedAllowedProducts(couponProperties?.allowedProducts);
      }
      if (
        couponProperties?.excludedProducts &&
        couponProperties?.excludedProducts.length > 0
      ) {
        setSelectedExcludeProduct(couponProperties?.excludedProducts);
      }
      if (
        couponProperties?.allowedCategories &&
        couponProperties?.allowedCategories.length > 0
      ) {
        setSelectedAllowedCategory(couponProperties?.allowedCategories);
      }
      if (
        couponProperties?.excludedCategories &&
        couponProperties?.excludedCategories.length > 0
      ) {
        setSelectedExcludeCategory(couponProperties?.excludedCategories);
      }
      if (
        couponProperties?.allowedPhoneNumbers &&
        couponProperties?.allowedPhoneNumbers.length > 0
      ) {
        const newAllowedPhoeNumber = [];

        for (let key of couponProperties?.allowedPhoneNumbers) {
          let obj = { phoneNumber: key };
          newAllowedPhoeNumber.push(obj);
        }
        setAllowedPhoneNo(newAllowedPhoeNumber);
      }
    }
  }, [couponProperties, allUsers]);

  useEffect(() => {
    fetchAllProducts();
    fetchAllCategories();
    fetchAllUsers();
    if (allowedProductsSearchTextChanged == " ") {
      fetchAllProducts();
    }
  }, []);

  const fetchAllProducts = async () => {
    try {
      const response = await products.getAll(1, 1000);
      let newProducts = [];
      response.data.map((item) => {
        let obj = {
          _id: item._id,
          name: item.name,
        };
        newProducts.push(obj);
      });
      setAllProduct(newProducts);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await user.search(
        allowedPhoneNoSearchTextCharged,
        1,
        10
      );
      // const response = await user.getAll();
      setAllUsers(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      (allowedProductsSearchTextChanged &&
        allowedProductsSearchTextChanged.length > 0) ||
      (excludeProductSearchTextChanged &&
        excludeProductSearchTextChanged.length > 0)
    ) {
      const timeout = setTimeout(handleProductSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  const handleProductSearch = async () => {
    if (!allowedProductsSearchTextChanged) {
    } else {
      try {
        const response = await products.search(
          allowedProductsSearchTextChanged
        );
        await setAllProduct(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    if (!excludeProductSearchTextChanged) {
    } else {
      try {
        const response = await products.search(excludeProductSearchTextChanged);
        await setAllProduct(response.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await Categories.getAll(1, 1000);
      let newCategories = [];
      response.data.map((item) => {
        let obj = {
          // _id: item._id,
          name: item.category,
        };
        newCategories.push(item.category);
      });
      setAllCategories(newCategories);
      // setExcludeAllCategoryProduct(response.data)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      (excludeCategorySearchTextChanged &&
        excludeCategorySearchTextChanged.length > 0) ||
      (allowedCategorySearchTextChanged &&
        allowedCategorySearchTextChanged.length > 0)
    ) {
      const timeout = setTimeout(handleCategoriesSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  const handleCategoriesSearch = async () => {
    if (!excludeCategorySearchTextChanged) {
    } else {
      try {
        const response = await Categories.search(
          excludeCategorySearchTextChanged
        );
        // setExcludeAllCategoryProduct(response.data);
        setAllCategories(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    if (!allowedCategorySearchTextChanged) {
    } else {
      try {
        const response = await Categories.search(
          allowedCategorySearchTextChanged
        );
        setAllCategories(response.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  // useEffect(() => {
  //   if (
  //     allowedPhoneNoSearchTextCharged &&
  //     allowedPhoneNoSearchTextCharged.length > 0
  //   ) {
  //     const timeout = setTimeout(handlePhoneNumberSearch, 300);
  //     return () => {
  //       clearTimeout(timeout);
  //     };
  //   }
  // }, []);
  // const handlePhoneNumberSearch = async () => {
  //   try {
  //     const response = await user.search(allowedPhoneNoSearchTextCharged);
  //     setAllUsers(response.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleSelectedAllowedProductsChange = (values) => {
    setSelectedAllowedProducts(values);
  };
  const handleSelectedAllowedProductsInputChange = (values) => {
    setSelectedAllowedProductSearchTextChanged(values);
  };

  const handleExcludeproductsChange = (values) => {
    setSelectedExcludeProduct(values);
  };

  const handleExcludeProductInputChange = (values) => {
    setExcludeProductSearchTextChanged(values);
  };

  const handleAllowedCategoryInputChange = (values) => {
    setAllowedCategorySearchTextChanged(values);
  };

  const handleAllowedCategoryChange = (values) => {
    setSelectedAllowedCategory(values);
  };

  const handleExcludeCategoryInputChange = (values) => {
    setExcludeCategorySearchTextChanged(values);
  };

  const handleAllowedPhoneNumberCategoryChange = (values) => {
    const NewValues = values;
    const newSelectedPhoneNo = [];
    const slectedNumber = NewValues.filter((val) => {
      newSelectedPhoneNo.push(val.phoneNumber);
    });
    setAllowedPhoneNo(values);
  };

  const handleAllowedPhoneNumberInputChange = (values) => {
    setAllowedPhoneNumberSearchTextChanged(values);
  };

  const handleExcludeCategoryChange = (values) => {
    setSelectedExcludeCategory(values);
  };

  const handleProduct = () => {
    const newAllowedProductsId = [];
    const newAllowedProducts = [];
    const newExcludeProductId = [];
    const newExcludeProduct = [];
    const newAllowedPhoneNumber = [];

    for (let key of selectedAllowedProducts) {
      newAllowedProductsId.push(key.id || key._id);
      let obj = {
        name: key.name,
        _id: key._id,
      };
      newAllowedProducts.push(obj);
    }
    //EXCLUDE PRODUCTS

    for (let key of selectedExcludeProduct) {
      newExcludeProductId.push(key.id || key._id);
      let obj = {
        name: key.name,
        _id: key._id,
      };
      newExcludeProduct.push(obj);
    }

    for (let key of allowedPhoneNo) {
      newAllowedPhoneNumber.push(key.phoneNumber);
    }

    let obj = {
      minimumSpend: minimumSpend,
      allowedProducts: newAllowedProducts,
      allowedProductsIds: newAllowedProductsId,
      excludedProducts: newExcludeProduct,
      excludeProductIds: newExcludeProductId,
      allowedCategories: selectedAllowedCategory,
      excludedCategories: selectedExcludeCategory,
      allowedPhoneNumbers: newAllowedPhoneNumber,
    };
    return obj;
  };

  const handleBlur = () => {
    const newObject = handleProduct();
    handleOnBlur(newObject);
  };
  const onNextPage = () => {
    const newObject = handleProduct();
    nextPageHandler(newObject);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Minimum spend"
            //   id="company-disabled"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: disabled,
              // type: "Number",
              value: minimumSpend,
              onChange: (e) => {
                if (e.target.value && !Number(e.target.value)) return;
                setMinimumSpend(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            id="tags-outlined"
            // options={[]}
            options={allProducts.map((option, index) => option)}
            disabled={
              selectedAllowedCategory.length > 0 ||
              couponProperties?.allowedCategories?.length > 0 ||
              selectedExcludeProduct.length > 0 ||
              couponProperties?.excludedProducts?.length > 0 ||
              couponProperties?.excludedCategories?.length > 0 ||
              selectedExcludeProduct.length > 0
                ? true
                : disabled
                ? true
                : false
            }
            getOptionLabel={(option) => option.name}
            onInputChange={(event, newValue) => {
              handleSelectedAllowedProductsInputChange(newValue);
            }}
            onChange={(event, newValue) => {
              handleSelectedAllowedProductsChange(newValue);
            }}
            onBlur={() => {
              handleBlur();
            }}
            value={selectedAllowedProducts}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                onBlur={() => {
                  handleBlur();
                }}
                {...params}
                label="Allowed products"
              />
            )}
          />
        </GridItem>
        {/* </GridContainer>
      <GridContainer> */}
        <GridItem xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            id="tags-outlined"
            disabled={
              selectedAllowedCategory.length > 0 ||
              couponProperties?.allowedCategories?.length > 0 ||
              selectedAllowedProducts.length > 0 ||
              couponProperties?.allowedProducts?.length > 0 ||
              couponProperties?.excludedCategories?.length > 0 ||
              selectedExcludeCategory.length > 0
                ? true
                : disabled
                ? true
                : false
            }
            options={allProducts.map((option, index) => option)}
            getOptionLabel={(option) => option.name}
            onInputChange={(event, newValue) => {
              handleExcludeProductInputChange(newValue);
            }}
            onChange={(event, newValue) => {
              handleExcludeproductsChange(newValue);
            }}
            onBlur={() => {
              handleBlur();
            }}
            value={selectedExcludeProduct}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Exclude products" />
            )}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            id="tags-outlined"
            disabled={
              selectedAllowedProducts.length > 0 ||
              couponProperties?.allowedProducts?.length > 0 ||
              selectedExcludeCategory.length > 0 ||
              couponProperties?.excludedCategories?.length > 0 ||
              selectedExcludeProduct.length > 0 ||
              couponProperties?.excludedProducts?.length > 0
                ? true
                : disabled
                ? true
                : false
            }
            options={allCategories.map((option, index) => option)}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => {
              handleAllowedCategoryInputChange(newValue);
            }}
            onChange={(event, newValue) => {
              handleAllowedCategoryChange(newValue);
            }}
            onBlur={() => {
              handleBlur();
            }}
            value={selectedAllowedCategory}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Allowed category" />
            )}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            id="tags-outlined"
            disabled={
              selectedAllowedProducts.length > 0 ||
              couponProperties?.allowedProducts?.length > 0 ||
              selectedAllowedCategory.length > 0 ||
              couponProperties?.allowedCategories?.length > 0 ||
              selectedExcludeProduct.length > 0 ||
              couponProperties?.excludedProducts?.length > 0
                ? true
                : disabled
                ? true
                : false
            }
            options={allCategories.map((option, index) => option)}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => {
              handleExcludeCategoryInputChange(newValue);
            }}
            onChange={(event, newValue) => {
              handleExcludeCategoryChange(newValue);
            }}
            onBlur={() => {
              handleBlur();
            }}
            value={selectedExcludeCategory}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Exclude category" />
            )}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <Autocomplete
            id="tags-outlined"
            multiple
            // disabled={
            //   couponProperties?.allowedCategories?.length > 0
            //     ? true
            //     : disabled
            //     ? true
            //     : false
            // }
            autoHighlight
            options={allUsers.map((option, index) => option)}
            getOptionLabel={(option) => option.phoneNumber}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <div>
                  {option.name} {option.phoneNumber}
                </div>
              </Box>
            )}
            onInputChange={(event, newInputValue) => {
              newInputValue = newInputValue.slice(0, 10);
              if (newInputValue && !Number(newInputValue)) return;
              handleAllowedPhoneNumberInputChange(newInputValue);
            }}
            onChange={(event, newValue) => {
              handleAllowedPhoneNumberCategoryChange(newValue);
            }}
            onBlur={() => {
              handleBlur();
            }}
            value={allowedPhoneNo}
            inputValue={allowedPhoneNoSearchTextCharged}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                params.inputValue = params.inputValue.slice(0, 10);
                if (params.inputValue && !Number(params.inputValue)) return;
                let obj = {
                  phoneNumber: params.inputValue,
                };
                filtered.push(obj);
              }
              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Allowed PhoneNo"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          justifyContent: "end",
          padding: "1rem",
        }}
      >
        {/* <div style={{ marginRight: "1rem" }}>
          <Button color="gray" >
            Cancel
          </Button>
        </div> */}
        <div style={{ marginRight: "1rem" }}>
          <Button color="success" onClick={onNextPage}>
            Next
          </Button>
        </div>
      </GridContainer>
    </>
  );
};

export default Interceptor(UsageRestriction);
