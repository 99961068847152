import Box from "@mui/material/Box";
import { Autocomplete, Chip, TextField } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import { useEffect, useState } from "react";
import { apiCms } from "Services/API/IndividualApis/Cms";
import Loader from "components/Loader";

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "background",
      "color",
      "font",
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
export const FooterNotification = ({ allNotification, getData }: any) => {
  const [footerNotification, setFooterNotification] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const saveHeaderNotification = async () => {
    setLoading(true);
    try {
      let obj = {
        footerNotification: footerNotification,
      };
      const response = await apiCms.AddFooterNotification(obj);
      if (response) {
        setLoading(false);
      }
      getData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (allNotification) {
      setFooterNotification(allNotification);
    }
  }, []);

  return (
    <Box>
      <Box
        sx={{
          paddingBottom: "3rem",
        }}
      >
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Autocomplete
              multiple
              fullWidth={true}
              id="tags-outlined"
              value={footerNotification}
              onChange={(e, value) => {
                setFooterNotification(value);
              }}
              options={[]}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Footer Notification"
                  placeholder="Type And Press Enter"
                />
              )}
            />
          </GridItem>
        </GridContainer>
      </Box>
      <GridContainer style={{ justifyContent: "flex-end" }}>
        <GridItem>
          <div style={{ marginRight: "1rem", position: "relative" }}>
            <Button color="success" onClick={() => saveHeaderNotification()}>
              Save
            </Button>
            {loading && <Loader />}
          </div>
        </GridItem>
      </GridContainer>
    </Box>
  );
};
