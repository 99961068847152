import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GridContainer from "components/Grid/GridContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import makeStyles from "@mui/styles/makeStyles";
import { Alert, Box, FormHelperText, Icon, Modal } from "@mui/material";
import classesss from "./uploadImage.module.scss";
import { green } from "@mui/material/colors";
import DeleteModal from "components/CustomDeleteModal";
import ImageModal from "views/Products/CommonComponents/ImageModal";
import { v4 as uuidv4 } from "uuid";

export enum fileSize {
  IMAGE_SIZE = 500,
  VIDEO_SIZE = 1024,
}
export enum acceptFileType {
  BOTH = "image/*,video/*",
  ONLY_IMAGE = "image/*",
  ONLY_VIDEO = "video/*",
}

const UploadImage = ({
  id,
  label,
  imageSize = fileSize.IMAGE_SIZE,
  videoSize = fileSize.VIDEO_SIZE,
  useFileType = acceptFileType.BOTH,
  handleFile = (file) => console.log(),
  renderSelf = true,
  multiple = false,
  fileUpload,
  addImageOrImages,
  selectedImage,
  editMode = false,
  deleteImage = (file) => console.log(file),
  handleUpdateImages,
  imgPrevBg = "",
  isEditImageModalOpen = false,
  handleAddNewProductMedia = (file) => console.log(file),
}: {
  id: string;
  label: string;
  imageSize?: number;
  videoSize?: number;
  useFileType?: string;
  handleFile?: Function;
  renderSelf?: boolean;
  multiple?: boolean;
  fileUpload?: any;
  addImageOrImages?: Function;
  selectedImage: any;
  editMode: boolean;
  deleteImage?: Function;
  handleUpdateImages?: Function;
  imgPrevBg?: string;
  isEditImageModalOpen?: boolean;
  handleAddNewProductMedia?: Function;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<string>("");
  const [deletedItemName, setDeletedItemName] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [showImage, setShowImageModal] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<any>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fileToBeUpdated, setFileToBeUpdated] = useState<any>("");
  const [fileTypeError, setFileTypeError] = useState<string>("");
  const errorImageMessage = `Image size should be less than  ${imageSize} KB`;
  const errorVideoSizeMessage = `Video size should be less than  ${videoSize / 1024
    } MB`;

  const useStyles = makeStyles((theme) => ({
    formControl: {
      paddingBottom: "10px",
      margin: "27px 0 0 0",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    input: {
      display: "none",
    },
    image: {
      border: "1px solid #e6e8eb",
      width: "150px",
      height: "150px",
    },
    imagePreview: {
      width: "150px",
      height: "150px",
      background: imgPrevBg || "white",
    },
  }));

  const classes = useStyles();
  const onHide = () => { };
  const checkFileSize = (file) => {
    const max_size = file[0].size;
    const file_size = Math.round(max_size / 1024);
    if (file[0].type === "video/mp4" || file[0].type === "video/webm") {
      if (file_size <= videoSize) {
        return true;
      } else {
        return false;
      }
    } else {
      if (file_size <= imageSize) {
        return true;
      } else {
        return false;
      }
    }
  };

  const onDrop = async (acceptedFiles) => {
    const fileSize = checkFileSize(acceptedFiles);
    if (fileSize) {
      await addImageOrImages(acceptedFiles);
    } else {
      const fileWithError = {
        ...acceptedFiles,
        fileLimitExceed: true,
      };
      await addImageOrImages(fileWithError);
    }
  };

  const onMultipleDrop = async (files) => {
    if (files) {
      var filesArray = [].slice.call(files);
      let tempFileArray = [];

      for (let file of filesArray) {
        const max_size = file.size;
        const file_size = Math.round(max_size / 1024);

        if (file.type === "video/mp4" || file.type === "video/webm") {
          if (file_size <= videoSize) {
            tempFileArray.push({ file });
          } else {
            tempFileArray.push({ file, fileLimitExceed: true });
          }
        } else if (
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/svg" ||
          file.type === "image/jpeg"
        ) {
          if (file_size <= imageSize) {
            tempFileArray.push({ file });
          } else {
            tempFileArray.push({ file, fileLimitExceed: true });
          }
        } else {
          setFileTypeError(file.type);
        }
      }
      const allfiles = await Promise.all(tempFileArray);
      if (editMode) {
        await handleAddNewProductMedia(allfiles);
      } else await addImageOrImages(allfiles);
    }
  };

  const onAddNewProductMedia = async (files) => {
    const fileSize = checkFileSize(files);
    if (fileSize) {
      await handleAddNewProductMedia(files);
    } else {
      const fileWithError = {
        ...files,
        fileLimitExceed: true,
      };

      await handleAddNewProductMedia(fileWithError);
    }
  };
  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const isImage = (fileType) => {
    if (!fileType) return false;
    if (!fileType.match(/.(jpg|jpeg|png|gif|svg)$/i)) return false;
    else return true;
  };

  const removeImage = async (itemToBeDeleted) => {
    await deleteImage(itemToBeDeleted);
    setShowDeleteModal(false);
  };

  const editPrimaryImage = async (file) => {
    const fileSize = checkFileSize(file);
    if (fileSize) {
      await handleFile(file);
    } else {
      const fileWithError = {
        ...file,
        fileLimitExceed: true,
      };
      await handleFile(fileWithError);
    }
  };

  const toBase64 = async (fileData) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const editMediaImage = async (file) => {
    setOpenModal(true);
    try {
      const fileName = file[0].name;
      const result = (await toBase64(file[0])) as string;
      const isFileSizeValid = checkFileSize(file);
      let obj = {
        _id: uuidv4(),
        file: result,
        fileName: fileName,
        fileLimitExceed: isFileSizeValid ? false : true,
      };
      if (renderSelf) {
        setSelectedFile(obj);
      } else {
        await handleUpdateImages(obj, fileToBeUpdated);
      }
      // handleEditProduct(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onCloseHandler = () => {
    setFileTypeError("");
  };
  return (
    <>
      {fileTypeError && (
        <Alert
          onClose={onCloseHandler}
          variant="filled"
          severity="error"
          style={{
            position: "fixed",
            top: "5px",
            right: "5px",
            zIndex: "10000",
            background: "red",
            textTransform: "capitalize",
          }}
        >{`File With Extension "${fileTypeError}" Is Not Supported.`}</Alert>
      )}
      <span className="mr-2">{label}</span>
      <div>
        {openModal && renderSelf && (
          <ImageModal
            fileType={useFileType}
            imageSize={imageSize}
            videoSize={videoSize}
            toggleModal={openModal}
            handleMediaUpload={handleFile}
            closeModal={() => setOpenModal(false)}
            mediaFile={selectedFile}
            previousFile={fileToBeUpdated}
          />
        )}
        {/* select multiple image */}
        {multiple ? (
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
            {selectedImage.map((photo, index) => {
              return (
                <>
                  <div className={classesss.container}>
                    {isImage(photo.url || photo.fileName) ? (
                      <div>
                        <img
                          style={{
                            border:
                              photo.fileLimitExceed === true
                                ? "1px solid red"
                                : "",
                          }}
                          src={photo.url || photo.file}
                          className={classes.imagePreview}
                        />{" "}
                        {photo.fileLimitExceed === true && (
                          <FormHelperText style={{ color: "red" }}>
                            {errorImageMessage}
                          </FormHelperText>
                        )}
                      </div>
                    ) : (
                      <Box>
                        <video
                          src={photo.url || photo.file}
                          className={classes.imagePreview}
                          controls
                          style={{
                            border: photo.fileLimitExceed
                              ? "1px solid red"
                              : "",
                          }}
                          autoPlay
                          muted
                        />
                        {photo.fileLimitExceed === true && (
                          <FormHelperText style={{ color: "red" }}>
                            {errorVideoSizeMessage}
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                    <div w-100 className={classesss.overlay}>
                      <input
                        accept="image/*,video/*"
                        className={classes.input}
                        id={id}
                        onChange={(e) => editMediaImage(e.target.files)}
                        type="file"
                      />
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        {!isEditImageModalOpen ? (
                          <VisibilityIcon
                            style={{ color: "#fff", margin: "0 5px" }}
                            onClick={() => {
                              setShowImageModal(true);
                              setOpenModal(false);
                              setModalImage(photo);
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <label htmlFor={id}>
                          <EditIcon
                            style={{ color: "#fff", margin: "0 5px" }}
                            onClick={() => {
                              // setOpenModal(true);
                              if (renderSelf)
                                setFileToBeUpdated(photo || index);
                            }}
                          />
                        </label>

                        <DeleteIcon
                          style={{ color: "#fff", margin: "0 5px" }}
                          onClick={() => {
                            setShowDeleteModal(true);
                            setItemToBeDeleted(photo || index);
                            setDeletedItemName(
                              photo?.url?.substring(
                                photo.url.indexOf("_") + 1
                              ) || photo.fileName
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            <>
              <label htmlFor={`${id}/media`} style={{ paddingTop: "0px" }}>
                <div className={classesss.addIcon}>
                  <Icon sx={{ color: green[500] }}>+</Icon>
                </div>
              </label>
            </>
          </div>
        ) : (
          // primary image rendering and update image
          <>
            {" "}
            <div
              style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
            >
              {selectedImage != "" ? (
                <div className={classesss.container}>
                  {isImage(
                    selectedImage.fileName ||
                    selectedImage?.file ||
                    selectedImage?.url ||
                    selectedImage
                  ) ? (
                    <>
                      <img
                        style={{
                          border: selectedImage.fileLimitExceed
                            ? "1px solid red"
                            : "",
                        }}
                        src={
                          selectedImage?.file ||
                          selectedImage?.url ||
                          selectedImage
                        }
                        className={classes.imagePreview}
                      />
                      {selectedImage.fileLimitExceed === true && (
                        <FormHelperText style={{ color: "red" }}>
                          {errorImageMessage}
                        </FormHelperText>
                      )}{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <video
                        src={selectedImage.file || selectedImage.url}
                        className={classes.imagePreview}
                        controls
                        autoPlay
                        style={{
                          border:
                            selectedImage.fileLimitExceed === true
                              ? "1px solid red"
                              : "",
                        }}
                      />
                      {selectedImage.fileLimitExceed === true && (
                        <FormHelperText style={{ color: "red" }}>
                          {errorVideoSizeMessage}
                        </FormHelperText>
                      )}
                    </>
                  )}
                  <div w-100 className={classesss.overlay}>
                    {/* update primary image */}
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      {!isEditImageModalOpen ? (
                        <VisibilityIcon
                          style={{ color: "#fff", margin: "0 5px" }}
                          onClick={() => {
                            setOpenModal(false);
                            setShowImageModal(true);
                            setModalImage(selectedImage);
                          }}
                        />
                      ) : (
                        ""
                      )}

                      <label
                        htmlFor={`${id}/primary`}
                        style={{ paddingTop: "0px" }}
                      >
                        <EditIcon
                          style={{ color: "#fff", margin: "0 5px" }}
                          onClick={() => {
                            editMode
                              ? setFileToBeUpdated(selectedImage)
                              : setFileToBeUpdated("");
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {!editMode && selectedImage == "" ? (
                <>
                  <label
                    htmlFor={`${id}/primary`}
                    style={{ paddingTop: "0px" }}
                  >
                    <div className={classesss.addIcon}>
                      <Icon sx={{ color: green[500] }}>+</Icon>
                    </div>
                  </label>
                </>
              ) : (
                "  "
              )}
            </div>
          </>
        )}

        {/* ===================== */}
        {multiple ? (
          <input
            accept="image/*,video/*"
            className={classes.input}
            id={`${id}/media`}
            onChange={(e) => {
              /*  editMode
                ? onAddNewProductMedia(e.target.files)
                :  */ onMultipleDrop(e.target.files);
            }}
            // onChange={(e) => onMultipleDrop(e.target.files)}
            type="file"
            multiple
          />
        ) : (
          <input
            accept={useFileType}
            className={classes.input}
            id={`${id}/primary`}
            onClick={handleClick}
            onChange={(e) => {
              editMediaImage(e.target.files);
            }}
            // onChange={(e) => onDrop(e.target.files)}
            type="file"
          />
        )}

        {/* delete media image */}
        {showDeleteModal ? (
          <DeleteModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            deleteItem={() => removeImage(itemToBeDeleted)}
            itemName={deletedItemName}
          />
        ) : (
          ""
        )}
      </div>
      {/* view image modal */}
      {showImage ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Modal
            open={showImage}
            onClose={onHide}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // maxHeight: "50vh",
            }}
          >
            <GridContainer
              sm={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  maxHeight: "90vh",
                  background: "#fff",
                }}
              >
                {editMode ? (
                  <>
                    {isImage(modalImage.url || modalImage) ? (
                      <>
                        <img
                          src={modalImage.url || modalImage}
                          style={{
                            border:
                              modalImage.fileLimitExceed === true
                                ? "1px solid red"
                                : "",
                          }}
                        />{" "}
                        {modalImage.fileLimitExceed === true && (
                          <FormHelperText style={{ color: "red" }}>
                            {errorImageMessage}
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <>
                        <video
                          src={modalImage.file || modalImage}
                          className={classes.imagePreview}
                          controls
                          autoPlay
                          style={{
                            border:
                              modalImage.fileLimitExceed === true
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {modalImage.fileLimitExceed === true && (
                          <FormHelperText style={{ color: "red" }}>
                            {errorVideoSizeMessage}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isImage(modalImage.fileName || modalImage.file) ? (
                      <>
                        <img
                          src={modalImage.file}
                          style={{
                            border:
                              modalImage.fileLimitExceed === true
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {modalImage.fileLimitExceed === true && (
                          <FormHelperText style={{ color: "red" }}>
                            {errorImageMessage}
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <>
                        <video
                          style={{
                            border:
                              modalImage.fileLimitExceed === true
                                ? "1px solid red"
                                : "",
                          }}
                          src={modalImage.file}
                          className={classes.imagePreview}
                          controls
                          autoPlay
                        />
                        {modalImage.fileLimitExceed === true && (
                          <FormHelperText style={{ color: "red" }}>
                            {errorVideoSizeMessage}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  color: "white",
                  right: "0",
                  top: "0",
                  padding: "1rem",
                }}
              >
                <CancelIcon onClick={() => setShowImageModal(false)} />
              </div>
            </GridContainer>
          </Modal>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default UploadImage;
