import { IProduct } from "./Product";

export enum reviewStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
export interface IReview {
  _id: string;
  createdAt: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  product: Partial<IProduct>;
  title: string;
  message: string;
  rating: number;
  status?: reviewStatus;
}
export interface IReviewPutBody {
  _id: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  product: string;
  message?: string;
  rating: number;
  status: reviewStatus;
}
