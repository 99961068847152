import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import { FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import UploadImage, {
  acceptFileType,
} from "components/CustomUpload/UploadImage";
import Button from "components/CustomButtons/Button";
import { apiBanner } from "Services/API/IndividualApis/Cms/Banner";
import { useNavigate, useParams } from "react-router-dom";

export enum imageSize {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

enum modeType {
  ADD = "ADD",
  EDIT = "EDIT",
}

const initialState = {
  _id: "",
  name: "",
  media: {
    mobile: null,
    desktop: null,
  },
  url: "",
};

const AddOrEditModal = () => {
  const [bannerData, setBannerData] = useState<any>(initialState);
  const [mode, setMode] = useState("ADD");
  const [requiredError, setRequiredError] = useState<{
    mobile: boolean;
    desktop: boolean;
    name: boolean;
    url: boolean;
  }>({ mobile: false, desktop: false, name: false, url: false });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!id && window.location.pathname === "/admin/dynamic-content/add") {
      setMode(modeType.ADD);
      setBannerData({
        _id: "",
        name: "",
        media: {
          mobile: null,
          desktop: null,
        },
        url: "",
      });
    } else {
      getBannerData();
      setMode(modeType.EDIT);
    }
  }, []);
  const handleFileUpload = (payload: any) => async (acceptedFiles) => {
    try {
      let newBannerData = { ...bannerData };
      if (!acceptedFiles.fileLimitExceed) {
        newBannerData.media[payload] = acceptedFiles;
        setBannerData(newBannerData);
      } else {
        newBannerData.media[payload] = null;
        setBannerData(newBannerData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (e) => {
    let newData = { ...bannerData };
    newData[e.target.name] = e.target.value;
    setBannerData(newData);
  };

  const getBannerData = async () => {
    try {
      const response = await apiBanner.getSingle(id);
      setBannerData(response);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSave = async () => {
    try {
      let response;
      if (mode === modeType.ADD) {
        if (
          bannerData.name &&
          bannerData.url &&
          bannerData.media.desktop &&
          bannerData.media.mobile
        ) {
          response = await apiBanner.post(bannerData);
          if (response) {
            navigate(-1);
          }
        } else {
          setRequiredError({
            mobile: bannerData.media.mobile === null,
            desktop: bannerData.media.desktop === null,
            name: !bannerData.name,
            url: !bannerData.url,
          });
        }
      } else {
        if (
          bannerData.name &&
          bannerData.url &&
          bannerData.media.desktop &&
          bannerData.media.mobile
        ) {
          response = await apiBanner.put(bannerData._id, bannerData);
          if (response) {
            navigate(-1);
          }
        } else {
          setRequiredError({
            mobile: bannerData.media.mobile === null,
            desktop: bannerData.media.desktop === null,
            name: !bannerData.name,
            url: !bannerData.url,
          });
        }
      }
      // await getAllBanners();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <GridContainer>
      <GridItem sm={12}>
        <Card>
          <CardHeader className="header">
            <h4>Add New Banner</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem sm={6}>
                <CustomInput
                  labelText="Name"
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    name: "name",
                    // disabled: disabled,
                    value: bannerData.name,
                    onChange: handleChange,
                  }}
                />
                {requiredError.name && (
                  <FormHelperText style={{ color: "red" }}>
                    Name is required
                  </FormHelperText>
                )}
              </GridItem>
              <GridItem sm={6}>
                <CustomInput
                  labelText="Url*"
                  //   id="company-disabled"
                  formControlProps={
                    {
                      // fullWidth: true,
                    }
                  }
                  inputProps={{
                    name: "url",
                    // disabled: disabled,
                    value: bannerData.url,
                    onChange: handleChange,
                  }}
                />
                {requiredError.url && (
                  <FormHelperText style={{ color: "red" }}>
                    URL is required
                  </FormHelperText>
                )}
              </GridItem>
              <GridItem xs={12} sm={6}>
                <UploadImage
                  // id={editMode ? "add new product media" : "Media-files"}
                  id="add-banner-mobile-image"
                  label="Mobile Image/Video *"
                  imageSize={500}
                  videoSize={5048}
                  useFileType={acceptFileType.BOTH}
                  selectedImage={bannerData.media?.mobile || ""}
                  editMode={mode === modeType.EDIT}
                  // handleUpdateImages={addImage(imageSize.MOBILE)}
                  handleFile={handleFileUpload(imageSize.MOBILE)}
                />
                {requiredError.mobile && (
                  <FormHelperText style={{ color: "red" }}>
                    Mobile banner image is required
                  </FormHelperText>
                )}
              </GridItem>
              <GridItem xs={12} sm={6}>
                <UploadImage
                  // id={editMode ? "add new product media" : "Media-files"}
                  id="add-banner-desktop-image"
                  label="Desktop Image/Video *"
                  imageSize={500}
                  videoSize={5048}
                  useFileType={acceptFileType.BOTH}
                  selectedImage={bannerData.media.desktop || ""}
                  editMode={mode === modeType.EDIT}
                  // handleUpdateImages={addImage(imageSize.DESKTOP)}
                  handleFile={handleFileUpload(imageSize.DESKTOP)}
                />
                {requiredError.desktop && (
                  <FormHelperText style={{ color: "red" }}>
                    Desktop banner image is required
                  </FormHelperText>
                )}
              </GridItem>
            </GridContainer>

            <hr />
            <GridContainer style={{ justifyContent: "flex-end" }}>
              <GridItem>
                <Button color="grey" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                &nbsp;
                <Button color="success" onClick={handleSave}>
                  Save
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AddOrEditModal;
