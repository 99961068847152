import React, { useCallback, useEffect, useRef, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Modal from "@mui/material/Modal";
import Button from "components/CustomButtons/Button";
import { IPolicy } from "Interfaces/Api/Policy";
import { apiPolicy } from "Services/API/IndividualApis/Policy";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface AttachPolicyModalProps {
  onHide: Function;
  userId: string;
  show: boolean;
  handleChangeRole: Function;
  editMode: boolean;
}

const AttachPolicyModal = ({
  show,
  onHide,
  handleChangeRole,
  userId,
  editMode,
}: AttachPolicyModalProps) => {
  const [allPolicies, setAllPolicies] = useState<IPolicy[]>([]);
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  const [selectedPolicy, setSelectedPolicy] = useState<any>();
  const [selectedPolicyInput, setSelectedPolicyInput] = useState<string>();
  const Navigate = useNavigate();
  useEffect(() => {
    fetchAllPolicies();
  }, [extraQueryParam]);

  const fetchAllPolicies = async () => {
    try {
      const response = await apiPolicy.getAll(1, 50, extraQueryParam);
      setAllPolicies(response.data);
      // setShowPagination(response.pagination);
    } catch (err) {
      console.log(err);
    }
  };

  function handleSubmit() {
    handleChangeRole(selectedPolicy._id);
    Navigate(`/admin/users`);
    onHide();
  }
  return (
    <>
      <div style={{ width: "50%" }}>
        <Modal
          open={show}
          onClose={() => onHide()}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridContainer sm={5}>
            <Card>
              <GridItem>
                <CardBody>
                  <GridItem>
                    <h2 style={{ textAlign: "center" }}>Attach policy</h2>
                    <GridContainer
                      style={{ justifyContent: "center", paddingTop: "10px" }}
                    >
                      <GridItem xs={10}>
                        <Autocomplete
                          id="tags-outlined"
                          // disabled={couponProperties?.allowedProducts?.length > 0 ? true : disabled ? true : false}
                          options={allPolicies.map((option, index) => option)}
                          getOptionLabel={(option) => option.name}
                          onInputChange={(event, newValue) => {
                            setSelectedPolicyInput(newValue);
                          }}
                          onChange={(event, newValue) => {
                            setSelectedPolicy(newValue);
                          }}
                          disableClearable={true}
                          value={selectedPolicy}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField {...params} label="Select Policy" />
                          )}
                        />
                        <Typography
                          sx={{
                            textAlign: "right",
                            fontSize: "0.8rem",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            // Navigate(`/admin/policies/add?userId=${userId}`, {
                            //   state: { editMode },
                            // })
                            Navigate(`/admin/policies/add?userId=${userId}`, {
                              state: { editMode },
                            })
                          }
                        >
                          Create New
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{ justifyContent: "center", paddingTop: "10px" }}
                    >
                      <GridItem>
                        <Button color="gray" onClick={onHide}>
                          Cancel
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button color="success" onClick={handleSubmit}>
                          Submit
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </CardBody>
              </GridItem>
            </Card>
          </GridContainer>
        </Modal>
      </div>
    </>
  );
};

export default AttachPolicyModal;
