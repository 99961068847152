import { ICms } from "Interfaces/Api/cms/Banner";
import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import { ApiCore } from "../../Core";

const url = "cms";

const apiCms = new ApiCore<ICms>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

apiCms.GetDynamicContent = () => {
  return apiProvider.getAll(`${url}/dynamicContent`);
};
apiCms.AddHeaderNotification = (model) => {
  return apiProvider.post(`${url}/headerNotification`, model);
};
apiCms.AddFooterNotification = (model) => {
  return apiProvider.post(`${url}/footerNotification`, model);
};
export { apiCms };
