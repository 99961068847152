import { Button, Container, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import { StyledGrayButton, StyledDividerWithText } from "@Styled/Common";
// import { BrandImage } from "@Styled/Layout/header";
import { MouseEventHandler, ReactChild } from "react";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import logo from "assets/img/econut-logo.png";
export interface RegistrationLayoutProps {
  title: string;
  subTitle?: string;
  dividerText?: string;
  buttonText?: string;
  loginModal?: boolean;
  onButtonClicked?: MouseEventHandler<HTMLButtonElement>;
  children: ReactChild;
  otpSpinner?: Boolean;
}

export const StyledDividerWithText = styled("div")`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  color: #303c49;
  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
`;

export const StyledButton = styled(Button)`
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  opacity: 1;
  padding: 0.5rem 1rem;
  text-align: center;
  font-family: Gotham-Light !important;
  font-size: 1.1rem;
  cursor: pointer;
  &:hover {
    background: unset;
  }
  @media (max-width: 600px) {
    width: 100%;
    font-size: 0.9rem;
    paddig: 0.2rem 1rem;
  }
`;

export const StyledSmallButton = styled(StyledButton)`
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  width: unset !important;
  height: 2.2rem;
  &:hover {
    background: ${(props: any) =>
      props.sx?.background! || props.theme.palette.neutral.dark};
  }
`;

export const StyledGrayButton = styled(StyledButton)`
  &:hover {
    background: grey;
  }
`;
export const StyledGreenButton = styled(StyledButton)`
  background: green;
  color: green;
  &:hover {
    background: green;
  }
  &:disabled {
    color: green;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export default function RegistrationLayout({
  title,
  children,
  dividerText,
  buttonText,
  subTitle,
  loginModal,
  onButtonClicked,
  otpSpinner,
}: RegistrationLayoutProps) {
  const Navigate = useNavigate();

  return (
    <div
      style={{
        height: loginModal ? "" : "calc(100vh - 56px)",
        margin: loginModal ? "2rem" : "",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          // justifyContent="center"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          {/* <BrandImage
            sx={{ width: { xs: "7rem", md: "9rem", cursor: "pointer" } }}
            src="/static/images/econut-logo.png"
            onClick={() => router.push("/")}
          /> */}
          <img
            src={logo}
            // onClick={() => Navigate("/admin/user")}
          />
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: 2,
              padding: "1rem",
              backgroundColor: "#fff",
              border: "1px solid lightgray",
              borderRadius: "5px",
              marginTop: "1.2rem",
              marginBottom: "1rem",
              width: loginModal
                ? { xs: "100%" }
                : { xs: "100%", sm: "60%", md: "40%" },
            }}
          >
            <Box marginBottom="1rem">
              <Typography variant="subtitle1" textAlign="center">
                {title}
              </Typography>
              {subTitle && (
                <Typography
                  textAlign="center"
                  fontSize="0.8rem"
                  marginBottom="1rem"
                >
                  {subTitle}
                </Typography>
              )}
            </Box>
            {children}
          </Grid>
          {dividerText && (
            <Grid
              sx={{
                width: loginModal
                  ? { xs: "100%" }
                  : { xs: "100%", sm: "60%", md: "40%" },
              }}
            >
              <StyledDividerWithText>{dividerText}</StyledDividerWithText>
              {buttonText && !!onButtonClicked && (
                <StyledGrayButton
                  onClick={onButtonClicked}
                  sx={{
                    // margin: "1rem",
                    background: "lightgray",
                    color: "unset",
                    marginTop: "1rem",
                  }}
                  fullWidth
                  color="secondary"
                >
                  {otpSpinner ? <Spinner spinColor={true} /> : ""} {buttonText}
                </StyledGrayButton>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
}
