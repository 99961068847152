import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import classes from "./Orders.module.scss";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { IOrder, orderStatus } from "Interfaces/Api/Orders";
import { cancelOrderStatus, ICancelOrder } from "Interfaces/Api/CancelOrder";
import { Box, Typography } from "@mui/material";

export interface IGenralDetailsProps {
  details: IOrder;
  isAbandoned: boolean;
  showModal?: Function;
  isCancelledOrder?: boolean;
  cancelOrderRequestDetail?: ICancelOrder;
  handleCancelStatusChange?: Function;
  disabledCancelRequestStatus?: Boolean;
  cancelRequestStatus?: string;
  disableCancelOrderFields?: Boolean;
  handleOrderStautusChange?: Function;
}

const GenralDetails = ({
  details,
  isAbandoned,
  showModal,
  isCancelledOrder,
  handleCancelStatusChange,
  disabledCancelRequestStatus,
  cancelRequestStatus,
  cancelOrderRequestDetail,
  disableCancelOrderFields,
  handleOrderStautusChange,
}: IGenralDetailsProps) => {
  const [date, setDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [refunStatus, setRefundStatus] = useState<string>("");

  useEffect(() => {
    if (details) {
      const newDate1 = details.createdAt;
      const newDate = new Date(newDate1).toLocaleDateString();
      if (details.status === "CANCELLED") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      const newStatus = Object.keys(orderStatus).filter((val) => {
        if (details.status === val) {
          setStatus(val);
        }
      });
      if (details?.refundDetails?.refund_status) {
        setRefundStatus(details?.refundDetails.refund_status);
      }
      setDate(newDate);
      setUserName(details?.user?.name);
    }
  }, [details]);

  const handleStatusChange = (e) => {
    if (e.target.value === "CANCELLED") {
      showModal();
    } else {
      handleOrderStautusChange(e.target.value);
      setStatus(e.target.value);
    }
  };
  console.log(details);

  const handleCancelRequestStatusChange = (e) => {
    handleCancelStatusChange(e);
  };

  return (
    <div style={{ display: "flex" }}>
      <GridContainer>
        <GridItem>
          {isAbandoned ? (
            <h4 style={{ wordBreak: "break-all" }}>
              Abandoned {details?._id} details{" "}
            </h4>
          ) : (
            <h4 style={{ wordBreak: "break-all" }}>
              Order {details?._id} details{" "}
            </h4>
          )}
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.container}>
            <h4>General</h4>
          </div>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <CustomInput
            labelText="User"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: disableCancelOrderFields ? true : disabled,
              value: userName,
              type: "text",
              onChange: (e) => setUserName(e.target.value),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <CustomInput
            labelText="Date*"
            formControlProps={
              {
                // fullWidth: true,
              }
            }
            inputProps={{
              disabled: disableCancelOrderFields ? true : disabled,
              value: date,
              onChange: (e) => setDate(e.target.value),
            }}
          />
        </GridItem>
        {isAbandoned == false && (
          <GridItem xs={12} sm={4}>
            <CustomSelect
              labelText="Status*"
              // native
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              id="age-native-required"
              inputProps={{
                disabled: disableCancelOrderFields ? true : disabled,
                value: status,
                onChange: (e) => handleStatusChange(e),
                // onChange: (e) => setStatus(e.target.value),
                name: "Select Status",
                native: true,
              }}
            >
              <option>{""}</option>
              {Object.keys(orderStatus).map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </CustomSelect>
          </GridItem>
        )}
        {isCancelledOrder == true && (
          <GridItem xs={12} sm={4}>
            <CustomSelect
              labelText="Request status*"
              // native
              formControlProps={
                {
                  // fullWidth: true,
                }
              }
              id="age-native-required"
              inputProps={{
                disabled: disabledCancelRequestStatus,
                value: cancelRequestStatus,
                onChange: (e) => handleCancelRequestStatusChange(e),
                name: "Select Status",
                native: true,
              }}
            >
              {cancelOrderRequestDetail &&
              cancelOrderRequestDetail.status === "PENDING" ? (
                <>
                  {" "}
                  {Object.keys(cancelOrderStatus).map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </>
              ) : (
                <>
                  {" "}
                  {Object.keys(cancelOrderStatus).map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </>
              )}
            </CustomSelect>
          </GridItem>
        )}

        <>
          {" "}
          {details && details?.refundDetails && (
            <GridItem style={{ margin: "2rem 0" }}>
              {details && details?.refundDetails && (
                <Box>
                  <Typography>Refund status</Typography>
                  {Array.isArray(details?.refundDetails) ? (
                    details.refundDetails[0].refund_status == "SUCCESS"
                  ) : details?.refundDetails?.refund_status == "SUCCESS" ? (
                    <Typography color="primary">
                      {Array.isArray(details.refundDetails)
                        ? details.refundDetails[0].refund_status
                        : details.refundDetails.refund_status}
                    </Typography>
                  ) : (
                    <Typography color="#ff8400">
                      {Array.isArray(details.refundDetails)
                        ? details.refundDetails[0].refund_status
                        : details.refundDetails.refund_status}{" "}
                    </Typography>
                  )}
                </Box>
              )}

              {details && Array.isArray(details.refundDetails) && (
                <Box>
                  <Typography>
                    <Typography>Refund status</Typography>
                    {details.refundDetails[0].refund_status == "PENDING" ? (
                      <Typography color="#ff8400">
                        {details.refundDetails[0].refund_status}
                      </Typography>
                    ) : (
                      <Typography color="primary">
                        {details.refundDetails[0].refund_status}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              )}
            </GridItem>
          )}
        </>
        <GridItem style={{ margin: "2rem 0" }}>
          {details && details?.cancelNote && (
            <>
              <div>
                <span style={{ fontWeight: "bold" }}> Cancelation Reason:</span>
                {details?.cancelNote}
              </div>
            </>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Interceptor(GenralDetails);
