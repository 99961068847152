import { IInquiry } from "Interfaces/Api/Policy";
import { ApiCore } from "../../Core";
import { apiProvider } from "Services/API/Provider";

const url = "inquiry";
const apiInquiry = new ApiCore<IInquiry>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

apiInquiry.search = (name, page, perPage) => {
  return apiProvider.getAll(
    `${url}/search?name=${name}&page=${page}&perPage=${perPage}`
  );
};
export { apiInquiry };
