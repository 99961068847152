import { ICategory } from "Interfaces/Api/Category";
import { apiProvider } from "Services/API/Provider";
import { taskI } from "../../../../Interfaces";
import { ApiCore } from "../../Core";

const url = "category";
const auth = JSON.parse(localStorage.getItem("auth"));
// const token = auth.token
const Categories = new ApiCore<ICategory>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

Categories.UpdateCategoryImage = (id, model) => {
  return apiProvider.put(`${url}/updateCategoryImage`, id, model);
};
Categories.UpdateCategoryIcon = (id, model, queryParam = "") => {
  return apiProvider.put(`${url}/updateCategoryIcon`, id, model, queryParam);
};
Categories.UpdateCategoryBanner = (id, model) => {
  return apiProvider.put(`${url}/updateBannerImage`, id, model);
};
Categories.AddFilter = (id, model) => {
  return apiProvider.post(`${url}/${id}/filter`, model);
};
Categories.EditFilter = (name, id, model) => {
  return apiProvider.patch(`${url}/${id}/filter`, name, model);
};
Categories.DeleteFilter = (name, id) => {
  return apiProvider.remove(`${url}/${id}/filter`, name);
};
Categories.UpdateCategoryBanner = (id, model) => {
  return apiProvider.put(`${url}/updateBannerImage`, id, model);
};
Categories.search = (name, page, perPage) => {
  return apiProvider.getAll(
    `${url}/search?name=${name}&page=${page}&perPage=${perPage}`
  );
};
// Categories.getPagination = (page, perPage) => {
//   return apiProvider.getAll(`${url}?page=${page}&perPage=${perPage}`);
// };

export { Categories };
