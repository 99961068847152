import React, { useCallback, useEffect, useState } from "react";
import { Categories as categoriesApi } from "../../Services/API/IndividualApis/Category";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import CommonTable from "components/CommonTable/CommonTable";
import { usePagination } from "hooks/tablePaginationHook";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import AddOrEditCategory from "./AddOrEditCategory";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "components/CustomDeleteModal";
import { ICategory } from "Interfaces/Api/Category";
import { filterCols } from "Services/Helper";

export const options = [
  { label: "Equal", value: "$eq" },
  { label: "Greater Than", value: "$gt" },
  { label: "Greater Than Equal", value: "$gte" },
  { label: "Less Than", value: "$lt" },
  { label: "Less Than Equal", value: "$lte" },
];
export const displayOptions = [
  { label: "", value: "" },
  { label: "Color", value: "Color" },
  { label: "Select", value: "Select" },
];
const Categories = () => {
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [allParentCategory, setAllParentCategory] = useState<ICategory[]>([]);
  const [allPaginationCategories, setAllPaginationCategories] = useState([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showAddButton, setShowAddButton] = useState<boolean>(true);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [allItems, setItem] = useState<string>("");
  const [itemName, setItemName] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, handleChangePage] = usePagination();
  const [searchText, setSearchText] = useState<string>(undefined);
  const [extraQueryParam, setExtraQueryParam] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation() as any;
  const Navigate = useNavigate();

  const handleEditCategory = async (item) => {
    Navigate(`/admin/categories/${item._id}`);
    // await fetchAllCategories();
    setEditMode(true);
  };

  const rowsData = allPaginationCategories?.map((item) => {
    return {
      name: item?.name,
      parent: item?.parent,
      icon: item?.icon?.url,
      image: item?.image?.url,
      id: item?._id,
      actions: item,
    };
  });
  const cols = React.useMemo(
    () => [
      { field: "name", headerName: "Name", width: 250 },
      {
        field: "parent",
        headerName: "Parent",
        width: 280,
        filterable: true,
        isValueSelectable: true,
        nameKey: "parent",
        options: [...allParentCategory],
        operator: [
          { label: "Equal", value: "$eq" },
          { label: "In", value: "$in" },
        ],
      },
      {
        field: "icon",
        headerName: "Icon",
        width: 200,
        renderCell: (params) => (
          <div>
            {params.value ? (
              <img src={params.value} style={{ width: "90px" }} />
            ) : (
              "  - - "
            )}
          </div>
        ),
      },
      {
        field: "image",
        headerName: "Image",
        width: 200,
        renderCell: (params) => (
          <div>
            <img src={params.value} style={{ width: "90px" }} />
          </div>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params) => {
          return (
            <>
              {state?.access.write && (
                <EditIcon
                  onClick={() => handleEditCategory(params.value)}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              )}
              {state?.access.delete && (
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setItem(params.value._id);
                    setItemName(params.value.name);
                  }}
                />
              )}
            </>
          );
        },
      },
    ],
    [allCategories, state, allParentCategory]
  );

  filterCols(state, cols);

  useEffect(() => {
    fetchAllCategories();
  }, [extraQueryParam, page, rowsPerPage]);

  useEffect(() => {
    if (searchText != undefined) {
      const timeout = setTimeout(handleSearch, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [page, searchText, rowsPerPage]);

  const handleSearch = async () => {
    if (searchText == "") {
      await fetchAllCategories();
    } else {
      try {
        const response = await categoriesApi.search(
          searchText,
          page,
          rowsPerPage
        );
        setAllPaginationCategories(response.data);
        setTotalCount(response.pagination.totalCount);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchAllParentCategories();
  }, []);

  const fetchAllParentCategories = async () => {
    try {
      const response = await categoriesApi.getAll(1, 100);
      setAllCategories(response.data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    let allParentCategories = allCategories.filter(
      (element, index) =>
        index ===
        allCategories.findIndex(
          (item) =>
            item.parent === element.parent && item.parent === element.parent
        )
    );
    setAllParentCategory(allParentCategories);
  }, [allCategories]);

  const fetchAllCategories = async () => {
    setLoading(true);
    try {
      const response = await categoriesApi.getAll(
        page,
        rowsPerPage,
        extraQueryParam
      );
      setLoading(false);
      setAllPaginationCategories(response.data);
      setTotalCount(response.pagination.totalCount);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const handleFilter = (filterRows, linkedOperator, clearFilter = false) => {
    if (clearFilter) {
      setExtraQueryParam("");
      return;
    }
    const filterArr = [];
    filterRows.forEach((element) => {
      let value = element.value;
      if (value instanceof Array) {
        value = value.filter((item) => item !== "");
      }
      const obj = {
        [element.name]: {
          [element.operator]: value,
        },
      };
      filterArr.push(obj);
    });
    const filterObj = {
      [linkedOperator]: filterArr,
    };
    let queryParam = `&filter=${JSON.stringify(filterObj)}`;

    setExtraQueryParam(queryParam);
  };

  const addItems = () => {
    setAddMode((prev) => !prev);
  };
  const removeItems = async (item) => {
    try {
      const response = await categoriesApi.remove(item);
      fetchAllCategories();
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {showDeleteModal ? (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteItem={() => removeItems(allItems)}
          itemName={itemName}
        />
      ) : (
        ""
      )}
      {addMode || editMode ? (
        <>
          <AddOrEditCategory />
        </>
      ) : (
        <CommonTable
          linkUrl={
            editMode ? "/admin/categories/: id" : "/admin/categories/add"
          }
          totalRows={totalCount}
          Title="Categories"
          button="Add Category"
          page={page}
          handleChangePage={handleChangePage}
          addItems={addItems}
          handleSearchTextChanged={(value) => setSearchText(value)}
          searchText={searchText}
          searchPlaceHolder="Search by category"
          rowsPerPage={rowsPerPage}
          setRowsPerPage={(value) => setRowsPerPage(value)}
          rows={rowsData}
          columns={cols}
          handleFilter={handleFilter}
          showAddButton={state?.access.write}
          loading={loading}
        />
      )}
    </>
  );
};

export default Interceptor(Categories);
