import { CircularProgress } from "@mui/material";

function Loader() {
  return (
    <div
      style={{
        position: "absolute",
        top: "30%",
        left: "55%",
        opacity: "1",
        padding: "0px 1.2rem",
      }}
    >
      {<CircularProgress size="20px" style={{ color: "#fff" }} />}
    </div>
  );
}

export default Loader;
