import React, { useEffect, useState } from "react";

// core components

import Admin from "./layouts/Admin.js";
import SignIn from "./layouts/signIn/SignIn";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import * as createActions from "./store/actions";

// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Users from "./views/Users";
import appRoutes from "routes.js";
import Products from "./views/Products/AddorEditProducts";
import Categories from "./views/Categories";
import AddOrEditCategory from "./views/Categories/AddOrEditCategory";
import CanceledOrderList from "./views/Cancelled-Order";
import CancelledOrderDetail from "./views/Cancelled-Order/CancelledOrderDetail";
import Attributes from "./views/Attributes";
import AddOrEditAttribute from "./views/Attributes/AddOrEditAttribute";
import UserDetails from "./views/Users/userDetails";
import Coupons from "./views/Coupons/index";
import AddOrEditCoupon from "./views/Coupons/AddOrEditCoupon";
import Orders from "./views/Orders";
import OrderDetails from "./views/Orders/OrderDetails";
import Product from "./views/Products";
import Abandoned from "./views/Abandoned";
import AbandonedDetails from "./views/Abandoned/AbandonedDetails";
import { useLocation } from "react-router-dom";
import { Roles } from "Interfaces/Api/User";
import AddOrEditPolicy from "views/Policy/AddOrEditPolicyPage";
import AddOrEditPolicyPage from "views/Policy/AddOrEditPolicyPage";
import { useAuthorization } from "hooks/autorizationHook";
import {
  IPolicy,
  IPolicyAccessMethodOptions,
  IPolicyModuleOptions,
} from "Interfaces/Api/Policy";
import AddOrEditModal from "views/DynamicContent/Banner/AddOrEditModal";
import { Authorize } from "store/helper";
import { Alert, Snackbar } from "@mui/material";
declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const App = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  useEffect(() => {
    props.checkAuthStatus().then(setTimeout(() => setLoading(false), 1000));
  }, []);

  const isProductAccessAllowed = useAuthorization(IPolicyModuleOptions.PRODUCT);
  const isOrderAccessAllowed = useAuthorization(IPolicyModuleOptions.ORDER);
  const userDetails = useSelector((state: any) => state.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    if (userDetails && userDetails.role === Roles.USER) {
      dispatch(createActions.logout());
    }
  }, [userDetails]);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress color="success" />
        {/* <Switch>
          <Route path="/signin" component={SignIn} />
          <Redirect to="/signIn" />
        </Switch> */}
      </div>
    );
  }

  const getFirstModuleAndPath = () => {
    const firstModule = userDetails?.policy?.statements[0].target;
    if (!firstModule) {
      return {
        firstModule: IPolicyModuleOptions.USER,
        path: "/admin/users",
      };
    }
    const lowerCaseName = firstModule.toLowerCase();
    let path = `/admin/${lowerCaseName}s`;
    if (firstModule === IPolicyModuleOptions.ORDER) {
      path = "/admin/orders/list";
    }
    return {
      path,
      firstModule,
    };
  };
  const { path, firstModule } = getFirstModuleAndPath();
  let accessdata = null;
  if (userDetails) {
    if (userDetails.role === Roles.SUPER_ADMIN) {
      accessdata = {
        [IPolicyAccessMethodOptions.READ]: true,
        [IPolicyAccessMethodOptions.WRITE]: true,
        [IPolicyAccessMethodOptions.DELETE]: true,
      };
    } else {
      accessdata = Authorize(userDetails.policy, firstModule);
    }
  }
  return (
    // <div>

    <div>
      {userDetails && userDetails?.shiprocketWalletBalance <= 100 ? (
        <Alert
          variant="filled"
          severity="error"
          style={{
            position: "fixed",
            top: "5px",
            right: "15px",
            zIndex: "10000",
            textTransform: "capitalize",
          }}
        >
          shiprocket wallet balance is insufficient.please recharge.
        </Alert>
      ) : (
        ""
      )}

      <div>
        {!loading && (
          <>
            <Routes>
              <Route path="/admin" element={<Admin />}>
                {appRoutes.map((item) => {
                  if (item.subMenu) {
                    return item.subMenu.map((subItem) => (
                      <Route
                        path={`/admin/${item.path}/${subItem.path}`}
                        element={<subItem.component />}
                      />
                    ));
                  } else
                    return (
                      <Route
                        path={`/admin/${item.path}`}
                        element={<item.component />}
                      />
                    );
                })}

                <Route
                  path="/admin/categories/add"
                  element={<AddOrEditCategory />}
                />

                <Route
                  path="/admin/categories/:id"
                  element={<AddOrEditCategory />}
                />

                <Route path="/admin/products/:id" element={<Products />} />
                <Route path="/admin/products/add" element={<Products />} />
                <Route path="/admin/product" element={<Product />} />

                <Route
                  path="/admin/orders/cancelRequests/:id"
                  element={<CancelledOrderDetail />}
                />

                <Route
                  path="/admin/attributes/add"
                  element={<AddOrEditAttribute />}
                />
                <Route
                  path="/admin/attributes/:id"
                  element={<AddOrEditAttribute />}
                />

                <Route path="/admin/users/:id" element={<UserDetails />} />

                <Route
                  path="/admin/coupons/add"
                  element={<AddOrEditCoupon />}
                />
                <Route
                  path="/admin/coupons/:id"
                  element={<AddOrEditCoupon />}
                />

                <Route
                  path="/admin/orders/list/:id"
                  element={<OrderDetails />}
                />

                <Route
                  path="/admin/orders/cart_abandoned"
                  element={<Abandoned />}
                />
                <Route
                  path="/admin/orders/cart_abandoned/:id"
                  element={<AbandonedDetails />}
                />

                {/* <AppRoutes /> */}
                <Route
                  path="/admin/policies/add"
                  element={<AddOrEditPolicyPage />}
                />
                <Route
                  path="/admin/policies/:id"
                  element={<AddOrEditPolicyPage />}
                />

                {/* Dynamic content */}
                <Route
                  path="/admin/dynamic-content/add"
                  element={<AddOrEditModal />}
                />
                <Route
                  path="/admin/dynamic-content/:id"
                  element={<AddOrEditModal />}
                />
              </Route>
              {/* <Route path="/signin" component={SignIn} /> */}
              {/* <Route path="/rtl" element={RTL} /> */}
              {/* <Navigate state={ } from="/" to="/admin/dashboard" /> */}
              <Route
                path="/"
                element={<Navigate to={path} state={{ access: accessdata }} />}
              />
              <Route path="/signIn" element={<SignIn />} />
            </Routes>
            {!props.isAuthenticated && location.pathname !== "/signIn" && (
              <Navigate to="/signIn" state={{ aaaa: "true" }} />
            )}
          </>
        )}
      </div>
    </div>

    // </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isLoggedIn,
    isError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthStatus: () => dispatch(createActions.checkAuthStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
