import React, { useEffect, useState } from "react";
import { orders } from "Services/API/IndividualApis/Orders";
import { Card } from "react-bootstrap";
import GridContainer from "components/Grid/GridContainer";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import Interceptor from "Services/hoc/Interceptor/Interceptor";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Grid from "components/Grid/Grid";
import ShippingAddress from "views/Orders/ShippingAddress";
import Modal from "@mui/material/Modal";
import { cancelorder } from "Services/API/IndividualApis/CancelOrders/indes";
import CustomProductDetailsTable from "components/CustomProductDetailTable/CustomProductDetailsTable";
import { IAddress } from "Interfaces/Api/User";
import { IOrder } from "Interfaces/Api/Orders";
import GenralDetails from "views/Orders/GenralDetails";
import CancelRequestStatusModal from "./CancelRequestStatusModal";
import { cancelOrderStatus } from "Interfaces/Api/CancelOrder";
import { stat } from "fs";
import { Box, Typography } from "@mui/material";

const steps = [
  "Order Received",
  "Order Picked",
  "Order In Transit",
  "Out for delivery",
  "Reached Destination",
  "Order Received",
  "Order Picked",
  "Order In Transit",
  "Out for delivery",
  "Reached Destination",
];

const CancelOrderDetails = () => {
  const [date, setDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [disabledCancelRequestStatus, setDisabledCancelRequestStatus] =
    useState<boolean>(true);
  const [rejectState, setRejectState] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<IAddress>();
  const [orderDetails, setOrderDetails] = useState<IOrder>();
  const [cancelOrderRequestDetail, setCancelOrderRequestDetail] =
    useState<any>("");
  const [cancelRequestStatus, setCancelRequestStatus] = useState<string>("");

  const Navigate = useNavigate();

  const onCancel = () => {
    Navigate("/admin/orders/cancelRequests");
  };

  useEffect(() => {
    handleOrderDetails(id);
  }, []);

  const handleOrderDetails = async (id: string) => {
    try {
      const response = await orders.getSingle(id);
      setOrderDetails(response);
    } catch (err) {
      console.log(err);
    }
  };

  const StatusData = [
    { id: "1", name: "PROCESSING" },
    { id: "2", name: "SHIPPED" },
    { id: "3", name: "PENDING_PAYMENT" },
    { id: "4", name: "COMPLETED" },
    { id: "2", name: "CANCELLED" },
    { id: "6", name: "FAILED" },
  ];

  useEffect(() => {
    if (orderDetails && cancelOrderRequestDetail) {
      const newDate1 = orderDetails.createdAt;
      setStatus(orderDetails?.status);
      const newDate = new Date(newDate1).toLocaleDateString();
      setCancelRequestStatus(cancelOrderRequestDetail.status);
      if (
        cancelOrderRequestDetail.status === "APPROVED" ||
        cancelOrderRequestDetail.status === "REJECTED"
      ) {
        setDisabledCancelRequestStatus(true);
      } else {
        setDisabledCancelRequestStatus(false);
      }
      setDate(newDate);
    }
  }, [orderDetails, cancelOrderRequestDetail]);

  const handleCancelStatusChange = (e: { target: { value: string } }) => {
    if (e.target.value === "REJECT") {
      setRejectState(true);
    }
    setOpenModal(true);
  };

  const onSaveOrderDetails = (obj: React.SetStateAction<IAddress>) => {
    setShippingAddress(obj);
  };

  const { state } = useLocation();

  useEffect(() => {
    if (state !== "") {
      setCancelOrderRequestDetail(state);
    }
  }, [state]);

  const { id } = useParams();

  const onSubmitCancelRequestStatus = async () => {
    if (rejectState) {
      let obj = {
        status: "REJECTED",
      };
      await handleOrderStatusChange(cancelOrderRequestDetail._id, obj);
    } else {
      let obj = {
        status: "APPROVED",
      };
      await handleOrderStatusChange(cancelOrderRequestDetail._id, obj);
    }
  };

  const handleOrderStatusChange = async (id, obj) => {
    try {
      const response = await cancelorder.patch(id, obj);
      setCancelRequestStatus(obj.status);
      setDisabledCancelRequestStatus(true);
      setOpenModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <GridContainer>
        <Grid xs={12}>
          <Card>
            <CardHeader className="header">
              <h4>Edit cancel request</h4>
            </CardHeader>
            <CardBody>
              <div style={{ display: "flex" }}>
                <GridItem xs={12}>
                  <GenralDetails
                    details={orderDetails}
                    isAbandoned={false}
                    cancelOrderRequestDetail={cancelOrderRequestDetail}
                    handleCancelStatusChange={handleCancelStatusChange}
                    disabledCancelRequestStatus={disabledCancelRequestStatus}
                    cancelRequestStatus={cancelRequestStatus}
                    disableCancelOrderFields={disabled}
                    isCancelledOrder={true}
                  />

                  <GridContainer>
                    <GridItem style={{ margin: "2rem 0" }}>
                      {orderDetails?.refundDetails && (
                        <Box>
                          <Typography>Refund status</Typography>
                          {Array.isArray(orderDetails.refundDetails) ? (
                            orderDetails.refundDetails[0].refund_status ==
                            "SUCCESS"
                          ) : orderDetails?.refundDetails?.refund_status ==
                            "SUCCESS" ? (
                            <Typography color="primary">
                              {Array.isArray(orderDetails.refundDetails)
                                ? orderDetails.refundDetails[0].refund_status
                                : orderDetails.refundDetails.refund_status}
                            </Typography>
                          ) : (
                            <Typography color="#ff8400">
                              {Array.isArray(orderDetails.refundDetails)
                                ? orderDetails.refundDetails[0].refund_status
                                : orderDetails.refundDetails.refund_status}{" "}
                            </Typography>
                          )}
                        </Box>
                      )}

                      {Array.isArray(orderDetails.refundDetails) && (
                        <Box>
                          <Typography>
                            <Typography>Refund status</Typography>
                            {orderDetails.refundDetails[0].refund_status ==
                            "PENDING" ? (
                              <Typography color="#ff8400">
                                {orderDetails.refundDetails[0].refund_status}
                              </Typography>
                            ) : (
                              <Typography color="primary">
                                {orderDetails.refundDetails[0].refund_status}
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                      )}
                      {orderDetails?.refundDetails?.refund_status ? (
                        <Box>
                          {orderDetails?.refundDetails?.refund_status ==
                          "SUCCESS" ? (
                            <Typography style={{ fontWeight: "bold" }}>
                              Refund Status:{" "}
                              <span style={{ color: "green" }}>
                                {orderDetails?.refundDetails?.refund_status}
                              </span>
                            </Typography>
                          ) : (
                            <Typography style={{ fontWeight: "bold" }}>
                              Refund Status:{" "}
                              <span style={{ color: "red" }}>
                                {orderDetails?.refundDetails?.refund_status}
                              </span>
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        ""
                      )}
                    </GridItem>
                    <GridItem style={{ margin: "2rem 0" }}>
                      {orderDetails && orderDetails?.cancelNote && (
                        <>
                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              Cancelation Reason:
                            </span>
                            {orderDetails?.cancelNote}
                          </div>
                        </>
                      )}
                    </GridItem>
                  </GridContainer>

                  <ShippingAddress
                    orderDetails={orderDetails}
                    onSaveOrderDetails={onSaveOrderDetails}
                  />

                  {orderDetails && (
                    <CustomProductDetailsTable orderDetails={orderDetails} />
                  )}
                </GridItem>
              </div>
              <GridContainer
                style={{
                  justifyContent: "end",
                  paddingTop: "4rem",
                  paddingRight: "2rem",
                  paddingBottom: "2rem",
                }}
              >
                <div style={{ marginRight: "1rem" }}>
                  <Button color="gray" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                </div>
              </GridContainer>
            </CardBody>
          </Card>
        </Grid>
      </GridContainer>
      {openModal ? (
        <CancelRequestStatusModal
          onSubmitCancelRequestStatus={onSubmitCancelRequestStatus}
          openModal={openModal}
          onHide={() => setOpenModal(false)}
          rejectState={rejectState}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default Interceptor(CancelOrderDetails);
