import { Modal } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import UploadImage from "components/CustomUpload/UploadImage";
import { useEffect, useState } from "react";

function ImageModal({
  toggleModal,
  imageSize,
  videoSize,
  fileType,
  closeModal,
  mediaFile,
  handleMediaUpload,
  previousFile,
}: {
  toggleModal: boolean;
  imageSize?: number;
  videoSize?: number;
  closeModal: Function;
  fileType?: string;
  mediaFile: any;
  previousFile: any;
  handleMediaUpload: Function;
}) {
  const [image, setImage] = useState<any>("");

  useEffect(() => {
    if (mediaFile) setImage(mediaFile);
  }, [mediaFile]);

  const toBase64 = async (fileData) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSaveMedia = () => {
    handleMediaUpload(image, previousFile);
    closeModal();
  };

  const checkFileSize = (file) => {
    if (file && "fileLimitExceed" in file) {
      if (file.fileLimitExceed) return true;
    } else {
      return false;
    }
  };

  const handleUpdate = (acceptedFiles) => {
    setImage(acceptedFiles);
  };
  return (
    <Modal
      open={toggleModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GridContainer>
        <Card>
          <CardHeader className="header">
            <h4>Edit Media</h4>
          </CardHeader>
          <CardBody>
            <GridContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "50vw",
              }}
            >
              <GridItem>
                <UploadImage
                  id={image._id}
                  label=""
                  renderSelf={false}
                  // multiple={true}
                  imageSize={imageSize}
                  videoSize={videoSize}
                  useFileType={fileType}
                  fileUpload={mediaFile}
                  addImageOrImages={() => console.log("add image")}
                  selectedImage={image}
                  editMode={true}
                  deleteImage={mediaFile}
                  handleUpdateImages={handleUpdate}
                  isEditImageModalOpen={true}
                />
              </GridItem>
            </GridContainer>
            <hr />
            <GridContainer style={{ justifyContent: "flex-end" }}>
              <GridItem>
                <Button color="gray" onClick={() => closeModal()}>
                  Close
                </Button>
              </GridItem>
              <GridItem>
                {" "}
                <Button
                  color="success"
                  onClick={handleSaveMedia}
                  disabled={image.fileLimitExceed}
                >
                  Save
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </Modal>
  );
}

export default ImageModal;
