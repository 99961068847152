import { IOrder } from "Interfaces/Api/Orders";
import { axios } from "Services/API/Axios";
import { apiProvider } from "Services/API/Provider";
import { handleError, handleResponse } from "Services/API/Response";
import { ApiCore } from "../../Core";

const url = "order";
const orders = new ApiCore<IOrder>({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  // token:token
});

orders.search = (text, page, perPage) => {
  return apiProvider.getAll(
    `${url}/search?text=${text}&page=${page}&perPage=${perPage}`
  );
};
orders.placeOrderOnShiprocket = (model) => {
  return apiProvider.post(`${url}/placeOrder`, model);
};
orders.getTrackDetailsById = (id) => {
  return apiProvider.getSingle(`${url}/track`, id);
};
export { orders };
