import {
  IPolicy,
  IPolicyAccessMethodOptions,
  IPolicyModuleOptions,
} from "Interfaces/Api/Policy";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const useAuthorization = (
  module: IPolicyModuleOptions,
  accessType?: IPolicyAccessMethodOptions
): boolean => {
  const [isAccessAllowed, setIsAccessAllowed] = React.useState<boolean>(false);
  const policy = useSelector(
    (state: any) => state.auth.user?.policy
  ) as IPolicy;

  useEffect(() => {
    if (policy && module) {
      const statements = policy.statements;
      const idx = statements.findIndex((item) => item.target === module);
      if (idx !== -1) {
        // module allowed
        if (!accessType) {
          // Just checking for module
          setIsAccessAllowed(true);
        } else if (statements[idx].access[accessType]) {
          // module allowed and accesstype both allowed
          setIsAccessAllowed(true);
        }
      }
    }
  }, [policy, accessType, module]);

  return isAccessAllowed;
};
